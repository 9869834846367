<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-text-field
          v-model="placa_search"
          label="Placa"
          outlined
          dense
          hide-details
          @input="listarVehiculos()"
        >
        </v-text-field>
      </v-col>
      <v-col cols="auto" align-self="center">
        <v-progress-circular
          v-if="loading_vehiculo"
          indeterminate
          color="primary"
          size="20"
        ></v-progress-circular>
      </v-col>
      <v-col cols="12" v-for="(item, i) in result_data" :key="i">
        <v-lazy
          min-height="50"
          :options="{
            threshold: 0.5,
          }"
          transition="scale-transition"
        >
          <v-card class="my-2" elevation="2">
            <v-card-title class="pb-0"
              >POLIZA: {{ item.num_poliza }} <br />
              ASEGURADO: {{ item.nombre_asegurado }}</v-card-title
            >
            <!-- CARACTERISTICAS DEL VEHICULO -->
            <v-card-text class="pa-1">
              <v-row>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.marca"
                    label="Marcar"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.modelo"
                    label="Modelo"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.año"
                    label="Año"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.placa"
                    label="Placa"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.chasis"
                    label="N° Chasis"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.motor"
                    label="N° Motor"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.version"
                    label="Versión"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.tipo_vehiculo"
                    label="Tipo vehículo"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.vencimiento_tarjeta"
                    label="Vencimiento tarjeta"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.vencimiento_dui"
                    label="Vencimiento dui"
                    filled
                    dense
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.cesion"
                    label="Cesión"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    v-model="item.monto_cesion"
                    prefix="$"
                    label="Monto cesión"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    v-model="item.beneficiario_cesion"
                    label="Beneficiario cesión"
                    filled
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <!-- TABLA DE ULTIMA CUOTA PAGADA -->
            <v-card-actions>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="header_cuota"
                    :items="item.cuota"
                    hide-default-footer
                    dense
                  >
                    <template v-slot:top>
                      <v-toolbar dense elevation="0">
                        <v-toolbar-title>Ultima Cuota Pagada</v-toolbar-title>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.fecha`]="{ item }">
                      <span>{{ formatoFecha(item.fecha) }}</span>
                    </template>
                    <template v-slot:[`item.monto`]="{ item }">
                      <span> ${{ item.monto }}</span>
                    </template>
                    <template v-slot:[`item.fecha_pago`]="{ item }">
                      <span>{{ formatoFecha(item.fecha_pago) }}</span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-lazy>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data: () => ({
    items_vehiculos: [],
    placa_search: "",
    marca: "",
    modelo: "",
    año: "",
    placa: "",
    n_chasis: "",
    n_motor: "",
    version: "",
    tipo_vehiculo: "",
    vencimiento_tarjeta: "",
    vencimiento_dui: "",
    cesion: "",
    monto_cesion: "",
    beneficiario_cesion: "",
    loading_vehiculo: false,
    result_data: [],
    header_cuota: [
      {
        text: "Fecha",
        value: "fecha",
        sortable: false,
        width: 110,
        align: "center",
      },
      {
        text: "Monto",
        value: "monto",
        sortable: false,
        width: 110,
        align: "center",
      },
      {
        text: "Fecha Pago",
        value: "fecha_pago",
        sortable: false,
        width: 110,
        align: "center",
      },
      {
        text: "Factura",
        value: "factura",
        sortable: false,
        width: 110,
        align: "center",
      },
    ],
  }),
  methods: {
    listarVehiculos() {
      if (this.placa_search.length < 3) {
        return;
      }

      this.loading_vehiculo = true;
      axios
        .get("api/Polizas/BusquedaPorPlaca/" + this.placa_search)
        .then((response) => {
          //console.log("Caracteristicas", response.data);
          this.items_vehiculos = response.data;
          this.loading_vehiculo = false;
          this.listarUltimaCuotaPagada();
        })
        .catch(() => {
          this.loading_vehiculo = false;
        });
    },
    async listarUltimaCuotaPagada() {
      for (var i = 0; i < this.items_vehiculos.length; i++) {
        var item = [];
        await axios
          .get(
            "api/Cuotas/ListarUltimaPagada/" +
              this.items_vehiculos[i].id_polizas
          )
          .then((response) => {
            if (response.data != "") {
              item.push(response.data);
              this.items_vehiculos[i].cuota = item;
              this.items_vehiculos[i].vencimiento_dui = this.formatoFecha(
                this.items_vehiculos[i].vencimiento_dui
              );
              this.items_vehiculos[i].vencimiento_tarjeta = this.formatoFecha(
                this.items_vehiculos[i].vencimiento_tarjeta
              );
            } else {
              this.items_vehiculos[i].cuota = [];
              this.items_vehiculos[i].vencimiento_dui = this.formatoFecha(
                this.items_vehiculos[i].vencimiento_dui
              );
              this.items_vehiculos[i].vencimiento_tarjeta = this.formatoFecha(
                this.items_vehiculos[i].vencimiento_tarjeta
              );
            }
          });
      }
      this.result_data = this.items_vehiculos;
    },
    formatoFecha(fecha) {
      if (fecha) {
        return moment(fecha).format("YYYY-MM-DD");
      } else {
        return null;
      }
    },
  },
};
</script>