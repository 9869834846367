import Vue from 'vue'
import VueRouter from 'vue-router'
import Bienvenida from '../components/Bienvenida'
import Usuarios from "../components/Accesos/Usuarios"
import Roles from "../components/Accesos/Roles"
import CambioContraseña from "../components/Seguridad/CambioContraseña"
import InicioSesion from "../components/Accesos/InicioSesion"
import Aseguradoras from "../components/Catalogos/Aseguradoras"
import TipoCuotas from "../components/Catalogos/TipoCuotas"
import CategoriaSeguros from "../components/Catalogos/CategoriaSeguros"
import RamoSeguros from "../components/Catalogos/RamoSeguros"
import TipoReclamos from "../components/Catalogos/TipoReclamos"
import TipoGestiones from "../components/Catalogos/TipoGestiones"
import TipoSeguros from "../components/Catalogos/TipoSeguros"
import TipoDocumentos from "../components/Catalogos/TipoDocumentos"
import Seguros from "../components/Catalogos/Seguros"
import Clientes from "../components/Catalogos/Clientes"
import Polizas from "../components/Polizas/Poliza"
import Departamentos from "../components/Catalogos/Departamentos"
import Municipios from "../components/Catalogos/Municipios"
import OneDriveLogueado from "../components/OneDriveLogueado"
import Dashboard from "../components/Dashboard"
import EstadoCuenta from "../components/Reportes/EstadoCuenta"
import ReporteProximaVencer from "../components/Reportes/ProximosVencer"
import Permisos from "../components/Accesos/Permisos"
import Planes from "../components/Catalogos/Planes"
import BeneficiariosCesion from "../components/Catalogos/BeneficiariosCesion"
import Busqueda from "../components/Polizas/Busqueda.vue"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'bienvenida',
      component: Bienvenida
    },
    {
      path: '/busqueda',
      name: 'busqueda',
      component: Busqueda
    },
    {
      path: '/beneficiarioCesion',
      name: 'beneficiarioCesion',
      component: BeneficiariosCesion
    },
    {
      path: '/planes',
      name: 'planes',
      component: Planes
    },
    {
      path: '/cambioPass',
      name: 'cambioPass',
      component: CambioContraseña
    },
    {
      path: '/proximosVencer',
      name: 'proximosVencer',
      component: ReporteProximaVencer
    },
    {
      path: '/estadoCuenta',
      name: 'estadoCuenta',
      component: EstadoCuenta
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard
    },
    {
      path: '/logueadoOneDrive',
      name: 'logueadoOneDrive',
      component: OneDriveLogueado
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: Usuarios
    },
    {
      path: '/roles',
      name: 'roles',
      component: Roles
    },
    {
      path: '/permisos',
      name: 'permisos',
      component: Permisos
    },
    {
      path: '/inicioSesion',
      name: 'inicioSesion',
      component: InicioSesion
    },
    {
      path: '/aseguradoras',
      name: 'aseguradoras',
      component: Aseguradoras
    },
    {
      path: '/tipoCuotas',
      name: 'tipoCuotas',
      component: TipoCuotas
    },
    {
      path: '/categoriaSeguros',
      name: 'categoriaSeguros',
      component: CategoriaSeguros
    },
    {
      path: '/ramoSeguros',
      name: 'ramoSeguros',
      component: RamoSeguros
    },
    {
      path: '/tipoReclamos',
      name: 'tipoReclamos',
      component: TipoReclamos
    },
    {
      path: '/tipoGestiones',
      name: 'tipoGestiones',
      component: TipoGestiones
    },
    {
      path: '/tipoSeguros',
      name: 'tipoSeguros',
      component: TipoSeguros
    },
    {
      path: '/tipoDocumentos',
      name: 'tipoDocumentos',
      component: TipoDocumentos
    },
    {
      path: '/departamentos',
      name: 'departamentos',
      component: Departamentos
    },
    {
      path: '/municipios',
      name: 'municipios',
      component: Municipios
    },
    {
      path: '/seguros',
      name: 'seguros',
      component: Seguros
    },
    {
      path: '/clientes',
      name: 'clientes',
      component: Clientes
    },
    {
      path: '/polizas/:id',
      name: 'polizas',
      component: Polizas
    },
  ]
})

router.beforeEach((to, from, next) => {
  const lackRole = to.matched.some(route => {
    return route.meta.roles && !route.meta.roles.include(store.state.usuario.rol)
  })
  if(lackRole){
    return next(false);
  }
  next();
});

export default router
