<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-3 ma-3"
        :search="search"
        :loading="loading_polizas"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  class="ml-3"
                  v-on="on"
                  @click="listar()"
                >
                  <i class="fas fa-sync-alt fa-2x"></i>
                </v-btn>
              </template>
              <span>Restablecer datos</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-row justify="center">
              <v-col cols="4">
                <v-autocomplete
                  v-model="tipo_buscador"
                  :items="[
                    'Buscar todo',
                    'Buscar por placa',
                    'Buscar por cliente',
                  ]"
                  label="Filtrar por"
                  auto-select-first
                  outlined
                  dense
                  hide-details
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="search"
                  v-if="tipo_buscador == 'Buscar todo'"
                  label="Texto"
                  single-line
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-text-field
                  v-model="search_placa"
                  v-if="tipo_buscador == 'Buscar por placa'"
                  label="Placa"
                  single-line
                  outlined
                  dense
                  hide-details
                  @input="ListarPolizasPorPlaca()"
                ></v-text-field>
                <v-text-field
                  v-model="search_cliente"
                  v-if="tipo_buscador == 'Buscar por cliente'"
                  label="Nombre"
                  single-line
                  outlined
                  dense
                  hide-details
                  @input="FiltrarPorCliente()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="abrirDialog()"> Nuevo </v-btn>
          </v-toolbar> </template
        >4
        <template v-slot:[`item.action_ver`]="{ item }">
          <v-icon @click="editarItem(item, 'ver')"> far fa-eye </v-icon>
        </template>
        <template v-slot:[`item.action_editar`]="{ item }">
          <v-icon @click="editarItem(item, 'editar')"> far fa-edit </v-icon>
        </template>
        <template v-slot:[`item.activo`]="{ item }">
          <v-chip :color="colorActivo(item.activo)" dark>
            <span v-if="item.activo == true">Vigente</span>
            <span v-else>CANCELADO</span>
          </v-chip>
        </template>
      </v-data-table>
    </v-col>

    <!--VENTANA PRINCIPAL -->
    <v-dialog v-model="dialog" width="1500" persistent scrollable>
      <v-card>
        <v-card-title>
          PÓLIZA
          <v-btn
            v-if="esAdministrador"
            color="red"
            icon
            dark
            @click="confirmarDesactivarPoliza()"
          >
            <v-icon>fas fa-trash-alt</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-icon size="30" color="black" @click="cerrarDialog()"
            >far fa-times-circle</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row no-gutters>
              <!-- SECCION IZQUIERDA -->
              <v-col cols="12" md="6">
                <v-row no-gutters>
                  <!-- SECCION IZQUIERDA (#poliza, cliente) -->
                  <v-row no-gutters class="pt-2 lineaMargen">
                    <v-col cols="12" md="6" class="pa-1">
                      <v-text-field
                        v-model="poliza"
                        label="# Póliza"
                        outlined
                        dense
                        hide-details
                        :rules="rules"
                        :disabled="inicial_renovacion == 'Es renovacion'"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="pa-1">
                      <v-autocomplete
                        v-model="año_creacion"
                        :items="items_año_creacion"
                        label="Año"
                        outlined
                        dense
                        hide-details
                        @input="listarPolizaRenovacion('mostrar')"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="2" class="pa-1">
                      <v-btn
                        color="primary"
                        dark
                        v-if="bandera == 2 && !deshabilitar_actualizar"
                        @click="listarPolizaRenovacion('crear')"
                      >
                        Renovar
                      </v-btn>
                    </v-col>
                    <v-col cols="7" class="pa-1">
                      <v-text-field
                        v-model="codigo_cliente"
                        label="Cliente"
                        outlined
                        dense
                        hide-details
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" class="pa-1 my-auto">
                      <v-btn
                        small
                        color="grey"
                        dark
                        @click="abrirDialogClientes()"
                        :disabled="inicial_renovacion == 'Es renovacion'"
                        v-if="!deshabilitar_actualizar"
                      >
                        <v-icon class="fas fa-search" small></v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="pa-1 my-auto">
                      <v-btn
                        small
                        color="green"
                        dark
                        @click="abrirDialogAgregarClientes()"
                        :disabled="inicial_renovacion == 'Es renovacion'"
                        v-if="!deshabilitar_actualizar"
                      >
                        <v-icon class="fas fa-plus" small></v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-1">
                      <v-text-field
                        v-model="nombre_cliente"
                        label="Nombre cliente"
                        outlined
                        dense
                        hide-details
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-1">
                      <v-text-field
                        v-model="nombre_cliente_carpeta"
                        label="Nombre según carpeta"
                        outlined
                        dense
                        hide-details
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- SECCION IZQUIERDA (aseguradora, categoria, seguro, tipo, ramo) -->
                  <v-row no-gutters class="pt-2 lineaMargen">
                    <v-col cols="12" md="6" class="pa-1">
                      <v-autocomplete
                        v-model="aseguradora"
                        label="Aseguradora"
                        :items="items_aseguradoras"
                        item-text="descripcion"
                        item-value="id_aseguradoras"
                        outlined
                        dense
                        hide-details
                        return-object
                        @change="
                          listarSeguros();
                          listarPlanes;
                        "
                        :rules="rules"
                        :disabled="inicial_renovacion == 'Es renovacion'"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-1">
                      <v-text-field
                        v-model="aseguradora_segun_carpeta"
                        label="Aseguradora según carpeta"
                        outlined
                        dense
                        hide-details
                        :disabled="inicial_renovacion == 'Es renovacion'"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-1">
                      <v-autocomplete
                        v-model="categoria"
                        label="Categoría"
                        :items="items_categorias"
                        item-text="descripcion"
                        item-value="id_categoria_seguros"
                        outlined
                        dense
                        hide-details
                        return-object
                        @change="
                          listarSeguros();
                          listarPlanes;
                        "
                        :rules="rules"
                        :disabled="inicial_renovacion == 'Es renovacion'"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="10" class="pa-1">
                      <v-autocomplete
                        v-model="seguro"
                        label="Seguros"
                        :items="items_seguros"
                        item-text="nombre"
                        item-value="id_seguros"
                        outlined
                        dense
                        hide-details
                        return-object
                        @change="
                          listarTipoSeguros();
                          listarRamoSeguros();
                        "
                        :rules="rules"
                        :disabled="inicial_renovacion == 'Es renovacion'"
                        >{{ listarPlanes }}</v-autocomplete
                      >
                    </v-col>
                    <v-col cols="2" md="auto" class="my-auto pa-1">
                      <v-btn
                        color="green"
                        dark
                        small
                        @click="nuevoProducto()"
                        :disabled="inicial_renovacion == 'Es renovacion'"
                        v-if="!deshabilitar_actualizar"
                      >
                        <v-icon small>fas fa-plus</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="pa-1">
                      <v-autocomplete
                        v-model="tipo_seguro"
                        label="Tipo seguro"
                        :items="items_tipo_seguros"
                        item-text="descripcion"
                        item-value="id_tipo_seguros"
                        outlined
                        dense
                        hide-details
                        disabled
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" class="pa-1">
                      <v-autocomplete
                        v-model="ramo_seguro"
                        label="Ramo"
                        :items="items_ramo_seguros"
                        item-text="descripcion"
                        item-value="id_ramo_seguros"
                        outlined
                        dense
                        hide-details
                        disabled
                        return-object
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- SECCION IZQUIERDA (fecha, cuotas, otros) -->
                  <v-row class="pt-2 mb-1 lineaMargen">
                    <v-col cols="12" md="6" class="py-1 px-1">
                      <v-autocomplete
                        v-model="periodos"
                        label="Vigencia"
                        :items="items_periodos"
                        outlined
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1 px-1">
                      <v-text-field
                        v-model="fecha_inicial"
                        label="Fecha desde"
                        outlined
                        dense
                        type="date"
                        hide-details
                        @change="calculoDiaCobro()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1 px-1">
                      <v-autocomplete
                        v-model="tipo_cuota"
                        label="Tipo cuotas"
                        :items="items_tipo_cuota"
                        item-text="nombre"
                        item-value="id_tipo_cuota"
                        outlined
                        dense
                        hide-details
                        return-object
                        @change="calculoMontoCuota()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1 px-1">
                      <v-text-field
                        v-model="fecha_fin"
                        label="Fecha Hasta"
                        outlined
                        dense
                        type="date"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-1 px-1"
                      v-if="tipo_cuota != null"
                    >
                      <v-select
                        v-model="cantidad_cuotas"
                        :items="items_cantidad_cuotas"
                        label="Cantidad cuotas"
                        outlined
                        dense
                        hide-details
                        v-if="
                          tipo_cuota.nombre == 'MENSUAL' ||
                          nombre_tipo_cuota == 'MENSUAL'
                        "
                        @change="calculoMontoCuota()"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1 px-1">
                      <v-text-field
                        v-model="dia_cobro"
                        label="Día cobro"
                        outlined
                        dense
                        type="number"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-row>
              </v-col>
              <!-- SECCION DERECHA -->
              <v-col cols="12" md="6" class="mb-1">
                <!-- SECCION DERECHA (checkbox, radiobutton) -->
                <v-row no-gutters justify="space-around" class="lineaMargen">
                  <v-col cols="auto" align-self="center">
                    <v-radio-group
                      v-model="activo"
                      hide-details
                      dense
                      class="mt-0"
                    >
                      <v-radio
                        label="VIGENTE"
                        :value="1"
                        color="green"
                      ></v-radio>
                      <v-radio
                        label="Cancelado/Excluido"
                        :value="0"
                        color="red"
                        @click="fecha_cancelado = formatoFecha(new Date())"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="auto" align-self="center">
                    <v-radio-group
                      v-model="inicial_renovacion"
                      hide-details
                      dense
                      class="mt-0"
                    >
                      <v-radio
                        label="Es poliza inicial"
                        value="Es poliza inicial"
                        @click="listarPlanesInicialRenovacion()"
                      ></v-radio>
                      <v-radio
                        label="Es renovación"
                        value="Es renovacion"
                        @click="listarPlanesInicialRenovacion()"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="auto" align-self="center">
                    <v-autocomplete
                      v-model="ejecutivo"
                      :items="items_ejecutivos"
                      item-text="nombre"
                      label="Ejecutivo"
                      outlined
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="8" v-if="activo == false">
                    <v-textarea                      
                      v-model="motivo_cancelado_excluido"
                      label="Motivo"
                      outlined
                      dense
                      hide-details
                      row-height="12"
                      auto-grow
                      class="mt-2 mx-2"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="4" v-if="activo == false">
                    <v-text-field
                      v-model="fecha_cancelado"
                      label="Fecha cancelado"
                      outlined
                      dense
                      hide-details
                      class="mt-2 mx-2"
                      type="date"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- SUMAS Y PRIMAS DE LA POLIZA (valores de poliza) -->
                <v-row class="mt-1 lineaMargen" justify="center">
                  <v-col cols="9" class="pa-1">
                    <div class="headline text-center">
                      SUMAS Y PRIMAS DE LA POLIZA
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field
                          v-model="prima_neta"
                          label="Prima neta"
                          outlined
                          dense
                          hide-details
                          @input="
                            calculoIva13(),
                              calculoTotalPagar(),
                              calculoGastoBomberos()
                          "
                          prefix="$"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="gasto_bomberos"
                          label="Gasto de Bomberos"
                          outlined
                          dense
                          hide-details
                          @input="calculoIva13(), calculoTotalPagar()"
                          class="mt-2"
                          prefix="$"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field
                          v-model="fraccionamiento"
                          label="Fraccionamiento"
                          outlined
                          dense
                          hide-details
                          @input="calculoIva13(), calculoTotalPagar()"
                          class="mt-2"
                          prefix="$"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" align-self="center">
                        <v-checkbox
                          v-model="calcular_fraccionamiento"
                          label="Calcular"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-text-field
                      v-model="gastos_emision"
                      label="Gastos de emisión"
                      outlined
                      dense
                      hide-details
                      @input="calculoIva13(), calculoTotalPagar()"
                      class="mt-2"
                      prefix="$"
                    ></v-text-field>
                    <v-text-field
                      v-model="iva_13_por_ciento"
                      label="IVA (13%)"
                      outlined
                      dense
                      hide-details
                      @input="calculoTotalPagar()"
                      class="mt-2"
                      prefix="$"
                    ></v-text-field>
                    <v-text-field
                      v-model="total_a_pagar"
                      label="Total a pagar"
                      outlined
                      dense
                      hide-details
                      class="mt-2"
                      @input="calculoMontoCuota()"
                      prefix="$"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="suma_asegurada"
                      label="Suma asegurada"
                      outlined
                      dense
                      hide-details
                      prefix="$"
                      @change="valoresMaxVitalicio()"
                    ></v-text-field>
                    <v-autocomplete
                      v-model="plan_seleccionado"
                      class="mt-2"
                      label="Planes"
                      :items="items_planes"
                      item-text="nombre"
                      item-value="id_planes"
                      return-object
                      outlined
                      dense
                      hide-details
                      @input="asignarComision()"
                    >
                      <template v-slot:item="data">
                        {{ data.item.nombre }} - % {{ data.item.comision }}
                      </template>
                    </v-autocomplete>
                    <v-text-field
                      v-model="comision"
                      class="mt-2"
                      label="Comisión"
                      outlined
                      dense
                      hide-details
                      prefix="%"
                    ></v-text-field>
                    <v-text-field
                      v-model="prima_comisionable"
                      class="mt-2"
                      label="Prima comisionable"
                      outlined
                      dense
                      hide-details
                      prefix="$"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- SUMAS Y PRIMAS DE LA POLIZA (valores de poliza) -->
                </v-row>
                <!-- AUMENTOS O INCLUSIONES -->
                <v-row no-gutters class="mt-4 lineaMargen">
                  <v-col cols="12" md="6" class="pa-1" align-self="center">
                    <div class="subtitle-1">AUMENTOS O INCLUSIONES</div>
                  </v-col>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-autocomplete
                      v-model="aumentos_inclusiones"
                      label="Seleccione"
                      :items="['SI', 'NO']"
                      outlined
                      dense
                      hide-details
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" align-self="end">
                    <v-col cols="12" class="pa-1">
                      <v-text-field
                        v-model="aumentos_inclusiones_fecha"
                        label="Fecha"
                        v-if="aumentos_inclusiones == 'SI'"
                        outlined
                        dense
                        hide-details
                        type="date"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-1">
                      <v-autocomplete
                        v-model="aumentos_inclusiones_cant_cuotas"
                        :items="items_aumentos_inclusiones_cant_cuotas"
                        v-if="aumentos_inclusiones == 'SI'"
                        label="Cantidad cuotas"
                        outlined
                        dense
                        hide-details
                        @input="calcularCuotasAumentos()"
                      ></v-autocomplete>
                    </v-col>
                  </v-col>
                  <v-col cols="6">
                    <v-col cols="12" class="pa-1">
                      <v-text-field
                        v-model="aumentos_inclusiones_prima"
                        v-if="aumentos_inclusiones == 'SI'"
                        label="Prima extra"
                        outlined
                        dense
                        hide-details
                        prefix="$"
                        @input="calcularMontosAumentos()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-1">
                      <v-text-field
                        v-model="aumentos_inclusiones_iva"
                        v-if="aumentos_inclusiones == 'SI'"
                        label="IVA"
                        outlined
                        dense
                        hide-details
                        prefix="$"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-1">
                      <v-text-field
                        v-model="aumentos_inclusiones_total"
                        v-if="aumentos_inclusiones == 'SI'"
                        label="Total prima extra"
                        outlined
                        dense
                        hide-details
                        prefix="$"
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-row>
                <!-- SECCION DERECHA (metodo de pago) -->
                <v-row class="mt-1 lineaMargen" justify="center">
                  <v-col cols="9" class="py-1">
                    <div class="headline text-center">FORMA DE PAGO</div>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      v-model="metodo_pago"
                      :items="items_metodo_pago"
                      label="Forma de pago"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <!-- TABS -->
              <v-col cols="12" class="mt-5 lineaMargen">
                <v-tabs
                  v-model="tabs"
                  background-color="primary"
                  dark
                  fixed-tabs
                >
                  <v-tabs-slider color="black"></v-tabs-slider>
                  <v-tab v-if="id_poliza">Polizas cliente</v-tab>
                  <v-tab>Cuotas</v-tab>
                  <v-tab v-if="tipo_seguro.descripcion == 'INDIVIDUAL'"
                    >Caracteristicas</v-tab
                  >
                  <v-tab v-if="categoria.descripcion == 'DAÑOS'"
                    >RIESGOS Y COBERTURAS</v-tab
                  >
                  <v-tab
                    v-if="categoria.descripcion == 'PERSONAS'"
                    @click="listarBeneficios()"
                    >BENEFICIOS</v-tab
                  >
                  <v-tab v-if="tipo_seguro.descripcion == 'COLECTIVO' && poliza"
                    >Certificados</v-tab
                  >
                  <v-tab v-if="poliza && id_cliente && id_seguro"
                    >Reclamos</v-tab
                  >
                  <v-tab @click="listarArchivos(id_poliza)">Archivos</v-tab>
                  <v-tab
                    v-if="
                      categoria.descripcion == 'PERSONAS' &&
                      tipo_seguro.descripcion == 'INDIVIDUAL'
                    "
                    >Beneficiarios</v-tab
                  >
                  <v-tab @click="listarObservacionesPoliza()"
                    >Observaciones</v-tab
                  >
                </v-tabs>
                <v-tabs-items v-model="tabs">
                  <!-- POLIZAS CLIENTE -->
                  <v-tab-item v-if="id_poliza">
                    <v-data-table
                      :headers="headers_polizas_cliente"
                      :items="items_polizas_cliente"
                      disable-pagination
                    >
                      <template v-slot:[`item.fecha_inicial`]="{ item }">
                        <span>
                          {{ formatoFecha(item.fecha_inicial) }}
                        </span>
                      </template>
                      <template v-slot:[`item.fecha_fin`]="{ item }">
                        <span>
                          {{ formatoFecha(item.fecha_fin) }}
                        </span>
                      </template>
                      <template v-slot:[`item.action_ver`]="{ item }">
                        <v-btn icon @click="editarItem(item, 'editar')">
                          <v-icon>far fa-eye</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <!-- CUOTAS tabla #1 -->
                  <v-tab-item>
                    <v-data-table
                      v-model="cuotas_polizas_seleccionadas"
                      :headers="headers_cuotas"
                      :items="items_cuotas"
                      disable-pagination
                      show-select
                      item-key="id_cuotas"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-btn
                            v-if="
                              items_cuotas.length == 0 &&
                              se_esta_renovando_poliza
                            "
                            color="primary"
                            dark
                            @click="calculoMontoCuota()"
                            >Generar cuotas
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            v-if="
                              esAdministrador &&
                              cuotas_polizas_seleccionadas.length != 0 &&
                              !deshabilitar_actualizar
                            "
                            color="red"
                            dark
                            small
                            @click="
                              dialog_confirmar_eliminar_cuotas_seleccionadas =
                                !dialog_confirmar_eliminar_cuotas_seleccionadas
                            "
                            >Eliminar cuotas seleccionadas
                          </v-btn>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.cont`]="{ item }">
                        <span>
                          {{ contadorDetalleCuotas(item) }}
                        </span>
                      </template>
                      <template v-slot:[`item.fecha`]="{ item }">
                        <v-edit-dialog
                          :return-value.sync="item.fecha"
                          large
                          save-text="Guardar"
                          cancel-text="Cancelar"
                          @save="actualizarFechaCuota(item)"
                        >
                          {{ formatoFecha(item.fecha) }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.fecha"
                              single-line
                              hide-details
                              dense
                              type="date"
                              :disabled="deshabilitar_actualizar"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.monto`]="{ item }">
                        <v-edit-dialog
                          :return-value.sync="item.monto"
                          large
                          save-text="Guardar"
                          cancel-text="Cancelar"
                          @save="actualizarMontoCuota(item)"
                        >
                          ${{ formatoMoneda(item.monto) }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.monto"
                              label="Editar"
                              single-line
                              hide-details
                              dense
                              type="number"
                              :disabled="deshabilitar_actualizar"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.fecha_pago`]="{ item }">
                        <span>
                          {{ formatoFecha(item.fecha_pago) }}
                        </span>
                      </template>
                      <template v-slot:[`item.agregar_archivo`]="{ item }">
                        <input
                          ref="uploader"
                          class="d-none"
                          type="file"
                          @change="guardarArchivoCuota"
                          multiple
                        />
                        <v-btn
                          color="primary"
                          rounded
                          @click="agregarArchivoCuota(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-save </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.ver_archivo`]="{ item }">
                        <v-btn
                          color="primary"
                          rounded
                          @click="verArchivoCuota(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-folder-open </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.esta_pagada`]="{ item }">
                        <v-btn
                          icon
                          @click="editCuotaPagada(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon v-if="estadoPagado(item.esta_pagada)"
                            >far fa-square</v-icon
                          >
                          <v-icon v-else>far fa-check-square</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.agregar_archivo_pago`]="{ item }">
                        <input
                          ref="uploaderPagoPoliza"
                          class="d-none"
                          type="file"
                          @change="guardarArchivoPagoCuota"
                          multiple
                        />
                        <v-btn
                          color="primary"
                          rounded
                          block
                          @click="agregarArchivoPagoCuota(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-save </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.ver_archivo_pago`]="{ item }">
                        <v-btn
                          color="primary"
                          rounded
                          block
                          @click="verArchivoPagoCuota(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-folder-open </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.gestion_cobro`]="{ item }">
                        <v-btn
                          color="primary"
                          rounded
                          block
                          @click="editCuotaGestionCobro(item)"
                          :disabled="
                            id_poliza == null || deshabilitar_actualizar
                          "
                        >
                          <v-icon>fa-regular fa-edit</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`body.append`] v-if="id_poliza">
                        <tr style="text-align: left">
                          <td colspan="12">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  @click="dialog_eliminar_cuotas = true"
                                  :disabled="deshabilitar_actualizar"
                                >
                                  <v-icon color="red">
                                    fas fa-trash-alt
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>ELIMINAR CUOTAS</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <!-- CUOTAS INDEPENDIENTES ICONO(+) tabla #2 -->
                    <v-data-table
                      :headers="headers_cuotas_independientes"
                      :items="items_cuotas_independientes"
                      v-if="id_poliza"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                small
                                dark
                                fab
                                color="green"
                                elevation="0"
                                @click="crearCuotaIndependiente()"
                                :disabled="deshabilitar_actualizar"
                              >
                                <v-icon>fas fa-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Cuotas independientes</span>
                          </v-tooltip>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.cont`]="{ item }">
                        <span>{{ item.cont }}</span>
                      </template>
                      <template v-slot:[`item.monto`]="{ item }">
                        <v-edit-dialog
                          :return-value.sync="item.monto"
                          large
                          save-text="Guardar"
                          cancel-text="Cancelar"
                          @save="actualizarMontoCuota(item)"
                        >
                          ${{ formatoMoneda(item.monto) }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.monto"
                              label="Editar"
                              single-line
                              hide-details
                              dense
                              type="number"
                              :disabled="deshabilitar_actualizar"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.fecha`]="{ item }">
                        <v-edit-dialog
                          :return-value.sync="item.monto"
                          large
                          save-text="Guardar"
                          cancel-text="Cancelar"
                          @save="actualizarMontoCuota(item)"
                        >
                          {{ formatoFecha(item.fecha) }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.fecha"
                              label="Editar"
                              single-line
                              hide-details
                              dense
                              type="date"
                              :disabled="deshabilitar_actualizar"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.fecha_pago`]="{ item }">
                        <span>
                          {{ formatoFecha(item.fecha_pago) }}
                        </span>
                      </template>
                      <template v-slot:[`item.agregar_archivo`]="{ item }">
                        <input
                          ref="uploader"
                          class="d-none"
                          type="file"
                          @change="guardarArchivoCuota"
                          multiple
                        />
                        <v-btn
                          color="primary"
                          rounded
                          @click="agregarArchivoCuota(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-save </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.ver_archivo`]="{ item }">
                        <v-btn
                          color="primary"
                          rounded
                          @click="verArchivoCuota(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-folder-open </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.esta_pagada`]="{ item }">
                        <v-btn
                          icon
                          @click="editCuotaPagada(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon v-if="estadoPagado(item.esta_pagada)"
                            >far fa-square</v-icon
                          >
                          <v-icon v-else>far fa-check-square</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.agregar_archivo_pago`]="{ item }">
                        <input
                          ref="uploaderPagoPoliza"
                          class="d-none"
                          type="file"
                          @change="guardarArchivoPagoCuota"
                          multiple
                        />
                        <v-btn
                          color="primary"
                          rounded
                          block
                          @click="agregarArchivoPagoCuota(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-save </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.ver_archivo_pago`]="{ item }">
                        <v-btn
                          color="primary"
                          rounded
                          block
                          @click="verArchivoPagoCuota(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-folder-open </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`body.append`] v-if="id_poliza">
                        <tr style="text-align: left">
                          <td colspan="12">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  @click="
                                    dialog_eliminar_cuotas_independiente = true
                                  "
                                  :disabled="deshabilitar_actualizar"
                                >
                                  <v-icon color="red">
                                    fas fa-trash-alt
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>ELIMINAR CUOTAS</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <!-- AUMENTOS O INCLUSIONES tabla #3 -->
                    <v-data-table
                      :headers="headers_aumentos_inclusiones"
                      :items="items_aumentos_inclusiones"
                      v-if="aumentos_inclusiones == 'SI'"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title
                            >Aumentos o Inclusiones</v-toolbar-title
                          >
                          <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.cont`]="{ item }">
                        <span>
                          {{ item.cont }}
                        </span>
                      </template>
                      <template v-slot:[`item.monto`]="{ item }">
                        <v-edit-dialog
                          :return-value.sync="item.monto"
                          large
                          save-text="Guardar"
                          cancel-text="Cancelar"
                          @save="actualizarMontoCuotaAumentoInclusion(item)"
                        >
                          {{ formatoMoneda(item.monto) }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.monto"
                              label="Editar"
                              single-line
                              dense
                              :disabled="deshabilitar_actualizar"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.fecha`]="{ item }">
                        <v-edit-dialog
                          :return-value.sync="item.fecha"
                          large
                          save-text="Guardar"
                          cancel-text="Cancelar"
                          @save="actualizarFechaCuotaAumentoInclusion(item)"
                        >
                          {{ formatoFecha(item.fecha) }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.fecha"
                              single-line
                              hide-details
                              dense
                              type="datetime-local"
                              :disabled="deshabilitar_actualizar"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.fecha_pago`]="{ item }">
                        <span>
                          {{ formatoFecha(item.fecha_pago) }}
                        </span>
                      </template>
                      <template v-slot:[`item.agregar_archivo`]="{ item }">
                        <input
                          ref="uploaderAumInclFile"
                          class="d-none"
                          type="file"
                          @change="guardarArchivoAumentosInclusiones"
                          multiple
                        />
                        <v-btn
                          color="primary"
                          rounded
                          @click="agregarArchivoAumentosInclusiones(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-save </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.ver_archivo`]="{ item }">
                        <v-btn
                          color="primary"
                          rounded
                          @click="verArchivoCertificado(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-folder-open </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.esta_pagada`]="{ item }">
                        <v-btn
                          icon
                          @click="editAumentoInclusionPagos(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon v-if="estadoPagado(item.esta_pagada)"
                            >far fa-square</v-icon
                          >
                          <v-icon v-else>far fa-check-square</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.agregar_archivo_pago`]="{ item }">
                        <input
                          ref="uploaderAumInclPago"
                          class="d-none"
                          type="file"
                          @change="guardarArchivoAumentosInclusionesPago"
                          multiple
                        />
                        <v-btn
                          color="primary"
                          rounded
                          block
                          @click="agregarArchivoCertificadoPago(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-save </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.ver_archivo_pago`]="{ item }">
                        <v-btn
                          color="primary"
                          rounded
                          block
                          @click="verArchivoCertificadoPago(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon> far fa-folder-open </v-icon>
                        </v-btn>
                      </template>
                      <template
                        v-slot:[`body.append`]
                        v-if="
                          id_poliza &&
                          aumentos_inclusiones_bandera_sin_cuotas == false
                        "
                      >
                        <tr style="text-align: left">
                          <td colspan="12">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  @click="
                                    dialog_eliminar_cuotas_aumentos_inclusiones = true
                                  "
                                  :disabled="deshabilitar_actualizar"
                                >
                                  <v-icon color="red">
                                    fas fa-trash-alt
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>ELIMINAR CUOTAS</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <!-- INCLUSIONES tabla #4 -->
                    <v-data-table
                      v-if="tipo_seguro.descripcion == 'COLECTIVO' && id_poliza"
                      :headers="headers_pago_certificados"
                      :items="items_pago_certificados_incluidos"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Inclusiones</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.cont`]="{ item }">
                        <span>
                          {{ contadorDetalleCuotasInclusiones(item) }}
                        </span>
                      </template>
                      <template v-slot:[`item.monto`]="{ item }">
                        <v-edit-dialog
                          :return-value.sync="item.monto"
                          large
                          save-text="Guardar"
                          cancel-text="Cancelar"
                          @save="actualizarMontoCuotaCertificado(item)"
                        >
                          {{ formatoMoneda(item.monto) }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.monto"
                              label="Editar"
                              single-line
                              dense
                              :disabled="deshabilitar_actualizar"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.fecha`]="{ item }">
                        <span>
                          {{ formatoFecha(item.fecha) }}
                        </span>
                      </template>
                      <template v-slot:[`item.fecha_pago`]="{ item }">
                        <span>
                          {{ formatoFecha(item.fecha_pago) }}
                        </span>
                      </template>
                      <template v-slot:[`item.agregar_archivo`]="{ item }">
                        <input
                          ref="uploaderCertFile"
                          class="d-none"
                          type="file"
                          @change="guardarArchivoCertificado"
                          multiple
                        />
                        <v-btn
                          color="primary"
                          rounded
                          @click="agregarArchivoCertificado(item)"
                          :disabled="
                            id_poliza == null || deshabilitar_actualizar
                          "
                        >
                          <v-icon> far fa-save </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.ver_archivo`]="{ item }">
                        <v-btn
                          color="primary"
                          rounded
                          @click="verArchivoCertificado(item)"
                          :disabled="
                            id_poliza == null || deshabilitar_actualizar
                          "
                        >
                          <v-icon> far fa-folder-open </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.esta_pagada`]="{ item }">
                        <v-btn
                          icon
                          @click="editCertPagos(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          <v-icon v-if="estadoPagado(item.esta_pagada)"
                            >far fa-square</v-icon
                          >
                          <v-icon v-else>far fa-check-square</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.agregar_archivo_pago`]="{ item }">
                        <input
                          ref="uploaderCertPago"
                          class="d-none"
                          type="file"
                          @change="guardarArchivoCertificadoPago"
                          multiple
                        />
                        <v-btn
                          color="primary"
                          rounded
                          block
                          @click="agregarArchivoCertificadoPago(item)"
                          :disabled="
                            id_poliza == null || deshabilitar_actualizar
                          "
                        >
                          <v-icon> far fa-save </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.ver_archivo_pago`]="{ item }">
                        <v-btn
                          color="primary"
                          rounded
                          block
                          @click="verArchivoCertificadoPago(item)"
                          :disabled="
                            id_poliza == null || deshabilitar_actualizar
                          "
                        >
                          <v-icon> far fa-folder-open </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <!-- INDIVIDUAL -->
                  <v-tab-item v-if="tipo_seguro.descripcion == 'INDIVIDUAL'">
                    <!-- AUTO -->
                    <v-card v-if="ramo_seguro.descripcion == 'AUTOS'">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_auto_marca"
                              label="Marca"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_auto_modelo"
                              label="Modelo"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_auto_año"
                              label="Año"
                              outlined
                              dense
                              hide-details
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_auto_placa"
                              label="Placa"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_auto_chasis"
                              label="N° chasis"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_auto_motor"
                              label="N° motor"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-autocomplete
                              v-model="prod_ind_auto_version"
                              :items="items_ind_auto_version"
                              label="Versión"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-autocomplete
                              v-model="prod_ind_auto_tipo_vehiculo"
                              :items="items_tipo_vehiculo"
                              label="Tipo vehiculo"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_auto_vencimiento_tarjeta"
                              label="Vencimiento tarjeta"
                              outlined
                              dense
                              hide-details
                              type="date"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_auto_vencimiento_dui"
                              label="Vencimiento DUI"
                              outlined
                              dense
                              hide-details
                              type="date"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_auto_vencimiento_licencia"
                              label="Vencimiento Licencia"
                              outlined
                              dense
                              hide-details
                              type="date"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="1">
                            <v-autocomplete
                              v-model="prod_ind_auto_cesion"
                              :items="['SI', 'NO']"
                              label="Cesión"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_auto_monto_cesion"
                              label="Monto cesión"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              v-model="prod_ind_auto_beneficiario_cesion"
                              label="Beneficiario cesión"
                              :items="items_beneficiario_cesion"
                              item-text="nombre"
                              item-value="nombre"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- VIDA -->
                    <v-card v-if="ramo_seguro.descripcion == 'VIDA'">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="9">
                            <v-text-field
                              v-model="prod_ind_vida_nombre"
                              label="Nombre"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              v-model="prod_ind_vida_sexo"
                              :items="['MASCULINO', 'FEMENINO']"
                              label="Sexo"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_vida_fecha_nacimiento"
                              label="Fecha nacimiento"
                              outlined
                              dense
                              hide-details
                              type="date"
                              @input="
                                calculoEdadProdIndVida(
                                  prod_ind_vida_fecha_nacimiento
                                )
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="1">
                            <v-text-field
                              v-model="prod_ind_vida_edad"
                              label="Edad"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-autocomplete
                              v-model="prod_ind_vida_cesion"
                              :items="['SI', 'NO']"
                              label="Cesión"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field
                              v-model="prod_ind_vida_monto_cesion"
                              label="Monto cesión"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="5">
                            <v-autocomplete
                              v-model="prod_ind_vida_beneficiario_cesion"
                              :items="items_beneficiario_cesion"
                              item-text="nombre"
                              item-value="nombre"
                              label="Beneficiario cesión"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-autocomplete
                              v-model="prod_ind_vida_temporal"
                              :items="['SI', 'NO']"
                              label="Temporal"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            md="2"
                            v-if="prod_ind_vida_temporal == 'SI'"
                          >
                            <v-combobox
                              v-model="prod_ind_vida_numero_años"
                              :items="items_prod_ind_vida_num_años"
                              label="Numero de años"
                              outlined
                              dense
                              hide-details
                            ></v-combobox>
                          </v-col>
                          <v-col cols="auto">
                            <v-btn
                              color="primary"
                              :disabled="deshabilitar_actualizar"
                              @click="abrirDialogIndividualBeneficiariosVida()"
                              >Beneficiarios</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- GASTO MEDICO -->
                    <v-card v-if="ramo_seguro.descripcion == 'GASTOS MEDICOS'">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_gastomedico_nombre"
                              label="Nombre"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_gastomedico_fecha_nacimiento"
                              label="Fecha nacimiento"
                              outlined
                              dense
                              hide-details
                              type="date"
                              @input="
                                calculoEdadProdIndGastoMedico(
                                  prod_ind_gastomedico_fecha_nacimiento
                                )
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_gastomedico_edad"
                              label="Edad"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-autocomplete
                              v-model="prod_ind_gastomedico_sexo"
                              :items="['MASCULINO', 'FEMENINO']"
                              label="Sexo"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_gastomedico_max_vit_contratado"
                              label="Máximo Vitalicio contratado"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_gastomedico_max_vit_vigente"
                              label="Máximo Vitalicio vigente"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-autocomplete
                              v-model="prod_ind_gastomedico_cobertura"
                              :items="items_prod_ind_gastomedico_cobertura"
                              label="Cobertura"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="auto">
                            <v-btn
                              color="primary"
                              @click="listarProdIndDepenGastoMedico()"
                              :disabled="deshabilitar_actualizar"
                              >Dependientes</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- RESIDENCIAL -->
                    <v-card v-if="ramo_seguro.descripcion == 'RESIDENCIA'">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_residencia_n_riesgo"
                              label="N° Riesgo"
                              outlined
                              dense
                              hide-details
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="10">
                            <v-text-field
                              v-model="prod_ind_residencia_direccion"
                              label="Dirección"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="
                                prod_ind_residencia_sum_aseg_construcciones
                              "
                              label="Suma asegurada construcciones"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                              @input="calcularTotalIndResidencia()"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="prod_ind_residencia_sum_aseg_contenido"
                              label="Suma asegurada contenido"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                              @input="calcularTotalIndResidencia()"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="prod_ind_residencia_total_suma_aseg"
                              label="Total suma asegurada"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              v-model="prod_ind_residencia_plan_contratado"
                              :items="items_prod_ind_residencia_contratado"
                              label="Plan contratado"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-autocomplete
                              v-model="prod_ind_residencia_cesion"
                              :items="['SI', 'NO']"
                              label="Cesión "
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_residencia_monto_cesion"
                              label="Monto cesion"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="5">
                            <v-autocomplete
                              v-model="prod_ind_residencia_beneficiario_cesion"
                              :items="items_beneficiario_cesion"
                              item-text="nombre"
                              item-value="nombre"
                              label="Beneficiario cesion"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-btn
                              v-if="
                                id_poliza == 0 || prod_ind_id_residencia == 0
                              "
                              color="primary"
                              @click="crearProdIndividual(id_poliza, 'nuevo')"
                              :disabled="deshabilitar_actualizar"
                              >Guardar</v-btn
                            >
                            <v-btn
                              v-if="prod_ind_id_residencia != 0"
                              color="primary"
                              @click="actualizarProdIndResidencia()"
                              :disabled="deshabilitar_actualizar"
                              >Actualizar</v-btn
                            >
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers_prod_ind_residencia"
                              :items="items_prod_ind_residencia"
                            >
                              <template
                                v-slot:[`item.action_eliminar`]="{ item }"
                              >
                                <v-icon
                                  @click="eliminarIndResidencia(item)"
                                  :disabled="deshabilitar_actualizar"
                                >
                                  fas fa-trash-alt
                                </v-icon>
                              </template>
                              <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                  @click="editarItemIndResidencia(item)"
                                  :disabled="deshabilitar_actualizar"
                                >
                                  far fa-edit
                                </v-icon>
                              </template>
                              <template
                                v-slot:[`item.sum_asegurada_construcciones`]="{
                                  item,
                                }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(
                                      item.sum_asegurada_construcciones
                                    )
                                  }}
                                </span>
                              </template>
                              <template
                                v-slot:[`item.sum_asegurada_contenido`]="{
                                  item,
                                }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(item.sum_asegurada_contenido)
                                  }}
                                </span>
                              </template>
                              <template
                                v-slot:[`item.total_suma_asegurada`]="{ item }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(item.total_suma_asegurada)
                                  }}
                                </span>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- INCENDIO -->
                    <v-card v-if="ramo_seguro.descripcion == 'INCENDIO'">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="1">
                            <v-checkbox
                              v-model="prod_ind_incendio_excluir_incendio"
                              label="Excluir"
                              dense
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_incendio_n_riesgo"
                              label="N° Registro"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="9">
                            <v-text-field
                              v-model="prod_ind_incendio_direccion"
                              label="Dirección"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="
                                prod_ind_incendio_sum_aseg_construcciones
                              "
                              label="Suma asegurada construcciones"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                              type="number"
                              :disabled="
                                prod_ind_incendio_excluir_construcciones
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="1">
                            <v-checkbox
                              v-model="prod_ind_incendio_excluir_construcciones"
                              label="Excluir"
                              dense
                              hide-details
                              >{{ sumasProdIndIncendio }}</v-checkbox
                            >
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_incendio_sum_aseg_contenido"
                              label="Suma asegurada contenido"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                              :disabled="prod_ind_incendio_excluir_contenido"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="1">
                            <v-checkbox
                              v-model="prod_ind_incendio_excluir_contenido"
                              label="Excluir"
                              dense
                              hide-details
                              >{{ sumasProdIndIncendio }}</v-checkbox
                            >
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="prod_ind_incendio_sum_aseg_existencias"
                              label="Suma asegurada existencias"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                              :disabled="prod_ind_incendio_excluir_existencias"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="1">
                            <v-checkbox
                              v-model="prod_ind_incendio_excluir_existencias"
                              label="Excluir"
                              dense
                              hide-details
                              >{{ sumasProdIndIncendio }}</v-checkbox
                            >
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="prod_ind_incendio_total_sum_aseg"
                              label="Total suma asegurada"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-textarea
                              v-if="prod_ind_incendio_excluir_construcciones"
                              v-model="prod_ind_incendio_motivo_construcciones"
                              label="Motivo contrucciones"
                              outlined
                              dense
                              hide-details
                              auto-grow
                              row-height="1"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="3">
                            <v-textarea
                              v-if="prod_ind_incendio_excluir_contenido"
                              v-model="prod_ind_incendio_motivo_contenido"
                              label="Motivo contenido"
                              outlined
                              dense
                              hide-details
                              auto-grow
                              row-height="1"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="3">
                            <v-textarea
                              v-if="prod_ind_incendio_excluir_existencias"
                              v-model="prod_ind_incendio_motivo_existencias"
                              label="Motivo existencias"
                              outlined
                              dense
                              hide-details
                              auto-grow
                              row-height="1"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-text-field
                              v-model="
                                prod_ind_incendio_intr_neg_lucr_ces_perd_unid
                              "
                              label="Interrupcion negocios/ lucro cesante/ perdida utilidades"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-autocomplete
                              v-model="prod_ind_incendio_cesion"
                              :items="['SI', 'NO']"
                              label="Cesión"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              v-model="prod_ind_incendio_monto_cesion"
                              label="Monto cedido"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-autocomplete
                              v-model="prod_ind_incendio_beneficiario_cesion"
                              :items="items_beneficiario_cesion"
                              item-text="nombre"
                              item-value="nombre"
                              label="Beneficiario cesión"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="
                              id_poliza == null &&
                              flag_editar_prod_ind_incendio == false
                            "
                          >
                            <v-btn
                              color="primary"
                              @click="addProdIndIncendio()"
                              :disabled="deshabilitar_actualizar"
                              >Agregar</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="
                              id_poliza == null &&
                              flag_editar_prod_ind_incendio == true
                            "
                          >
                            <v-btn
                              color="primary"
                              @click="updateProdIndIncendio()"
                              :disabled="deshabilitar_actualizar"
                              >Aplicar cambios</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="id_poliza && prod_ind_id_incendio == 0"
                          >
                            <v-btn
                              color="primary"
                              @click="crearProdIndividual(id_poliza, 'nuevo')"
                              :disabled="deshabilitar_actualizar"
                              >Guardar</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="id_poliza && prod_ind_id_incendio != 0"
                          >
                            <v-btn
                              color="primary"
                              @click="actualizarProdIndIncendio()"
                              :disabled="deshabilitar_actualizar"
                              >Actualizar</v-btn
                            >
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers_prod_ind_incendio"
                              :items="items_prod_ind_incendio"
                            >
                              <template
                                v-slot:[`item.action_delete`]="{ item }"
                              >
                                <v-icon
                                  @click="
                                    confirmarEliminarProdIndIncendio(item)
                                  "
                                  :disabled="deshabilitar_actualizar"
                                >
                                  fas fa-trash-alt
                                </v-icon>
                              </template>
                              <template v-slot:[`item.editar`]="{ item }">
                                <v-icon
                                  @click="editarDetallesProdIndIncendio(item)"
                                  :disabled="deshabilitar_actualizar"
                                >
                                  far fa-edit
                                </v-icon>
                              </template>
                              <template
                                v-slot:[`item.sum_aseg_construcciones`]="{
                                  item,
                                }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(item.sum_aseg_construcciones)
                                  }}
                                </span>
                              </template>
                              <template
                                v-slot:[`item.sum_aseg_contenido`]="{ item }"
                              >
                                <span>
                                  ${{ formatoMoneda(item.sum_aseg_contenido) }}
                                </span>
                              </template>
                              <template
                                v-slot:[`item.sum_aseg_existencias`]="{ item }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(item.sum_aseg_existencias)
                                  }}
                                </span>
                              </template>
                              <template
                                v-slot:[`item.total_suma_asegurada`]="{ item }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(item.total_suma_asegurada)
                                  }}
                                </span>
                              </template>
                            </v-data-table>
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers_prod_ind_incendio"
                              :items="items_prod_ind_incendio_excluidos"
                            >
                              <template
                                v-slot:[`item.action_delete`]="{ item }"
                              >
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      class="mr-2"
                                      @click="
                                        confirmarEliminarProdIndIncendio(item)
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      fas fa-trash-alt
                                    </v-icon>
                                  </template>
                                  <span>Eliminar</span>
                                </v-tooltip>
                              </template>
                              <template v-slot:[`item.editar`]="{ item }">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      class="mr-2"
                                      @click="
                                        editarDetallesProdIndIncendio(item)
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      far fa-edit
                                    </v-icon>
                                  </template>
                                  <span>Opciones</span>
                                </v-tooltip>
                              </template>
                              <template
                                v-slot:[`item.sum_aseg_construcciones`]="{
                                  item,
                                }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(item.sum_aseg_construcciones)
                                  }}
                                </span>
                              </template>
                              <template
                                v-slot:[`item.sum_aseg_contenido`]="{ item }"
                              >
                                <span>
                                  ${{ formatoMoneda(item.sum_aseg_contenido) }}
                                </span>
                              </template>
                              <template
                                v-slot:[`item.sum_aseg_existencias`]="{ item }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(item.sum_aseg_existencias)
                                  }}
                                </span>
                              </template>
                              <template
                                v-slot:[`item.total_suma_asegurada`]="{ item }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(item.total_suma_asegurada)
                                  }}
                                </span>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- FIDELIDAD -->
                    <v-card v-if="ramo_seguro.descripcion == 'FIDELIDAD'">
                      <v-card-text>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              v-model="prod_ind_fidelidad_n_riesgo"
                              label="N° Riesgo"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="prod_ind_fidelidad_direccion"
                              label="Dirección"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="prod_ind_fidelidad_nombre_empleado"
                              label="Nombre Empleado"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="prod_ind_fidelidad_puesto_afianzado"
                              label="Puesto Afianzado"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="!id_poliza && !flag_prod_ind_fidelidad"
                          >
                            <v-btn
                              color="primary"
                              @click="addDetalleProdIndFidelidad()"
                              :disabled="deshabilitar_actualizar"
                              >Agregar</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="!id_poliza && flag_prod_ind_fidelidad"
                          >
                            <v-btn
                              color="primary"
                              @click="updateDetallesProdIndFidelidad()"
                              :disabled="deshabilitar_actualizar"
                              >Aplicar cambios</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="id_poliza && !flag_prod_ind_fidelidad"
                          >
                            <v-btn
                              color="primary"
                              @click="agregarProdIndFidelidad()"
                              :disabled="deshabilitar_actualizar"
                              >Guardar</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="id_poliza && flag_prod_ind_fidelidad"
                          >
                            <v-btn
                              color="primary"
                              @click="actualizarProdIndFidelidad()"
                              :disabled="deshabilitar_actualizar"
                              >Actualizar</v-btn
                            >
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers_prod_ind_fidelidad"
                              :items="items_prod_ind_fidelidad"
                            >
                              <template v-slot:[`item.editar`]="{ item }">
                                <v-icon
                                  @click="editarDetallesProdIndFidelidad(item)"
                                  :disabled="deshabilitar_actualizar"
                                >
                                  far fa-edit
                                </v-icon>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- DINERO Y VALORES -->
                    <v-card
                      v-if="ramo_seguro.descripcion == 'DINERO Y VALORES'"
                    >
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="prod_ind_dinero_valores_n_riesgo"
                              label="N° Riesgo"
                              outlined
                              dense
                              hide-details
                              type="number"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="prod_ind_dinero_valores_direccion"
                              label="Dirección"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="auto" v-if="id_poliza">
                            <v-btn
                              color="primary"
                              @click="agregarProdIndDineroValores()"
                              :disabled="deshabilitar_actualizar"
                              >Guardar</v-btn
                            >
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers_prod_ind_dinero_valores"
                              :items="items_prod_ind_dinero_valores"
                            >
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- ROBO HURTO -->
                    <v-card v-if="ramo_seguro.descripcion == 'ROBO HURTO'">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="prod_ind_robo_hurto_n_riesgo"
                              label="N° Riesgo"
                              outlined
                              dense
                              hide-details
                              type="number"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="prod_ind_robo_hurto_direccion"
                              label="Dirección"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="auto" v-if="id_poliza">
                            <v-btn
                              color="primary"
                              @click="agregarProdIndRoboHurto()"
                              :disabled="deshabilitar_actualizar"
                              >Guardar</v-btn
                            >
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers_prod_ind_robo_hurto"
                              :items="items_prod_ind_robo_hurto"
                            >
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- TRANSPORTE INTERNO -->
                    <v-card
                      v-if="ramo_seguro.descripcion == 'TRANSPORTE INTERNO'"
                    >
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="prod_ind_transporte_interno_direccion"
                              label="Dirección"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="
                                prod_ind_transporte_interno_tipo_mercaderia
                              "
                              label="Tipo de mercaderia"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-autocomplete
                              v-model="
                                prod_ind_transporte_interno_guardia_seguridad
                              "
                              label="Guardia de seguridad"
                              :items="['SI', 'NO']"
                              outlined
                              dense
                              hide-details
                            >
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- TRANSPORTE MERCADERIA -->
                    <v-card
                      v-if="ramo_seguro.descripcion == 'TRANSPORTE MERCADERIA'"
                    >
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-row no-gutters justify="center" align="center">
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_num_trans_merca
                                  "
                                  label="N° Certificado"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_asegurado
                                  "
                                  label="Asegurado"
                                  outlined
                                  dense
                                  hide-details
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_tipo_mercaderia
                                  "
                                  label="Tipo mercaderia"
                                  outlined
                                  dense
                                  hide-details
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_proveedor_cliente
                                  "
                                  label="Proveedor o cliente"
                                  outlined
                                  dense
                                  hide-details
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="auto" class="pa-1">
                                <v-checkbox
                                  v-model="
                                    prod_ind_transporte_mercaderia_maritimo
                                  "
                                  label="Marítimo"
                                  hide-details
                                  dense
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="auto" class="pa-1">
                                <v-checkbox
                                  v-model="prod_ind_transporte_mercaderia_aereo"
                                  label="Aéreo"
                                  hide-details
                                  dense
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="auto" class="pa-1">
                                <v-checkbox
                                  v-model="
                                    prod_ind_transporte_mercaderia_terrestre
                                  "
                                  label="Terrestre"
                                  hide-details
                                  dense
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="12" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_nombre_vapor
                                  "
                                  label="Nombre vapor"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_procedente_de
                                  "
                                  label="Procedente de"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_destino_a
                                  "
                                  label="Destino a"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_num_factura
                                  "
                                  label="Factura No"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_fecha_factura
                                  "
                                  label="Fecha factura"
                                  outlined
                                  dense
                                  hide-details
                                  type="date"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_fecha_despacho
                                  "
                                  label="Fecha despacho mercaderia"
                                  outlined
                                  dense
                                  hide-details
                                  type="date"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_vigencia_desde
                                  "
                                  label="Vigencia desde"
                                  outlined
                                  dense
                                  hide-details
                                  type="date"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_vigencia_hasta
                                  "
                                  label="Vigencia hasta"
                                  outlined
                                  dense
                                  hide-details
                                  type="date"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row
                              no-gutters
                              style="
                                border: 1px solid black;
                                border-radius: 5px;
                              "
                            >
                              <v-col cols="12" class="text-center">
                                <span class="title">Suma Asegurada</span>
                              </v-col>
                              <v-col cols="12" md="6" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_valor_factura
                                  "
                                  label="Valor factura"
                                  outlined
                                  dense
                                  hide-details
                                  prefix="$"
                                  @input="calculoIndTransporteMercaderia()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="pa-1">
                                <v-text-field
                                  v-model="prod_ind_transporte_mercaderia_flete"
                                  label="Flete"
                                  outlined
                                  dense
                                  hide-details
                                  prefix="$"
                                  @input="calculoIndTransporteMercaderia()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_sub_total
                                  "
                                  label="Sub-Total"
                                  outlined
                                  dense
                                  hide-details
                                  prefix="$"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_porcentaje_adicional
                                  "
                                  label="10% Adicional"
                                  outlined
                                  dense
                                  hide-details
                                  prefix="$"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="pa-1">
                                <v-text-field
                                  v-model="
                                    prod_ind_transporte_mercaderia_total_suma_asegurada
                                  "
                                  label="Total suma asegurada"
                                  outlined
                                  dense
                                  hide-details
                                  prefix="$"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers_prod_ind_transporte_mercaderia"
                              :items="items_prod_ind_transporte_mercaderia"
                            >
                              <template v-slot:top>
                                <v-toolbar flat>
                                  <v-btn
                                    v-if="
                                      id_poliza == null &&
                                      flag_editar_transporte_mercaderia == false
                                    "
                                    color="primary"
                                    dark
                                    @click="addProdIndTransMerca()"
                                    :disabled="deshabilitar_actualizar"
                                  >
                                    Agregar
                                  </v-btn>
                                  <v-btn
                                    v-if="
                                      id_poliza == null &&
                                      flag_editar_transporte_mercaderia == true
                                    "
                                    color="primary"
                                    dark
                                    @click="updateProdIndTransMerca()"
                                    :disabled="deshabilitar_actualizar"
                                  >
                                    Aplicar cambios
                                  </v-btn>
                                  <v-btn
                                    v-if="
                                      id_poliza != null &&
                                      !flag_editar_transporte_mercaderia
                                    "
                                    color="primary"
                                    dark
                                    @click="
                                      crearProdIndividual(id_poliza, 'nuevo')
                                    "
                                    :disabled="deshabilitar_actualizar"
                                  >
                                    Guardar
                                  </v-btn>
                                  <v-btn
                                    v-if="
                                      id_poliza != null &&
                                      flag_editar_transporte_mercaderia
                                    "
                                    color="primary"
                                    dark
                                    @click="actualizarProdIndTransMerca()"
                                    :disabled="deshabilitar_actualizar"
                                    class="mx-1"
                                  >
                                    Actualizar
                                  </v-btn>
                                  <v-btn
                                    v-if="
                                      prod_ind_transporte_mercaderia_num_trans_merca
                                    "
                                    color="primary"
                                    @click="listarArchivosTransMerca()"
                                    :disabled="deshabilitar_actualizar"
                                    class="mx-1"
                                  >
                                    Archivos
                                  </v-btn>
                                </v-toolbar>
                              </template>

                              <template
                                v-slot:[`item.suma_asegurada`]="{ item }"
                              >
                                <span
                                  >$
                                  {{ formatoMoneda(item.valor_factura) }}</span
                                >
                              </template>
                              <template v-slot:[`item.flete`]="{ item }">
                                <span>$ {{ formatoMoneda(item.flete) }}</span>
                              </template>
                              <template v-slot:[`item.sub_total`]="{ item }">
                                <span
                                  >$ {{ formatoMoneda(item.sub_total) }}</span
                                >
                              </template>
                              <template
                                v-slot:[`item.porcentaje_adicional`]="{ item }"
                              >
                                <span
                                  >$
                                  {{
                                    formatoMoneda(item.porcentaje_adicional)
                                  }}</span
                                >
                              </template>
                              <template
                                v-slot:[`item.total_suma_asegurada`]="{ item }"
                              >
                                <span
                                  >$
                                  {{
                                    formatoMoneda(item.total_suma_asegurada)
                                  }}</span
                                >
                              </template>

                              <template v-slot:[`item.editar`]="{ item }">
                                <v-icon
                                  @click="editarDetallesProdIndTransMerca(item)"
                                  :disabled="deshabilitar_actualizar"
                                >
                                  far fa-edit
                                </v-icon>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- RESPONSABILIDAD CIVIL -->
                    <v-card
                      v-if="ramo_seguro.descripcion == 'RESPONSABILIDAD CIVIL'"
                    >
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="prod_ind_responsabilidad_civil_n_riesgo"
                              label="N° Riesgo"
                              outlined
                              dense
                              hide-details
                              type="number"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="prod_ind_responsabilidad_civil_direccion"
                              label="Dirección"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="auto" v-if="id_poliza">
                            <v-btn
                              color="primary"
                              @click="agregarProdIndResponCivil()"
                              :disabled="deshabilitar_actualizar"
                              >Guardar</v-btn
                            >
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers_prod_ind_respon_civil"
                              :items="items_prod_ind_respon_civil"
                            >
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- EQUIPOS ELECTRONICOS -->
                    <v-card
                      v-if="ramo_seguro.descripcion == 'EQUIPOS ELECTRONICOS'"
                    >
                      <v-card-text>
                        <v-row>
                          <v-col cols="4">
                            <v-text-field
                              v-model="prod_ind_equipo_electronico_n_riesgo"
                              label="N° Registro"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="
                                prod_ind_equipo_electronico_suma_asegurada
                              "
                              label="Suma asegurada equipo"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-autocomplete
                              v-model="prod_ind_equipo_electronico_cesion"
                              :items="['SI', 'NO']"
                              label="Cesión"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="prod_ind_equipo_electronico_direccion"
                              label="Dirección"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-textarea
                              v-model="
                                prod_ind_equipo_electronico_riesgo_nombre
                              "
                              label="Riesgos basicos cubiertos"
                              outlined
                              dense
                              hide-details
                              auto-grow
                              row-height="1"
                              append-icon="fas fa-plus"
                              @click:append="
                                addProdIndEquipoElectronicoRiesgo()
                              "
                              :disabled="deshabilitar_actualizar"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="6">
                            <v-textarea
                              v-model="
                                prod_ind_equipo_electronico_cobertura_nombre
                              "
                              label="Cobertura complementarias"
                              outlined
                              dense
                              hide-details
                              auto-grow
                              row-height="1"
                              append-icon="fas fa-plus"
                              @click:append="
                                addProdEquipoElectronicoCobertura()
                              "
                              :disabled="deshabilitar_actualizar"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="6">
                            <v-expansion-panels focusable accordion>
                              <v-expansion-panel
                                v-for="(
                                  item, i
                                ) in items_prod_ind_equipo_electronico_riesgos"
                                :key="i"
                              >
                                <v-expansion-panel-header>
                                  {{ "item " + (i + 1) }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pa-1">
                                  <v-row no-gutters>
                                    <v-textarea
                                      outlined
                                      auto-grow
                                      row-height="1"
                                      dense
                                      hide-details
                                      v-model="item.nombre"
                                    ></v-textarea>
                                    <v-spacer></v-spacer>
                                    <v-col
                                      cols="auto"
                                      v-if="item.id_riesgo > 0"
                                      align-self="center"
                                    >
                                      <v-btn
                                        outlined
                                        @click="
                                          editarItemIndEquipoElectronicoRiesgo(
                                            item
                                          )
                                        "
                                        :disabled="deshabilitar_actualizar"
                                      >
                                        <v-icon color="primary"
                                          >fas fa-save</v-icon
                                        >
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                          <v-col cols="6">
                            <v-expansion-panels focusable accordion>
                              <v-expansion-panel
                                v-for="(
                                  item, i
                                ) in items_prod_ind_equipo_electronico_coberturas"
                                :key="i"
                              >
                                <v-expansion-panel-header>
                                  {{ "item " + (i + 1) }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pa-1">
                                  <v-row no-gutters>
                                    <v-textarea
                                      outlined
                                      auto-grow
                                      row-height="1"
                                      dense
                                      hide-details
                                      v-model="item.nombre"
                                    ></v-textarea>
                                    <v-spacer></v-spacer>
                                    <v-col
                                      cols="auto"
                                      v-if="item.id_cobertura > 0"
                                      align-self="center"
                                    >
                                      >
                                      <v-btn
                                        outlined
                                        @click="
                                          editarItemIndEquipoElectronicoCobertura(
                                            item
                                          )
                                        "
                                        :disabled="deshabilitar_actualizar"
                                      >
                                        <v-icon color="primary"
                                          >fas fa-save</v-icon
                                        >
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="
                              id_poliza == null &&
                              flag_editar_prod_ind_equipo_electronico == false
                            "
                          >
                            <v-btn
                              color="primary"
                              @click="addProdIndEquipoElectronico()"
                              :disabled="deshabilitar_actualizar"
                              >Agregar</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="
                              id_poliza == null &&
                              flag_editar_prod_ind_equipo_electronico == true
                            "
                          >
                            <v-btn
                              color="primary"
                              @click="updateProdEquipoElectronico()"
                              :disabled="deshabilitar_actualizar"
                              >Aplicar cambios</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="
                              id_poliza && id_individual_equipo_eletronico == 0
                            "
                          >
                            <v-btn
                              color="primary"
                              @click="crearProdIndividual(id_poliza, 'nuevo')"
                              :disabled="deshabilitar_actualizar"
                              >Guardar</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="
                              id_poliza && id_individual_equipo_eletronico != 0
                            "
                          >
                            <v-btn
                              color="primary"
                              @click="actualizarProdEquipoElectronico()"
                              :disabled="deshabilitar_actualizar"
                              >Actualizar</v-btn
                            >
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers_prod_ind_equipo_electronico"
                              :items="items_prod_ind_equipo_electronico"
                            >
                              <template v-slot:[`item.editar`]="{ item }">
                                <v-icon
                                  class="mr-2"
                                  @click="
                                    editarDetallesProdIndEquipoElectronico(item)
                                  "
                                  :disabled="deshabilitar_actualizar"
                                >
                                  far fa-edit
                                </v-icon>
                              </template>
                              <template
                                v-slot:[`item.suma_asegurada_equipo`]="{ item }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(item.suma_asegurada_equipo)
                                  }}
                                </span>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- INCAPACIDAD TOTAL Y PERMANENTE -->
                    <v-card
                      v-if="
                        ramo_seguro.descripcion ==
                        'INCAPACIDAD TOTAL Y PERMANENTE'
                      "
                    >
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="prod_ind_inc_total_perma_suma_aseg"
                              label="Suma asegurada"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="auto" align-self="center">
                            <v-checkbox
                              v-model="prod_ind_inc_total_perma_desme_acci"
                              label="Desmembramiento Accidental"
                              hide-details
                              class="mt-0"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="auto" align-self="center">
                            <v-checkbox
                              v-model="prod_ind_inc_total_perma_enfer_graves"
                              label="Enfermedades Graves"
                              hide-details
                              class="mt-0"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="auto" align-self="center">
                            <v-checkbox
                              v-model="prod_ind_inc_total_perma_muerte_acci"
                              label="Muerte Accidental"
                              hide-details
                              class="mt-0"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-model="prod_ind_inc_total_perma_otros"
                              label="Otros productos o beneficios"
                              outlined
                              dense
                              hide-details
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- SEGURO DE VIAJE -->
                    <v-card
                      v-if="ramo_seguro.descripcion == 'SEGURO DE VIAJES'"
                    >
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="9">
                            <v-text-field
                              v-model="prod_ind_seguro_viaje_nombre"
                              label="Nombre"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              v-model="prod_ind_seguro_viaje_sexo"
                              label="Sexo"
                              :items="items_prod_ind_seguro_viaje_sexo"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_seguro_viaje_fecha_nacimiento"
                              label="Fecha de nacimiento"
                              type="date"
                              @input="
                                calculoEdadProdIndSeguroViaje(
                                  prod_ind_seguro_viaje_fecha_nacimiento
                                )
                              "
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_seguro_viaje_edad"
                              label="Edad"
                              type="number"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="prod_ind_seguro_viaje_numero_dias"
                              label="Número de días"
                              type="number"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="auto">
                            <v-btn
                              v-if="
                                id_poliza == null &&
                                flag_prod_ind_seguro_viaje == true
                              "
                              color="primary"
                              @click="addProdIndSeguroViajes()"
                              :disabled="deshabilitar_actualizar"
                              >Agregar</v-btn
                            >
                            <v-btn
                              v-if="
                                id_poliza == null &&
                                flag_prod_ind_seguro_viaje == false
                              "
                              color="primary"
                              @click="updateProdIndSeguroViaje()"
                              :disabled="deshabilitar_actualizar"
                              >Aplicar cambios</v-btn
                            >
                            <v-btn
                              v-if="
                                id_poliza != null &&
                                flag_prod_ind_seguro_viaje == true
                              "
                              color="primary"
                              @click="crearProdIndividual(id_poliza, 'nuevo')"
                              :disabled="deshabilitar_actualizar"
                              >Guardar</v-btn
                            >
                            <v-btn
                              v-if="
                                id_poliza != null &&
                                flag_prod_ind_seguro_viaje == false
                              "
                              color="primary"
                              @click="actualizarProdIndSeguroViaje()"
                              :disabled="deshabilitar_actualizar"
                              >Actualizar</v-btn
                            >
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers_prod_ind_seguro_viaje"
                              :items="items_prod_ind_seguro_viaje"
                            >
                              <template
                                v-slot:[`item.fecha_nacimiento`]="{ item }"
                              >
                                <span>
                                  {{ formatoFecha(item.fecha_nacimiento) }}
                                </span>
                              </template>
                              <template v-slot:[`item.editar`]="{ item }">
                                <v-icon
                                  @click="
                                    editarDetallesProdIndSeguroViajes(item)
                                  "
                                  :disabled="deshabilitar_actualizar"
                                >
                                  far fa-edit
                                </v-icon>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- TODO RIESGO CONSTRUCCIÓN -->
                    <v-card
                      v-if="
                        ramo_seguro.descripcion == 'TODO RIESGO CONSTRUCCIÓN'
                      "
                    >
                      <v-card-text>
                        <v-row>
                          <v-col cols="4">
                            <v-text-field
                              v-model="prod_ind_riesgo_constru_n_riesgo"
                              label="N° Registro"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="prod_ind_riesgo_constru_direccion"
                              label="Dirección"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="
                                prod_ind_riesgo_constru_sum_aseg_construcciones
                              "
                              label="Suma asegurada construcciones"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              v-model="
                                prod_ind_riesgo_constru_sum_aseg_contenido
                              "
                              label="Suma asegurada contenido"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              v-model="
                                prod_ind_riesgo_constru_sum_aseg_existencias
                              "
                              label="Suma asegurada existencias"
                              outlined
                              dense
                              hide-details
                              prefix="$"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="
                                prod_ind_riesgo_constru_intr_neg_lucr_ces_perd_unid
                              "
                              label="Interrupcion negocios/ lucro cesante/ perdida utilidades"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-autocomplete
                              v-model="prod_ind_riesgo_constru_cesion"
                              :items="['SI', 'NO']"
                              label="Cesión"
                              outlined
                              dense
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="6">
                            <v-textarea
                              v-model="prod_ind_riesgo_constru_riesgos_cubierto"
                              label="Riesgos basicos cubiertos"
                              outlined
                              dense
                              hide-details
                              auto-grow
                              row-height="1"
                              append-icon="fas fa-plus"
                              @click:append="addProdIndRiesgoConstruRiesgo()"
                              :disabled="deshabilitar_actualizar"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="6">
                            <v-textarea
                              v-model="prod_ind_riesgo_constru_cobertura_compl"
                              label="Cobertura complementarias"
                              outlined
                              dense
                              hide-details
                              auto-grow
                              row-height="1"
                              append-icon="fas fa-plus"
                              @click:append="addProdIndRiesgoConstruCobertura()"
                              :disabled="deshabilitar_actualizar"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="6">
                            <v-expansion-panels focusable accordion>
                              <v-expansion-panel
                                v-for="(
                                  item, i
                                ) in items_prod_ind_riesgo_constru_riesgos"
                                :key="i"
                              >
                                <v-expansion-panel-header>
                                  {{ "item " + (i + 1) }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pa-1">
                                  <v-row no-gutters>
                                    <v-textarea
                                      outlined
                                      auto-grow
                                      row-height="1"
                                      dense
                                      hide-details
                                      v-model="item.nombre"
                                    ></v-textarea>
                                    <v-spacer></v-spacer>
                                    <v-col
                                      cols="auto"
                                      v-if="item.id_riesgo > 0"
                                      align-self="center"
                                    >
                                      <v-btn
                                        outlined
                                        @click="
                                          editarItemIndRiesgoConstruRiesgo(item)
                                        "
                                        :disabled="deshabilitar_actualizar"
                                      >
                                        <v-icon color="primary"
                                          >fas fa-save</v-icon
                                        >
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                          <v-col cols="6">
                            <v-expansion-panels focusable accordion>
                              <v-expansion-panel
                                v-for="(
                                  item, i
                                ) in items_prod_ind_riesgo_constru_coberturas"
                                :key="i"
                              >
                                <v-expansion-panel-header>
                                  {{ "item " + (i + 1) }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pa-1">
                                  <v-row no-gutters>
                                    <v-textarea
                                      outlined
                                      auto-grow
                                      row-height="1"
                                      dense
                                      hide-details
                                      v-model="item.nombre"
                                    ></v-textarea>
                                    <v-spacer></v-spacer>
                                    <v-col
                                      cols="auto"
                                      v-if="item.id_cobertura > 0"
                                      align-self="center"
                                    >
                                      <v-btn
                                        outlined
                                        @click="
                                          editarItemIndRiesgoConstruCobertura(
                                            item
                                          )
                                        "
                                        :disabled="deshabilitar_actualizar"
                                      >
                                        <v-icon color="primary"
                                          >fas fa-save</v-icon
                                        >
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="
                              id_poliza == null &&
                              flag_editar_prod_ind_riesgo_constru == false
                            "
                          >
                            <v-btn
                              color="primary"
                              @click="addProdIndRiesgoConstru()"
                              :disabled="deshabilitar_actualizar"
                              >Agregar</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="
                              id_poliza == null &&
                              flag_editar_prod_ind_riesgo_constru == true
                            "
                          >
                            <v-btn
                              color="primary"
                              @click="updateProdIndRiesgoConstru()"
                              :disabled="deshabilitar_actualizar"
                              >Aplicar cambios</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="id_poliza && prod_ind_id_riesgo_constru == 0"
                          >
                            <v-btn
                              color="primary"
                              @click="crearProdIndividual(id_poliza, 'nuevo')"
                              :disabled="deshabilitar_actualizar"
                              >Guardar</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            v-if="id_poliza && prod_ind_id_riesgo_constru != 0"
                          >
                            <v-btn
                              color="primary"
                              @click="actualizarProdIndRiesgoConstru()"
                              :disabled="deshabilitar_actualizar"
                              >Actualizar</v-btn
                            >
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers_prod_ind_riesgo_constru"
                              :items="items_prod_ind_riesgo_constru"
                            >
                              <template v-slot:[`item.editar`]="{ item }">
                                <v-icon
                                  @click="editarDetallesProdRiesgoConstru(item)"
                                  :disabled="deshabilitar_actualizar"
                                >
                                  far fa-edit
                                </v-icon>
                              </template>
                              <template
                                v-slot:[`item.sum_aseg_construcciones`]="{
                                  item,
                                }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(item.sum_aseg_construcciones)
                                  }}
                                </span>
                              </template>
                              <template
                                v-slot:[`item.sum_aseg_contenido`]="{ item }"
                              >
                                <span>
                                  ${{ formatoMoneda(item.sum_aseg_contenido) }}
                                </span>
                              </template>
                              <template
                                v-slot:[`item.sum_aseg_existencias`]="{ item }"
                              >
                                <span>
                                  ${{
                                    formatoMoneda(item.sum_aseg_existencias)
                                  }}
                                </span>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- TIPO DE FIANZAS -->
                    <v-card v-if="ramo_seguro.descripcion == 'TIPO DE FIANZAS'">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-autocomplete
                              v-model="prod_ind_fianza_tipo_fianza"
                              :items="[
                                'OFERTA',
                                'FIEL CUMPLIMIENTO',
                                'BUENA CALIDAD',
                                'BUENA OBRA',
                                'ANTICIPO',
                              ]"
                              label="Tipo"
                              outlined
                              dense
                              hide-details
                              clearable
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="prod_ind_fianza_otras"
                              label="Otras"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-textarea
                              v-model="prod_ind_fianza_especificar"
                              label="Especificar"
                              outlined
                              dense
                              hide-details
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <!-- CERTIFICADOS -->
                  <v-tab-item
                    v-if="tipo_seguro.descripcion == 'COLECTIVO' && poliza"
                  >
                    <v-data-table
                      :headers="getHeadersCertificados()"
                      :items="items_certificados"
                      disable-pagination
                      :search="search_certificados"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-row no-gutters>
                            <v-col cols="auto">
                              <v-btn
                                color="primary"
                                @click="abrirDialogCertificado()"
                                :disabled="
                                  deshabilitar_actualizar || !id_poliza
                                "
                                >Agregar certificado</v-btn
                              >
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="6">
                              <v-text-field
                                v-model="search_certificados"
                                label="Busqueda"
                                outlined
                                dense
                                hide-details
                                single-line
                              ></v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                          </v-row>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.fecha_inicio`]="{ item }">
                        <span>
                          {{ formatoFecha(item.fecha_inicio) }}
                        </span>
                      </template>
                      <template v-slot:[`item.fecha_fin`]="{ item }">
                        <span>
                          {{ formatoFecha(item.fecha_fin) }}
                        </span>
                      </template>
                      <template v-slot:[`item.prima`]="{ item }">
                        <span> ${{ formatoMoneda(item.prima) }} </span>
                      </template>
                      <template v-slot:[`item.cuota`]="{ item }">
                        <span> ${{ formatoMoneda(item.cuota) }} </span>
                      </template>
                      <template v-slot:[`item.propietario`]="{ item }">
                        <span> {{ item.propietario }} </span>
                      </template>
                      <template v-slot:[`item.pagado`]="{ item }">
                        <v-chip v-if="item.pagado" color="green" dark
                          >Pagado</v-chip
                        >
                        <v-chip v-else color="red" dark>No pagado</v-chip>
                      </template>
                      <template v-slot:[`item.action_delete`]="{ item }">
                        <v-icon
                          @click="confirmarEliminarCertificado(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          fas fa-trash-alt
                        </v-icon>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                          @click="editarCertificado(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          far fa-edit
                        </v-icon>
                      </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <!-- CERTIFICADOS EXCLUIDOS -->
                    <v-data-table
                      :headers="getHeadersCertificados()"
                      :items="items_certificados_excluidos"
                      disable-pagination
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-row no-gutters>
                            <v-col cols="12">
                              <span class="title">EXCLUIDOS</span>
                            </v-col>
                          </v-row>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.fecha_inicio`]="{ item }">
                        <span>
                          {{ formatoFecha(item.fecha_inicio) }}
                        </span>
                      </template>
                      <template v-slot:[`item.fecha_fin`]="{ item }">
                        <span>
                          {{ formatoFecha(item.fecha_fin) }}
                        </span>
                      </template>
                      <template v-slot:[`item.prima`]="{ item }">
                        <span> ${{ formatoMoneda(item.prima) }} </span>
                      </template>
                      <template v-slot:[`item.cuota`]="{ item }">
                        <span> ${{ formatoMoneda(item.cuota) }} </span>
                      </template>
                      <template v-slot:[`item.propietario`]="{ item }">
                        <span> {{ item.propietario }} </span>
                      </template>
                      <template v-slot:[`item.action_delete`]="{ item }">
                        <v-icon
                          @click="confirmarEliminarCertificado(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          fas fa-trash-alt
                        </v-icon>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                          class="mr-2"
                          @click="editarCertificado(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          far fa-edit
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <!-- RIESGOS Y COBERTURAS -->
                  <v-tab-item v-if="categoria.descripcion == 'DAÑOS'">
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6">
                            <v-textarea
                              v-model="prod_ind_incendio_riesgos_cubierto"
                              label="Riesgos basicos cubiertos"
                              outlined
                              dense
                              hide-details
                              auto-grow
                              row-height="1"
                              append-icon="fas fa-plus"
                              @click:append="addProdIndIncendioRiesgo()"
                              :disabled="deshabilitar_actualizar"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="6">
                            <v-textarea
                              v-model="prod_ind_incendio_cobertura_compl"
                              label="Cobertura complementarias"
                              outlined
                              dense
                              hide-details
                              auto-grow
                              row-height="1"
                              append-icon="fas fa-plus"
                              @click:append="addProdIndIncendioCobertura()"
                              :disabled="deshabilitar_actualizar"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <v-divider></v-divider>
                          </v-col>
                          <v-col cols="6">
                            <v-row
                              v-for="(item, i) in items_prod_ind_riesgos"
                              :key="i"
                            >
                              <v-textarea
                                outlined
                                auto-grow
                                row-height="1"
                                dense
                                hide-details
                                v-model="item.nombre"
                                class="py-1 pl-3"
                              ></v-textarea>
                              <v-col
                                cols="auto"
                                v-if="item.id_riesgo > 0"
                                class="py-1"
                              >
                                <v-btn
                                  outlined
                                  @click="eliminarItemIndIncendioRiesgo(item)"
                                  :disabled="deshabilitar_actualizar"
                                >
                                  <v-icon color="red">far fa-trash-alt</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="6">
                            <v-row
                              v-for="(item, i) in items_prod_ind_coberturas"
                              :key="i"
                            >
                              <v-textarea
                                outlined
                                auto-grow
                                row-height="1"
                                dense
                                hide-details
                                v-model="item.nombre"
                                class="py-1 pl-3"
                              ></v-textarea>
                              <v-col
                                cols="auto"
                                v-if="item.id_cobertura > 0"
                                class="py-1"
                              >
                                <v-btn
                                  outlined
                                  @click="
                                    eliminarItemIndIncendioCobertura(item)
                                  "
                                  :disabled="deshabilitar_actualizar"
                                >
                                  <v-icon color="red">far fa-trash-alt</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <!-- BENEFICIOS -->
                  <v-tab-item v-if="categoria.descripcion == 'PERSONAS'">
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              v-model="nombre_beneficio"
                              label="Ingrese el texto"
                              outlined
                              dense
                              hide-details
                              append-icon="fas fa-plus"
                              @click:append="addBeneficios()"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12">
                            <v-row
                              v-for="(item, i) in items_beneficios"
                              :key="i"
                            >
                              <v-textarea
                                v-model="item.nombre"
                                outlined
                                dense
                                hide-details
                                auto-grow
                                row-height="1"
                                class="py-1 px-3"
                              ></v-textarea>
                              <v-btn
                                v-if="item.id_beneficio > 0"
                                outlined
                                icon
                                :disabled="!puedeEliminar"
                                @click="confirmarEliminarBeneficio(item)"
                              >
                                <v-icon color="red">far fa-trash-alt</v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <!-- RECLAMOS -->
                  <v-tab-item v-if="poliza && id_cliente && id_seguro">
                    <v-data-table
                      :headers="headers_reclamos"
                      :items="items_reclamos"
                      disable-pagination
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-row no-gutters>
                            <v-col cols="12">
                              <v-btn
                                color="primary"
                                @click="abrirDialogReclamo()"
                                :disabled="deshabilitar_actualizar"
                                >Agregar reclamo</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.fecha_reclamo`]="{ item }">
                        <div>{{ formatoFecha(item.fecha_reclamo) }}</div>
                      </template>
                      <template v-slot:[`item.fecha_pago`]="{ item }">
                        <div>{{ formatoFecha(item.fecha_pago) }}</div>
                      </template>
                      <template v-slot:[`item.action_eliminar`]="{ item }">
                        <v-icon
                          class="mr-2"
                          @click="eliminarReclamo(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          fas fa-trash-alt
                        </v-icon>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                          @click="editarReclamo(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          far fa-edit
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <!-- ARCHIVOS -->
                  <v-tab-item>
                    <v-row justify="center">
                      <v-col cols="12" md="2" class="mt-5 pa-2">
                        <v-autocomplete
                          v-model="tipo_carpeta_archivo_poliza"
                          :items="items_tipo_carpeta_archivo_poliza"
                          label="Tipo carpeta"
                          outlined
                          dense
                          hide-details
                          @change="listarArchivos(id_poliza)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                        class="mt-5 pa-2"
                        v-if="
                          false &&
                          tipo_carpeta_archivo_poliza == 'CERTIFICADO' &&
                          tipo_seguro.descripcion == 'COLECTIVO'
                        "
                      >
                        <v-autocomplete
                          v-model="asignacion_certificados_archivos"
                          :items="items_certificados"
                          item-text="num_certificado"
                          item-value="num_certificado"
                          label="# Certificado"
                          outlined
                          dense
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6" class="mt-5 pa-2">
                        <v-file-input
                          v-model="file_input"
                          label="Archivo"
                          outlined
                          dense
                          multiple
                        >
                        </v-file-input>
                      </v-col>
                      <v-col cols="auto" class="mt-5">
                        <v-btn
                          color="primary"
                          @click="crearArchivo()"
                          :disabled="deshabilitar_actualizar"
                          >Guardar</v-btn
                        >
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-data-table
                          v-model="archivos_seleccionados"
                          :headers="headers_archivos"
                          :items="items_archivos"
                          :show-select="id_poliza != null"
                        >
                          <template v-slot:top v-if="id_poliza != null">
                            <v-toolbar
                              flat
                              v-if="
                                bandera_archivos == 2 &&
                                archivos_seleccionados.length > 0
                              "
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                color="green"
                                dark
                                @click="descargarArchivo()"
                                >Descargar archivos</v-btn
                              >
                            </v-toolbar>
                          </template>

                          <template v-slot:[`item.opciones`]="{ item }">
                            <v-icon @click="vistaPreviaArchivo(item)">
                              far fa-folder-open
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <!-- BENEFICIARIOS -->
                  <v-tab-item
                    v-if="
                      categoria.descripcion == 'PERSONAS' &&
                      tipo_seguro.descripcion == 'INDIVIDUAL'
                    "
                  >
                    <v-data-table
                      :headers="headers_detalles"
                      :items="items_detalles"
                      disable-pagination
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-btn
                            color="primary"
                            @click="abrirDialogDetalles()"
                            :disabled="deshabilitar_actualizar"
                            >NUEVO</v-btn
                          >
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.porcentaje`]="{ item }">
                        <span> {{ item.porcentaje }} % </span>
                      </template>
                      <template v-slot:[`item.action_edit`]="{ item }">
                        <v-icon
                          @click="editarDetalles(item)"
                          :disabled="deshabilitar_actualizar"
                        >
                          far fa-edit
                        </v-icon>
                      </template>
                      <template
                        v-slot:[`item.action_delete`]="{ item }"
                        v-if="puedeEliminar"
                      >
                        <v-icon
                          @click="
                            confirmarEliminarBeneficiarioProdIndVida(item)
                          "
                          :disabled="deshabilitar_actualizar"
                        >
                          fas fa-trash-alt
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <!-- OBSERVACIONES -->
                  <v-tab-item>
                    <v-card>
                      <v-card-title>
                        <v-btn
                          color="primary"
                          @click="nuevaObservacionPoliza()"
                          :disabled="deshabilitar_actualizar"
                          >Nuevo</v-btn
                        >
                      </v-card-title>
                      <v-card-text>
                        <v-list dense>
                          <v-list-item
                            v-for="(item, i) in items_observaciones_poliza"
                            :key="i"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-textarea
                                  v-model="item.observaciones"
                                  single-line
                                  auto-grow
                                  row-height="1"
                                  outlined
                                  dense
                                  hide-details
                                ></v-textarea>
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action v-if="poliza">
                              <v-row>
                                <v-col
                                  cols="auto"
                                  v-if="item.id_observaciones_poliza"
                                >
                                  <v-btn
                                    color="primary"
                                    dark
                                    @click="actualizarObservacionesPoliza(item)"
                                    :disabled="deshabilitar_actualizar"
                                  >
                                    Actualizar
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            v-if="bandera == 2 && !deshabilitar_actualizar && id_poliza"
            @click="actualizarPoliza()"
            >ACTUALIZAR POLIZA</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera == 1 && !id_poliza"
            @click="crearPoliza()"
            >GUARDAR</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CONFIRMAR ELIMINAR CUOTAS SELECCIONADAS -->
    <v-dialog
      v-model="dialog_confirmar_eliminar_cuotas_seleccionadas"
      width="500"
    >
      <v-card>
        <v-card-title>¿Esta seguro de eliminar los registros?</v-card-title>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarCuotasSeleccionadas()"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialog_confirmar_eliminar_cuotas_seleccionadas =
                !dialog_confirmar_eliminar_cuotas_seleccionadas
            "
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CONFIRMAR ELIMINAR LOS CERTIFIADOS DE TRANSPORTE MERCADERIA -->
    <v-dialog v-model="dialog_eliminar_cert_transporte_mercaderia" width="500">
      <v-card>
        <v-card-title>¿Esta seguro de eliminar el resigtro?</v-card-title>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarCertificado()"
            >Confirmar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialog_eliminar_cert_transporte_mercaderia =
                !dialog_eliminar_cert_transporte_mercaderia
            "
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- BENEFICIARIOS INDIVIDUAL VIDA -->
    <v-dialog
      v-model="dialog_individual_vida_beneficiario"
      width="1000"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>Beneficiarios</v-card-title>
        <v-card-text class="pt-1">
          <v-row>
            <v-col cols="5">
              <v-text-field
                v-model="individual_vida_beneficiario_parentesco"
                label="Parentesco"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="individual_vida_beneficiario_nombre"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" align-self="center">
              <v-checkbox
                v-model="individual_vida_beneficiario_excluido"
                class="ma-0"
                label="Excluido"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="individual_vida_beneficiario_fecha_nacimiento"
                label="Fecha nacimiento"
                outlined
                dense
                hide-details
                type="date"
                @input="
                  calculoEdadProdIndVidaBeneficiarios(
                    individual_vida_beneficiario_fecha_nacimiento
                  )
                "
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="individual_vida_beneficiario_edad"
                label="Edad"
                outlined
                dense
                hide-details
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="individual_vida_beneficiario_sexo"
                :items="['MASCULINO', 'FEMENINO']"
                label="Sexo"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="individual_vida_beneficiario_porcentaje"
                label="Porcentaje"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers_individual_vida_beneficiarios"
                :items="items_individual_vida_beneficiarios"
              >
                <template v-slot:[`item.fecha_nacimiento`]="{ item }">
                  <span>{{ formatoFecha(item.fecha_nacimiento) }}</span>
                </template>
                <template v-slot:[`item.action_eliminar`]="{ item }">
                  <v-icon
                    @click="eliminarProdIndVidaBeneficiario(item)"
                    v-if="esAdministrador"
                    >fas fa-trash-alt</v-icon
                  >
                </template>
                <template v-slot:[`item.action_editar`]="{ item }">
                  <v-icon @click="editarProdIndVidaBeneficiarios(item)"
                    >far fa-edit</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers_individual_vida_beneficiarios"
                :items="items_individual_vida_beneficiarios_excluidos"
              >
                <template v-slot:[`item.fecha_nacimiento`]="{ item }">
                  <span>{{ formatoFecha(item.fecha_nacimiento) }}</span>
                </template>
                <template v-slot:[`item.action_eliminar`]="{ item }">
                  <v-icon @click="eliminarProdIndVidaBeneficiario(item)"
                    >fas fa-trash-alt</v-icon
                  >
                </template>
                <template v-slot:[`item.action_editar`]="{ item }">
                  <v-icon @click="editarProdIndVidaBeneficiarios(item)"
                    >far fa-edit</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!id_poliza && flag_individual_vida_beneficiario == 1"
            color="primary"
            @click="agregarProdIndVidaBeneficiarios()"
          >
            Agregar nuevo
          </v-btn>
          <v-btn
            v-if="!id_poliza && flag_individual_vida_beneficiario == 2"
            color="primary"
            @click="aplicarCambiosProdIndVidaBeneficiarios()"
            >Aplicar cambios</v-btn
          >
          <v-btn
            color="primary"
            v-if="id_poliza && flag_individual_vida_beneficiario == 1"
            @click="crearProdIndVidaBeneficiarios()"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            v-if="id_poliza && flag_individual_vida_beneficiario == 2"
            @click="actualizarProdIndVidabeneficiarios()"
            >Actualizar</v-btn
          >
          <v-btn
            color="primary"
            @click="
              dialog_individual_vida_beneficiario =
                !dialog_individual_vida_beneficiario
            "
            >Finalizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="limpiarIndvidualVidaBeneficiarios()"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CONFIRMAR ELIMINAR LOS BENEFICIARIOS DE INDIVIDUAL DE VIDA -->
    <v-dialog v-model="dialog_eliminar_ind_vida_beneficiarios" width="500">
      <v-card>
        <v-card-title>¿Esta seguro de eliminar el resigtro?</v-card-title>
        <v-card-actions>
          <v-btn
            color="red"
            dark
            @click="confirmarEliminarProdIndVidaBeneficiarios()"
            >Confirmar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialog_eliminar_ind_vida_beneficiarios =
                !dialog_eliminar_ind_vida_beneficiarios
            "
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- BENEFICIARIOS COLECTIVO VIDA -->
    <v-dialog
      v-model="dialog_colectivo_vida_beneficiario"
      width="1000"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>Beneficiarios</v-card-title>
        <v-card-text class="pt-1">
          <v-row>
            <v-col cols="5">
              <v-text-field
                v-model="colectivo_vida_beneficiario_parentesco"
                label="Parentesco"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="colectivo_vida_beneficiario_nombre"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" align-self="center">
              <v-checkbox
                v-model="colectivo_vida_beneficiario_excluido"
                class="ma-0"
                label="Excluido"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="colectivo_vida_beneficiario_fecha_nacimiento"
                label="Fecha nacimientoo"
                outlined
                dense
                hide-details
                type="date"
                @input="
                  calculoEdadProdCertificadoVida(
                    colectivo_vida_beneficiario_fecha_nacimiento
                  )
                "
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="colectivo_vida_beneficiario_edad"
                label="Edad"
                outlined
                dense
                hide-details
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="colectivo_vida_beneficiario_sexo"
                :items="['MASCULINO', 'FEMENINO']"
                label="Sexo"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="colectivo_vida_beneficiario_porcentaje"
                label="Porcentaje"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers_colectivo_vida_beneficiarios"
                :items="items_colectivo_vida_beneficiarios"
              >
                <template v-slot:[`item.fecha_nacimiento`]="{ item }">
                  <span>{{ formatoFecha(item.fecha_nacimiento) }}</span>
                </template>
                <template v-slot:[`item.action_eliminar`]="{ item }">
                  <v-icon
                    @click="eliminarProdColVidaBeneficiario(item)"
                    v-if="esAdministrador"
                    >fas fa-trash-alt</v-icon
                  >
                </template>
                <template v-slot:[`item.action_editar`]="{ item }">
                  <v-icon @click="editarProdColVidaBeneficiarios(item)"
                    >far fa-edit</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers_colectivo_vida_beneficiarios"
                :items="items_colectivo_vida_beneficiarios_excluidos"
              >
                <template v-slot:[`item.fecha_nacimiento`]="{ item }">
                  <span>{{ formatoFecha(item.fecha_nacimiento) }}</span>
                </template>
                <template v-slot:[`item.action_eliminar`]="{ item }">
                  <v-icon @click="eliminarProdColVidaBeneficiario(item)"
                    >fas fa-trash-alt</v-icon
                  >
                </template>
                <template v-slot:[`item.action_editar`]="{ item }">
                  <v-icon @click="editarProdColVidaBeneficiarios(item)"
                    >far fa-edit</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!id_certificado && flag_colectivo_vida_beneficiario == 1"
            color="primary"
            @click="agregarProdColVidaBeneficiarios()"
          >
            Agregar nuevo
          </v-btn>
          <v-btn
            v-if="!id_certificado && flag_colectivo_vida_beneficiario == 2"
            color="primary"
            @click="aplicarCambiosProdColVidaBeneficiarios()"
            >Aplicar cambios</v-btn
          >
          <v-btn
            color="primary"
            v-if="id_certificado && flag_colectivo_vida_beneficiario == 1"
            @click="crearProdColVidaBeneficiarios()"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            v-if="id_certificado && flag_colectivo_vida_beneficiario == 2"
            @click="actualizarProdColVidabeneficiarios()"
            >Actualizar</v-btn
          >
          <v-btn
            color="primary"
            @click="
              dialog_colectivo_vida_beneficiario =
                !dialog_colectivo_vida_beneficiario
            "
            >Finalizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="limpiarColectivoVidaBeneficiarios()"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CONFIRMAR ELIMINAR LOS BENEFICIARIOS DE INDIVIDUAL DE VIDA -->
    <v-dialog v-model="dialog_eliminar_col_vida_beneficiarios" width="500">
      <v-card>
        <v-card-title>¿Esta seguro de eliminar el resigtro?</v-card-title>
        <v-card-actions>
          <v-btn
            color="red"
            dark
            @click="confirmarEliminarProdColVidaBeneficiarios()"
            >Confirmar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialog_eliminar_col_vida_beneficiarios =
                !dialog_eliminar_col_vida_beneficiarios
            "
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--AL PRESIONAR EL CHECKBOX DE CUOTAS GENERADAS -->
    <v-dialog v-model="dialog_cuotas_tipo_documento" width="500" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="cuota_fecha"
                label="Fecha"
                outlined
                dense
                hide-details
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="cuota_monto"
                label="Monto"
                outlined
                dense
                hide-details
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="cuota_fecha_pago"
                label="Fecha pago"
                type="date"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="id_tipo_documentos"
                label="Tipo documento"
                :items="items_cuota_tipo_documento"
                item-text="nombre"
                item-value="id_tipo_documentos"
                dense
                outlined
                hide-details
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="cuota_num_documento"
                label="# Documento"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            v-if="bandera_cuota == 1"
            @click="aplicarCambiosCuotas()"
            >APLICAR CAMBIOS</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_cuota == 2"
            @click="actualizarCuotas()"
            >ACTUALIZAR</v-btn
          >
          <v-tooltip top class="mx-1" v-if="bandera_cuota == 2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                @click="restablecerCuotas()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>fas fa-undo-alt</v-icon>
              </v-btn>
            </template>
            <span>Restablecer cuota</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogCuotaTipoDocumento()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--VENTANA DE GESTION DE COBRO DE CUOTAS POLIZA -->
    <v-dialog v-model="dialog_cuotas_gestion_cobro" width="800" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="auto">
              <v-btn color="primary" @click="nuevaGestionCobro()"
                >Nueva gestión</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers_gestion_cobro"
                    :items="items_gestion_cobro"
                  >
                    <template v-slot:[`item.fecha`]="{ item }">
                      <span>{{ formatoFecha(item.fecha) }}</span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogCuotaGestionCobro()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog
        v-model="dialog_gestion_cobro_observaciones"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="fecha_cuota_gestion_cobro"
                  label="Fecha gestión de cobro"
                  type="date"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="observaciones_cuota_gestion_cobro"
                  label="Observaciones"
                  outlined
                  dense
                  hide-details
                  auto-grow
                  row-height="10"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="crearGestionCobro()">Guardar</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="cerrarDialogGestionCobroObserva()"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>

    <!--LISTA DE CLIENTES A SELECCIONAR -->
    <v-dialog v-model="dialog_clientes" width="1000">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-data-table
            :items="items_clientes"
            :headers="headers_clientes"
            @dblclick:row="setCliente"
            :search="search_cliente"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Clientes</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search_cliente"
                  append-icon="fas fa-search"
                  label="Busqueda"
                  single-line
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <div class="subtitle-1 font-weight-bold">
                  [Haga doble clic para seleccionar el cliente]
                </div>
              </v-toolbar>
            </template>
            <template v-slot:[`item.con_retencion`]="{ item }">
              <div>
                <span>{{ formatoBooleano(item.con_retencion) }}</span>
              </div>
            </template>
            <template v-slot:[`item.con_percepcion`]="{ item }">
              <div>
                <span>{{ formatoBooleano(item.con_percepcion) }}</span>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--VENTANA DE CERTIFICADO -->
    <v-dialog v-model="dialog_certificado" width="1500" persistent scrollable>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row no-gutters class="mt-1">
            <v-col cols="6">
              <v-text-field
                v-model="num_certificado"
                label="# Certificado"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-checkbox
                v-model="certificado_pagado"
                label="Pagado"
                dense
                hide-details
                @click="
                  !certificado_pagado
                    ? (certificado_fecha_pago = '')
                    : (certificado_fecha_pago = formatoFecha(new Date()))
                "
              ></v-checkbox>
            </v-col>
            <v-col cols="2" class="pr-3">
              <v-text-field
                v-model="certificado_fecha_pago"
                label="Fecha pago"
                outlined
                dense
                hide-details
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="certificado_aviso_cobro"
                label="Aviso cobro"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="pr-3 mt-3">
              <v-text-field
                v-model="certificado_fecha_inicio"
                label="Fecha Desde"
                outlined
                dense
                type="date"
                hide-details
                @input="calculoFecha()"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="pr-3 mt-3">
              <v-text-field
                v-model="certificado_fecha_fin"
                label="Fecha Hasta"
                outlined
                dense
                type="date"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="auto" class="mt-3">
              <v-checkbox
                v-model="certificado_individual"
                label="Individual"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="auto" class="mt-3 ml-1">
              <v-checkbox
                v-model="certificado_inclusion"
                label="Inclusión"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="auto" class="mt-3 ml-1">
              <v-checkbox
                v-model="certificado_excluido"
                label="Excluido"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="4" class="pr-3 mt-3">
              <v-text-field
                v-model="certificado_dia_cobro"
                label="Día cobro"
                outlined
                dense
                type="number"
                hide-details
                :disabled="certificado_individual"
                @input="calculoFechaPrimerPago()"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="pr-3 mt-3">
              <v-text-field
                v-model="certificado_fecha_primer_pago"
                label="Fecha 1° pago"
                outlined
                dense
                type="date"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="mt-3">
              <v-autocomplete
                v-model="certificado_periodo"
                label="Vigencia"
                :items="items_periodos"
                outlined
                dense
                hide-details
                :disabled="certificado_individual"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" class="pr-3 mt-3">
              <v-autocomplete
                v-model="certificado_tipo_cuota"
                label="Tipo cuota"
                :items="items_tipo_cuota"
                item-text="nombre"
                item-value="id_tipo_cuota"
                outlined
                dense
                hide-details
                return-object
                :disabled="certificado_individual"
                @input="calculoCuotaCertificado()"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="3"
              class="pr-3 mt-3"
              v-if="certificado_tipo_cuota != null"
            >
              <v-select
                v-model="certificado_cantidad_cuotas"
                :items="items_cantidad_cuotas"
                label="Cantidad cuotas"
                outlined
                dense
                hide-details
                v-if="certificado_tipo_cuota.nombre == 'MENSUAL'"
                @input="calculoCuotaCertificado()"
              ></v-select>
            </v-col>
            <v-col cols="3" class="pr-3 mt-3">
              <v-text-field
                v-model="certificado_prima"
                label="Prima"
                outlined
                dense
                type="number"
                hide-details
                @change="calculoCuotaCertificado()"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="mt-3">
              <v-text-field
                v-model="certificado_cuota"
                label="Cuota"
                outlined
                dense
                type="number"
                hide-details
                :disabled="certificado_individual"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="certificado_excluido" class="mt-3">
              <v-textarea
                v-model="certificado_motivo_excluido"
                label="Motivo excluido"
                auto-grow
                row-height="10"
                outlined
                dense
                hide-details
              ></v-textarea>
            </v-col>
            <!-- TABS -->
            <v-col cols="12" class="mt-3 lineaMargen">
              <v-tabs
                v-model="tab_certificados"
                background-color="primary"
                dark
                fixed-tabs
              >
                <v-tabs-slider color="black"></v-tabs-slider>
                <v-tab v-if="ramo_seguro.descripcion == 'AUTOS'">Auto</v-tab>
                <v-tab
                  v-if="
                    ramo_seguro.descripcion == 'VIDA' ||
                    ramo_seguro.descripcion == 'ACCIDENTES PERSONALES'
                  "
                  >Vida</v-tab
                >
                <v-tab v-if="ramo_seguro.descripcion == 'GASTOS MEDICOS'"
                  >Gasto médico</v-tab
                >
                <v-tab
                  v-if="ramo_seguro.descripcion == 'TRANSPORTE MERCADERIA'"
                >
                  Transporte mercaderia
                </v-tab>
                <v-tab v-if="ramo_seguro.descripcion == 'INCENDIO'">
                  Incendio
                </v-tab>
                <v-tab>Cuotas</v-tab>
                <v-tab
                  v-if="id_poliza"
                  @click="listarArchivosCertificadoArchivos()"
                  >Archivos</v-tab
                >
              </v-tabs>
              <v-tabs-items v-model="tab_certificados">
                <!-- AUTO -->
                <v-tab-item v-if="ramo_seguro.descripcion == 'AUTOS'">
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="certificado_autos_tipo_vehiculo"
                            label="Tipo vehiculo"
                            :items="items_tipo_vehiculo"
                            outlined
                            dense
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="certificado_autos_marca"
                            label="Marca"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="certificado_autos_modelo"
                            label="Modelo"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="certificado_autos_año"
                            label="Año"
                            outlined
                            dense
                            hide-details
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="certificado_autos_color"
                            label="color"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="certificado_autos_placa"
                            label="Placa"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="certificado_autos_num_chasis"
                            label="No Chasis"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="certificado_autos_num_motor"
                            label="No Motor"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="certificado_autos_version"
                            :items="items_certificado_autos_version"
                            label="Version"
                            outlined
                            dense
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="certificado_autos_cesion"
                            :items="['SI', 'NO']"
                            label="Cesión"
                            outlined
                            dense
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="certificado_autos_vencimiento_tarjeta"
                            label="Vencimiento tarjeta"
                            outlined
                            dense
                            hide-details
                            type="date"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="certificado_autos_vencimiento_dui"
                            label="Vencimiento dui"
                            outlined
                            dense
                            hide-details
                            type="date"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="certificado_autos_propietario"
                            label="Propietario"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!-- VIDA -->
                <v-tab-item
                  v-if="
                    ramo_seguro.descripcion == 'VIDA' ||
                    ramo_seguro.descripcion == 'ACCIDENTES PERSONALES'
                  "
                >
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="2">
                          <v-text-field
                            v-model="certificado_vida_clase"
                            label="Clase"
                            outlined
                            dense
                            hide-details
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            v-model="certificado_vida_nombre"
                            label="Nombre"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-autocomplete
                            v-model="certificado_vida_sexo"
                            label="Sexo"
                            :items="['MASCULINO', 'FEMENINO']"
                            outlined
                            dense
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="certificado_vida_fecha_nacimiento"
                            label="Fecha de nacimiento"
                            outlined
                            dense
                            hide-details
                            type="date"
                            @input="
                              calculoEdadCertificadoVida(
                                certificado_vida_fecha_nacimiento
                              )
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                          <v-text-field
                            v-model="certificado_vida_edad"
                            label="Edad"
                            outlined
                            dense
                            hide-details
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="certificado_vida_dui"
                            label="DUI"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field
                            v-model="certificado_vida_suma_asegurada"
                            label="Suma asegurada"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn
                            color="primary"
                            @click="abrirDialogColectivoBeneficiariosVida()"
                            >Beneficiarios</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!-- GASTO MEDICO -->
                <v-tab-item v-if="ramo_seguro.descripcion == 'GASTOS MEDICOS'">
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="certificado_gasto_medico_clase"
                            label="Clase"
                            outlined
                            dense
                            hide-details
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="certificado_gasto_medico_nombre"
                            label="Nombre"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select
                            v-model="certificado_gasto_medico_sexo"
                            :items="['MASCULINO', 'FEMENINO']"
                            label="Sexo"
                            outlined
                            dense
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field
                            v-model="certificado_gasto_medico_fecha_nacimiento"
                            label="Fecha nacimiento"
                            type="date"
                            outlined
                            dense
                            hide-details
                            @input="
                              calculoEdadCertificadoGastoMedico(
                                certificado_gasto_medico_fecha_nacimiento
                              )
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                          <v-text-field
                            v-model="certificado_gasto_medico_edad"
                            label="Edad"
                            type="number"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="
                              certificado_gasto_medico_max_vit_contratado
                            "
                            label="Máximo vitalicio contratado"
                            outlined
                            dense
                            hide-details
                            type="text"
                            prefix="$"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="certificado_gasto_medico_max_vit_vigente"
                            label="Máximo vitalicio vigente"
                            outlined
                            dense
                            hide-details
                            type="text"
                            prefix="$"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-select
                            v-model="certificado_gasto_medico_cobertura"
                            label="Cobertura"
                            :items="items_certificado_gasto_medico_cobertura"
                            outlined
                            dense
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col cols="12" v-if="id_certificado_gasto_medico">
                          <v-btn
                            color="primary"
                            @click="nuevoDependienteGastoMedico()"
                            >Dependientes</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!-- TRANSPORTE MERCADERIA -->
                <v-tab-item
                  v-if="ramo_seguro.descripcion == 'TRANSPORTE MERCADERIA'"
                >
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          style="border: 1px solid black; border-radius: 5px"
                        >
                          <v-row no-gutters justify="center" align="center">
                            <v-col cols="12" md="4">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_num_trans_merca
                                "
                                label="N° Certificado"
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-1">
                              <v-text-field
                                v-model="cert_transporte_mercaderia_asegurado"
                                label="Asegurado"
                                outlined
                                dense
                                hide-details
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-1">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_tipo_mercaderia
                                "
                                label="Tipo mercaderia"
                                outlined
                                dense
                                hide-details
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-1">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_proveedor_cliente
                                "
                                label="Proveedor o cliente"
                                outlined
                                dense
                                hide-details
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="auto" class="pa-1">
                              <v-checkbox
                                v-model="cert_transporte_mercaderia_maritimo"
                                label="Marítimo"
                                hide-details
                                dense
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="auto" class="pa-1">
                              <v-checkbox
                                v-model="cert_transporte_mercaderia_aereo"
                                label="Aéreo"
                                hide-details
                                dense
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="auto" class="pa-1">
                              <v-checkbox
                                v-model="cert_transporte_mercaderia_terrestre"
                                label="Terrestre"
                                hide-details
                                dense
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" class="pa-1">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_nombre_vapor
                                "
                                label="Nombre vapor"
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-1">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_procedente_de
                                "
                                label="Procedente de"
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-1">
                              <v-text-field
                                v-model="cert_transporte_mercaderia_destino_a"
                                label="Destino a"
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-1">
                              <v-text-field
                                v-model="cert_transporte_mercaderia_num_factura"
                                label="Factura No"
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-1">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_fecha_factura
                                "
                                label="Fecha factura"
                                outlined
                                dense
                                hide-details
                                type="date"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-1">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_fecha_despacho
                                "
                                label="Fecha despacho mercaderia"
                                outlined
                                dense
                                hide-details
                                type="date"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-1">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_vigencia_desde
                                "
                                label="Vigencia desde"
                                outlined
                                dense
                                hide-details
                                type="date"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-1">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_vigencia_hasta
                                "
                                label="Vigencia hasta"
                                outlined
                                dense
                                hide-details
                                type="date"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-row
                            no-gutters
                            style="border: 1px solid black; border-radius: 5px"
                          >
                            <v-col cols="12" class="text-center">
                              <span class="title">Suma Asegurada</span>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-1">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_valor_factura
                                "
                                label="Valor factura"
                                outlined
                                dense
                                hide-details
                                prefix="$"
                                @input="calculoCertTransporteMercaderia()"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-1">
                              <v-text-field
                                v-model="cert_transporte_mercaderia_flete"
                                label="Flete"
                                outlined
                                dense
                                hide-details
                                prefix="$"
                                @input="calculoCertTransporteMercaderia()"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-1">
                              <v-text-field
                                v-model="cert_transporte_mercaderia_sub_total"
                                label="Sub-Total"
                                outlined
                                dense
                                hide-details
                                prefix="$"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-1">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_porcentaje_adicional
                                "
                                label="10% Adicional"
                                outlined
                                dense
                                hide-details
                                prefix="$"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-1">
                              <v-text-field
                                v-model="
                                  cert_transporte_mercaderia_total_suma_asegurada
                                "
                                label="Total suma asegurada"
                                outlined
                                dense
                                hide-details
                                prefix="$"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <v-data-table
                            :headers="headers_cert_transporte_mercaderia"
                            :items="items_cert_transporte_mercaderia"
                          >
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-btn
                                  color="primary"
                                  dark
                                  @click="addCertificadoTransporteMercaderia()"
                                  :disabled="deshabilitar_actualizar"
                                >
                                  Agregar
                                </v-btn>
                              </v-toolbar>
                            </template>

                            <template v-slot:[`item.suma_asegurada`]="{ item }">
                              <span
                                >$ {{ formatoMoneda(item.valor_factura) }}</span
                              >
                            </template>
                            <template v-slot:[`item.flete`]="{ item }">
                              <span>$ {{ formatoMoneda(item.flete) }}</span>
                            </template>
                            <template v-slot:[`item.sub_total`]="{ item }">
                              <span>$ {{ formatoMoneda(item.sub_total) }}</span>
                            </template>
                            <template
                              v-slot:[`item.porcentaje_adicional`]="{ item }"
                            >
                              <span
                                >$
                                {{
                                  formatoMoneda(item.porcentaje_adicional)
                                }}</span
                              >
                            </template>
                            <template
                              v-slot:[`item.total_suma_asegurada`]="{ item }"
                            >
                              <span
                                >$
                                {{
                                  formatoMoneda(item.total_suma_asegurada)
                                }}</span
                              >
                            </template>

                            <template v-slot:[`item.editar`]="{ item }">
                              <v-icon
                                @click="
                                  editarCertificadoTransporteMercaderia(item)
                                "
                                :disabled="deshabilitar_actualizar"
                              >
                                far fa-edit
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!-- INCENDIO -->
                <v-tab-item v-if="ramo_seguro.descripcion == 'INCENDIO'">
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="1">
                          <v-checkbox
                            v-model="prod_cert_incendio_excluir_incendio"
                            label="Excluir"
                            dense
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field
                            v-model="prod_cert_incendio_n_riesgo"
                            label="N° Registro"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="9">
                          <v-text-field
                            v-model="prod_cert_incendio_direccion"
                            label="Dirección"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field
                            v-model="prod_cert_incendio_sum_aseg_construcciones"
                            label="Suma asegurada contrucciones"
                            outlined
                            dense
                            hide-details
                            prefix="$"
                            @input="calculoTotalSumAseguradaCertIncendio()"
                            :disabled="
                              prod_cert_incendio_excluir_construcciones
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-checkbox
                            v-model="prod_cert_incendio_excluir_construcciones"
                            label="Excluir"
                            dense
                            hide-details
                            @click="
                              (prod_cert_incendio_sum_aseg_construcciones = 0),
                                calculoTotalSumAseguradaCertIncendio()
                            "
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field
                            v-model="prod_cert_incendio_sum_aseg_contenido"
                            label="Suma asegurada contenido"
                            outlined
                            dense
                            hide-details
                            prefix="$"
                            @input="calculoTotalSumAseguradaCertIncendio()"
                            :disabled="prod_cert_incendio_excluir_contenido"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-checkbox
                            v-model="prod_cert_incendio_excluir_contenido"
                            label="Excluir"
                            dense
                            hide-details
                            @click="
                              (prod_cert_incendio_sum_aseg_contenido = 0),
                                calculoTotalSumAseguradaCertIncendio()
                            "
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field
                            v-model="prod_cert_incendio_sum_aseg_existencias"
                            label="Suma asegurada existencias"
                            outlined
                            dense
                            hide-details
                            prefix="$"
                            @input="calculoTotalSumAseguradaCertIncendio()"
                            :disabled="prod_cert_incendio_excluir_existencias"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-checkbox
                            v-model="prod_cert_incendio_excluir_existencias"
                            label="Excluir"
                            dense
                            hide-details
                            @click="
                              (prod_cert_incendio_sum_aseg_existencias = 0),
                                calculoTotalSumAseguradaCertIncendio()
                            "
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="prod_cert_incendio_total_sum_aseg"
                            label="Total suma asegurada"
                            outlined
                            dense
                            hide-details
                            prefix="$"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-textarea
                            v-model="prod_cert_incendio_motivo_construcciones"
                            label="Motivo"
                            outlined
                            dense
                            hide-details
                            auto-grow
                            v-if="prod_cert_incendio_excluir_construcciones"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="3">
                          <v-textarea
                            v-model="prod_cert_incendio_motivo_contenido"
                            label="Motivo"
                            outlined
                            dense
                            hide-details
                            auto-grow
                            v-if="prod_cert_incendio_excluir_contenido"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="3">
                          <v-textarea
                            v-model="prod_cert_incendio_motivo_existencias"
                            label="Motivo"
                            outlined
                            dense
                            hide-details
                            auto-grow
                            v-if="prod_cert_incendio_excluir_existencias"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="
                              prod_cert_incendio_intr_neg_lucr_ces_perd_unid
                            "
                            label="Interrupciónnegocios/lucro cesante/perdida utilidades"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-autocomplete
                            v-model="prod_cert_incendio_cesion"
                            :items="['SI', 'NO']"
                            label="Cesion"
                            outlined
                            dense
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="prod_cert_incendio_monto_cesion"
                            label="Monto cedido"
                            outlined
                            dense
                            hide-details
                            prefix="$"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-autocomplete
                            v-model="prod_cert_incendio_beneficiario_cesion"
                            :items="items_beneficiario_cesion"
                            item-text="nombre"
                            item-value="nombre"
                            label="Beneficiario cesion"
                            outlined
                            dense
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn
                            color="primary"
                            @click="addCertificadoIncendio()"
                            >Agregar detalle</v-btn
                          >
                        </v-col>
                        <v-col cols="12">
                          <v-data-table
                            :headers="headers_prod_cert_incendio"
                            :items="items_prod_cert_incendio"
                          >
                            <template v-slot:[`item.editar`]="{ item }">
                              <v-icon @click="editarCertificadoIncendio(item)"
                                >far fa-edit</v-icon
                              >
                            </template>
                            <template v-slot:[`item.action_delete`]="{ item }">
                              <v-icon
                                @click="
                                  confirmarEliminarCertificadoIncendio(item)
                                "
                                >far fa-trash-alt</v-icon
                              >
                            </template>
                          </v-data-table>
                        </v-col>
                        <!-- EXCLUIDOS -->
                        <v-col cols="12">
                          <v-data-table>
                            <template v-slot:top>
                              <span>Excluidos</span>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!-- CUOTAS -->
                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            :items="items_certificado_pagos"
                            :headers="headers_pago_certificados"
                          >
                            <template v-slot:[`item.cont`]="{ item }">
                              <span>
                                {{ item.cont }} /
                                {{ items_certificado_pagos.length }}
                              </span>
                            </template>
                            <template v-slot:[`item.monto`]="{ item }">
                              <v-edit-dialog
                                :return-value.sync="item.monto"
                                large
                                save-text="Guardar"
                                cancel-text="Cancelar"
                                @save="actualizarMontoCuotaCertificado(item)"
                              >
                                {{ formatoMoneda(item.monto) }}
                                <template v-slot:input>
                                  <v-text-field
                                    v-model="item.monto"
                                    label="Editar"
                                    single-line
                                    dense
                                  ></v-text-field>
                                </template>
                              </v-edit-dialog>
                            </template>
                            <template v-slot:[`item.fecha`]="{ item }">
                              <span>
                                {{ formatoFecha(item.fecha) }}
                              </span>
                            </template>
                            <template v-slot:[`item.fecha_pago`]="{ item }">
                              <span>
                                {{ formatoFecha(item.fecha_pago) }}
                              </span>
                            </template>
                            <template
                              v-slot:[`item.agregar_archivo`]="{ item }"
                            >
                              <input
                                ref="uploaderCertFile"
                                class="d-none"
                                type="file"
                                @change="guardarArchivoCertificado"
                                multiple
                              />
                              <v-btn
                                color="primary"
                                rounded
                                @click="agregarArchivoCertificado(item)"
                                :disabled="id_poliza == null"
                              >
                                <v-icon> far fa-save </v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:[`item.ver_archivo`]="{ item }">
                              <v-btn
                                color="primary"
                                rounded
                                @click="verArchivoCertificado(item)"
                                :disabled="id_poliza == null"
                              >
                                <v-icon> far fa-folder-open </v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:[`item.esta_pagada`]="{ item }">
                              <v-btn icon @click="editCertPagos(item)">
                                <v-icon v-if="estadoPagado(item.esta_pagada)"
                                  >far fa-square</v-icon
                                >
                                <v-icon v-else>far fa-check-square</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-slot:[`item.agregar_archivo_pago`]="{ item }"
                            >
                              <input
                                ref="uploaderCertPago"
                                class="d-none"
                                type="file"
                                @change="guardarArchivoCertificadoPago"
                                multiple
                              />
                              <v-btn
                                color="primary"
                                rounded
                                block
                                @click="agregarArchivoCertificadoPago(item)"
                                :disabled="id_poliza == null"
                              >
                                <v-icon> far fa-save </v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-slot:[`item.ver_archivo_pago`]="{ item }"
                            >
                              <v-btn
                                color="primary"
                                rounded
                                block
                                @click="verArchivoCertificadoPago(item)"
                                :disabled="id_poliza == null"
                              >
                                <v-icon> far fa-folder-open </v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-slot:[`body.append`]
                              v-if="
                                id_certificado &&
                                items_certificado_pagos.length != 0
                              "
                            >
                              <tr style="text-align: left">
                                <td colspan="12">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click="
                                          dialog_eliminar_cuotas_certificados = true
                                        "
                                      >
                                        <v-icon color="red">
                                          fas fa-trash-alt
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>ELIMINAR CUOTAS</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!-- ARCHIVOS -->
                <v-tab-item v-if="id_poliza">
                  <v-row justify="center">
                    <v-col cols="12" md="2" class="ma-2" align-self="center">
                      <v-autocomplete
                        v-model="tipo_carpeta_archivo_certificado"
                        label="Tipo carpeta"
                        :items="items_tipo_carpeta_archivo_certificado"
                        outlined
                        dense
                        hide-details
                        @input="listarArchivosCertificadoArchivos()"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      class="ma-2"
                      v-if="tipo_carpeta_archivo_certificado == 'CERTIFICADO'"
                    >
                      <v-autocomplete
                        v-model="certificado_seleccionado_archivo"
                        :items="items_cert_transporte_mercaderia"
                        label="# Certificado"
                        item-text="num_trans_merca"
                        outlined
                        dense
                        hide-details
                        return-object
                        @input="listarArchivosCertificadoArchivos()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" class="ma-2" align-self="center">
                      <v-file-input
                        v-model="file_input_certificado_archivo"
                        label="Archivo"
                        outlined
                        dense
                        multiple
                        hide-details
                      >
                      </v-file-input>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="ma-2"
                      v-if="
                        bandera_archivos_certificado_archivo == 2 &&
                        tipo_carpeta_archivo_certificado != 'CERTIFICADO'
                      "
                      align-self="center"
                    >
                      <v-btn
                        color="primary"
                        @click="crearArchivoCertificadoArchivo(id_certificado)"
                        v-if="id_certificado"
                        >Guardar</v-btn
                      >
                    </v-col>
                    <v-col
                      cols="auto"
                      class="ma-2"
                      v-if="
                        bandera_archivos_certificado_archivo == 2 &&
                        tipo_carpeta_archivo_certificado == 'CERTIFICADO'
                      "
                      align-self="center"
                    >
                      <v-btn
                        color="primary"
                        @click="guardarArchivoCertificadoFactura()"
                        v-if="id_certificado"
                        >Guardar</v-btn
                      >
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-data-table
                        v-model="archivos_seleccionados_certificado_archivo"
                        :headers="headers_archivos"
                        :items="items_archivos_certificado_archivo"
                        item-key="nombre"
                        :show-select="id_poliza != null"
                      >
                        <template v-slot:top v-if="id_poliza != null">
                          <v-toolbar
                            flat
                            v-if="
                              bandera_archivos_certificado_archivo == 2 &&
                              archivos_seleccionados_certificado_archivo.length >
                                0
                            "
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green"
                              dark
                              @click="descargarCertificadoArchivos()"
                              >Descargar archivos</v-btn
                            >
                          </v-toolbar>
                        </template>

                        <template v-slot:[`item.opciones`]="{ item }">
                          <v-icon
                            @click="vistPreviaArchivoPolizaCertificado(item)"
                          >
                            far fa-folder-open
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            v-if="bandera_certificado == 1 && id_poliza"
            @click="crearCertificado()"
            >GUARDAR</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_certificado == 1 && !id_poliza"
            @click="addCertificado()"
            >AGREGAR CERTIFICADO</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_certificado == 2 && id_poliza"
            @click="actualizarCertificado()"
            >ACTUALIZAR</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_certificado == 2 && !id_poliza"
            @click="updateCertificado()"
            >APLICAR CAMBIOS</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_certificado == 2 && !id_poliza"
            @click="deleteCertificado()"
            >REMOVER</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogCertificado()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DEPENDIENTES CERTIFICADO GASTO MEDICO -->
    <v-dialog
      v-model="dialog_dependiantes_cert_gasto_medico"
      width="1200"
      persistent
    >
      <v-card>
        <v-card-title>DEPENDIENTE</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="dependientes_cert_gasto_medico_nombre"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                v-model="dependientes_cert_gasto_medico_excluido"
                label="Excluido"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="dependientes_cert_gasto_medico_fecha_nacimiento"
                label="Fecha nacimiento"
                type="date"
                outlined
                dense
                hide-details
                @input="
                  calculoEdadCertificadoGastoMedicoDependientes(
                    dependientes_cert_gasto_medico_fecha_nacimiento
                  )
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="dependientes_cert_gasto_medico_edad"
                label="Edad"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="dependientes_cert_gasto_medico_sexo"
                :items="['MASCULINO', 'FEMENINO']"
                label="Sexo"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="dependientes_cert_gasto_medico_parentesco"
                label="Parentesco"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="dependientes_cert_gasto_medico_max_vita_contratado"
                label="Máximo vitalicio contratado"
                outlined
                dense
                hide-details
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="dependientes_cert_gasto_medico_max_vita_vigente"
                label="Máximo vitalicio vigente"
                outlined
                dense
                hide-details
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="dependientes_cert_gasto_medico_cobertura"
                :items="items_dependientes_cert_gasto_medico_cobertura"
                label="Cobertura"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-textarea
                v-if="dependientes_cert_gasto_medico_excluido"
                v-model="dependientes_cert_gasto_medico_motivo_excluido"
                label="Motivo excluido"
                outlined
                dense
                hide-details
                auto-grow
                row-height="1"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers_dependientes_cert_gasto_medico"
                :items="items_dependientes_cert_gasto_medico"
              >
                <template v-slot:[`item.fecha_nacimiento`]="{ item }">
                  <span>
                    {{ formatoFecha(item.fecha_nacimiento) }}
                  </span>
                </template>

                <template
                  v-slot:[`item.maximo_vitalicio_contratado`]="{ item }"
                >
                  <span>
                    ${{ formatoMoneda(item.maximo_vitalicio_contratado) }}
                  </span>
                </template>

                <template v-slot:[`item.maximo_vitalicio_vigente`]="{ item }">
                  <span>
                    ${{ formatoMoneda(item.maximo_vitalicio_vigente) }}
                  </span>
                </template>

                <template v-slot:[`item.action_edit`]="{ item }">
                  <v-btn icon @click="editarDependientesCertGastoMedico(item)">
                    <v-icon>fas fa-edit</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.action_delete`]="{ item }">
                  <v-btn
                    icon
                    @click="confirmarEliminarDepenCertGastoMedico(item)"
                  >
                    <v-icon>fas fa-trash-alt</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers_dependientes_cert_gasto_medico"
                :items="items_dependientes_cert_gasto_medico_excluidos"
              >
                <template v-slot:top>
                  <span class="title font-weight-bold"> EXCLUIDOS </span>
                </template>
                <template v-slot:[`item.fecha_nacimiento`]="{ item }">
                  <span>
                    {{ formatoFecha(item.fecha_nacimiento) }}
                  </span>
                </template>

                <template
                  v-slot:[`item.maximo_vitalicio_contratado`]="{ item }"
                >
                  <span>
                    ${{ formatoMoneda(item.maximo_vitalicio_contratado) }}
                  </span>
                </template>

                <template v-slot:[`item.maximo_vitalicio_vigente`]="{ item }">
                  <span>
                    ${{ formatoMoneda(item.maximo_vitalicio_vigente) }}
                  </span>
                </template>

                <template v-slot:[`item.action_edit`]="{ item }">
                  <v-btn icon @click="editarDependientesCertGastoMedico(item)">
                    <v-icon>fas fa-edit</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.action_delete`]="{ item }">
                  <v-btn
                    icon
                    @click="confirmarEliminarDepenCertGastoMedico(item)"
                  >
                    <v-icon>fas fa-trash-alt</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="flag_dependientes_cert_gasto_medico == 1"
            color="primary"
            @click="guardarDependiente()"
            >Guardar</v-btn
          >
          <v-btn
            v-if="flag_dependientes_cert_gasto_medico == 2"
            color="primary"
            @click="actualizarDependientes()"
            >Actualizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogDependietenes()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--AL PRESIONAR EL CHECKBOX DE PAGOS GENERADOS DEL CERTIFICADO -->
    <v-dialog v-model="dialog_cert_pago" width="500" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="cert_pago_fecha"
                label="Fecha"
                outlined
                dense
                hide-details
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="cert_pago_monto"
                label="Monto"
                outlined
                dense
                hide-details
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="cert_pago_fecha_pago"
                label="Fecha pago"
                type="date"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="id_cert_pago_tipo_documentos"
                label="Tipo documento"
                :items="items_cuota_tipo_documento"
                item-text="nombre"
                item-value="id_tipo_documentos"
                dense
                outlined
                hide-details
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="cert_pago_num_documento"
                label="# Documento"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            v-if="bandera_cert_pago == 1"
            @click="aplicarCambiosCertPago()"
            >APLICAR CAMBIOS</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_cert_pago == 2"
            @click="actualizarCertPagos()"
            >ACTUALIZAR</v-btn
          >
          <v-tooltip top class="mx-1" v-if="bandera_cert_pago == 2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                @click="restablecerCertCuotas()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>fas fa-undo-alt</v-icon>
              </v-btn>
            </template>
            <span>Restablecer cuota</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogCertPago()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--AL PRESIONAR EL CHECKBOX DE PAGOS GENERADOS DEL AUMENTO O INCLUSION -->
    <v-dialog v-model="dialog_aumento_inclusion_pago" width="500" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="aumento_inclu_pago_fecha"
                label="Fecha"
                outlined
                dense
                hide-details
                type="date"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="aumento_inclu_pago_monto"
                label="Monto"
                outlined
                dense
                hide-details
                type="number"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="aumento_inclu_pago_fecha_pago"
                label="Fecha pago"
                type="date"
                dense
                outlined
                hide-details
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="id_aumento_inclu_pago_tipo_documentos"
                label="Tipo documento"
                :items="items_cuota_tipo_documento"
                item-text="nombre"
                item-value="id_tipo_documentos"
                dense
                outlined
                hide-details
                return-object
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="aumento_inclu_pago_num_documento"
                label="# Documento"
                dense
                outlined
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            v-if="id_aumento_inclusion == null"
            @click="aplicarCambiosAumentoInclusionesPago()"
            >APLICAR CAMBIOS</v-btn
          >
          <v-btn
            color="primary"
            v-if="id_aumento_inclusion != null"
            @click="actualizarAumentosInclusiones()"
            >ACTUALIZAR</v-btn
          >
          <v-tooltip top class="mx-1" v-if="id_aumento_inclusion != null">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                @click="restablecerAumentoInclusionCuotas()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>fas fa-undo-alt</v-icon>
              </v-btn>
            </template>
            <span>Restablecer cuota</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogAumentoInclusionPago()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--VENTANA DE RECLAMO -->
    <v-dialog v-model="dialog_reclamo" width="800" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="4">
              <v-text-field
                v-model="num_reclamo"
                label="# Reclamo"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="px-2">
              <v-text-field
                v-model="reclamo_poliza"
                label="# Póliza"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="reclamo_placa_nombre"
                label="Placa o nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pr-2 mt-3">
              <v-text-field
                v-model="fecha_reclamo"
                label="Fecha reclamo"
                outlined
                dense
                type="date"
                hide-details
                @input="formatoNumeroReclamo()"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="mt-3">
              <v-text-field
                v-model="reclamo_fecha_pago"
                label="Fecha pago"
                outlined
                dense
                type="date"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-3">
              <v-text-field
                v-model="reclamo_cliente"
                label="Cliente"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-3">
              <v-text-field
                v-model="reclamo_nombre_cliente"
                label="Nombre cliente"
                outlined
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-3">
              <v-autocomplete
                v-model="reclamo_aseguradora"
                label="Aseguradora"
                :items="items_aseguradoras"
                item-text="descripcion"
                item-value="id_aseguradoras"
                outlined
                dense
                hide-details
                disabled
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" class="pr-2 mt-3">
              <v-text-field
                v-model="reclamo_taller"
                label="Taller"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="mt-3">
              <v-autocomplete
                v-model="reclamo_estado"
                :items="items_tipo_reclamos"
                item-text="descripcion"
                item-value="id_tipo_reclamo"
                label="Estado"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" class="pr-2 mt-3">
              <v-text-field
                v-model="reclamo_caracteristica_auto"
                label="Caracteristicas del auto"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="mt-3">
              <v-text-field
                v-model="reclamo_valor_indemnizado"
                label="valor indemnizado"
                outlined
                dense
                hide-details
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-3">
              <v-textarea
                v-model="reclamo_motivo"
                label="Motivo"
                outlined
                dense
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            v-if="bandera_reclamo == 1 && id_poliza != null"
            @click="crearReclamo()"
            >GUARDAR</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_reclamo == 2 && id_poliza != null"
            @click="actualizarReclamo()"
            >ACTUALIZAR</v-btn
          >
          <v-btn color="primary" @click="listarArchivosReclamo()"
            >ARCHIVOS</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_reclamo == 1 && id_poliza == null"
            @click="addReclamo()"
            >AGREGAR RECLAMO</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_reclamo == 2 && id_poliza == null"
            @click="updateReclamo()"
            >APLICAR CAMBIOS</v-btn
          >
          <v-btn
            color="red"
            v-if="bandera_reclamo == 2 && id_poliza == null"
            @click="deleteReclamo()"
            >Remover reclamo</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogReclamo()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA RECLAMOS ARCHIVOS -->
    <v-dialog v-model="dialog_reclamos_archivos" width="1000">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-file-input
                v-model="items_reclamos_archivo_guardar"
                label="Archivo"
                outlined
                dense
                hide-details
                multiple
              ></v-file-input>
            </v-col>
            <v-col cols="6">
              <v-btn color="blue" dark @click="guardarArchivoReclamo()"
                >Guardar</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-data-table
                v-model="items_reclamo_archivo_seleccionados"
                :headers="headers_reclamos_archivos"
                :items="items_reclamos_archivos"
                show-select
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Archivos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :disabled="
                        items_reclamo_archivo_seleccionados.length == 0
                      "
                      dark
                      @click="dercargarReclamoArchivo()"
                    >
                      Descargar
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.ver_archivo`]="{ item }">
                  <v-icon class="mr-2" @click="verArchivoReclamo(item)">
                    far fa-edit
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--VENTANA DE BENEFICIARIOS -->
    <v-dialog v-model="dialog_beneficiarios" width="1000" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="pb-1">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="beneficiarios_parentesco"
                label="Parentesco"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="beneficiarios_nombre"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="beneficiarios_porcentaje"
                label="Porcentaje"
                outlined
                dense
                type="number"
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="beneficiarios_fecha_nacimiento"
                label="Fecha nacimiento"
                outlined
                dense
                type="date"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="beneficiarios_sexo"
                label="Sexo"
                :items="['MASCULINO', 'FEMENINO']"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="beneficiarios_dui"
                label="Dui"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="beneficiarios_nivel"
                label="Nivel"
                :items="['PRIMARIO', 'SECUNDARIO']"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            v-if="bandera_beneficiarios == 1 && id_poliza"
            @click="crearDetalle()"
            >GUARDAR</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_beneficiarios == 1 && !id_poliza"
            @click="addDetalle()"
            >AGREGAR REGISTRO</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_beneficiarios == 2 && id_poliza"
            @click="actualizarDetalle()"
            >ACTUALIZAR</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_beneficiarios == 2 && !id_poliza"
            @click="updateDetalle()"
            >APLICAR CAMBIOS</v-btn
          >
          <v-btn
            color="red"
            dark
            v-if="bandera_beneficiarios == 2 && !id_poliza"
            @click="deleteDetalle()"
            >REMOVER REGISTRO</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogDetalles()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--VENTANA DE NUEVO CLIENTE -->
    <v-dialog v-model="dialog_nuevo_cliente" width="1000" persistent>
      <v-card>
        <v-card-title>Nuevo cliente</v-card-title>
        <v-card-text>
          <v-row class="lineaMargen">
            <v-col cols="12" md="4" class="pa-1">
              <v-autocomplete
                v-model="cliente_tipo_persona"
                label="Tipo persona"
                :items="items_tipo_persona"
                outlined
                dense
                hide-details
                :rules="rules_clientes"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="cliente_codigo"
                label="Código"
                outlined
                dense
                hide-details
                :rules="rules_clientes"
                >{{ clienteTipoPersona }}</v-text-field
              >
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="cliente_fecha_ingreso"
                label="Fecha ingreso"
                outlined
                dense
                hide-details
                type="date"
                :rules="rules_clientes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-1">
              <v-text-field
                v-model="cliente_nombre"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-1">
              <v-text-field
                v-model="cliente_razon_social"
                label="Razón social"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-7 lineaMargen">
            <v-col cols="12" md="6" class="pa-1">
              <v-autocomplete
                v-model="departamento"
                label="Departamento"
                :items="items_departamentos"
                item-text="nombre"
                outlined
                dense
                hide-details
                return-object
                @change="listarMunicipios()"
                :rules="rules_clientes"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-autocomplete
                v-model="municipio"
                label="Municipio"
                :items="items_municipios"
                item-text="nombre"
                outlined
                dense
                hide-details
                return-object
                :rules="rules_clientes"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-1">
              <v-text-field
                v-model="cliente_direccion"
                label="Dirección"
                outlined
                dense
                hide-details
                :rules="rules_clientes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="cliente_fecha_nacimiento"
                label="Fecha nacimiento"
                outlined
                dense
                hide-details
                type="date"
                :rules="rules_clientes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="cliente_dui"
                label="DUI"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="cliente_nit"
                label="NIT"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="cliente_celular"
                label="Celular"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="cliente_telefono"
                label="Teléfono"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="cliente_correo"
                label="Correo"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-5 lineaMargen">
            <v-col cols="12" md="6" class="pa-1">
              <v-text-field
                v-model="cliente_giro"
                label="Giro"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-autocomplete
                v-model="cliente_tamaño"
                label="Tamaño"
                :items="items_tamaño"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-1">
              <v-text-field
                v-model="cliente_registro"
                label="Registro"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-text-field
                v-model="cliente_contacto_1"
                label="Contacto 1"
                outlined
                dense
                hide-details
                :rules="rules_clientes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-text-field
                v-model="cliente_telefono_1"
                label="Teléfono 1"
                outlined
                dense
                hide-details
                :rules="rules_clientes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-text-field
                v-model="cliente_contacto_2"
                label="Contacto 2"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-text-field
                v-model="cliente_telefono_2"
                label="Teléfono 2"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-text-field
                v-model="cliente_contacto_3"
                label="Contacto 3"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-text-field
                v-model="cliente_telefono_3"
                label="Teléfono 3"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue" dark @click="guardarCliente()">Guardar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogAgregarCliente()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--VENTANA DE PRODUCTO INDIVIDUAL DEPENDIENTES - GASTO MEDICO -->
    <v-dialog v-model="prod_ind_depen_dialog" width="1200" persistent>
      <v-card>
        <v-card-title>DEPENDIENTES</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="11">
              <v-text-field
                v-model="prod_ind_depen_gastomedico_nombre"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-checkbox
                v-model="prod_ind_depen_gastomedico_excluido"
                label="Excluido"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="prod_ind_depen_gastomedico_fecha_nacimiento"
                label="Fecha nacimiento"
                type="date"
                outlined
                dense
                hide-details
                @input="
                  calculoEdadProdIndGastoMedicoDependientes(
                    prod_ind_depen_gastomedico_fecha_nacimiento
                  )
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="prod_ind_depen_gastomedico_edad"
                label="Edad"
                type="number"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="prod_ind_depen_gastomedico_sexo"
                :items="['MASCULINO', 'FEMENINO']"
                label="Sexo"
                outlined
                dense
                hide-details
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="prod_ind_depen_gastomedico_parentesco"
                label="Parentesco"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="prod_ind_depen_gastomedico_max_vit_contratado"
                label="Maximo vitalicio contratado"
                outlined
                dense
                hide-details
                prefix="$"
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="prod_ind_depen_gastomedico_max_vit_vigente"
                label="Maximo vitalicio vigente"
                outlined
                dense
                hide-details
                prefix="$"
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="prod_ind_depen_gastomedico_cobertura"
                :items="items_prod_ind_gastomedico_cobertura"
                label="Cobertura"
                outlined
                dense
                hide-details
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-textarea
                v-model="prod_ind_depen_gastomedico_motivo_excluido"
                label="Motivo excluido"
                outlined
                dense
                hide-details
                auto-grow
                row-height="1"
                v-if="prod_ind_depen_gastomedico_excluido"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers_prod_ind_depen_gastomedico"
                :items="items_prod_ind_depen_gastomedico"
              >
                <template v-slot:[`item.fecha_nacimiento`]="{ item }">
                  <span>
                    {{ formatoFecha(item.fecha_nacimiento) }}
                  </span>
                </template>

                <template v-slot:[`item.max_vit_contratado`]="{ item }">
                  <span> ${{ formatoMoneda(item.max_vit_contratado) }} </span>
                </template>

                <template v-slot:[`item.max_vit_vigente`]="{ item }">
                  <span> ${{ formatoMoneda(item.max_vit_vigente) }} </span>
                </template>
                <template v-slot:[`item.editar`]="{ item }">
                  <v-icon @click="editarProduIndDepenGastoMedico(item)">
                    fas fa-edit
                  </v-icon>
                </template>
                <template
                  v-slot:[`item.eliminar`]="{ item }"
                  v-if="puedeEliminar"
                >
                  <v-icon
                    @click="confirmarEliminarProdIndDepenGastoMedico(item)"
                  >
                    fas fa-trash-alt
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
            <!-- EXCLUIDOS -->
            <v-col cols="12">
              <v-data-table
                :headers="headers_prod_ind_depen_gastomedico"
                :items="items_prod_ind_depen_gastomedico_excluidos"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Excluidos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.fecha_nacimiento`]="{ item }">
                  <span>
                    {{ formatoFecha(item.fecha_nacimiento) }}
                  </span>
                </template>

                <template v-slot:[`item.max_vit_contratado`]="{ item }">
                  <span> ${{ formatoMoneda(item.max_vit_contratado) }} </span>
                </template>

                <template v-slot:[`item.max_vit_vigente`]="{ item }">
                  <span> ${{ formatoMoneda(item.max_vit_vigente) }} </span>
                </template>
                <template v-slot:[`item.editar`]="{ item }">
                  <v-icon @click="editarProduIndDepenGastoMedico(item)">
                    fas fa-edit
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="flag_individual == 0"
            color="primary"
            @click="addDependienteGastoMedico()"
            >Agregar</v-btn
          >
          <v-btn
            v-if="prod_ind_depen_bandera_editar == 0"
            color="primary"
            @click="crearProdIndDepenGastoMedico()"
            >Guardar</v-btn
          >
          <v-btn
            v-if="prod_ind_depen_bandera_editar == 1"
            color="primary"
            @click="actualizarProdIndDepenGastoMedico()"
            >Actualizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="limpiarProducoIndividualDepenGastoMedico()"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA PARA CONFIRMAR ELIMINAR UN DEPENDIENTE INDV GASTO MEDICO -->
    <v-dialog
      v-model="dialog_eliminar_prod_cert_incendio"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>¿Esta seguro de eliminar el registro?</v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarProdCertIncendio()"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialog_eliminar_prod_cert_incendio =
                !dialog_eliminar_prod_cert_incendio
            "
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA PARA CONFIRMAR ELIMINAR UN DEPENDIENTE INDV GASTO MEDICO -->
    <v-dialog
      v-model="dialog_eliminar_prod_ind_depen_gastomedico"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>¿Esta seguro de eliminar el registro?</v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarProduIndDepenGastoMedico()"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialog_eliminar_prod_ind_depen_gastomedico =
                !dialog_eliminar_prod_ind_depen_gastomedico
            "
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA PARA CONFIRMAR ELIMINAR UN DEPENDIENTE COLECTIVO GASTO MEDICO -->
    <v-dialog
      v-model="dialog_eliminar_cert_depen_gastomedico"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>¿Esta seguro de eliminar el registro?</v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarDependientesCertGastoMedico()"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialog_eliminar_cert_depen_gastomedico =
                !dialog_eliminar_cert_depen_gastomedico
            "
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA PARA CONFIRMAR ELIMINAR UN BENEFICIARIO -->
    <v-dialog v-model="dialog_eliminar_beneficiarios" width="500" persistent>
      <v-card>
        <v-card-title>¿Esta seguro de eliminar el registro?</v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarBeneficiarioProdIndVida()"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialog_eliminar_beneficiarios = !dialog_eliminar_beneficiarios
            "
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_eliminar_prod_ind_incendio"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>¿Esta seguro de eliminar el registro?</v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarProdIndIncendio()"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialog_eliminar_prod_ind_incendio =
                !dialog_eliminar_prod_ind_incendio
            "
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA DE NUEVO PRODUCTO -->
    <v-dialog v-model="dialog_nuevo_producto" width="800">
      <v-card>
        <v-card-title>Nuevo producto</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="nuevo_producto_codigo"
                label="Codigo"
                readonly
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" align-self="center">
              <v-checkbox
                v-model="nuevo_producto_activo"
                label="Activo"
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="auto" align-self="center">
              <v-checkbox
                v-model="nuevo_producto_exento"
                label="Exento"
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="nuevo_producto_aseguradora"
                :items="items_aseguradoras"
                item-text="descripcion"
                item-value="id_aseguradoras"
                label="Aseguradora"
                outlined
                dense
                hide-details
                return-object
                @change="crearCodigoNuevoProducto"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="nuevo_producto_categoria"
                :items="items_categorias"
                item-text="descripcion"
                item-value="id_categoria_seguros"
                label="Categoria"
                outlined
                dense
                hide-details
                return-object
                @change="crearCodigoNuevoProducto"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="nuevo_producto_ramo"
                :items="items_ramo_seguros_nuevo_prod"
                item-text="descripcion"
                item-value="id_ramo_seguros"
                label="Ramo"
                outlined
                dense
                hide-details
                return-object
                @change="crearCodigoNuevoProducto"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="nuevo_producto_tipo"
                :items="items_tipo_seguros_nuevo_prod"
                item-text="descripcion"
                item-value="id_tipo_seguros"
                label="Tipo"
                outlined
                dense
                hide-details
                return-object
                @change="crearCodigoNuevoProducto"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nuevo_producto_nombre"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="nuevo_producto_descripcion"
                label="Descripción"
                auto-grow
                row-height="10"
                outlined
                dense
                hide-details
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="nuevo_producto_comision"
                label="% Comisión"
                outlined
                dense
                hide-details
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="crearProducto()">Guardar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogNuevoProducto()"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA DE DESACTIVAR POLIZA -->
    <v-dialog v-model="dialog_desactivar_poliza" width="500">
      <v-card>
        <v-card-title>Está seguro de eliminar este registro?</v-card-title>
        <v-card-actions>
          <v-btn color="red" dark @click="desactivarPoliza()">Eliminar</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog_desactivar_poliza = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA QUE INDICA QUE SE ELIMINARAN LAS CUOTAS Y DETALLES DE PAGOS DE CADA CUOTA -->
    <v-dialog v-model="dialog_eliminar_cuotas" width="500">
      <v-card>
        <v-card-title>ADVERTENCIA!</v-card-title>
        <v-card-text>
          <div class="subtitle-1">
            Si se eliminan las cuotas también se eliminarían los detalles de
            pagos de cada cuota de la póliza.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarCuotas()">Si, eliminar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_eliminar_cuotas = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA QUE INDICA QUE SE ELIMINARAN LAS CUOTAS Y DETALLES DE PAGOS DE CADA CUOTA -->
    <v-dialog v-model="dialog_eliminar_cuotas_independiente" width="500">
      <v-card>
        <v-card-title>ADVERTENCIA!</v-card-title>
        <v-card-text>
          <div class="subtitle-1">
            Si se eliminan las cuotas también se eliminarían los detalles de
            pagos de cada cuota de la póliza.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarCuotasIndependientes()"
            >Si, eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_eliminar_cuotas_independiente = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA QUE INDICA QUE SE ELIMINARAN LAS CUOTAS Y DETALLES DE CERTIFICADOS -->
    <v-dialog v-model="dialog_eliminar_cuotas_certificados" width="500">
      <v-card>
        <v-card-title>ADVERTENCIA!</v-card-title>
        <v-card-text>
          <div class="subtitle-1">
            Si se eliminan las cuotas también se eliminarían los detalles de
            pagos de cada cuota de la póliza.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarCuotasCertificados()"
            >Si, eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_eliminar_cuotas_certificados = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA QUE INDICA QUE SE ELIMINARAN LAS CUOTAS Y DETALLES DE AUMENTOS O INCLUSIONES -->
    <v-dialog v-model="dialog_eliminar_cuotas_aumentos_inclusiones" width="500">
      <v-card>
        <v-card-title>ADVERTENCIA!</v-card-title>
        <v-card-text>
          <div class="subtitle-1">
            Si se eliminan las cuotas también se eliminarían los detalles de
            pagos de cada cuota.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarAumentosInclusiones()"
            >Si, eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog_eliminar_cuotas_aumentos_inclusiones = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ARCHIVOS TRASPORTE MERCADERIA -->
    <v-dialog v-model="dialog_archivos_trans_merca" width="1000">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-file-input
                v-model="items_file_input_trans_merca"
                multiple
                label="Seleccionar archivos"
                outlined
                dense
                hide-details
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers_archivos_trans_merca"
                :items="items_archivos_trans_merca"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Archivos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn color="primary" @click="GuardarArchivoTransMerca()"
                      >Guardar</v-btn
                    >
                  </v-toolbar>
                </template>
                <template v-slot:[`item.action_ver`]="{ item }">
                  <v-icon
                    class="mr-2"
                    @click="verArchivosProdIndTransMerca(item)"
                  >
                    fa-solid fa-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import apiOneDrive from "../../ApiOneDrive/API";
import apiLocal from "../../AccesosToken/Api";

export default {
  data: () => ({
    loading_polizas: false,
    tipo_buscador: "Buscar todo",
    search: "",
    search_placa: "",
    search_cliente: "",
    rules: [(v) => !!v || "El campo es obligatorio"],
    headers: [
      { text: "Poliza", value: "num_poliza" },
      { text: "Aseguradora", value: "nombre_aseguradora" },
      { text: "Categoria", value: "nombre_categoria" },
      { text: "Cliente", value: "nombre_cliente" },
      { text: "Seguro", value: "nombre_seguro" },
      { text: "Estado", value: "activo", align: "center" },
      { text: "Creado por", value: "nombre_usuario" },
      { text: "Ejecutivo", value: "ejecutivo" },
      { text: "Ver", value: "action_ver", align: "center", sortable: false },
      {
        text: "Editar",
        value: "action_editar",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    items_filtrados: [],
    headers_polizas_cliente: [
      { text: "Poliza", value: "num_poliza" },
      { text: "Aseguradora", value: "nombre_aseguradora" },
      { text: "Categoria", value: "nombre_categoria" },
      { text: "Seguro", value: "nombre_seguro" },
      { text: "Fecha inicial", value: "fecha_inicial" },
      { text: "Fecha final", value: "fecha_fin" },
      { text: "Fecha Cobro", value: "dia_cobro" },
      { text: "Ver", value: "action_ver", align: "center", sortable: false },
    ],
    items_polizas_cliente: [],
    headers_cuotas: [
      { text: "Cuota", value: "cont", align: "center", sortable: false },
      { text: "Fecha", value: "fecha", sortable: false, width: 110 },
      { text: "Monto", value: "monto", sortable: false },
      {
        text: "Nuevo archivo",
        value: "agregar_archivo",
        align: "center",
        sortable: false,
        width: 40,
      },
      {
        text: "Ver archivo",
        value: "ver_archivo",
        align: "center",
        sortable: false,
        width: 40,
      },
      {
        text: "Pagada",
        value: "esta_pagada",
        align: "center",
        sortable: false,
      },
      { text: "Fecha pago", value: "fecha_pago", sortable: false, width: 110 },
      {
        text: "Tipo documento",
        value: "nombre_tipo_documento",
        sortable: false,
      },
      { text: "Factura", value: "factura", sortable: false },
      {
        text: "Nuevo archivo pago",
        value: "agregar_archivo_pago",
        align: "center",
        sortable: false,
        width: 100,
      },
      {
        text: "Ver archivo pago",
        value: "ver_archivo_pago",
        align: "center",
        sortable: false,
        width: 100,
      },
      {
        text: "Gestion de cobros",
        value: "gestion_cobro",
        align: "center",
        sortable: false,
        width: 100,
      },
    ],
    items_cuotas: [],
    cuotas_polizas_seleccionadas: [],
    dialog_confirmar_eliminar_cuotas_seleccionadas: false,
    headers_cuotas_independientes: [
      { text: "Cuota", value: "cont", sortable: false },
      { text: "Fecha", value: "fecha", sortable: false, width: 110 },
      { text: "Monto", value: "monto", sortable: false },
      {
        text: "Nuevo archivo",
        value: "agregar_archivo",
        align: "center",
        sortable: false,
        width: 40,
      },
      {
        text: "Ver archivo",
        value: "ver_archivo",
        align: "center",
        sortable: false,
        width: 40,
      },
      {
        text: "Pagada",
        value: "esta_pagada",
        align: "center",
        sortable: false,
      },
      { text: "Fecha pago", value: "fecha_pago", sortable: false, width: 110 },
      {
        text: "Tipo documento",
        value: "nombre_tipo_documento",
        sortable: false,
      },
      { text: "Factura", value: "factura", sortable: false },
      {
        text: "Nuevo archivo pago",
        value: "agregar_archivo_pago",
        align: "center",
        sortable: false,
        width: 100,
      },
      {
        text: "Ver archivo pago",
        value: "ver_archivo_pago",
        align: "center",
        sortable: false,
        width: 100,
      },
    ],
    items_cuotas_independientes: [],
    items_certificados: [],
    items_certificados_excluidos: [],
    headers_reclamos: [
      { text: "# Reclamo", value: "num_reclamo" },
      { text: "Fecha", value: "fecha_reclamo" },
      { text: "Fecha pago", value: "fecha_pago" },
      { text: "Motivo", value: "motivo" },
      { text: "Estado", value: "nombre_tipo_reclamo" },
      { text: "Taller", value: "taller" },
      { text: "Eliminar", value: "action_eliminar", align: "center" },
      { text: "Opciones", value: "actions", align: "center", sortable: false },
    ],
    items_reclamos: [],
    headers_detalles: [
      { text: "Parentesco", value: "parentesco" },
      { text: "Nombre", value: "nombre" },
      { text: "Porcentaje", value: "porcentaje" },
      { text: "Nivel", value: "nivel" },
      {
        text: "Editar",
        value: "action_edit",
        align: "center",
        sortable: false,
      },
      {
        text: "Eliminar",
        value: "action_delete",
        align: "center",
        sortable: false,
      },
    ],
    items_detalles: [],
    dialog: false,
    dialog_certificado: false,
    dialog_reclamo: false,
    dialog_clientes: false,
    tabs: null,
    poliza: null,
    año_creacion: new Date().getFullYear().toString(),
    items_año_creacion: [],
    codigo_cliente: null,
    nombre_cliente: null,
    nombre_cliente_carpeta: null,
    tipo_doc_cliente: null,
    items_clientes: [],
    headers_clientes: [
      { text: "Código", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "Dirección", value: "direccion" },
      { text: "Teléfono", value: "telefono" },
      { text: "Registro", value: "registro" },
      { text: "Nit", value: "nit" },
      { text: "Giro", value: "giro" },
      { text: "Con retencion", value: "con_retencion", align: "center" },
      { text: "Con percepción", value: "con_percepcion", align: "center" },
    ],
    id_poliza: null,
    id_cliente: null,
    id_seguro: null,
    id_usuario: null,
    aseguradora: "",
    items_aseguradoras: [],
    aseguradora_segun_carpeta: "",
    categoria: "",
    items_categorias: [],
    seguro: "",
    items_seguros: [],
    tipo_seguro: "",
    items_tipo_seguros: [],
    ramo_seguro: "",
    items_ramo_seguros: [],
    periodos: "",
    items_periodos: ["ANUAL", "PRORRATA"],
    fecha_inicial: null,
    fecha_fin: null,
    tipo_cuota: null,
    items_tipo_cuota: [],
    dia_cobro: null,
    activo: false,
    inicial_renovacion: "Es poliza inicial",
    motivo_cancelado_excluido: "",
    fecha_cancelado: "",
    ejecutivo: "",
    items_ejecutivos: [
      { id_usuario: 2, id_rol: 1, nombre: "Mariana López", usuario: "mlopez" },
      {
        id_usuario: 7,
        id_rol: 1,
        nombre: "Luis Adonay Vasquez",
        usuario: "Lvasquez",
      },
      {
        id_usuario: 8,
        id_rol: 1,
        nombre: "José Alexander Ayala",
        usuario: "Jayala",
      },
      {
        id_usuario: 9,
        id_rol: 1,
        nombre: "Nelson Daniel Claros",
        usuario: "Nclaros",
      },
    ],
    nombre_beneficio: "",
    items_beneficios: [],
    prima_neta: 0,
    gasto_bomberos: 0,
    fraccionamiento: 0,
    calcular_fraccionamiento: false,
    valor_cuota: 0,
    gastos_emision: 0,
    iva_13_por_ciento: 0,
    total_a_pagar: 0,
    suma_asegurada: null,
    bandera: -1,
    deshabilitar_actualizar: false,
    nombre_aseguradora: "",
    nombre_categoria: null,
    nombre_ramo: null,
    aux_fecha_pago_cuota: null,
    metodo_pago: null,
    items_metodo_pago: [
      "EFECTIVO",
      "TARJETA DE CREDITO/DEBITO",
      "CHEQUE",
      "DEPÓSITOS",
      "TRANSFERENCIAS",
    ],
    cantidad_cuotas: 12,
    items_cantidad_cuotas: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    tipo_carpeta_archivo_poliza: "CCF",
    items_tipo_carpeta_archivo_poliza: [
      "CCF",
      "FAC",
      "CORRESP GRAL",
      "RENOVACION",
      "PÓLIZA",
      "INSPECCIÓN",
      "REVALUO",
      //"CERTIFICADO"
    ],
    asignacion_certificados_archivos: "",
    tipo_carpeta_archivo_certificado: "CCF",
    items_tipo_carpeta_archivo_certificado: [
      "CCF",
      "FAC",
      "CORRESP GRAL",
      "RENOVACION",
      "PÓLIZA",
      "CERTIFICADO",
    ],
    dialog_eliminar_cuotas: false,
    dialog_eliminar_cuotas_independiente: false,
    //PLANES
    id_planes: 0,
    items_planes: [],
    plan_seleccionado: "",
    comision: 0,
    prima_comisionable: 0,
    //GESTION COBROS
    dialog_cuotas_gestion_cobro: false,
    dialog_gestion_cobro_observaciones: false,
    headers_gestion_cobro: [
      { text: "Fecha", value: "fecha" },
      { text: "Usuario", value: "nombre_usuario" },
      { text: "Observaciones", value: "observaciones" },
    ],
    items_gestion_cobro: [],
    fecha_cuota_gestion_cobro: null,
    observaciones_cuota_gestion_cobro: null,
    //NUEVOS CLIENTES
    dialog_nuevo_cliente: false,
    items_tipo_persona: ["PERSONA NATURAL", "PERSONA JURIDICA"],
    departamento: null,
    items_departamentos: [],
    municipio: null,
    items_municipios: [],
    rules_clientes: [(v) => !!v || "El campo es obligatorio"],
    items_tamaño: ["PEQUEÑA", "MEDIANA", "GRANDE", "OTROS"],
    cliente_tipo_persona: "",
    cliente_codigo: "",
    cliente_fecha_ingreso: "",
    cliente_nombre: "",
    cliente_razon_social: "",
    cliente_direccion: "",
    cliente_fecha_nacimiento: "",
    cliente_dui: "",
    cliente_nit: "",
    cliente_celular: "",
    cliente_telefono: "",
    cliente_correo: "",
    cliente_giro: "",
    cliente_tamaño: "",
    cliente_registro: "",
    cliente_contacto_1: "",
    cliente_telefono_1: "",
    cliente_contacto_2: "",
    cliente_telefono_2: "",
    cliente_contacto_3: "",
    cliente_telefono_3: "",
    //CUOTAS
    id_aux: null,
    id_cuota: null,
    id_tipo_documentos: null,
    cuota_fecha: null,
    cuota_independiente: null,
    cuota_monto: null,
    cuota_pagada: false,
    cuota_tipo_documento: null,
    items_cuota_tipo_documento: [],
    dialog_cuotas_tipo_documento: false,
    cuota_fecha_pago: null,
    cuota_num_documento: null,
    nombre_tipo_cuota: null,
    bandera_cuota: 1,
    aux_fecha_cuota: null,
    aux_id_cuota: null,
    aux_editar_cuota: null,
    //RECLAMO
    id_reclamos: null,
    reclamo_id_polizas: null,
    reclamo_id_tipo_reclamos: null,
    items_tipo_reclamos: [],
    num_reclamo: null,
    reclamo_poliza: null,
    reclamo_placa_nombre: null,
    fecha_reclamo: null,
    reclamo_fecha_pago: null,
    reclamo_cliente: null,
    reclamo_nombre_cliente: null,
    reclamo_aseguradora: null,
    reclamo_taller: null,
    reclamo_estado: null,
    reclamo_motivo: null,
    bandera_reclamo: -1,
    dialog_reclamos_archivos: false,
    headers_reclamos_archivos: [
      { text: "Nombre", value: "name" },
      { text: "Ver archivo", value: "ver_archivo" },
    ],
    items_reclamos_archivos: [],
    items_reclamo_archivo_seleccionados: [],
    items_reclamos_archivo_guardar: [],
    reclamo_caracteristica_auto: "",
    reclamo_valor_indemnizado: 0,
    //CERTIFICADO
    tab_certificados: null,
    id_certificado: null,
    id_certificado_poliza: null,
    num_certificado: null,
    certificado_pagado: false,
    certificado_fecha_pago: null,
    certificado_aviso_cobro: null,
    certificado_fecha_inicio: null,
    certificado_fecha_fin: null,
    certificado_individual: false,
    certificado_dia_cobro: null,
    certificado_fecha_primer_pago: null,
    certificado_periodo: null,
    certificado_tipo_cuota: null,
    certificado_nombre_tipo_cuota: null,
    certificado_cantidad_cuotas: null,
    certificado_prima: null,
    certificado_cuota: null,
    bandera_certificado: -1,
    contador_certificado: 0,
    certificado_inclusion: false,
    certificado_excluido: false,
    certificado_motivo_excluido: "",
    //CERTIFICADO X PAGOS
    id_cert_pago_aux: null,
    id_cert_pago: null,
    id_cert_pago_tipo_documentos: null,
    cert_pago_fecha: null,
    cert_pago_monto: null,
    cert_pago_pagada: false,
    cert_pago_tipo_documento: null,
    cert_pago_fecha_pago: null,
    cert_pago_num_documento: null,
    cert_pago_nombre_tipo_cuota: null,
    headers_pago_certificados: [
      {
        text: "Cuota",
        value: "cont",
        align: "center",
        sortable: false,
      },
      {
        text: "# Cert.",
        value: "num_certificado",
        align: "center",
        sortable: false,
      },
      { text: "Fecha", value: "fecha" },
      { text: "Monto", value: "monto" },
      { text: "Agregar archivo", value: "agregar_archivo", sortable: false },
      { text: "ver archivo", value: "ver_archivo", sortable: false },
      { text: "Pagada", value: "esta_pagada" },
      { text: "Fecha pago", value: "fecha_pago" },
      { text: "Tipo documento", value: "nombre_tipo_documento" },
      { text: "Factura", value: "factura" },
      {
        text: "Agregar archivo pago",
        value: "agregar_archivo_pago",
        sortable: false,
      },
      { text: "Ver archivo pago", value: "ver_archivo_pago", sortable: false },
    ],
    items_certificado_pagos: [],
    items_pago_certificados_incluidos: [],
    bandera_cert_pago: 1,
    dialog_cert_pago: false,
    aux_fecha_certificado: null,
    aux_id_certificado: null,
    aux_fecha_certificado_pago: null,
    dialog_eliminar_cuotas_certificados: false,
    bandera_cert_pago_sin_cuotas: 0,
    //CERTIFICADO ARCHIVO
    file_input_certificado_archivo: [],
    archivos_seleccionados_certificado_archivo: [],
    items_archivos_certificado_archivo: [],
    bandera_archivos_certificado_archivo: -1,
    //ARCHIVOS
    headers_archivos: [
      { text: "Nombre", value: "name" },
      { text: "Ver", value: "opciones" },
    ],
    items_archivos: [],
    file_input: [],
    archivos_seleccionados: [],
    bandera_archivos: -1,
    //NUEVO PRODUCTO
    dialog_nuevo_producto: false,
    nuevo_producto_codigo: "",
    nuevo_producto_activo: false,
    nuevo_producto_exento: false,
    nuevo_producto_aseguradora: "",
    nuevo_producto_categoria: "",
    nuevo_producto_ramo: "",
    nuevo_producto_tipo: "",
    nuevo_producto_nombre: "",
    nuevo_producto_descripcion: "",
    nuevo_producto_comision: 0,
    items_ramo_seguros_nuevo_prod: [],
    items_tipo_seguros_nuevo_prod: [],
    //BENEFICIARIOS
    id_beneficiarios: null,
    beneficiarios_parentesco: null,
    beneficiarios_nombre: null,
    beneficiarios_porcentaje: null,
    beneficiarios_fecha_nacimiento: null,
    beneficiarios_sexo: null,
    beneficiarios_dui: null,
    beneficiarios_nivel: null,
    dialog_beneficiarios: false,
    bandera_beneficiarios: -1,
    contador: null,
    identificador: null,
    identificador_aux: null,
    dialog_eliminar_beneficiarios: false,
    //CERTIFICADO_AUTOS
    id_certificado_auto: null,
    certificado_autos_tipo_vehiculo: null,
    items_tipo_vehiculo: [
      "AUTOMÓVIL",
      "AUTOBÚS",
      "CAMION",
      "CABEZAL",
      "CAMIONETA",
      "CUADRIMOTO",
      "MOTOCICLETA",
      "MICROBUS",
      "PANEL",
      "PICK UP",
      "REMOLQUE",
      "TRICIMOTO",
    ],
    certificado_autos_marca: null,
    certificado_autos_modelo: null,
    certificado_autos_año: null,
    certificado_autos_color: null,
    certificado_autos_placa: null,
    certificado_autos_num_chasis: null,
    certificado_autos_num_motor: null,
    certificado_autos_version: null,
    items_certificado_autos_version: ["Agencia", "Traído"],
    certificado_autos_cesion: null,
    certificado_autos_vencimiento_tarjeta: null,
    certificado_autos_vencimiento_dui: null,
    certificado_autos_propietario: null,
    search_certificados: null,
    //CERTIFICADO_VIDA
    id_certificado_vida: null,
    certificado_vida_nombre: "",
    certificado_vida_sexo: "",
    certificado_vida_dui: "",
    certificado_vida_fecha_nacimiento: "",
    certificado_vida_clase: 0,
    certificado_vida_edad: 0,
    certificado_vida_suma_asegurada: 0,
    //CERTIFICADO GASTO MEDICO
    id_certificado_gasto_medico: 0,
    certificado_gasto_medico_clase: "",
    certificado_gasto_medico_nombre: "",
    certificado_gasto_medico_edad: 0,
    certificado_gasto_medico_fecha_nacimiento: "",
    certificado_gasto_medico_sexo: "",
    certificado_gasto_medico_max_vit_vigente: "",
    certificado_gasto_medico_max_vit_contratado: "",
    certificado_gasto_medico_cobertura: "",
    items_certificado_gasto_medico_cobertura: [
      "MUNDIAL",
      "CENTROAMERICANO",
      "PLAN BASICO EL SALVADOR",
    ],
    //CERTIFICADO TRANSPORTE MERCADERIA
    headers_cert_transporte_mercaderia: [
      { text: "N° Certificado", value: "num_trans_merca" },
      { text: "Asegurado", value: "asegurado" },
      { text: "Valor factura", value: "valor_factura" },
      { text: "Flete", value: "flete" },
      { text: "Sub-Total", value: "sub_total" },
      { text: "10% Adicional", value: "porcentaje_adicional" },
      { text: "Total", value: "total_suma_asegurada" },
      { text: "Tipo mercaderia", value: "tipo_mercaderia" },
      { text: "Editar", value: "editar", align: "center", sortable: false },
    ],
    items_cert_transporte_mercaderia: [],
    cert_id_transporte_mercaderia: 0,
    cert_transporte_mercaderia_id_individuales: 0,
    cert_transporte_mercaderia_num_trans_merca: "",
    cert_transporte_mercaderia_asegurado: "",
    cert_transporte_mercaderia_valor_factura: 0,
    cert_transporte_mercaderia_flete: 0,
    cert_transporte_mercaderia_sub_total: 0,
    cert_transporte_mercaderia_porcentaje_adicional: 0,
    cert_transporte_mercaderia_total_suma_asegurada: 0,
    cert_transporte_mercaderia_tipo_mercaderia: "",
    cert_transporte_mercaderia_proveedor_cliente: "",
    cert_transporte_mercaderia_maritimo: false,
    cert_transporte_mercaderia_aereo: false,
    cert_transporte_mercaderia_terrestre: false,
    cert_transporte_mercaderia_nombre_vapor: "",
    cert_transporte_mercaderia_procedente_de: "",
    cert_transporte_mercaderia_destino_a: "",
    cert_transporte_mercaderia_num_factura: "",
    cert_transporte_mercaderia_fecha_factura: "",
    cert_transporte_mercaderia_fecha_despacho: "",
    cert_transporte_mercaderia_vigencia_desde: "",
    cert_transporte_mercaderia_vigencia_hasta: "",
    flag_editar_cert_transporte_mercaderia: false,
    contador_certificado_transporte_mercaderia: 0,
    dialog_cert_archivos_trans_merca: false,
    items_file_input_cert_trans_merca: [],
    headers_archivos_cert_trans_merca: [
      { text: "Nombre", value: "name" },
      { text: "Ver", value: "action_ver" },
    ],
    items_archivos_cert_trans_merca: [],
    dialog_eliminar_cert_transporte_mercaderia: false,
    //CERTIFICADO INCENDIO
    dialog_eliminar_prod_cert_incendio: false,
    prod_cert_id_incendio: 0,
    prod_cert_incendio_id_certificado: 0,
    prod_cert_incendio_excluir_incendio: false,
    prod_cert_incendio_n_riesgo: 0,
    prod_cert_incendio_direccion: "",
    prod_cert_incendio_sum_aseg_construcciones: 0,
    prod_cert_incendio_sum_aseg_contenido: 0,
    prod_cert_incendio_sum_aseg_existencias: 0,
    prod_cert_incendio_total_sum_aseg: 0,
    prod_cert_incendio_intr_neg_lucr_ces_perd_unid: "",
    prod_cert_incendio_cesion: "",
    prod_cert_incendio_monto_cesion: "",
    prod_cert_incendio_beneficiario_cesion: "",
    prod_cert_incendio_riesgos_cubierto: "",
    prod_cert_incendio_cobertura_compl: "",
    headers_prod_cert_incendio: [
      { text: "N° Riesgo", value: "n_riesgo" },
      { text: "Dirección", value: "direccion" },
      { text: "Suma aseg. constru.", value: "sum_aseg_construcciones" },
      { text: "Suma aseg Contenido", value: "sum_aseg_contenido" },
      { text: "Suma aseg. existencias", value: "sum_aseg_existencias" },
      { text: "Interr. negocio/lucro", value: "intr_neg_lucr_ces_perd_unid" },
      { text: "Cesión", value: "cesion" },
      { text: "Total suma asegurada", value: "total_suma_asegurada" },
      { text: "Editar", value: "editar", sortable: false, align: "center" },
      {
        text: "Eliminar",
        value: "action_delete",
        sortable: false,
        align: "center",
      },
    ],
    items_prod_cert_incendio: [],
    items_prod_cert_incendio_excluidos: [],
    contador_items_prod_cert_incendio: 1,
    flag_editar_prod_cert_incendio: false,
    items_prod_cert_coberturas: [],
    items_prod_cert_riesgos: [],
    dialog_eliminar_prod_cert_incendio: false,
    prod_cert_incendio_excluir_construcciones: false,
    prod_cert_incendio_excluir_contenido: false,
    prod_cert_incendio_excluir_existencias: false,
    prod_cert_incendio_motivo_construcciones: "",
    prod_cert_incendio_motivo_contenido: "",
    prod_cert_incendio_motivo_existencias: "",
    //DEPENDIENTES - GASTO MEDICO - CERTIFICADO
    headers_dependientes_cert_gasto_medico: [
      { text: "Nombre", value: "nombre" },
      { text: "Edad", value: "edad", align: "center" },
      { text: "Sexo", value: "sexo", align: "center" },
      { text: "Fecha nacimiento", value: "fecha_nacimiento", align: "center" },
      {
        text: "Máximo vitalicio contratado",
        value: "maximo_vitalicio_contratado",
        align: "center",
      },
      {
        text: "Máximo vitalicio vigente",
        value: "maximo_vitalicio_vigente",
        align: "center",
      },
      { text: "Cobertura", value: "cobertura", align: "center" },
      { text: "Parentesco", value: "parentesco", align: "center" },
      {
        text: "Editar",
        value: "action_edit",
        align: "center",
        sortable: false,
      },
      {
        text: "Eliminar",
        value: "action_delete",
        align: "center",
        sortable: false,
      },
    ],
    items_dependientes_cert_gasto_medico: [],
    items_dependientes_cert_gasto_medico_excluidos: [],
    dialog_dependiantes_cert_gasto_medico: false,
    id_dependientes_cert_gasto_medico: 0,
    dependientes_cert_gasto_medico_nombre: "",
    dependientes_cert_gasto_medico_excluido: false,
    dependientes_cert_gasto_medico_edad: 0,
    dependientes_cert_gasto_medico_sexo: "",
    dependientes_cert_gasto_medico_parentesco: "",
    dependientes_cert_gasto_medico_fecha_nacimiento: "",
    dependientes_cert_gasto_medico_max_vita_contratado: "",
    dependientes_cert_gasto_medico_max_vita_vigente: "",
    dependientes_cert_gasto_medico_cobertura: "",
    dependientes_cert_gasto_medico_motivo_excluido: "",
    items_dependientes_cert_gasto_medico_cobertura: [
      "MUNDIAL",
      "CENTROAMERICANO",
      "PLAN BASICO EL SALVADOR",
    ],
    flag_dependientes_cert_gasto_medico: 1,
    dialog_eliminar_cert_depen_gastomedico: false,
    //PRODUCTOS INDIVIDUALES
    flag_individual: 0,
    items_beneficiario_cesion: [],
    //AUTO
    prod_ind_auto_id_indiv_auto: 0,
    prod_ind_auto_id_indiv: 0,
    prod_ind_auto_marca: "",
    prod_ind_auto_modelo: "",
    prod_ind_auto_año: 0,
    prod_ind_auto_placa: "",
    prod_ind_auto_chasis: "",
    prod_ind_auto_motor: "",
    prod_ind_auto_version: "",
    prod_ind_auto_cesion: "",
    prod_ind_auto_monto_cesion: "",
    prod_ind_auto_beneficiario_cesion: "",
    prod_ind_auto_tipo_vehiculo: "",
    prod_ind_auto_vencimiento_tarjeta: "",
    prod_ind_auto_vencimiento_dui: "",
    items_ind_auto_version: ["AGENCIA", "TRAIDO"],
    prod_ind_auto_vencimiento_licencia: "",
    //VIDA
    prod_ind_vida_id_indiv_vida: 0,
    prod_ind_vida_id_indiv: 0,
    prod_ind_vida_nombre: "",
    prod_ind_vida_edad: 0,
    prod_ind_vida_sexo: "",
    prod_ind_vida_fecha_nacimiento: "",
    prod_ind_vida_cesion: "",
    prod_ind_vida_monto_cesion: "",
    prod_ind_vida_beneficiario_cesion: "",
    prod_ind_vida_temporal: "",
    prod_ind_vida_numero_años: 0,
    items_prod_ind_vida_num_años: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
    //GASTO MEDICO
    prod_ind_gastomedico_id_individual_gasto_medico: 0,
    prod_ind_gastomedico_id_indiv: 0,
    prod_ind_gastomedico_nombre: "",
    prod_ind_gastomedico_edad: 0,
    prod_ind_gastomedico_sexo: "",
    prod_ind_gastomedico_fecha_nacimiento: "",
    prod_ind_gastomedico_max_vit_contratado: "",
    prod_ind_gastomedico_max_vit_vigente: "",
    prod_ind_gastomedico_cobertura: "",
    items_prod_ind_gastomedico_cobertura: [
      "MUNDIAL",
      "CENTROAMERICA",
      "PLAN BASICO EL SALVADOR",
    ],
    //DEPENDIENTE - GASTO MEDICO
    headers_prod_ind_depen_gastomedico: [
      { text: "Nombre", value: "nombre" },
      { text: "Edad", value: "edad" },
      { text: "Sexo", value: "sexo" },
      { text: "Fecha nacimiento", value: "fecha_nacimiento" },
      { text: "Maximo vit contratado", value: "max_vit_contratado" },
      { text: "Maximo vit vigente", value: "max_vit_vigente" },
      { text: "Cobertura", value: "cobertura" },
      { text: "Parentesco", value: "parentesco" },
      { text: "Editar", value: "editar", sortable: false, align: "center" },
      { text: "Eliminar", value: "eliminar", sortable: false, align: "center" },
    ],
    items_prod_ind_depen_gastomedico: [],
    items_prod_ind_depen_gastomedico_excluidos: [],
    prod_ind_depen_dialog: false,
    prod_ind_depen_id_depen_gastomedico: 0,
    prod_ind_depen_id_gastomedico: 0,
    prod_ind_depen_gastomedico_nombre: "",
    prod_ind_depen_gastomedico_excluido: false,
    prod_ind_depen_gastomedico_edad: 0,
    prod_ind_depen_gastomedico_sexo: "",
    prod_ind_depen_gastomedico_fecha_nacimiento: "",
    prod_ind_depen_gastomedico_max_vit_contratado: "",
    prod_ind_depen_gastomedico_max_vit_vigente: "",
    prod_ind_depen_gastomedico_cobertura: "",
    prod_ind_depen_gastomedico_parentesco: "",
    prod_ind_depen_gastomedico_motivo_excluido: "",
    prod_ind_depen_bandera_editar: 0,
    dialog_eliminar_prod_ind_depen_gastomedico: false,
    //RESIDENCIA
    prod_ind_id_residencia: 0,
    prod_ind_residencia_id_individuales: 0,
    prod_ind_residencia_n_riesgo: 0,
    prod_ind_residencia_direccion: "",
    prod_ind_residencia_sum_aseg_construcciones: 0,
    prod_ind_residencia_sum_aseg_contenido: 0,
    prod_ind_residencia_total_suma_aseg: 0,
    prod_ind_residencia_cesion: "",
    prod_ind_residencia_monto_cesion: "",
    prod_ind_residencia_beneficiario_cesion: "",
    prod_ind_residencia_plan_contratado: "",
    items_prod_ind_residencia_contratado: [
      "RESIDENCIAL PLATINO",
      "RESIDENCIAL TODO RIESGO",
      "FAMILIA HOGAR SEGURO",
    ],
    headers_prod_ind_residencia: [
      { text: "N° Riesgo", value: "n_riesgo" },
      { text: "Dirección", value: "direccion" },
      { text: "Suma aseg. constru.", value: "sum_asegurada_construcciones" },
      { text: "Suma aseg Contenido", value: "sum_asegurada_contenido" },
      { text: "Cesión", value: "cesion" },
      { text: "Total suma asegurada", value: "total_suma_asegurada" },
      { text: "Plan contratado", value: "plan_contratado" },
      { text: "Eliminar", value: "action_eliminar", align: "center" },
      { text: "Editar", value: "actions", align: "center", sortable: false },
    ],
    items_prod_ind_residencia: [],
    //INCENDIO
    prod_ind_id_incendio: 0,
    prod_ind_incendio_id_individuales: 0,
    prod_ind_incendio_excluir_incendio: false,
    prod_ind_incendio_n_riesgo: 0,
    prod_ind_incendio_direccion: "",
    prod_ind_incendio_sum_aseg_construcciones: 0,
    prod_ind_incendio_sum_aseg_contenido: 0,
    prod_ind_incendio_sum_aseg_existencias: 0,
    prod_ind_incendio_total_sum_aseg: 0,
    prod_ind_incendio_intr_neg_lucr_ces_perd_unid: "",
    prod_ind_incendio_cesion: "",
    prod_ind_incendio_monto_cesion: "",
    prod_ind_incendio_beneficiario_cesion: "",
    prod_ind_incendio_riesgos_cubierto: "",
    prod_ind_incendio_cobertura_compl: "",
    headers_prod_ind_incendio: [
      { text: "N° Riesgo", value: "n_riesgo" },
      { text: "Dirección", value: "direccion" },
      { text: "Suma aseg. constru.", value: "sum_aseg_construcciones" },
      { text: "Suma aseg Contenido", value: "sum_aseg_contenido" },
      { text: "Suma aseg. existencias", value: "sum_aseg_existencias" },
      { text: "Interr. negocio/lucro", value: "intr_neg_lucr_ces_perd_unid" },
      { text: "Cesión", value: "cesion" },
      { text: "Total suma asegurada", value: "total_suma_asegurada" },
      { text: "Editar", value: "editar", sortable: false, align: "center" },
      {
        text: "Eliminar",
        value: "action_delete",
        sortable: false,
        align: "center",
      },
    ],
    items_prod_ind_incendio: [],
    items_prod_ind_incendio_excluidos: [],
    contador_items_prod_ind_incendio: 1,
    flag_editar_prod_ind_incendio: false,
    items_prod_ind_coberturas: [],
    items_prod_ind_riesgos: [],
    dialog_eliminar_prod_ind_incendio: false,
    prod_ind_incendio_excluir_construcciones: false,
    prod_ind_incendio_excluir_contenido: false,
    prod_ind_incendio_excluir_existencias: false,
    prod_ind_incendio_motivo_construcciones: "",
    prod_ind_incendio_motivo_contenido: "",
    prod_ind_incendio_motivo_existencias: "",
    //FIDELIDAD
    contador_items_prod_ind_fidelidad: 0,
    flag_prod_ind_fidelidad: false,
    prod_ind_id_fidelidad: 0,
    prod_ind_fidelidad_id_individuales: 0,
    prod_ind_fidelidad_n_riesgo: 0,
    prod_ind_fidelidad_direccion: "",
    prod_ind_fidelidad_nombre_empleado: "",
    prod_ind_fidelidad_puesto_afianzado: "",
    headers_prod_ind_fidelidad: [
      { text: "N° Riesgo", value: "n_riesgo" },
      { text: "Dirección", value: "direccion" },
      { text: "Nombre empleado", value: "nombre_empleado" },
      { text: "Puesto afianzado", value: "puesto_afianzado" },
      { text: "Editar", value: "editar", align: "center", sortable: false },
    ],
    items_prod_ind_fidelidad: [],
    //DINERO VALORES
    prod_ind_id_dinero_valores: 0,
    prod_ind_dinero_valores_id_individuales: 0,
    prod_ind_dinero_valores_n_riesgo: 0,
    prod_ind_dinero_valores_direccion: "",
    headers_prod_ind_dinero_valores: [
      { text: "N° Riesgo", value: "n_riesgo" },
      { text: "Dirección", value: "direccion" },
    ],
    items_prod_ind_dinero_valores: [],
    //ROBO - HURTO
    prod_ind_id_robo_hurto: 0,
    prod_ind_robo_hurto_id_individuales: 0,
    prod_ind_robo_hurto_n_riesgo: 0,
    prod_ind_robo_hurto_direccion: "",
    headers_prod_ind_robo_hurto: [
      { text: "N° Riesgo", value: "n_riesgo" },
      { text: "Dirección", value: "direccion" },
    ],
    items_prod_ind_robo_hurto: [],
    //TRANSPORTE INTERNO
    prod_ind_id_transporte_interno: 0,
    prod_ind_transporte_interno_id_individuales: 0,
    prod_ind_transporte_interno_direccion: "",
    prod_ind_transporte_interno_tipo_mercaderia: "",
    prod_ind_transporte_interno_guardia_seguridad: "",
    //TRANSPORTE MERCADERIA
    headers_prod_ind_transporte_mercaderia: [
      { text: "N° Certificado", value: "num_trans_merca" },
      { text: "Asegurado", value: "asegurado" },
      { text: "Valor factura", value: "valor_factura" },
      { text: "Flete", value: "flete" },
      { text: "Sub-Total", value: "sub_total" },
      { text: "10% Adicional", value: "porcentaje_adicional" },
      { text: "Total", value: "total_suma_asegurada" },
      { text: "Tipo mercaderia", value: "tipo_mercaderia" },
      { text: "Editar", value: "editar", align: "center", sortable: false },
    ],
    items_prod_ind_transporte_mercaderia: [],
    prod_ind_id_transporte_mercaderia: 0,
    prod_ind_transporte_mercaderia_id_individuales: 0,
    prod_ind_transporte_mercaderia_num_trans_merca: "",
    prod_ind_transporte_mercaderia_asegurado: "",
    prod_ind_transporte_mercaderia_valor_factura: 0,
    prod_ind_transporte_mercaderia_flete: 0,
    prod_ind_transporte_mercaderia_sub_total: 0,
    prod_ind_transporte_mercaderia_porcentaje_adicional: 0,
    prod_ind_transporte_mercaderia_total_suma_asegurada: 0,
    prod_ind_transporte_mercaderia_tipo_mercaderia: "",
    prod_ind_transporte_mercaderia_proveedor_cliente: "",
    prod_ind_transporte_mercaderia_maritimo: false,
    prod_ind_transporte_mercaderia_aereo: false,
    prod_ind_transporte_mercaderia_terrestre: false,
    prod_ind_transporte_mercaderia_nombre_vapor: "",
    prod_ind_transporte_mercaderia_procedente_de: "",
    prod_ind_transporte_mercaderia_destino_a: "",
    prod_ind_transporte_mercaderia_num_factura: "",
    prod_ind_transporte_mercaderia_fecha_factura: "",
    prod_ind_transporte_mercaderia_fecha_despacho: "",
    prod_ind_transporte_mercaderia_vigencia_desde: "",
    prod_ind_transporte_mercaderia_vigencia_hasta: "",
    flag_editar_transporte_mercaderia: false,
    contador_prod_ind_trans_merca: 1,
    dialog_archivos_trans_merca: false,
    items_file_input_trans_merca: [],
    headers_archivos_trans_merca: [
      { text: "Nombre", value: "name" },
      { text: "Ver", value: "action_ver" },
    ],
    items_archivos_trans_merca: [],
    //RESPONSABILIDAD CIVIL
    prod_ind_id_responsabilidad_civil: 0,
    prod_ind_responsabilidad_civil_id_individuales: 0,
    prod_ind_responsabilidad_civil_n_riesgo: 0,
    prod_ind_responsabilidad_civil_direccion: "",
    headers_prod_ind_respon_civil: [
      { text: "N° Riesgo", value: "n_riesgo" },
      { text: "Dirección", value: "direccion" },
    ],
    items_prod_ind_respon_civil: [],
    bandera_sin_cuotas: false,
    dialog_desactivar_poliza: false,
    //INDIVIDUAL EQUIPOS ELECTRONICOS
    contador_items_prod_ind_equipo_electronico: 1,
    headers_prod_ind_equipo_electronico: [
      { text: "No reisgo", value: "n_riesgo" },
      { text: "Dirección", value: "direccion" },
      { text: "Cesión", value: "cesion" },
      { text: "Suma asegurada", value: "suma_asegurada_equipo" },
      { text: "Editar", value: "editar", align: "center", sortable: false },
    ],
    items_prod_ind_equipo_electronico: [],
    id_individual_equipo_eletronico: 0,
    prod_ind_equipo_electronico_id_individuales: 0,
    prod_ind_equipo_electronico_n_riesgo: "",
    prod_ind_equipo_electronico_direccion: "",
    prod_ind_equipo_electronico_cesion: "",
    prod_ind_equipo_electronico_suma_asegurada: 0,
    flag_editar_prod_ind_equipo_electronico: false,
    //INDIVIDUAL EQUIPO ELECTRONICOS RIESGOS
    items_prod_ind_equipo_electronico_riesgos: [],
    prod_ind_equipo_electronico_riesgo_id_riesgo: 0,
    prod_ind_equipo_electronico_riesgo_nombre: "",
    //INDIVIDUAL EQUIPO ELECTRONICOS COBERTURAS
    items_prod_ind_equipo_electronico_coberturas: [],
    prod_ind_equipo_electronico_cobertura_id_cobertura: 0,
    prod_ind_equipo_electronico_cobertura_nombre: "",
    //INDIVIDUAL INCAPACIDAD TOTAL Y PERMANENTE
    id_prod_ind_inc_total_perma: 0,
    prod_ind_inc_total_perma_id_individuales: 0,
    prod_ind_inc_total_perma_suma_aseg: 0,
    prod_ind_inc_total_perma_desme_acci: false,
    prod_ind_inc_total_perma_enfer_graves: false,
    prod_ind_inc_total_perma_muerte_acci: false,
    prod_ind_inc_total_perma_otros: "",
    //INDIVIDUAL SEGURO DE VIAJE
    contador_prod_ind_seguro_viajes: 1,
    flag_prod_ind_seguro_viaje: true,
    headers_prod_ind_seguro_viaje: [
      { text: "Nombre", value: "nombre" },
      { text: "Sexo", value: "sexo" },
      { text: "Fecha de nacimiento", value: "fecha_nacimiento" },
      { text: "Edad", value: "edad" },
      { text: "Número de días", value: "numero_dias" },
      { text: "Editar", value: "editar", align: "center", sortable: false },
    ],
    items_prod_ind_seguro_viaje: [],
    id_prod_ind_seguro_viaje: 0,
    prod_ind_seguro_viaje_id_individuales: 0,
    prod_ind_seguro_viaje_nombre: "",
    prod_ind_seguro_viaje_sexo: "",
    items_prod_ind_seguro_viaje_sexo: ["MASCULINO", "FEMENINO"],
    prod_ind_seguro_viaje_fecha_nacimiento: "",
    prod_ind_seguro_viaje_edad: 0,
    prod_ind_seguro_viaje_numero_dias: 0,
    //INDIVIDUAL RIESGO CONSTRUCCIONES
    prod_ind_id_riesgo_constru: 0,
    prod_ind_riesgo_constru_id_individuales: 0,
    prod_ind_riesgo_constru_n_riesgo: 0,
    prod_ind_riesgo_constru_direccion: "",
    prod_ind_riesgo_constru_sum_aseg_construcciones: 0,
    prod_ind_riesgo_constru_sum_aseg_contenido: 0,
    prod_ind_riesgo_constru_sum_aseg_existencias: 0,
    prod_ind_riesgo_constru_intr_neg_lucr_ces_perd_unid: "",
    prod_ind_riesgo_constru_cesion: "",
    prod_ind_riesgo_constru_riesgos_cubierto: "",
    prod_ind_riesgo_constru_cobertura_compl: "",
    headers_prod_ind_riesgo_constru: [
      { text: "N° Riesgo", value: "n_riesgo" },
      { text: "Dirección", value: "direccion" },
      { text: "Suma aseg. constru.", value: "sum_aseg_construcciones" },
      { text: "Suma aseg Contenido", value: "sum_aseg_contenido" },
      { text: "Suma aseg. existencias", value: "sum_aseg_existencias" },
      { text: "Interr. negocio/lucro", value: "intr_neg_lucr_ces_perd_unid" },
      { text: "Cesión", value: "cesion" },
      { text: "Editar", value: "editar", sortable: false, align: "center" },
    ],
    items_prod_ind_riesgo_constru: [],
    contador_items_prod_ind_riesgo_constru: 1,
    flag_editar_prod_ind_riesgo_constru: false,
    items_prod_ind_riesgo_constru_coberturas: [],
    items_prod_ind_riesgo_constru_riesgos: [],
    //PRODUCTO INDIVIDUAL FIANZA
    id_prod_ind_fianza: 0,
    prod_ind_fianza_id_individuales: 0,
    prod_ind_fianza_tipo_fianza: "",
    prod_ind_fianza_otras: "",
    prod_ind_fianza_especificar: "",
    //INDIVIDUAL VIDA BENEFICIARIOS
    dialog_individual_vida_beneficiario: false,
    dialog_eliminar_ind_vida_beneficiarios: false,
    headers_individual_vida_beneficiarios: [
      { text: "Parentesco", value: "parentesco" },
      { text: "Nombre", value: "nombre" },
      { text: "Fecha nacimiento", value: "fecha_nacimiento" },
      { text: "Edad", value: "edad" },
      { text: "Sexo", value: "sexo" },
      { text: "Porcentaje", value: "porcentaje" },
      { text: "Eliminar", value: "action_eliminar", align: "center" },
      { text: "Editar", value: "action_editar", align: "center" },
    ],
    items_individual_vida_beneficiarios: [],
    items_individual_vida_beneficiarios_excluidos: [],
    individual_vida_beneficiario_id_beneficiario: 0,
    individual_vida_beneficiario_parentesco: "",
    individual_vida_beneficiario_nombre: "",
    individual_vida_beneficiario_fecha_nacimiento: "",
    individual_vida_beneficiario_edad: 0,
    individual_vida_beneficiario_sexo: "",
    individual_vida_beneficiario_porcentaje: "",
    flag_individual_vida_beneficiario: 1,
    contador_prod_ind_vida_beneficiarios: 0,
    individual_vida_beneficiario_excluido: false,
    //BENEFICIARIOS COLECTIVO ACCIDENTES PERSONALES
    dialog_colectivo_vida_beneficiario: false,
    dialog_eliminar_col_vida_beneficiarios: false,
    headers_colectivo_vida_beneficiarios: [
      { text: "Parentesco", value: "parentesco" },
      { text: "Nombre", value: "nombre" },
      { text: "Fecha nacimiento", value: "fecha_nacimiento" },
      { text: "Edad", value: "edad" },
      { text: "Sexo", value: "sexo" },
      { text: "Porcentaje", value: "porcentaje" },
      { text: "Eliminar", value: "action_eliminar", align: "center" },
      { text: "Editar", value: "action_editar", align: "center" },
    ],
    items_colectivo_vida_beneficiarios: [],
    items_colectivo_vida_beneficiarios_excluidos: [],
    colectivo_vida_beneficiario_id_beneficiario: 0,
    colectivo_vida_beneficiario_parentesco: "",
    colectivo_vida_beneficiario_nombre: "",
    colectivo_vida_beneficiario_fecha_nacimiento: "",
    colectivo_vida_beneficiario_edad: 0,
    colectivo_vida_beneficiario_sexo: "",
    colectivo_vida_beneficiario_porcentaje: "",
    flag_colectivo_vida_beneficiario: 1,
    contador_prod_col_vida_beneficiarios: 0,
    colectivo_vida_beneficiario_excluido: false,
    //AUMENTOS O INCLUSIONES
    dialog_aumento_inclusion_pago: false,
    dialog_eliminar_cuotas_aumentos_inclusiones: false,
    headers_aumentos_inclusiones: [
      { text: "Cuota", value: "cont", sortable: false },
      { text: "Fecha", value: "fecha", sortable: false, width: 110 },
      { text: "Monto", value: "monto", sortable: false },
      {
        text: "Nuevo archivo",
        value: "agregar_archivo",
        align: "center",
        sortable: false,
        width: 40,
      },
      {
        text: "Ver archivo",
        value: "ver_archivo",
        align: "center",
        sortable: false,
        width: 40,
      },
      {
        text: "Pagada",
        value: "esta_pagada",
        align: "center",
        sortable: false,
      },
      { text: "Fecha pago", value: "fecha_pago", sortable: false, width: 110 },
      {
        text: "Tipo documento",
        value: "nombre_tipo_documento",
        sortable: false,
      },
      { text: "Factura", value: "factura", sortable: false },
      {
        text: "Nuevo archivo pago",
        value: "agregar_archivo_pago",
        align: "center",
        sortable: false,
        width: 100,
      },
      {
        text: "Ver archivo pago",
        value: "ver_archivo_pago",
        align: "center",
        sortable: false,
        width: 100,
      },
    ],
    items_aumentos_inclusiones: [],
    id_aumento_inclusion: null,
    aumentos_inclusiones: "",
    aumentos_inclusiones_fecha: "",
    aumentos_inclusiones_prima: 0.0,
    aumentos_inclusiones_iva: 0.0,
    aumentos_inclusiones_total: 0.0,
    aumentos_inclusiones_cant_cuotas: "",
    items_aumentos_inclusiones_cant_cuotas: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
    ],
    aumentos_inclusiones_bandera_sin_cuotas: false,
    aumento_inclu_pago_fecha: "",
    aumento_inclu_pago_monto: "",
    aumento_inclu_pago_fecha_pago: "",
    id_aumento_inclu_pago_tipo_documentos: "",
    aumento_inclu_pago_num_documento: "",
    contador_aumento_inclusion: null,
    //OTROS
    aux_item: "",
    se_esta_renovando_poliza: false,
    certificado_seleccionado_archivo: "",
    //OBSERVACIONES
    observaciones_poliza: "",
    items_observaciones_poliza: [],
  }),
  created() {
    this.listar();
    this.listarAseguradoras();
    this.listarCategorias();
    this.listarTipoCuotas();
    this.listarTipoDocumentos();
    this.listarDepartamentos();
    this.añosCreacionPoliza();
    this.listarBeneficiariosCesion();

    var href_param = window.location.pathname.split("/");
    this.listarPorNumPoliza(href_param[href_param.length - 1]);

    //console.log(this.$store.state.usuario);
  },
  computed: {
    clienteTipoPersona() {
      if (this.cliente_tipo_persona == "PERSONA NATURAL") {
        this.cliente_nit = null;
        this.cliente_dui = this.cliente_codigo;
      } else {
        this.cliente_dui = null;
        this.cliente_nit = this.cliente_codigo;
      }
    },
    listarPlanes() {
      if (
        !this.aseguradora.id_aseguradoras ||
        !this.categoria.id_categoria_seguros ||
        !this.ramo_seguro.id_ramo_seguros
      ) {
        return;
      }

      this.items_planes = [];

      axios
        .get(
          "api/Planes/Listar/" +
            this.aseguradora.id_aseguradoras +
            "/" +
            this.ramo_seguro.id_ramo_seguros +
            "/" +
            this.categoria.id_categoria_seguros
        )
        .then((response) => {
          if (this.inicial_renovacion == "Es poliza inicial") {
            response.data.forEach((element) => {
              this.items_planes.push({
                id_planes: element.id_planes,
                nombre: element.nombre,
                comision: element.comision_inicial,
              });
            });
          } else {
            response.data.forEach((element) => {
              this.items_planes.push({
                id_planes: element.id_planes,
                nombre: element.nombre,
                comision: element.comision_renovacion,
              });
            });
          }

          var index = this.items_planes.findIndex(
            (obj) => obj.id_planes == this.id_planes
          );

          if (index != -1) {
            this.plan_seleccionado = index;
          }
        });
    },
    esAdministrador() {
      if (this.$store.state.usuario) {
        if (
          this.$store.state.usuario.rol == "Administrador" &&
          this.bandera == 2
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    sumasProdIndIncendio() {
      if (this.prod_ind_incendio_excluir_construcciones) {
        this.prod_ind_incendio_sum_aseg_construcciones = (0.0).toFixed(2);
      }
      if (this.prod_ind_incendio_excluir_contenido) {
        this.prod_ind_incendio_sum_aseg_contenido = (0.0).toFixed(2);
      }
      if (this.prod_ind_incendio_excluir_existencias) {
        this.prod_ind_incendio_sum_aseg_existencias = (0.0).toFixed(2);
      }

      this.prod_ind_incendio_total_sum_aseg = (
        parseFloat(this.prod_ind_incendio_sum_aseg_construcciones) +
        parseFloat(this.prod_ind_incendio_sum_aseg_contenido) +
        parseFloat(this.prod_ind_incendio_sum_aseg_existencias)
      ).toFixed(2);
    },
    puedeEliminar() {
      if (this.$store.state.usuario) {
        if (this.$store.state.usuario.rol == "Administrador") {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    async guardarArchivoCertificadoFactura() {
      console.log(this.certificado_seleccionado_archivo);

      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      for (var i = 0; i < this.file_input_certificado_archivo.length; i++) {
        const result = await apiOneDrive.metodos.CrearArchivoCertificadoFactura(
          this.$store.state.usuario.id_usuario,
          this.nombre_cliente_carpeta,
          this.aseguradora.nombre_carpeta,
          this.fecha_inicial,
          this.categoria.descripcion,
          this.ramo_seguro.descripcion,
          this.poliza,
          this.tipo_carpeta_archivo_certificado,
          this.certificado_seleccionado_archivo.num_trans_merca +
            this.certificado_seleccionado_archivo.num_factura,
          this.file_input_certificado_archivo[i]
        );

        if (result.status == 201) {
          this.alertaMensaje(
            "Archivo guardado: " + result.data.name,
            "success"
          );
          this.listarArchivosCertificadoArchivos();
        } else {
          this.alertaMensaje(
            "Archivo no se pudo guardar: " + result.data.name,
            "error"
          );
        }
      }
    },
    calculoCertTransporteMercaderia() {
      this.cert_transporte_mercaderia_sub_total = (
        Number(this.cert_transporte_mercaderia_valor_factura) +
        Number(this.cert_transporte_mercaderia_flete)
      ).toFixed(2);

      this.cert_transporte_mercaderia_porcentaje_adicional = (
        Number(this.cert_transporte_mercaderia_sub_total) * 0.1
      ).toFixed(2);

      this.cert_transporte_mercaderia_total_suma_asegurada = (
        Number(this.cert_transporte_mercaderia_sub_total) +
        Number(this.cert_transporte_mercaderia_porcentaje_adicional)
      ).toFixed(2);
    },
    calculoIndTransporteMercaderia() {
      this.prod_ind_transporte_mercaderia_sub_total = (
        Number(this.prod_ind_transporte_mercaderia_valor_factura) +
        Number(this.prod_ind_transporte_mercaderia_flete)
      ).toFixed(2);

      this.prod_ind_transporte_mercaderia_porcentaje_adicional = (
        Number(this.prod_ind_transporte_mercaderia_sub_total) * 0.1
      ).toFixed(2);

      this.prod_ind_transporte_mercaderia_total_suma_asegurada = (
        Number(this.prod_ind_transporte_mercaderia_sub_total) +
        Number(this.prod_ind_transporte_mercaderia_porcentaje_adicional)
      ).toFixed(2);
    },
    addBeneficios() {
      this.items_beneficios.push({
        id_polizas: this.id_poliza == null ? 0 : this.id_poliza,
        nombre: this.nombre_beneficio,
      });

      this.nombre_beneficio = "";
    },
    listarBeneficios() {
      if (!this.id_poliza) {
        return;
      }

      axios.get("api/Beneficios/Listar/" + this.id_poliza).then((response) => {
        //console.log(response.data);
        this.items_beneficios = response.data;
      });
    },
    confirmarEliminarBeneficio(item) {
      //console.log(item);
      axios.delete("api/Beneficios/Eliminar/" + item.id_beneficio).then(() => {
        this.alerta("info", "Registro eliminado");
        this.listarBeneficios();
      });
    },
    abrirDialogIndividualBeneficiariosVida() {
      this.flag_individual_vida_beneficiario = 1;
      this.dialog_individual_vida_beneficiario = true;

      if (!this.id_poliza) {
        return;
      }
      this.items_individual_vida_beneficiarios = [];
      this.items_individual_vida_beneficiarios_excluidos = [];
      if (this.id_poliza) {
        axios
          .get(
            "api/IndividualVidaBeneficiarios/Listar/" +
              this.prod_ind_vida_id_indiv_vida
          )
          .then((response) => {
            //console.log(response.data);
            response.data.forEach((element) => {
              if (element.excluido) {
                this.items_individual_vida_beneficiarios_excluidos.push(
                  element
                );
              } else {
                this.items_individual_vida_beneficiarios.push(element);
              }
            });
            //this.items_individual_vida_beneficiarios = response.data;
          });
      }
    },
    agregarProdIndVidaBeneficiarios() {
      this.items_individual_vida_beneficiarios.push({
        contador: this.contador_prod_ind_vida_beneficiarios++,
        parentesco: this.individual_vida_beneficiario_parentesco,
        nombre: this.individual_vida_beneficiario_nombre,
        fecha_nacimiento: this.individual_vida_beneficiario_fecha_nacimiento,
        edad: this.individual_vida_beneficiario_edad,
        sexo: this.individual_vida_beneficiario_sexo,
        porcentaje: this.individual_vida_beneficiario_porcentaje,
        excluido: this.individual_vida_beneficiario_excluido,
      });

      this.individual_vida_beneficiario_parentesco = "";
      this.individual_vida_beneficiario_nombre = "";
      this.individual_vida_beneficiario_fecha_nacimiento = "";
      this.individual_vida_beneficiario_edad = 0;
      this.individual_vida_beneficiario_sexo = "";
      this.individual_vida_beneficiario_porcentaje = "";
      this.individual_vida_beneficiario_excluido = false;
    },
    aplicarCambiosProdIndVidaBeneficiarios() {
      this.items_individual_vida_beneficiarios[0].contador =
        this.contador_prod_ind_vida_beneficiarios;
      this.items_individual_vida_beneficiarios[0].parentesco =
        this.individual_vida_beneficiario_parentesco;
      this.items_individual_vida_beneficiarios[0].nombre =
        this.individual_vida_beneficiario_nombre;
      this.items_individual_vida_beneficiarios[0].fecha_nacimiento =
        this.individual_vida_beneficiario_fecha_nacimiento;
      this.items_individual_vida_beneficiarios[0].edad =
        this.individual_vida_beneficiario_edad;
      this.items_individual_vida_beneficiarios[0].sexo =
        this.individual_vida_beneficiario_sexo;
      this.items_individual_vida_beneficiarios[0].porcentaje =
        this.individual_vida_beneficiario_porcentaje;
      this.items_individual_vida_beneficiarios[0].excluido =
        this.individual_vida_beneficiario_excluido;

      this.individual_vida_beneficiario_parentesco = "";
      this.individual_vida_beneficiario_nombre = "";
      this.individual_vida_beneficiario_fecha_nacimiento = "";
      this.individual_vida_beneficiario_edad = 0;
      this.individual_vida_beneficiario_sexo = "";
      this.individual_vida_beneficiario_porcentaje = "";
      this.individual_vida_beneficiario_excluido = false;

      this.flag_individual_vida_beneficiario = 1;
    },
    editarProdIndVidaBeneficiarios(item) {
      //console.log(item);
      this.contador_prod_ind_vida_beneficiarios = item.contador;
      this.individual_vida_beneficiario_id_beneficiario =
        item.id_beneficiario_vida;
      this.prod_ind_vida_id_indiv_vida = item.id_individual_vida;
      this.individual_vida_beneficiario_parentesco = item.parentesco;
      this.individual_vida_beneficiario_nombre = item.nombre;
      this.individual_vida_beneficiario_fecha_nacimiento = this.formatoFecha(
        item.fecha_nacimiento
      );
      this.individual_vida_beneficiario_edad = item.edad;
      this.individual_vida_beneficiario_sexo = item.sexo;
      this.individual_vida_beneficiario_porcentaje = item.porcentaje;
      this.individual_vida_beneficiario_excluido = item.excluido;

      this.flag_individual_vida_beneficiario = 2;
    },
    crearProdIndVidaBeneficiarios() {
      axios
        .post("api/IndividualVidaBeneficiarios/Crear", {
          id_individual_vida: this.prod_ind_vida_id_indiv_vida,
          parentesco: this.individual_vida_beneficiario_parentesco,
          nombre: this.individual_vida_beneficiario_nombre,
          fecha_nacimiento: this.individual_vida_beneficiario_fecha_nacimiento,
          edad: this.individual_vida_beneficiario_edad,
          sexo: this.individual_vida_beneficiario_sexo,
          porcentaje: this.individual_vida_beneficiario_porcentaje,
          excluido: this.individual_vida_beneficiario_excluido,
        })
        .then(() => {
          this.abrirDialogIndividualBeneficiariosVida();
          this.limpiarIndvidualVidaBeneficiarios();
        })
        .catch(() => {
          this.alerta("info", "No se pudo crear el registro");
        });
    },
    actualizarProdIndVidabeneficiarios() {
      axios
        .put("api/IndividualVidaBeneficiarios/Actualizar", {
          id_beneficiario_vida:
            this.individual_vida_beneficiario_id_beneficiario,
          id_individual_vida: this.prod_ind_vida_id_indiv_vida,
          parentesco: this.individual_vida_beneficiario_parentesco,
          nombre: this.individual_vida_beneficiario_nombre,
          fecha_nacimiento: this.individual_vida_beneficiario_fecha_nacimiento,
          edad: this.individual_vida_beneficiario_edad,
          sexo: this.individual_vida_beneficiario_sexo,
          porcentaje: this.individual_vida_beneficiario_porcentaje,
          excluido: this.individual_vida_beneficiario_excluido,
        })
        .then(() => {
          this.abrirDialogIndividualBeneficiariosVida();
          this.limpiarIndvidualVidaBeneficiarios();
        })
        .catch(() => {
          this.alerta("info", "No se pudo actualizar el registro");
        });
    },
    eliminarProdIndVidaBeneficiario(item) {
      this.individual_vida_beneficiario_id_beneficiario =
        item.id_beneficiario_vida;
      this.contador_prod_ind_vida_beneficiarios = item.contador;
      this.dialog_eliminar_ind_vida_beneficiarios = true;
    },
    confirmarEliminarProdIndVidaBeneficiarios() {
      if (
        this.individual_vida_beneficiario_id_beneficiario == 0 ||
        this.individual_vida_beneficiario_id_beneficiario == undefined
      ) {
        this.items_individual_vida_beneficiarios.splice(
          this.contador_prod_ind_vida_beneficiarios,
          1
        );
        this.dialog_eliminar_ind_vida_beneficiarios = false;
        return;
      }

      axios
        .delete(
          "api/IndividualVidaBeneficiarios/Eliminar/" +
            this.individual_vida_beneficiario_id_beneficiario
        )
        .then(() => {
          this.dialog_eliminar_ind_vida_beneficiarios = false;
          this.alerta("info", "Registro eliminado");
          this.abrirDialogIndividualBeneficiariosVida();
        })
        .catch(() => {
          this.alerta("info", "No se pudo eliminar el registro");
        });
    },
    limpiarIndvidualVidaBeneficiarios() {
      this.individual_vida_beneficiario_id_beneficiario = 0;
      this.individual_vida_beneficiario_parentesco = "";
      this.individual_vida_beneficiario_nombre = "";
      this.individual_vida_beneficiario_fecha_nacimiento = "";
      this.individual_vida_beneficiario_edad = 0;
      this.individual_vida_beneficiario_sexo = "";
      this.individual_vida_beneficiario_porcentaje = "";
      this.individual_vida_beneficiario_excluido = false;

      this.dialog_individual_vida_beneficiario = false;
    },
    abrirDialogColectivoBeneficiariosVida() {
      this.flag_colectivo_vida_beneficiario = 1;
      this.dialog_colectivo_vida_beneficiario = true;

      if (!this.id_certificado_vida) {
        return;
      }
      this.items_colectivo_vida_beneficiarios = [];
      this.items_colectivo_vida_beneficiarios_excluidos = [];
      if (this.id_poliza) {
        axios
          .get(
            "api/CertificadoVidaBeneficiarios/Listar/" +
              this.id_certificado_vida
          )
          .then((response) => {
            //console.log(response.data);
            response.data.forEach((element) => {
              if (element.excluido) {
                this.items_colectivo_vida_beneficiarios_excluidos.push(element);
              } else {
                this.items_colectivo_vida_beneficiarios.push(element);
              }
            });
          });
      }
    },
    agregarProdColVidaBeneficiarios() {
      this.items_colectivo_vida_beneficiarios.push({
        contador: this.contador_prod_col_vida_beneficiarios++,
        parentesco: this.colectivo_vida_beneficiario_parentesco,
        nombre: this.colectivo_vida_beneficiario_nombre,
        fecha_nacimiento: this.colectivo_vida_beneficiario_fecha_nacimiento,
        edad: this.colectivo_vida_beneficiario_edad,
        sexo: this.colectivo_vida_beneficiario_sexo,
        porcentaje: this.colectivo_vida_beneficiario_porcentaje,
        excluido: this.colectivo_vida_beneficiario_excluido,
      });

      this.colectivo_vida_beneficiario_parentesco = "";
      this.colectivo_vida_beneficiario_nombre = "";
      this.colectivo_vida_beneficiario_fecha_nacimiento = "";
      this.colectivo_vida_beneficiario_edad = 0;
      this.colectivo_vida_beneficiario_sexo = "";
      this.colectivo_vida_beneficiario_porcentaje = "";
      this.colectivo_vida_beneficiario_excluido = false;
    },
    aplicarCambiosProdColVidaBeneficiarios() {
      this.items_colectivo_vida_beneficiarios[0].contador =
        this.contador_prod_col_vida_beneficiarios;
      this.items_colectivo_vida_beneficiarios[0].parentesco =
        this.colectivo_vida_beneficiario_parentesco;
      this.items_colectivo_vida_beneficiarios[0].nombre =
        this.colectivo_vida_beneficiario_nombre;
      this.items_colectivo_vida_beneficiarios[0].fecha_nacimiento =
        this.colectivo_vida_beneficiario_fecha_nacimiento;
      this.items_colectivo_vida_beneficiarios[0].edad =
        this.colectivo_vida_beneficiario_edad;
      this.items_colectivo_vida_beneficiarios[0].sexo =
        this.colectivo_vida_beneficiario_sexo;
      this.items_colectivo_vida_beneficiarios[0].porcentaje =
        this.colectivo_vida_beneficiario_porcentaje;
      this.items_colectivo_vida_beneficiarios[0].excluido =
        this.colectivo_vida_beneficiario_excluido;

      this.colectivo_vida_beneficiario_parentesco = "";
      this.colectivo_vida_beneficiario_nombre = "";
      this.colectivo_vida_beneficiario_fecha_nacimiento = "";
      this.colectivo_vida_beneficiario_edad = 0;
      this.colectivo_vida_beneficiario_sexo = "";
      this.colectivo_vida_beneficiario_porcentaje = "";
      this.colectivo_vida_beneficiario_excluido = false;

      this.flag_colectivo_vida_beneficiario = 1;
    },
    editarProdColVidaBeneficiarios(item) {
      //console.log(item);
      this.contador_prod_col_vida_beneficiarios = item.contador;
      this.colectivo_vida_beneficiario_id_beneficiario =
        item.id_beneficiario_vida;
      this.prod_col_vida_id_indiv_vida = item.id_certificado_vida;
      this.colectivo_vida_beneficiario_parentesco = item.parentesco;
      this.colectivo_vida_beneficiario_nombre = item.nombre;
      this.colectivo_vida_beneficiario_fecha_nacimiento = this.formatoFecha(
        item.fecha_nacimiento
      );
      this.colectivo_vida_beneficiario_edad = item.edad;
      this.colectivo_vida_beneficiario_sexo = item.sexo;
      this.colectivo_vida_beneficiario_porcentaje = item.porcentaje;
      this.colectivo_vida_beneficiario_excluido = item.excluido;

      this.flag_colectivo_vida_beneficiario = 2;
    },
    crearProdColVidaBeneficiarios() {
      axios
        .post("api/CertificadoVidaBeneficiarios/Crear", {
          id_certificado_vida: this.id_certificado_vida,
          parentesco: this.colectivo_vida_beneficiario_parentesco,
          nombre: this.colectivo_vida_beneficiario_nombre,
          fecha_nacimiento: this.colectivo_vida_beneficiario_fecha_nacimiento,
          edad: this.colectivo_vida_beneficiario_edad,
          sexo: this.colectivo_vida_beneficiario_sexo,
          porcentaje: this.colectivo_vida_beneficiario_porcentaje,
          excluido: this.colectivo_vida_beneficiario_excluido,
        })
        .then(() => {
          this.abrirDialogColectivoBeneficiariosVida();
          this.limpiarColectivoVidaBeneficiarios();
        })
        .catch(() => {
          this.alerta("info", "No se pudo crear el registro");
        });
    },
    actualizarProdColVidabeneficiarios() {
      axios
        .put("api/CertificadoVidaBeneficiarios/Actualizar", {
          id_beneficiario_vida:
            this.colectivo_vida_beneficiario_id_beneficiario,
          id_certificado_vida: this.prod_col_vida_id_indiv_vida,
          parentesco: this.colectivo_vida_beneficiario_parentesco,
          nombre: this.colectivo_vida_beneficiario_nombre,
          fecha_nacimiento: this.colectivo_vida_beneficiario_fecha_nacimiento,
          edad: this.colectivo_vida_beneficiario_edad,
          sexo: this.colectivo_vida_beneficiario_sexo,
          porcentaje: this.colectivo_vida_beneficiario_porcentaje,
          excluido: this.colectivo_vida_beneficiario_excluido,
        })
        .then(() => {
          this.abrirDialogColectivoBeneficiariosVida();
          this.limpiarColectivoVidaBeneficiarios();
        })
        .catch(() => {
          this.alerta("info", "No se pudo actualizar el registro");
        });
    },
    eliminarProdColVidaBeneficiario(item) {
      this.colectivo_vida_beneficiario_id_beneficiario =
        item.id_beneficiario_vida;
      this.contador_prod_col_vida_beneficiarios = item.contador;
      this.dialog_eliminar_col_vida_beneficiarios = true;
    },
    confirmarEliminarProdColVidaBeneficiarios() {
      if (
        this.colectivo_vida_beneficiario_id_beneficiario == 0 ||
        this.colectivo_vida_beneficiario_id_beneficiario == undefined
      ) {
        this.items_colectivo_vida_beneficiarios.splice(
          this.contador_prod_col_vida_beneficiarios,
          1
        );
        this.dialog_eliminar_col_vida_beneficiarios = false;
        return;
      }

      axios
        .delete(
          "api/CertificadoVidaBeneficiarios/Eliminar/" +
            this.colectivo_vida_beneficiario_id_beneficiario
        )
        .then(() => {
          this.dialog_eliminar_col_vida_beneficiarios = false;
          this.alerta("info", "Registro eliminado");
          this.abrirDialogColectivoBeneficiariosVida();
        })
        .catch(() => {
          this.alerta("info", "No se pudo eliminar el registro");
        });
    },
    limpiarColectivoVidaBeneficiarios() {
      this.colectivo_vida_beneficiario_id_beneficiario = 0;
      this.colectivo_vida_beneficiario_parentesco = "";
      this.colectivo_vida_beneficiario_nombre = "";
      this.colectivo_vida_beneficiario_fecha_nacimiento = "";
      this.colectivo_vida_beneficiario_edad = 0;
      this.colectivo_vida_beneficiario_sexo = "";
      this.colectivo_vida_beneficiario_porcentaje = "";
      this.colectivo_vida_beneficiario_excluido = false;

      this.dialog_colectivo_vida_beneficiario = false;
    },
    filtarDataTable() {
      axios.get("api/Polizas/Listar").then((response) => {
        this.items = response.data.filter((element) =>
          element.nombre_cliente.includes(this.search)
        );
      });
    },
    listarBeneficiariosCesion() {
      axios.get("api/BeneficiariosCesion/Listar").then((response) => {
        this.items_beneficiario_cesion = response.data;
      });
    },
    listarObservacionesPoliza() {
      if (this.id_poliza != null) {
        axios
          .get("api/Observaciones/Listar/" + this.id_poliza)
          .then((response) => {
            //console.log(response.data);
            this.items_observaciones_poliza = response.data;
          });
      }
    },
    listarPolizaRenovacion(tipo) {
      if (tipo == "crear") {
        axios
          .get(
            "api/Polizas/ListarPolizaRenovacion/" +
              this.poliza +
              "/" +
              this.año_creacion
          )
          .then((response) => {
            //console.log(response.data);
            if (response.data.length == 0) {
              this.eliminarDatosPorRenovacion();
            } else {
              this.alerta("info", "Ya existe esta póliza para este año");
            }
          });
      } else if (tipo == "mostrar") {
        if (!this.poliza) {
          return;
        }
        axios
          .get(
            "api/Polizas/ListarPolizaRenovacion/" +
              this.poliza +
              "/" +
              this.año_creacion
          )
          .then((response) => {
            //console.log(response.data);
            if (response.data.length > 0) {
              this.editarItem(response.data[0], "editar");
            }
          });
      }
    },
    eliminarDatosPorRenovacion() {
      //console.log(this.aux_item);
      this.items_cuotas = [];
      this.items_reclamos = [];
      this.items_archivos = [];
      this.items_prod_ind_transporte_mercaderia = [];
      this.items_observaciones_poliza = [];
      this.se_esta_renovando_poliza = true;

      this.id_poliza = null;
      this.inicial_renovacion = "Es renovacion";
      this.activo = 1;

      this.bandera = 1;
      this.bandera_archivos = 1;
      this.bandera_archivos_certificado_archivo = 1;
    },
    nuevaObservacionPoliza() {
      var hoy = new Date();
      this.items_observaciones_poliza.push({
        id_usuario: this.$store.state.usuario.id_usuario,
        fecha_creacion:
          hoy.getFullYear() + "-" + (hoy.getMonth() + 1) + "-" + hoy.getDate(),
        observaciones: "",
      });
    },
    actualizarObservacionesPoliza(item) {
      axios
        .put("api/Observaciones/Actualizar", {
          id_observaciones_poliza: item.id_observaciones_poliza,
          id_polizas: item.id_polizas,
          id_usuario: item.id_usuario,
          fecha_creacion: item.fecha_creacion,
          observaciones: item.observaciones,
        })
        .then(() => {
          this.alerta("success", "Observación actualizada");
        })
        .catch(() => {
          this.alerta("error", "No se pudo actualizar");
        });
    },
    añosCreacionPoliza() {
      var date = new Date().getFullYear();
      for (var i = date - 5; i <= date + 5; i++) {
        this.items_año_creacion.push(i);
      }
    },
    //AUMENTOS O INCLUSIONES
    calcularMontosAumentos() {
      this.aumentos_inclusiones_iva = (
        this.aumentos_inclusiones_prima * 0.13
      ).toFixed(2);
      this.aumentos_inclusiones_total = (
        parseFloat(this.aumentos_inclusiones_prima) +
        parseFloat(this.aumentos_inclusiones_iva)
      ).toFixed(2);
    },
    calcularCuotasAumentos() {
      if (this.items_aumentos_inclusiones.length > 0) {
        return;
      }
      var fecha = new Date(this.aumentos_inclusiones_fecha + " " + "00:00:00");
      var fecha_dia = fecha.getDate();
      var fecha_año = fecha.getFullYear();
      var index_mes = fecha.getMonth() + 1;

      var cont = 0;
      this.items_aumentos_inclusiones = [];
      for (var i = 0; i < this.aumentos_inclusiones_cant_cuotas; i++) {
        var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
        var format_date = this.validarLimiteFecha(fecha_cuota);

        this.items_aumentos_inclusiones.push({
          id: cont++,
          id_poliza: this.id_poliza,
          id_tipo_documentos: null,
          fecha: format_date,
          monto: (
            this.aumentos_inclusiones_total /
            this.aumentos_inclusiones_cant_cuotas
          ).toFixed(2),
          esta_pagada: false,
          fecha_pago: "",
          nombre_tipo_documento: "",
          factura: "",
        });
        if (index_mes == 12) {
          fecha_año++;
          index_mes = 1;
        } else {
          index_mes++;
        }
      }
    },
    getHeadersCertificados() {
      if (this.nombre_ramo == "AUTOS") {
        return [
          { text: "Num certificado", value: "num_certificado" },
          { text: "Fecha inicio", value: "fecha_inicio" },
          { text: "Fecha fin", value: "fecha_fin" },
          { text: "Prima", value: "prima" },
          { text: "Cuota", value: "cuota" },
          { text: "Placa", value: "personalizado1" },
          { text: "Propietario", value: "propietario" },
          { text: "Pagado", value: "pagado", sortable: false },
          {
            text: "Eliminar",
            value: "action_delete",
            align: "center",
            sortable: false,
          },
          { text: "Opciones", value: "actions", align: "center" },
        ];
      } else if (this.nombre_ramo == "VIDA") {
        return [
          { text: "Num certificado", value: "num_certificado" },
          { text: "Fecha inicio", value: "fecha_inicio" },
          { text: "Fecha fin", value: "fecha_fin" },
          { text: "Prima", value: "prima" },
          { text: "Cuota", value: "cuota" },
          { text: "Nombre", value: "personalizado1" },
          { text: "Pagado", value: "pagado", sortable: false },
          {
            text: "Eliminar",
            value: "action_delete",
            align: "center",
            sortable: false,
          },
          {
            text: "Opciones",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ];
      } else if (this.nombre_ramo == "GASTOS MEDICOS") {
        return [
          { text: "Num certificado", value: "num_certificado" },
          { text: "Fecha inicio", value: "fecha_inicio" },
          { text: "Fecha fin", value: "fecha_fin" },
          { text: "Prima", value: "prima" },
          { text: "Cuota", value: "cuota" },
          { text: "Nombre", value: "personalizado1" },
          { text: "Pagado", value: "pagado", sortable: false },
          {
            text: "Eliminar",
            value: "action_delete",
            align: "center",
            sortable: false,
          },
          {
            text: "Opciones",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ];
      } else {
        return [
          { text: "Num certificado", value: "num_certificado" },
          { text: "Fecha inicio", value: "fecha_inicio" },
          { text: "Fecha fin", value: "fecha_fin" },
          { text: "Prima", value: "prima" },
          { text: "Cuota", value: "cuota" },
          { text: "Nombre", value: "personalizado1" },
          { text: "Pagado", value: "pagado", sortable: false },
          {
            text: "Eliminar",
            value: "action_delete",
            align: "center",
            sortable: false,
          },
          {
            text: "Opciones",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ];
      }
    },
    listar() {
      this.loading_polizas = true;
      axios
        .get("api/Polizas/Listar")
        .then((response) => {
          this.items = response.data;
          this.loading_polizas = false;
        })
        .catch((error) => {
          this.loading_polizas = false;
        });
    },
    ListarPolizasPorPlaca() {
      if (!this.search_placa) {
        return;
      }

      this.loading_polizas = true;
      axios
        .get("api/Polizas/ListarPorPlaca/" + this.search_placa)
        .then((response) => {
          this.items = response.data;
          this.loading_polizas = false;
        })
        .catch(() => {
          this.loading_polizas = false;
        });
    },
    FiltrarPorCliente() {
      if (!this.search_cliente) {
        return;
      }

      this.loading_polizas = true;
      axios
        .get("api/Polizas/FiltrarPorCliente/" + this.search_cliente)
        .then((response) => {
          this.items = response.data;
          this.loading_polizas = false;
        })
        .catch(() => {
          this.loading_polizas = false;
        });
    },
    listarAseguradoras() {
      axios
        .get("api/Aseguradoras/Listar")
        .then((response) => {
          this.items_aseguradoras = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarCategorias() {
      axios
        .get("api/CategoriaSeguros/Listar")
        .then((response) => {
          this.items_categorias = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarTipoDocumentos() {
      axios
        .get("api/TipoDocumentos/Listar")
        .then((response) => {
          this.items_cuota_tipo_documento = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarSeguros() {
      if (this.aseguradora && this.categoria) {
        axios
          .get(
            "api/Seguros/Listar/" +
              this.aseguradora.id_aseguradoras +
              "/" +
              this.categoria.id_categoria_seguros
          )
          .then((response) => {
            this.items_seguros = response.data;
            this.aseguradora_segun_carpeta = this.aseguradora.nombre_carpeta;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    listarTipoSeguros() {
      this.id_seguro = this.seguro.id_seguros;
      axios
        .get("api/TipoSeguros/Listar/" + this.seguro.id_tipo_seguros)
        .then((response) => {
          this.items_tipo_seguros = response.data;

          this.tipo_seguro = {
            id_tipo_seguro: response.data[0].id_tipo_seguro,
            descripcion: response.data[0].descripcion,
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarRamoSeguros() {
      axios
        .get("api/RamoSeguros/Listar/" + this.seguro.id_ramo_seguros)
        .then((response) => {
          this.items_ramo_seguros = response.data;
          this.ramo_seguro = {
            id_ramo_seguros: response.data[0].id_ramo_seguros,
            descripcion: response.data[0].descripcion,
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarTipoCuotas() {
      axios
        .get("api/TipoCuotas/Listar")
        .then((response) => {
          this.items_tipo_cuota = response.data;
        })
        .catch((error) => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    abrirDialog() {
      //ESTABLECER EN AUTOMATICO LAS FECHAS DE INICIO, FIN Y DIA DE COBRO
      var date = new Date();
      this.fecha_inicial = this.formatoFecha(date);
      this.fecha_fin = this.formatoFecha(date.setDate(date.getDate() + 365));
      this.dia_cobro = date.getDate();
      this.inicial_renovacion = "Es poliza inicial";

      this.listarTipoReclamos();
      this.bandera = 1;
      this.bandera_archivos = 1;
      this.bandera_archivos_certificado_archivo = 1;
      this.activo = true;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;
      this.se_esta_renovando_poliza = false;

      this.limpiar();
      this.limpiarArchivos();
    },
    listarDepartamentos() {
      axios
        .get("api/Departamentos/Listar")
        .then((response) => {
          this.items_departamentos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarMunicipios() {
      axios
        .get("api/Municipios/Listar/" + this.departamento.codigo)
        .then((response) => {
          this.items_municipios = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setSeguro(id) {
      axios
        .get("api/Seguros/ListarUnico/" + id)
        .then((response) => {
          this.items_seguros = response.data;

          this.aseguradora = {
            id_aseguradoras: response.data[0].id_aseguradoras,
            descripcion: response.data[0].nombre_aseguradora,
            nombre_carpeta: response.data[0].nombre_carpeta,
          };

          this.categoria = {
            id_categoria_seguros: response.data[0].id_categoria_seguros,
            descripcion: response.data[0].nombre_categoria_seguro,
          };

          this.seguro = {
            id_seguros: response.data[0].id_seguros,
            id_tipo_seguros: response.data[0].id_tipo_seguros,
            id_ramo_seguros: response.data[0].id_ramo_seguros,
          };

          this.listarTipoSeguros();
          this.listarRamoSeguros();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /////////////////////////////////////////// AGREGAR CLIENTES //////////////////////
    abrirDialogClientes() {
      this.dialog_clientes = true;

      axios
        .get("api/Clientes/Listar")
        .then((response) => {
          this.items_clientes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abrirDialogAgregarClientes() {
      this.dialog_nuevo_cliente = true;
    },
    cerrarDialogAgregarCliente() {
      this.dialog_nuevo_cliente = false;
      this.limpiarAgregarCliente();
    },
    guardarCliente() {
      if (
        !this.cliente_tipo_persona ||
        !this.cliente_codigo ||
        !this.cliente_fecha_ingreso ||
        !this.departamento ||
        !this.municipio ||
        !this.cliente_direccion ||
        !this.cliente_fecha_nacimiento ||
        !this.cliente_contacto_1 ||
        !this.cliente_telefono_1
      ) {
        this.alerta("info", "Complete los campos obligatorios");
        return;
      }

      axios
        .post("api/Clientes/Crear", {
          id_departamentos: this.departamento.id_departamentos,
          id_municipios: this.municipio.id_municipios,
          tipo_persona: this.cliente_tipo_persona,
          codigo: this.cliente_codigo,
          fecha_ingreso: this.cliente_fecha_ingreso,
          nombre: this.cliente_nombre,
          razon_social: this.cliente_razon_social,
          direccion: this.cliente_direccion,
          fecha_nacimiento: this.cliente_fecha_nacimiento,
          dui: this.cliente_dui,
          nit: this.cliente_nit,
          celular: this.cliente_celular,
          telefono: this.cliente_telefono,
          correo: this.cliente_correo,
          giro: this.cliente_giro,
          tamaño: this.cliente_tamaño,
          registro: this.cliente_registro,
          contacto1: this.cliente_contacto_1,
          contacto2: this.cliente_contacto_2,
          contacto3: this.cliente_contacto_3,
          telefono1: this.cliente_telefono_1,
          telefono2: this.cliente_telefono_2,
          telefono3: this.cliente_telefono_3,
          nombre_RL: "",
          dui_Rl: "",
          ppe_RL: false,
        })
        .then(() => {
          this.alerta("success", "Cliente creado con exito");
          this.cerrarDialogAgregarCliente();
          this.limpiarAgregarCliente();
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    limpiarAgregarCliente() {
      this.cliente_tipo_persona = "";
      this.cliente_codigo = "";
      this.cliente_fecha_ingreso = "";
      this.cliente_nombre = "";
      this.cliente_razon_social = "";
      this.cliente_direccion = "";
      this.cliente_fecha_nacimiento = "";
      this.cliente_dui = "";
      this.cliente_nit = "";
      this.cliente_celular = "";
      this.cliente_telefono = "";
      this.cliente_correo = "";
      this.cliente_giro = "";
      this.cliente_tamaño = "";
      this.cliente_registro = "";
      this.cliente_contacto_1 = "";
      this.cliente_contacto_2 = "";
      this.cliente_contacto_3 = "";
      this.cliente_telefono_1 = "";
      this.cliente_telefono_2 = "";
      this.cliente_telefono_3 = "";
    },
    setCliente(event, { item }) {
      this.id_cliente = item.id_cliente;
      this.codigo_cliente = item.codigo;
      this.nombre_cliente = item.nombre;
      this.nombre_cliente_carpeta = item.nombre_carpeta;
      if (item.registro) {
        this.tipo_doc_cliente = "CCF";
      } else {
        this.tipo_doc_cliente = "FAC";
      }

      this.dialog_clientes = false;
    },
    ///////////////////////////////////////// AGREGAR NUEVO PRODUCTO ////////////////
    listarRamoSegurosNuevoProducto() {
      axios.get("api/RamoSeguros/Listar").then((response) => {
        this.items_ramo_seguros_nuevo_prod = response.data;
      });
    },
    listarTipoSegurosNuevoProducto() {
      axios.get("api/TipoSeguros/Listar").then((response) => {
        this.items_tipo_seguros_nuevo_prod = response.data;
      });
    },
    nuevoProducto() {
      this.listarRamoSegurosNuevoProducto();
      this.listarTipoSegurosNuevoProducto();

      this.dialog_nuevo_producto = true;
    },
    crearProducto() {
      axios
        .post("api/Seguros/Crear", {
          id_aseguradoras: this.nuevo_producto_aseguradora.id_aseguradoras,
          id_categoria_seguros:
            this.nuevo_producto_categoria.id_categoria_seguros,
          id_ramo_seguros: this.nuevo_producto_ramo.id_ramo_seguros,
          id_tipo_seguros: this.nuevo_producto_tipo.id_tipo_seguro,
          codigo: this.nuevo_producto_codigo,
          nombre: this.nuevo_producto_nombre,
          descripcion: this.nuevo_producto_descripcion,
          comision: this.nuevo_producto_comision,
          activo: this.nuevo_producto_activo,
          exento: this.nuevo_producto_exento,
        })
        .then(() => {
          this.alerta("success", "Producto creado");

          this.cerrarDialogNuevoProducto();
          this.listarSeguros();
        })
        .catch(() => {
          this.alerta("error", "No se puedo crear");
        });
    },
    crearCodigoNuevoProducto() {
      if (
        this.nuevo_producto_aseguradora &&
        this.nuevo_producto_categoria &&
        this.nuevo_producto_ramo &&
        this.nuevo_producto_tipo
      ) {
        this.nuevo_producto_codigo =
          this.nuevo_producto_aseguradora.codigo +
          this.nuevo_producto_categoria.codigo +
          this.nuevo_producto_ramo.codigo +
          this.nuevo_producto_tipo.codigo;
        this.crearNombreNuevoProducto();
      }
    },
    crearNombreNuevoProducto() {
      this.nuevo_producto_nombre =
        "SEGURO DE" +
        this.nuevo_producto_ramo.descripcion +
        " " +
        this.nuevo_producto_tipo.descripcion;
    },
    cerrarDialogNuevoProducto() {
      this.dialog_nuevo_producto = false;

      this.limpiarNuevoProducto();
    },
    limpiarNuevoProducto() {
      this.nuevo_producto_aseguradora = "";
      this.nuevo_producto_categoria = "";
      this.nuevo_producto_ramo = "";
      this.nuevo_producto_tipo = "";
      this.nuevo_producto_codigo = "";
      this.nuevo_producto_nombre = "";
      this.nuevo_producto_descripcion = "";
      this.nuevo_producto_comision = "";
      this.nuevo_producto_activo = false;
      this.nuevo_producto_exento = false;
    },
    //////////////////////////////////////// POLIZAS CLIENTES //////////////////////
    listarPolizasCliente(id) {
      this.items_polizas_cliente = [];
      axios
        .get("api/Polizas/Listar/" + id)
        .then((response) => {
          response.data.forEach((element) => {
            if (this.poliza != element.num_poliza) {
              this.items_polizas_cliente.push(element);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /////////////////////////////////////// CUOTAS ////////////////////////////////
    listarCuotas(id) {
      this.items_cuotas = [];
      axios
        .get("api/Cuotas/Listar/" + id)
        .then((response) => {
          //console.log(response.data);
          if (response.data.length == 0) {
            this.bandera_sin_cuotas = true;
          }
          response.data.forEach((element, index) => {
            this.items_cuotas.push({
              index: index + 1,
              id_polizas: element.id_polizas,
              id_cuotas: element.id_cuotas,
              id_tipo_documentos: element.id_tipo_documentos,
              fecha: element.fecha,
              monto: element.monto,
              esta_pagada: element.esta_pagada,
              fecha_pago: element.fecha_pago,
              nombre_tipo_documento: element.nombre_tipo_documento,
              factura: element.factura,
              cuota_independiente: element.cuota_independiente,
            });
          });

          this.cantidad_cuotas = response.data.length;

          if (this.items_cuotas.length == 0) {
            this.bandera_cuota = 0;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarCuotasIndependientes(id) {
      axios.get("api/Cuotas/ListarIndependiente/" + id).then((response) => {
        this.items_cuotas_independientes = response.data;
      });
    },
    listarCuotasInclusivosCertificados(id) {
      axios
        .get("api/CertificadoPagos/ListarCuotasCert/" + id)
        .then((response) => {
          //console.log(response.data);
          this.items_pago_certificados_incluidos = response.data;
        });
    },
    listarCuotasAumentosInclusivos() {
      axios
        .get("api/AumentosInclusiones/Listar/" + this.id_poliza)
        .then((response) => {
          //console.log(response.data);
          this.items_aumentos_inclusiones = response.data;
          if (response.data.length == 0) {
            this.aumentos_inclusiones_bandera_sin_cuotas = true;
          } else {
            this.aumentos_inclusiones_bandera_sin_cuotas = false;
          }
        });
    },
    calculoDiaCobro() {
      var lista_fecha = this.fecha_inicial.split("-")[2];
      this.dia_cobro = parseInt(lista_fecha);

      var date = new Date(this.fecha_inicial);
      date.setFullYear(date.getFullYear() + 1);
      this.fecha_fin = this.formatoFecha(date);
    },
    contadorDetalleCuotas(item) {
      //console.log(item);
      if (item.index) {
        return item.index + "/" + this.items_cuotas.length;
      }
      return item.cont + 1 + "/" + this.items_cuotas.length;
    },
    contadorDetalleCuotasInclusiones(item) {
      if (item.index) {
        return item.index + "/" + this.items_pago_certificados_incluidos.length;
      }
      return (
        item.cont + 1 + "/" + this.items_pago_certificados_incluidos.length
      );
    },
    agregarArchivoCuota(item) {
      //console.log(item);
      this.aux_fecha_cuota = this.formatoFecha(item.fecha);
      this.$refs.uploader.click();
    },
    async guardarArchivoCuota(e) {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      var list_files = e.target.files;
      for (var i = 0; i < list_files.length; i++) {
        const result = await apiOneDrive.metodos.CrearArchivoPolizaCuota(
          this.$store.state.usuario.id_usuario,
          this.nombre_cliente_carpeta,
          this.aseguradora.nombre_carpeta,
          this.fecha_inicial,
          this.categoria.descripcion,
          this.ramo_seguro.descripcion,
          this.poliza,
          this.fecha_inicial,
          this.tipo_doc_cliente,
          list_files[i]
        );
        if (result.status == 201) {
          this.alertaMensaje(
            "Archivo guardado: " + result.data.name,
            "success"
          );
        } else {
          this.alertaMensaje(
            "Archivo no se pudo guardar: " + result.data.name,
            "error"
          );
        }
      }
      this.alerta("info", "Proceso terminado.");
    },
    async verArchivoCuota(item) {
      let credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      let date = new Date(this.fecha_inicial);
      let year = date.getFullYear();

      var ruta =
        this.nombre_cliente_carpeta +
        "/" +
        year.toString() +
        "/" +
        this.aseguradora.nombre_carpeta.split(/[.,]+/).join("") +
        "/" +
        this.categoria.descripcion +
        "/" +
        this.ramo_seguro.descripcion +
        "/" +
        this.poliza +
        "/" +
        this.tipo_doc_cliente;

      credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      let token = await apiLocal.metodos.getItemObject(
        "access_token",
        credenciales
      ).valor;
      let token_decrypt = await apiLocal.metodos.desencriptar(token);

      let header = { Authorization: "Bearer " + token_decrypt };
      let configuracion = { headers: header };

      axios
        .get(
          "https://graph.microsoft.com/v1.0/me/drive/root:/" +
            process.env.VUE_APP_RAIZ_CARPETA +
            "/1-Asegurados/" +
            ruta,
          configuracion
        )
        .then((response) => {
          window.open(response.data.webUrl, "_blank").focus();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editCuotaPagada(item) {
      //console.log(item);
      this.id_aux = item.id;

      if (item.id_cuotas) {
        this.bandera_cuota = 2;
      } else {
        if (this.id_poliza) {
          this.bandera_cuota = 0;
        } else {
          this.bandera_cuota = 1;
        }
      }

      this.id_cuota = item.id_cuotas;
      this.cuota_fecha = this.formatoFecha(item.fecha);
      this.cuota_monto = parseFloat(item.monto).toFixed(2);
      this.id_tipo_documentos = {
        id_tipo_documentos: item.id_tipo_documentos,
        nombre: item.tipo_documento,
      };
      if (item.fecha_pago) {
        this.cuota_fecha_pago = this.formatoFecha(item.fecha_pago);
      }
      this.cuota_independiente = item.cuota_independiente;

      this.cuota_num_documento = item.factura;

      this.dialog_cuotas_tipo_documento = true;
    },
    actualizarCuotas() {
      //AL PRESIONAR EL CHECK-BOX LEVANTA UN DIALOG
      axios
        .put("api/Cuotas/Actualizar", {
          id_cuotas: this.id_cuota,
          id_polizas: this.id_poliza,
          id_tipo_documentos: this.id_tipo_documentos.id_tipo_documentos,
          fecha: this.cuota_fecha,
          monto: this.cuota_monto,
          esta_pagada: true,
          fecha_pago: this.cuota_fecha_pago,
          factura: this.cuota_num_documento,
          cuota_independiente: this.cuota_independiente,
        })
        .then(() => {
          this.cerrarDialogCuotaTipoDocumento();
          this.listarCuotas(this.id_poliza);
          this.listarCuotasIndependientes(this.id_poliza);
          this.alerta("success", "Registro creado con exito");
        })
        .catch((error) => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    actualizarMontoCuota(item) {
      if (item.id_cuotas) {
        axios
          .put("api/Cuotas/ActualizarMontoCuota", {
            id_cuotas: item.id_cuotas,
            id_polizas: item.id_polizas,
            monto: item.monto,
            fecha: item.fecha,
          })
          .then(() => {
            this.alerta("success", "Cuota actualizado");
          })
          .catch(() => {
            this.alerta("error", "Sucedio algún error");
          });
      } else {
        if (item.id == 1) {
          for (var i = 1; i < this.items_cuotas.length; i++) {
            this.items_cuotas[i].monto = item.monto;
          }
        }
      }
    },
    actualizarFechaCuota(item) {
      if (item.id_cuotas) {
        axios
          .put("api/Cuotas/ActualizarFechaCuota", {
            id_cuotas: item.id_cuotas,
            fecha: item.fecha,
          })
          .then(() => {
            this.alerta("success", "Actualizado con exito");
          })
          .catch(() => {
            this.alerta("error", "No se pudo actualizar");
          });
      } else {
        this.items_cuotas[item.id].fecha = item.fecha;
      }
    },
    actualizarFechaCuotaAumentoInclusion(item) {
      //console.log(item);
      if (item.id_polizas) {
        axios
          .put("api/AumentosInclusiones/ActualizarFechaCuota", {
            id_aumento_inclusion: item.id_aumento_inclusion,
            fecha: item.fecha,
          })
          .then(() => {
            this.alerta("success", "Fecha actualizado");
          })
          .catch(() => {
            this.alerta("error", "Sucedio algún error");
          });
      } else {
        if (item.id == 1) {
          for (var i = 1; i < this.items_aumentos_inclusiones.length; i++) {
            this.items_aumentos_inclusiones[i].fecha = item.fecha;
          }
        }
      }
    },
    actualizarMontoCuotaAumentoInclusion(item) {
      //console.log(item);
      if (item.id_polizas) {
        axios
          .put("api/AumentosInclusiones/ActualizarMontoCuota", {
            id_aumento_inclusion: item.id_aumento_inclusion,
            monto: item.monto,
          })
          .then(() => {
            this.alerta("success", "Monto actualizado");
          })
          .catch(() => {
            this.alerta("error", "Sucedio algún error");
          });
      } else {
        if (item.id == 1) {
          for (var i = 1; i < this.items_aumentos_inclusiones.length; i++) {
            this.items_aumentos_inclusiones[i].monto = item.monto;
          }
        }
      }
    },
    /////////////////////////////// CUOTAS - PAGOS ////////////////////////////////////////////////////////
    agregarArchivoPagoCuota(item) {
      this.aux_fecha_pago_cuota = this.formatoFecha(item.fecha);
      this.$refs.uploaderPagoPoliza.click();
    },
    async guardarArchivoPagoCuota(e) {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      var list_files = e.target.files;
      for (var i = 0; i < list_files.length; i++) {
        const result = await apiOneDrive.metodos.CrearArchivoPagoPolizaCuota(
          this.$store.state.usuario.id_usuario,
          this.nombre_cliente_carpeta,
          this.aseguradora.nombre_carpeta,
          this.fecha_inicial,
          this.categoria.descripcion,
          this.ramo_seguro.descripcion,
          this.poliza,
          this.aux_fecha_pago_cuota,
          this.tipo_doc_cliente,
          list_files[i]
        );
        if (result.status == 201) {
          this.alertaMensaje(
            "Archivo guardado: " + result.data.name,
            "success"
          );
        } else {
          this.alertaMensaje(
            "Archivo no se pudo guardar: " + result.data.name,
            "error"
          );
        }
      }
    },
    async verArchivoPagoCuota(item) {
      let credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      let date = new Date(this.fecha_inicial);
      let year = date.getFullYear();

      var ruta =
        this.nombre_cliente_carpeta +
        "/" +
        year.toString() +
        "/" +
        this.aseguradora.nombre_carpeta.split(/[.,]+/).join("") +
        "/" +
        this.categoria.descripcion +
        "/" +
        this.ramo_seguro.descripcion +
        "/" +
        this.poliza +
        "/" +
        this.tipo_doc_cliente;

      credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      let token = await apiLocal.metodos.getItemObject(
        "access_token",
        credenciales
      ).valor;
      let token_decrypt = await apiLocal.metodos.desencriptar(token);

      let header = { Authorization: "Bearer " + token_decrypt };
      let configuracion = { headers: header };

      axios
        .get(
          "https://graph.microsoft.com/v1.0/me/drive/root:/" +
            process.env.VUE_APP_RAIZ_CARPETA +
            "/1-Asegurados/" +
            ruta,
          configuracion
        )
        .then((response) => {
          window.open(response.data.webUrl, "_blank").focus();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ////////////////////////////////CALCULO CUOTAS POLIZAS////////////////////////////////////////////////
    calculoGastoBomberos() {
      this.prima_comisionable = (this.prima_neta / 1.05).toFixed(2);
      this.gasto_bomberos = (this.prima_neta - this.prima_comisionable).toFixed(
        2
      );
    },
    calculoTotalPagar() {
      this.total_a_pagar = (
        parseFloat(this.prima_neta) +
        parseFloat(this.gasto_bomberos) +
        parseFloat(this.fraccionamiento) +
        parseFloat(this.gastos_emision) +
        parseFloat(this.iva_13_por_ciento)
      ).toFixed(2);

      if (this.calcular_fraccionamiento) {
        this.fraccionamiento = (
          this.total_a_pagar / this.cantidad_cuotas
        )?.toFixed(2);
        this.valor_cuota = (this.total_a_pagar / this.cantidad_cuotas)?.toFixed(
          2
        );
      }

      this.calculoMontoCuota();
    },
    calculoMontoCuota() {
      if (this.periodos == "ANUAL") {
        if (this.tipo_cuota && this.prima_neta) {
          if (this.tipo_cuota.nombre == "MENSUAL" || this.tipo_cuota == 1) {
            this.valor_cuota = (
              this.total_a_pagar / this.cantidad_cuotas
            )?.toFixed(2);
            this.creacionCuotasMensual();
          } else if (
            this.tipo_cuota.nombre == "TRIMESTRAL" ||
            this.tipo_cuota == 2
          ) {
            this.valor_cuota = (this.total_a_pagar / 4)?.toFixed(2);
            this.creacionCuotasTrimestral();
          } else if (
            this.tipo_cuota.nombre == "SEMESTRAL" ||
            this.tipo_cuota == 3
          ) {
            this.valor_cuota = (this.total_a_pagar / 2)?.toFixed(2);
            this.creacionCuotasSemestral();
          } else if (
            this.tipo_cuota.nombre == "ANUAL" ||
            this.tipo_cuota == 4
          ) {
            this.valor_cuota = (this.total_a_pagar / 1)?.toFixed(2);
            this.creacionCuotasAnual();
          }
        }
      } else if (this.periodos == "PRORRATA" && this.prima_neta) {
        this.creacionCuotaProrrata();
      }
    },
    calculoIva13() {
      this.iva_13_por_ciento = (
        (parseFloat(this.prima_neta) +
          parseFloat(this.gasto_bomberos) +
          parseFloat(this.fraccionamiento) +
          parseFloat(this.gastos_emision)) *
        0.13
      ).toFixed(2);
    },
    creacionCuotasMensual() {
      var fecha = new Date(this.fecha_inicial + " " + "00:00:00");
      var fecha_dia = fecha.getDate();
      var fecha_año = fecha.getFullYear();
      var index_mes = fecha.getMonth() + 1;

      this.items_cuotas = [];
      var contador = 0;
      if (!this.id_poliza) {
        for (var i = 0; i < this.cantidad_cuotas; i++) {
          var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
          var format_date = this.validarLimiteFecha(fecha_cuota);

          this.items_cuotas.push({
            cont: contador,
            id: contador,
            id_tipo_documentos: null,
            esta_pagada: false,
            fecha: format_date,
            monto: this.valor_cuota,
            fecha_pago: null,
            factura: "",
          });
          contador++;

          if (index_mes == 12) {
            fecha_año++;
            index_mes = 1;
          } else {
            index_mes++;
          }
        }
      } else {
        for (var i = 0; i < this.cantidad_cuotas; i++) {
          var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
          var format_date = this.validarLimiteFecha(fecha_cuota);

          this.items_cuotas.push({
            cont: contador,
            id: contador,
            id_polizas: this.id_poliza,
            id_tipo_documentos: null,
            esta_pagada: false,
            fecha: format_date,
            monto: this.valor_cuota,
            fecha_pago: null,
            factura: "",
          });
          contador++;

          if (index_mes == 12) {
            fecha_año++;
            index_mes = 1;
          } else {
            index_mes++;
          }
        }
      }
    },
    creacionCuotasAnual() {
      this.items_cuotas = [];
      var contador = 0;
      if (!this.id_poliza) {
        for (var i = 0; i < 1; i++) {
          this.items_cuotas.push({
            cont: contador,
            id: contador,
            id_tipo_documentos: null,
            esta_pagada: false,
            fecha: this.formatoFecha(this.fecha_fin),
            monto: this.valor_cuota,
            fecha_pago: null,
            factura: "",
          });
          contador++;
        }
      } else {
        for (var i = 0; i < 1; i++) {
          this.items_cuotas.push({
            cont: contador,
            id: contador,
            id_polizas: this.id_poliza,
            id_tipo_documentos: null,
            esta_pagada: false,
            fecha: this.formatoFecha(this.fecha_fin),
            monto: this.valor_cuota,
            fecha_pago: null,
            factura: "",
          });
          contador++;
        }
      }
    },
    creacionCuotasSemestral() {
      var fecha = new Date(this.fecha_inicial + " " + "00:00:00");
      this.items_cuotas = [];
      var contador = 0;

      if (!this.id_poliza) {
        for (var i = 0; i < 2; i++) {
          var fecha_dia = fecha.getDate();
          var fecha_año = fecha.getFullYear();
          var index_mes = fecha.getMonth() + 1;
          var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
          var format_date = this.validarLimiteFecha(fecha_cuota);

          this.items_cuotas.push({
            cont: contador,
            id: contador,
            id_tipo_documentos: null,
            esta_pagada: false,
            fecha: format_date,
            monto: this.valor_cuota,
            fecha_pago: null,
            factura: "",
          });
          contador++;

          //SOLO SE SUMAN 5 MESES YA QUE AL OBTENER index_mes se suma un mes mas
          fecha.setMonth(fecha.getMonth() + 5);
        }
      } else {
        for (var i = 0; i < 2; i++) {
          var fecha_dia = fecha.getDate();
          var fecha_año = fecha.getFullYear();
          var index_mes = fecha.getMonth() + 1;
          var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
          var format_date = this.validarLimiteFecha(fecha_cuota);

          this.items_cuotas.push({
            cont: contador,
            id: contador,
            id_polizas: this.id_poliza,
            id_tipo_documentos: null,
            esta_pagada: false,
            fecha: format_date,
            monto: this.valor_cuota,
            fecha_pago: null,
            factura: "",
          });
          contador++;

          //SOLO SE SUMAN 5 MESES YA QUE AL OBTENER index_mes se suma un mes mas
          fecha.setMonth(fecha.getMonth() + 5);
        }
      }
    },
    creacionCuotasTrimestral() {
      var fecha = new Date(this.fecha_inicial + " " + "00:00:00");
      this.items_cuotas = [];
      var contador = 0;

      if (!this.id_poliza) {
        for (var i = 0; i < 4; i++) {
          var fecha_dia = fecha.getDate();
          var fecha_año = fecha.getFullYear();
          var index_mes = fecha.getMonth() + 1;
          var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
          var format_date = this.validarLimiteFecha(fecha_cuota);

          this.items_cuotas.push({
            cont: contador,
            id: contador,
            id_tipo_documentos: null,
            esta_pagada: false,
            fecha: format_date,
            monto: this.valor_cuota,
            fecha_pago: null,
            factura: "",
          });
          contador++;

          //SOLO SE SUMAN 4 MESES YA QUE AL OBTENER index_mes se suma un mes mas
          fecha.setMonth(fecha.getMonth() + 4);
        }
      } else {
        for (var i = 0; i < 4; i++) {
          var fecha_dia = fecha.getDate();
          var fecha_año = fecha.getFullYear();
          var index_mes = fecha.getMonth() + 1;
          var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
          var format_date = this.validarLimiteFecha(fecha_cuota);

          this.items_cuotas.push({
            cont: contador,
            id: contador,
            id_polizas: this.id_poliza,
            id_tipo_documentos: null,
            esta_pagada: false,
            fecha: format_date,
            monto: this.valor_cuota,
            fecha_pago: null,
            factura: "",
          });
          contador++;

          //SOLO SE SUMAN 4 MESES YA QUE AL OBTENER index_mes se suma un mes mas
          fecha.setMonth(fecha.getMonth() + 4);
        }
      }
    },
    creacionCuotaProrrata() {
      var fecha = new Date(this.fecha_inicial + " " + "00:00:00");
      //var fecha_fin = new Date(this.fecha_fin + " " + "00:00:00");
      //var date_diff = fecha_fin.getMonth() - fecha.getMonth() + 12 * (fecha_fin.getFullYear() - fecha.getFullYear());

      var fecha_dia = fecha.getDate();
      var fecha_año = fecha.getFullYear();
      var index_mes = fecha.getMonth() + 1;

      this.items_cuotas = [];
      var contador = 0;
      if (!this.id_poliza) {
        for (var i = 0; i < this.cantidad_cuotas; i++) {
          var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
          var format_date = this.validarLimiteFecha(fecha_cuota);

          this.items_cuotas.push({
            cont: contador,
            id: contador,
            id_tipo_documentos: null,
            esta_pagada: false,
            fecha: format_date,
            monto: this.valor_cuota,
            fecha_pago: null,
            factura: "",
          });
          contador++;

          if (index_mes == 12) {
            fecha_año++;
            index_mes = 1;
          } else {
            index_mes++;
          }
        }
      } else {
        for (var i = 0; i < this.cantidad_cuotas; i++) {
          var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
          var format_date = this.validarLimiteFecha(fecha_cuota);

          this.items_cuotas.push({
            cont: contador,
            id: contador,
            id_polizas: this.id_poliza,
            id_tipo_documentos: null,
            esta_pagada: false,
            fecha: format_date,
            monto: this.valor_cuota,
            fecha_pago: null,
            factura: "",
          });
          contador++;

          if (index_mes == 12) {
            fecha_año++;
            index_mes = 1;
          } else {
            index_mes++;
          }
        }
      }
    },
    validarLimiteFecha(fecha) {
      var split_date = fecha.split("-");
      if (
        moment(
          split_date[0] +
            "-" +
            ("0" + split_date[1]).slice(-2) +
            "-" +
            split_date[2]
        ).isValid()
      ) {
        return moment(fecha).format("YYYY-MM-DD");
      } else {
        return moment(
          split_date[0] +
            "-" +
            split_date[1] +
            "-" +
            new Date(split_date[0], split_date[1], 0).getDate()
        ).format("YYYY-MM-DD");
      }
    },
    ///////////////////////////////// CUOTA - GESTION COBRO ////////////////////////////////////
    listarGestionCobros() {
      axios
        .get("api/GestionCobros/Listar/" + this.id_cuota)
        .then((response) => {
          this.items_gestion_cobro = response.data;
        });
    },
    nuevaGestionCobro() {
      this.dialog_gestion_cobro_observaciones = true;
    },
    crearGestionCobro() {
      axios
        .post("api/GestionCobros/Crear", {
          id_cuotas: this.id_cuota,
          id_usuario: this.$store.state.usuario.id_usuario,
          fecha: this.fecha_cuota_gestion_cobro,
          observaciones: this.observaciones_cuota_gestion_cobro,
        })
        .then(() => {
          this.alerta("success", "Registro creado");
          this.cerrarDialogGestionCobroObserva();
          this.listarGestionCobros();
        });
    },
    editCuotaGestionCobro(item) {
      this.dialog_cuotas_gestion_cobro = true;
      this.id_cuota = item.id_cuotas;
      this.listarGestionCobros();
    },
    cerrarDialogCuotaGestionCobro() {
      this.dialog_cuotas_gestion_cobro = false;
      this.id_cuota = null;
      this.fecha_cuota_gestion_cobro = null;
      this.observaciones_cuota_gestion_cobro = null;
    },
    cerrarDialogGestionCobroObserva() {
      this.dialog_gestion_cobro_observaciones = false;
      this.fecha_cuota_gestion_cobro = "";
      this.observaciones_cuota_gestion_cobro = "";
    },
    ///////////////////////////////////////////////////////////////////////////////////////////
    cerrarDialogCuotaTipoDocumento() {
      this.dialog_cuotas_tipo_documento = false;

      this.limpiarCuotasTipoDocumentos();
    },
    aplicarCambiosCuotas() {
      this.items_cuotas[this.id_aux].fecha = this.cuota_fecha;
      this.items_cuotas[this.id_aux].monto = this.cuota_monto;
      this.items_cuotas[this.id_aux].esta_pagada = true;
      this.items_cuotas[this.id_aux].fecha_pago = this.cuota_fecha_pago;
      this.items_cuotas[this.id_aux].id_tipo_documentos =
        this.id_tipo_documentos.id_tipo_documentos;
      this.items_cuotas[this.id_aux].factura = this.cuota_num_documento;

      this.dialog_cuotas_tipo_documento = false;
      this.cerrarDialogCuotaTipoDocumento();
    },
    limpiarCuotasTipoDocumentos() {
      this.id_cuota = null;
      this.cuota_fecha = null;
      this.cuota_monto = null;
      this.id_tipo_documentos = null;
      this.cuota_fecha_pago = null;
      this.cuota_num_documento = null;
    },
    estadoPagado(item) {
      if (!item) {
        return true;
      } else {
        return false;
      }
    },
    /////////////////////////////////////// CERTIFICADO ////////////////////////////////
    listarCertificados(id) {
      var nombre_url = "";
      if (this.nombre_ramo == "AUTOS") {
        nombre_url = "ListarPorAuto";
      } else if (
        this.nombre_ramo == "VIDA" ||
        this.nombre_ramo == "ACCIDENTES PERSONALES"
      ) {
        nombre_url = "ListarPorVida";
      } else if (this.nombre_ramo == "GASTOS MEDICOS") {
        nombre_url = "ListarPorGastoMedico";
      } else if (this.nombre_ramo == "TRANSPORTE MERCADERIA") {
        nombre_url = "ListarPorTransporteMercaderia";
      } else if (this.nombre_ramo == "INCENDIO") {
        nombre_url = "ListarPorIncendio";
      } else {
        nombre_url = "Listar";
      }

      axios
        .get("api/Certificados/" + nombre_url + "/" + id + "/" + false)
        .then((response) => {
          //console.log(response.data);
          this.items_certificados = response.data;
        });

      axios
        .get("api/Certificados/" + nombre_url + "/" + id + "/" + true)
        .then((response) => {
          //console.log("EXCLUIDOS",response.data);
          this.items_certificados_excluidos = response.data;
        });
    },
    listarCertificadosExcluidos(id) {
      var nombre_url = "";
      if (this.nombre_ramo == "AUTOS") {
        nombre_url = "ListarPorAuto";
      } else if (
        this.nombre_ramo == "VIDA" ||
        this.nombre_ramo == "ACCIDENTES PERSONALES"
      ) {
        nombre_url = "ListarPorVida";
      } else if (this.nombre_ramo == "GASTOS MEDICOS") {
        nombre_url = "ListarPorGastoMedico";
      }

      axios
        .get("api/Certificados/" + nombre_url + "/" + id + "/" + "excluido")
        .then((response) => {
          //console.log("EXCLUIDOS",response.data);
          this.items_certificados_excluidos = response.data;
        });
    },
    cerrarDialogCertificado() {
      this.dialog_certificado = false;
      this.limpiarCertificado();
      this.limpiarCertificadoVida();
      this.limpiarCertificadoAuto();
      this.limpiarCertificadoGastoMedico();
    },
    limpiarCertificado() {
      this.id_certificado = null;
      this.id_certificado_poliza = null;
      this.num_certificado = null;
      this.certificado_fecha_inicio = null;
      this.certificado_fecha_fin = null;
      this.certificado_individual = false;
      this.certificado_dia_cobro = null;
      this.certificado_fecha_primer_pago = null;
      this.certificado_periodo = null;
      this.certificado_tipo_cuota = null;
      this.certificado_prima = null;
      this.certificado_cuota = null;
      this.certificado_inclusion = false;
      this.certificado_excluido = false;
      this.certificado_motivo_excluido = "";
      this.items_certificado_pagos = [];
      this.file_input_certificado_archivo = [];
      this.archivos_seleccionados_certificado_archivo = [];
      this.items_archivos_certificado_archivo = [];
      this.items_cert_transporte_mercaderia = [];
    },
    editarCertificadoTransporteMercaderia(item) {
      //console.log("editar", item);
      this.contador_certificado_transporte_mercaderia = item.cont;
      this.cert_id_transporte_mercaderia =
        item.id_certificado_transporte_mercaderia;
      this.cert_transporte_mercaderia_num_trans_merca = item.num_trans_merca;
      this.cert_transporte_mercaderia_asegurado = item.asegurado;
      this.cert_transporte_mercaderia_tipo_mercaderia = item.tipo_mercaderia;
      this.cert_transporte_mercaderia_proveedor_cliente =
        item.proveedor_cliente;
      this.cert_transporte_mercaderia_maritimo = item.marititmo;
      this.cert_transporte_mercaderia_aereo = item.aereo;
      this.cert_transporte_mercaderia_terrestre = item.terrestre;
      this.cert_transporte_mercaderia_nombre_vapor = item.nombre_vapor;
      this.cert_transporte_mercaderia_procedente_de = item.procedente_de;
      this.cert_transporte_mercaderia_destino_a = item.destino_a;
      this.cert_transporte_mercaderia_num_factura = item.num_factura;
      this.cert_transporte_mercaderia_fecha_factura = this.formatoFecha(
        item.fecha_factura
      );
      this.cert_transporte_mercaderia_fecha_despacho = this.formatoFecha(
        item.fecha_despacho_merca_proveedor
      );
      this.cert_transporte_mercaderia_vigencia_desde = this.formatoFecha(
        item.vigencia_certificado_desde
      );
      this.cert_transporte_mercaderia_vigencia_hasta = this.formatoFecha(
        item.vigencia_certificado_hasta
      );
      this.cert_transporte_mercaderia_valor_factura = item.valor_factura;
      this.cert_transporte_mercaderia_sub_total = item.sub_total;
      this.cert_transporte_mercaderia_flete = item.flete;
      this.cert_transporte_mercaderia_porcentaje_adicional =
        item.porcentaje_adicional;
      this.cert_transporte_mercaderia_total_suma_asegurada =
        item.total_suma_asegurada;

      this.flag_editar_cert_transporte_mercaderia = true;
    },
    updateCertificadoTransporteMercaderia() {
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].num_trans_merca = this.cert_transporte_mercaderia_num_trans_merca;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].asegurado = this.cert_transporte_mercaderia_asegurado;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].tipo_mercaderia = this.cert_transporte_mercaderia_tipo_mercaderia;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].proveedor_cliente = this.cert_transporte_mercaderia_proveedor_cliente;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].marititmo = this.cert_transporte_mercaderia_maritimo;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].aereo = this.cert_transporte_mercaderia_aereo;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].terrestre = this.cert_transporte_mercaderia_terrestre;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].nombre_vapor = this.cert_transporte_mercaderia_nombre_vapor;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].procedente_de = this.cert_transporte_mercaderia_procedente_de;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].destino_a = this.cert_transporte_mercaderia_destino_a;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].num_factura = this.cert_transporte_mercaderia_num_factura;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].fecha_factura = this.cert_transporte_mercaderia_fecha_factura;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].fecha_despacho_merca_proveedor =
        this.cert_transporte_mercaderia_fecha_despacho;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].vigencia_certificado_desde =
        this.cert_transporte_mercaderia_vigencia_desde;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].vigencia_certificado_hasta =
        this.cert_transporte_mercaderia_vigencia_hasta;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].valor_factura = this.cert_transporte_mercaderia_valor_factura;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].sub_total = this.cert_transporte_mercaderia_sub_total;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].flete = this.cert_transporte_mercaderia_flete;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].porcentaje_adicional =
        this.cert_transporte_mercaderia_porcentaje_adicional;
      this.items_cert_transporte_mercaderia[
        this.contador_certificado_transporte_mercaderia
      ].total_suma_asegurada =
        this.cert_transporte_mercaderia_total_suma_asegurada;
      this.flag_editar_cert_transporte_mercaderia = false;
      this.limpiarCertificadoTransporteMercaderia();
    },
    editarCertificado(item) {
      //console.log(item);
      //EN EL CASO DE SER NUEVA POLIZA EL ID NO EXISTE
      if (this.id_poliza) {
        switch (this.ramo_seguro.descripcion) {
          case "VIDA":
          case "ACCIDENTES PERSONALES":
            this.listarCertificadoVida(item.id_certificados);
            break;
          case "AUTOS":
            this.listarCertificadoAuto(item.id_certificados);
            break;
          case "GASTOS MEDICOS":
            this.listarCertificadoGastoMedico(item.id_certificados);
            break;
          case "TRANSPORTE MERCADERIA":
            this.listarCertificadosTransporteMercaderia(item.id_certificados);
            break;
          case "INCENDIO":
            this.listarCertificadosIncendio(item.id_certificados);
            break;
        }
      } else {
        switch (this.ramo_seguro.descripcion) {
          case "VIDA":
          case "ACCIDENTES PERSONALES":
            if (item.crearVida.length > 0) {
              this.certificado_vida_nombre = item.crearVida[0].nombre;
              this.certificado_vida_sexo = item.crearVida[0].sexo;
              this.certificado_vida_dui = item.crearVida[0].dui;
              this.certificado_vida_fecha_nacimiento = this.formatoFecha(
                item.crearVida[0].fecha_nacimiento
              );
              this.certificado_vida_clase = item.crearVida[0].clase;
              this.certificado_vida_edad = item.crearVida[0].edad;
              this.certificado_vida_suma_asegurada =
                item.crearVida[0].suma_asegurada;
            }
            break;
          case "AUTOS":
            if (item.crearAuto.length > 0) {
              this.certificado_autos_tipo_vehiculo =
                item.crearAuto[0].tipo_vehiculo;
              this.certificado_autos_marca = item.crearAuto[0].marca;
              this.certificado_autos_modelo = item.crearAuto[0].modelo;
              this.certificado_autos_año = item.crearAuto[0].año;
              this.certificado_autos_color = item.crearAuto[0].color;
              this.certificado_autos_placa = item.crearAuto[0].placa;
              this.certificado_autos_num_motor = item.crearAuto[0].num_motor;
              this.certificado_autos_num_chasis = item.crearAuto[0].num_chasis;
              this.certificado_autos_num_motor = item.crearAuto[0].version;
              this.certificado_autos_cesion = item.crearAuto[0].cesion;
              this.certificado_autos_vencimiento_tarjeta = this.formatoFecha(
                item.crearAuto[0].vencimiento_tarjeta
              );
              this.certificado_autos_vencimiento_dui = this.formatoFecha(
                item.crearAuto[0].vencimiento_dui
              );
              this.certificado_autos_propietario =
                item.crearAuto[0].propietario;
            }
            break;
          case "GASTOS MEDICOS":
            if (item.crearGastoMedico.length > 0) {
              this.certificado_gasto_medico_clase =
                item.crearGastoMedico[0].clase;
              this.certificado_gasto_medico_nombre =
                item.crearGastoMedico[0].nombre;
              this.certificado_gasto_medico_edad =
                item.crearGastoMedico[0].edad;
              this.certificado_gasto_medico_fecha_nacimiento =
                item.crearGastoMedico[0].fecha_nacimiento;
              this.certificado_gasto_medico_sexo =
                item.crearGastoMedico[0].sexo;
              this.certificado_gasto_medico_max_vit_contratado =
                item.crearGastoMedico[0].maximo_vitalicio_contratado;
              this.certificado_gasto_medico_max_vit_vigente =
                item.crearGastoMedico[0].maximo_vitalicio_vigente;
              this.certificado_gasto_medico_cobertura =
                item.crearGastoMedico[0].cobertura;
            }
            break;
        }
      }

      //console.log(item);
      this.id_certificado = item.id_certificados;
      this.id_certificado_poliza = item.id_polizas;
      this.certificado_tipo_cuota = item.id_tipo_cuotas;
      this.certificado_nombre_tipo_cuota = item.nombre_cuota;
      this.num_certificado = item.num_certificado;
      this.certificado_fecha_inicio = this.formatoFecha(item.fecha_inicio);
      this.certificado_fecha_fin = this.formatoFecha(item.fecha_fin);
      this.certificado_individual = item.individual;
      this.certificado_dia_cobro = item.dia_cobro;
      this.certificado_fecha_primer_pago = this.formatoFecha(
        item.fecha_primer_pago
      );
      this.certificado_periodo = item.periodo;
      this.certificado_prima = item.prima?.toFixed(2);
      this.certificado_cuota = item.cuota?.toFixed(2);
      this.certificado_inclusion = item.inclusion;
      this.certificado_excluido = item.excluido;
      this.certificado_motivo_excluido = item.motivo_excluido;
      this.certificado_pagado = item.pagado;
      this.certificado_fecha_pago = this.formatoFecha(item.fecha_pagado);
      this.certificado_aviso_cobro = item.aviso_cobro;

      this.items_cert_transporte_mercaderia = item.crearTransporteMercaderia;

      if (item.crearPago && item.crearPago.length > 0) {
        item.crearPago.forEach((element) => {
          this.items_certificado_pagos.push(element);
        });
      }

      if (this.id_poliza) {
        this.listarCertPagos(item.id_certificados);
      }

      this.bandera_certificado = 2;
      this.dialog_certificado = true;
    },
    confirmarEliminarCertificado(item) {
      //console.log(item);
      this.id_certificado = item.id_certificados;
      this.dialog_eliminar_cert_transporte_mercaderia = true;
    },
    eliminarCertificado() {
      //console.log(item);
      axios
        .put("api/Certificados/SafeDelete", {
          id_certificados: this.id_certificado,
          es_eliminado: true,
        })
        .then(() => {
          this.dialog_eliminar_cert_transporte_mercaderia = false;
          this.listarCertificados(this.id_poliza);
          this.alerta("success", "Registro actualizado con exito");
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    listarCertificadoVida(id) {
      axios
        .get("api/CertificadoVidas/Listar/" + id)
        .then((response) => {
          if (response.data.length > 0) {
            this.id_certificado_vida = response.data[0].id_certificado_vida;
            this.certificado_vida_nombre = response.data[0].nombre;
            this.certificado_vida_sexo = response.data[0].sexo;
            this.certificado_vida_dui = response.data[0].dui;
            this.certificado_vida_suma_asegurada =
              response.data[0].suma_asegurada;
            this.certificado_vida_fecha_nacimiento = this.formatoFecha(
              response.data[0].fecha_nacimiento
            );
            this.certificado_vida_clase = response.data[0].clase;
            this.certificado_vida_edad = response.data[0].edad;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarCertificadoAuto(id) {
      axios
        .get("api/CertificadoAutos/Listar/" + id)
        .then((response) => {
          //console.log(response.data);
          if (response.data.length == 0) {
            return;
          }
          this.id_certificado_auto = response.data[0].id_certificado_auto;
          this.certificado_autos_tipo_vehiculo = response.data[0].tipo_vehiculo;
          this.certificado_autos_marca = response.data[0].marca;
          this.certificado_autos_modelo = response.data[0].modelo;
          this.certificado_autos_año = response.data[0].año;
          this.certificado_autos_color = response.data[0].color;
          this.certificado_autos_placa = response.data[0].placa;
          this.certificado_autos_num_chasis = response.data[0].num_chasis;
          this.certificado_autos_num_motor = response.data[0].num_motor;
          this.certificado_autos_version = response.data[0].version;
          this.certificado_autos_cesion = response.data[0].cesion;
          this.certificado_autos_vencimiento_tarjeta = this.formatoFecha(
            response.data[0].vencimiento_tarjeta
          );
          this.certificado_autos_vencimiento_dui = this.formatoFecha(
            response.data[0].vencimiento_dui
          );
          this.certificado_autos_propietario = response.data[0].propietario;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarCertificadoGastoMedico(id) {
      axios
        .get("api/CertificadoGastoMedicos/Listar/" + id)
        .then((response) => {
          if (response.data.length > 0) {
            this.id_certificado_gasto_medico =
              response.data[0].id_certificado_gasto_medico;
            this.certificado_gasto_medico_clase = response.data[0].clase;
            this.certificado_gasto_medico_nombre = response.data[0].nombre;
            this.certificado_gasto_medico_edad = response.data[0].edad;
            this.certificado_gasto_medico_fecha_nacimiento = this.formatoFecha(
              response.data[0].fecha_nacimiento
            );
            this.certificado_gasto_medico_sexo = response.data[0].sexo;
            this.certificado_gasto_medico_max_vit_vigente = this.formatoMoneda(
              response.data[0].maximo_vitalicio_vigente
            );
            this.certificado_gasto_medico_max_vit_contratado =
              this.formatoMoneda(response.data[0].maximo_vitalicio_contratado);
            this.certificado_gasto_medico_cobertura =
              response.data[0].cobertura;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarCertificadosTransporteMercaderia(id) {
      axios
        .get("api/CertificadoTransporteMercaderia/Listar/" + id)
        .then((response) => {
          //console.log(response.data);
          this.items_cert_transporte_mercaderia = response.data;
        });
    },
    listarCertificadosIncendio(id) {
      axios.get("api/CertificadoIncendios/Listar/" + id).then((response) => {
        //console.log(response.data);
        this.items_prod_cert_incendio = response.data;
      });
    },
    addCertificadoIncendio() {
      var index = this.items_prod_cert_incendio.findIndex(
        (obj) => obj.id_certificado_incendio == this.prod_cert_id_incendio
      );

      if (index == -1) {
        this.items_prod_cert_incendio.push({
          contador: this.contador_items_prod_cert_incendio,
          id_certificados:
            this.id_certificado == null || this.id_certificado == undefined
              ? 0
              : this.id_certificado,
          n_riesgo: this.prod_cert_incendio_n_riesgo,
          direccion: this.prod_cert_incendio_direccion,
          sum_aseg_contenido: this.prod_cert_incendio_sum_aseg_contenido,
          sum_aseg_construcciones:
            this.prod_cert_incendio_sum_aseg_construcciones,
          sum_aseg_existencias: this.prod_cert_incendio_sum_aseg_existencias,
          intr_neg_lucr_ces_perd_unid:
            this.prod_cert_incendio_intr_neg_lucr_ces_perd_unid,
          cesion: this.prod_cert_incendio_cesion,
          es_eliminado: false,
          total_suma_asegurada: this.prod_cert_incendio_total_sum_aseg,
          monto_cesion: this.prod_cert_incendio_monto_cesion,
          beneficiario_cesion: this.prod_cert_incendio_beneficiario_cesion,
          excluir_construcciones:
            this.prod_cert_incendio_excluir_construcciones,
          excluir_contenido: this.prod_cert_incendio_excluir_contenido,
          excluir_existencias: this.prod_cert_incendio_excluir_existencias,
          motivo_construcciones: this.prod_cert_incendio_motivo_construcciones,
          motivo_contenido: this.prod_cert_incendio_motivo_contenido,
          motivo_existencias: this.prod_cert_incendio_motivo_existencias,
          excluido: this.prod_cert_incendio_excluir_incendio,
        });
      } else {
        this.items_prod_cert_incendio[index].n_riesgo =
          this.prod_cert_incendio_n_riesgo;
        this.items_prod_cert_incendio[index].direccion =
          this.prod_cert_incendio_direccion;
        this.items_prod_cert_incendio[index].sum_aseg_contenido =
          this.prod_cert_incendio_sum_aseg_contenido;
        this.items_prod_cert_incendio[index].sum_aseg_construcciones =
          this.prod_cert_incendio_sum_aseg_construcciones;
        this.items_prod_cert_incendio[index].sum_aseg_existencias =
          this.prod_cert_incendio_sum_aseg_existencias;
        this.items_prod_cert_incendio[index].intr_neg_lucr_ces_perd_unid =
          this.prod_cert_incendio_intr_neg_lucr_ces_perd_unid;
        this.items_prod_cert_incendio[index].cesion =
          this.prod_cert_incendio_cesion;
        this.items_prod_cert_incendio[index].beneficiario_cesion =
          this.prod_cert_incendio_beneficiario_cesion;
        this.items_prod_cert_incendio[index].total_suma_asegurada =
          this.prod_cert_incendio_total_sum_aseg;
        this.items_prod_cert_incendio[index].excluir_construcciones =
          this.prod_cert_incendio_excluir_construcciones;
        this.items_prod_cert_incendio[index].excluir_contenido =
          this.prod_cert_incendio_excluir_contenido;
        this.items_prod_cert_incendio[index].excluir_existencias =
          this.prod_cert_incendio_excluir_existencias;
        this.items_prod_cert_incendio[index].motivo_construcciones =
          this.prod_cert_incendio_motivo_construcciones;
        this.items_prod_cert_incendio[index].motivo_contenido =
          this.prod_cert_incendio_motivo_contenido;
        this.items_prod_cert_incendio[index].motivo_existencias =
          this.prod_cert_incendio_motivo_existencias;
        this.items_prod_cert_incendio[index].excluido =
          this.prod_cert_incendio_excluir_incendio;
      }
      this.contador_items_prod_cert_incendio++;
      this.limpiarCertificadoIncendio();
    },
    calculoTotalSumAseguradaCertIncendio() {
      this.prod_cert_incendio_total_sum_aseg =
        Number(this.prod_cert_incendio_sum_aseg_construcciones) +
        Number(this.prod_cert_incendio_sum_aseg_contenido) +
        Number(this.prod_cert_incendio_sum_aseg_existencias);
    },
    crearCertificado() {
      var crearVida = [];
      var crearAuto = [];
      var crearGastoMedico = [];

      switch (this.ramo_seguro.descripcion) {
        case "VIDA":
        case "ACCIDENTES PERSONALES":
          crearVida.push({
            clase: this.certificado_vida_clase,
            edad: this.certificado_vida_edad,
            nombre: this.certificado_vida_nombre,
            sexo: this.certificado_vida_sexo,
            dui: this.certificado_vida_dui,
            fecha_nacimiento: this.certificado_vida_fecha_nacimiento,
            suma_asegurada: this.certificado_vida_suma_asegurada,
            items_beneficiarios: this.items_colectivo_vida_beneficiarios,
          });
          break;
        case "AUTOS":
          crearAuto.push({
            tipo_vehiculo: this.certificado_autos_tipo_vehiculo,
            marca: this.certificado_autos_marca,
            modelo: this.certificado_autos_modelo,
            año: this.certificado_autos_año,
            color: this.certificado_autos_color,
            placa: this.certificado_autos_placa,
            num_motor: this.certificado_autos_num_motor,
            num_chasis: this.certificado_autos_num_chasis,
            version: this.certificado_autos_version,
            cesion: this.certificado_autos_cesion,
            vencimiento_tarjeta: this.certificado_autos_vencimiento_tarjeta,
            vencimiento_dui: this.certificado_autos_vencimiento_dui,
            propietario: this.certificado_autos_propietario,
          });
          break;
        case "GASTOS MEDICOS":
          crearGastoMedico.push({
            clase: this.certificado_gasto_medico_clase,
            nombre: this.certificado_gasto_medico_nombre,
            edad: this.certificado_gasto_medico_edad,
            fecha_nacimiento: this.certificado_gasto_medico_fecha_nacimiento,
            sexo: this.certificado_gasto_medico_sexo,
            maximo_vitalicio_contratado:
              this.certificado_gasto_medico_max_vit_contratado,
            maximo_vitalicio_vigente:
              this.certificado_gasto_medico_max_vit_vigente,
            cobertura: this.certificado_gasto_medico_cobertura,
          });
          break;
      }

      axios
        .post("api/Certificados/Crear", {
          id_polizas: this.id_poliza,
          id_tipo_cuotas: this.certificado_tipo_cuota.id_tipo_cuota,
          num_certificado: this.num_certificado,
          fecha_inicio: this.certificado_fecha_inicio,
          fecha_fin: this.certificado_fecha_fin,
          individual: this.certificado_individual,
          dia_cobro: parseFloat(this.certificado_dia_cobro),
          fecha_primer_pago: this.certificado_fecha_primer_pago,
          periodo: this.certificado_periodo,
          prima: parseFloat(this.certificado_prima),
          cuota: parseFloat(this.certificado_cuota),
          inclusion: this.certificado_inclusion,
          excluido: this.certificado_excluido,
          motivo_excluido: this.certificado_motivo_excluido,
          pagado: this.certificado_pagado,
          fecha_pagado: this.certificado_fecha_pago,
          aviso_cobro: this.certificado_aviso_cobro,
          crearVida: crearVida,
          crearAuto: crearAuto,
          crearPago: this.items_certificado_pagos,
          crearGastoMedico: crearGastoMedico,
          transporteMercaderia: this.items_cert_transporte_mercaderia,
          incendio: this.items_prod_cert_incendio,
        })
        .then((response) => {
          if (this.items_archivos_certificado_archivo.length > 0) {
            this.crearArchivoCertificadoArchivo(response.data);
          }
          this.listarCertificados(this.id_poliza);
          this.cerrarDialogCertificado();
          this.alerta("success", "Registro creado con exito");
        })
        .catch((error) => {
          if (error.response.data == -1) {
            this.alerta("info", "El número de certificado ya existe");
          } else {
            this.alerta("error", "Sucedio algún error");
          }
        });
    },
    crearCertificadoNuevo(id) {
      if (this.items_certificados.length == 0) {
        return;
      }
      this.items_certificados.forEach((element) => {
        axios
          .post("api/Certificados/Crear", {
            id_polizas: id,
            id_tipo_cuotas: element.id_tipo_cuotas,
            num_certificado: element.num_certificado,
            fecha_inicio: element.fecha_inicio,
            fecha_fin: element.fecha_fin,
            individual: element.individual,
            dia_cobro: parseFloat(element.dia_cobro),
            fecha_primer_pago: element.fecha_primer_pago,
            periodo: element.periodo,
            prima: parseFloat(element.prima),
            cuota: parseFloat(element.cuota),
            pagado: element.pagado,
            fecha_pagado: element.fecha_pagado,
            inclusion: element.inclusion,
            crearPago: element.crearPago,
            crearVida: element.crearVida,
            crearAuto: element.crearAuto,
            crearGastoMedico: element.crearGastoMedico,
            transporteMercaderia: element.crearTransporteMercaderia,
          })
          .then((response) => {
            if (this.items_archivos_certificado_archivo.length > 0) {
              this.crearArchivoCertificadoArchivo(response.data);
            }

            this.cerrarDialogCertificado();
            this.cerrarDialog();
            this.listar();
            this.alerta("success", "Registro creado con exito");
          })
          .catch(() => {
            this.alerta("error", "Sucedio algún error");
          });
      });
    },
    actualizarCertificado() {
      switch (this.ramo_seguro.descripcion) {
        case "VIDA":
        case "ACCIDENTES PERSONALES":
          this.actualizarCertificadoVida();
          break;
        case "AUTOS":
          this.actualizarCertificadoAuto();
          break;
        case "GASTOS MEDICOS":
          this.actualizarCertificadoGastoMedico();
          break;
        case "INCENDIO":
          this.actualizarCertificadoIncendio();
          break;
        case "TRANSPORTE MERCADERIA":
          this.actualizarCertificadoTransporteMercaderia();
          break;
      }

      axios
        .put("api/Certificados/Actualizar", {
          id_certificados: this.id_certificado,
          id_polizas: this.id_poliza,
          id_tipo_cuotas:
            this.certificado_tipo_cuota.id_tipo_cuota == undefined
              ? this.certificado_tipo_cuota
              : this.certificado_tipo_cuota.id_tipo_cuota,
          num_certificado: this.num_certificado,
          fecha_inicio: this.certificado_fecha_inicio,
          fecha_fin: this.certificado_fecha_fin,
          individual: this.certificado_individual,
          dia_cobro: parseFloat(this.certificado_dia_cobro),
          fecha_primer_pago: this.certificado_fecha_primer_pago,
          periodo: this.certificado_periodo,
          prima: parseFloat(this.certificado_prima),
          cuota: parseFloat(this.certificado_cuota),
          inclusion: this.certificado_inclusion,
          excluido: this.certificado_excluido,
          motivo_excluido: this.certificado_motivo_excluido,
          pagado: this.certificado_pagado,
          fecha_pagado: this.certificado_fecha_pago,
          aviso_cobro: this.certificado_aviso_cobro,
        })
        .then(() => {
          this.listarCertificados(this.id_poliza);
          this.alerta("success", "Registro actualizado con exito");

          if (
            this.bandera_cert_pago_sin_cuotas == 0 &&
            this.items_certificado_pagos.length > 0
          ) {
            this.crearCuotasCertificados();
          }
          this.cerrarDialogCertificado();
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    actualizarCertificadoTransporteMercaderia() {
      this.items_cert_transporte_mercaderia.forEach((element) => {
        axios
          .put("api/CertificadoTransporteMercaderia/Actualizar", element)
          .then(() => {
            this.limpiarCertificadoTransporteMercaderia();
            this.limpiarCertificado();
            this.listarCertificadosTransporteMercaderia(this.id_certificado);
            this.alerta("success", "Registro actualizado");
          })
          .catch(() => {
            this.alerta("error", "No se pudo actualizar");
          });
      });
    },
    editarCertificadoIncendio(item) {
      //console.log(item);
      this.prod_cert_id_incendio = item.id_certificado_incendio;
      this.prod_cert_incendio_id_certificado = item.id_certificados;
      this.prod_cert_incendio_n_riesgo = item.n_riesgo;
      this.prod_cert_incendio_direccion = item.direccion;
      this.prod_cert_incendio_sum_aseg_construcciones =
        item.sum_aseg_construcciones;
      this.prod_cert_incendio_sum_aseg_contenido = item.sum_aseg_contenido;
      this.prod_cert_incendio_sum_aseg_existencias = item.sum_aseg_existencias;
      this.prod_cert_incendio_total_sum_aseg = item.total_suma_asegurada;
      this.prod_cert_incendio_excluir_incendio = item.excluido;
      this.prod_cert_incendio_excluir_construcciones =
        item.excluir_construcciones;
      this.prod_cert_incendio_excluir_contenido = item.excluir_contenido;
      this.prod_cert_incendio_excluir_existencias = item.excluir_existencias;
      this.prod_cert_incendio_motivo_construcciones =
        item.motivo_construcciones;
      this.prod_cert_incendio_motivo_contenido = item.motivo_contenido;
      this.prod_cert_incendio_motivo_existencias = item.motivo_existencias;
      this.prod_cert_incendio_intr_neg_lucr_ces_perd_unid =
        item.intr_neg_lucr_ces_perd_unid;
      this.prod_cert_incendio_cesion = item.cesion;
      this.prod_cert_incendio_beneficiario_cesion = item.beneficiario_cesion;
      this.prod_cert_incendio_monto_cesion = item.monto_cesion;
    },
    actualizarCertificadoIncendio() {
      this.items_prod_cert_incendio.forEach((element) => {
        axios.put("api/CertificadoIncendios/Actualizar", element);
      });
      this.contador_items_prod_cert_incendio = 0;
    },
    confirmarEliminarCertificadoIncendio(item) {
      this.prod_cert_id_incendio = item.id_certificado_incendio;
      this.dialog_eliminar_prod_cert_incendio = true;
    },
    eliminarProdCertIncendio() {
      axios
        .put("api/CertificadoIncendios/SafeDelete", {
          id_certificado_incendio: this.prod_cert_id_incendio,
          es_eliminado: true,
        })
        .then(() => {
          this.dialog_eliminar_prod_cert_incendio = false;
          this.listarCertificadosIncendio(this.id_certificado);
        });
    },
    limpiarCertificadoIncendio() {
      this.prod_cert_id_incendio = null;
      this.prod_cert_incendio_n_riesgo = "";
      this.prod_cert_incendio_direccion = "";
      this.prod_cert_incendio_sum_aseg_contenido = "";
      this.prod_cert_incendio_sum_aseg_construcciones = "";
      this.prod_cert_incendio_sum_aseg_existencias = "";
      this.prod_cert_incendio_intr_neg_lucr_ces_perd_unid = "";
      this.prod_cert_incendio_cesion = "";
      this.prod_cert_incendio_total_sum_aseg = "";
      this.prod_cert_incendio_monto_cesion = "";
      this.prod_cert_incendio_beneficiario_cesion = "";
      this.prod_cert_incendio_excluir_construcciones = false;
      this.prod_cert_incendio_excluir_contenido = false;
      this.prod_cert_incendio_excluir_existencias = false;
      this.prod_cert_incendio_motivo_construcciones = "";
      this.prod_cert_incendio_motivo_contenido = "";
      this.prod_cert_incendio_motivo_existencias = "";
      this.prod_cert_incendio_excluir_incendio = false;
    },
    crearCuotasCertificados() {
      this.items_certificado_pagos.forEach((element) => {
        axios
          .post("api/CertificadoPagos/Crear", {
            id_certificados: element.id_certificados,
            id_tipo_documentos: element.id_tipo_documentos,
            fecha: element.fecha,
            monto: element.monto,
            esta_pagada: element.esta_pagada,
            fecha_pago: element.fecha_pago,
            factura: element.factura,
          })
          .then(() => {
            this.alerta("success", "Cuotas creadas");
          })
          .catch(() => {
            this.alerta("error", "No se pudieron crear las cuotas");
          });
      });
    },
    eliminarCuotasCertificados() {
      this.items_certificado_pagos.forEach((element) => {
        axios
          .delete("api/Certificadopagos/Eliminar/" + element.id_cert_pago)
          .then(() => {
            this.alerta("success", "Cuotas eliminadas");
            this.listarCertPagos(this.id_certificado);
            this.dialog_eliminar_cuotas_certificados = false;
          })
          .catch(() => {
            this.alerta("error", "No se pueden eliminar las cuotas");
            this.dialog_eliminar_cuotas_certificados = false;
          });
      });
    },
    actualizarCertificadoVida() {
      if (this.id_certificado_vida == null) {
        this.crearCertificadoVida();
        return;
      }

      axios
        .put("api/CertificadoVidas/Actualizar", {
          id_certificado_vida: this.id_certificado_vida,
          id_certificados: this.id_certificado,
          nombre: this.certificado_vida_nombre,
          sexo: this.certificado_vida_sexo,
          dui: this.certificado_vida_dui,
          fecha_nacimiento: this.certificado_vida_fecha_nacimiento,
          clase: this.certificado_vida_clase,
          edad: this.certificado_vida_edad,
          suma_asegurada: this.certificado_vida_suma_asegurada,
        })
        .then(() => {
          this.listarCertificados(this.id_poliza);
        })
        .catch((error) => {
          console.log("certificado vida", error);
        });
    },
    actualizarCertificadoAuto() {
      if (this.id_certificado_auto == null) {
        this.crearCertificadoAuto();
        return;
      }

      axios
        .put("api/CertificadoAutos/Actualizar", {
          id_certificado_auto: this.id_certificado_auto,
          id_certificados: this.id_certificado,
          tipo_vehiculo: this.certificado_autos_tipo_vehiculo,
          marca: this.certificado_autos_marca,
          modelo: this.certificado_autos_modelo,
          año: this.certificado_autos_año,
          placa: this.certificado_autos_placa,
          num_chasis: this.certificado_autos_num_chasis,
          num_motor: this.certificado_autos_num_motor,
          version: this.certificado_autos_version,
          cesion: this.certificado_autos_cesion,
          vencimiento_tarjeta: this.certificado_autos_vencimiento_tarjeta,
          vencimiento_dui: this.certificado_autos_vencimiento_dui,
          propietario: this.certificado_autos_propietario,
        })
        .then(() => {
          this.listarCertificados(this.id_poliza);
        })
        .catch((error) => {
          console.log("certificado auto", error);
        });
    },
    crearCertificadoGastoMedico() {
      axios
        .post("api/CertificadoGastoMedicos/Crear", {
          id_certificados: this.id_certificado,
          nombre: this.certificado_gasto_medico_nombre,
          edad: this.certificado_gasto_medico_edad,
          sexo: this.certificado_gasto_medico_sexo,
          clase: this.certificado_gasto_medico_clase,
          fecha_nacimiento: this.certificado_gasto_medico_fecha_nacimiento,
          maximo_vitalicio_contratado:
            this.certificado_gasto_medico_max_vit_contratado,
          maximo_vitalicio_vigente:
            this.certificado_gasto_medico_max_vit_vigente,
          cobertura: this.certificado_gasto_medico_cobertura,
        })
        .then(() => {
          this.listarCertificados(this.id_poliza);
        })
        .catch(() => {
          this.alerta("error", "No se pudo crear el registro");
        });
    },
    actualizarCertificadoGastoMedico() {
      if (this.id_certificado_gasto_medico == 0) {
        this.crearCertificadoGastoMedico();
        return;
      }
      axios
        .put("api/CertificadoGastoMedicos/Actualizar", {
          id_certificado_gasto_medico: this.id_certificado_gasto_medico,
          id_certificados: this.id_certificado,
          nombre: this.certificado_gasto_medico_nombre,
          edad: this.certificado_gasto_medico_edad,
          sexo: this.certificado_gasto_medico_sexo,
          clase: this.certificado_gasto_medico_clase,
          fecha_nacimiento: this.certificado_gasto_medico_fecha_nacimiento,
          maximo_vitalicio_contratado:
            this.certificado_gasto_medico_max_vit_contratado,
          maximo_vitalicio_vigente:
            this.certificado_gasto_medico_max_vit_vigente,
          cobertura: this.certificado_gasto_medico_cobertura,
        })
        .then(() => {
          this.listarCertificados(this.id_poliza);
        })
        .catch(() => {
          console.log("certificado gasto medico", error);
        });
    },
    crearCertificadoVida() {
      axios
        .post("api/CertificadoVidas/Crear", {
          id_certificados: this.id_certificado,
          nombre: this.certificado_vida_nombre,
          sexo: this.certificado_vida_sexo,
          dui: this.certificado_vida_dui,
          fecha_nacimiento: this.certificado_vida_fecha_nacimiento,
          clase: this.certificado_vida_clase,
          edad: this.certificado_vida_edad,
          suma_asegurada: this.certificado_vida_suma_asegurada,
        })
        .then(() => {
          this.listarCertificados(this.id_poliza);
        })
        .catch(() => {
          this.alerta("error", "No se pudo crear el certificado");
        });
    },
    crearCertificadoAuto() {
      axios
        .post("api/CertificadoAutos/Crear", {
          id_certificados: this.id_certificado,
          tipo_vehiculo: this.certificado_autos_tipo_vehiculo,
          marca: this.certificado_autos_marca,
          modelo: this.certificado_autos_modelo,
          año: this.certificado_autos_año,
          color: this.certificado_autos_color,
          placa: this.certificado_autos_placa,
          num_chasis: this.certificado_autos_num_chasis,
          num_motor: this.certificado_autos_num_motor,
          version: this.certificado_autos_version,
          cesion: this.certificado_autos_cesion,
          vencimiento_tarjeta: this.certificado_autos_vencimiento_tarjeta,
          vencimiento_dui: this.certificado_autos_vencimiento_dui,
          propietario: this.certificado_autos_propietario,
        })
        .then(() => {
          this.listarCertificados(this.id_poliza);
        })
        .catch(() => {
          this.alerta("error", "No se pudo crear el certificado");
        });
    },
    limpiarCertificadoVida() {
      this.id_certificado_vida = null;
      this.certificado_vida_nombre = null;
      this.certificado_vida_sexo = null;
      this.certificado_vida_dui = null;
      this.certificado_vida_fecha_nacimiento = null;
      this.certificado_vida_clase = null;
      this.certificado_vida_edad = null;
      this.certificado_vida_suma_asegurada = null;
    },
    limpiarCertificadoAuto() {
      this.id_certificado_auto = null;
      this.certificado_autos_tipo_vehiculo = null;
      this.certificado_autos_marca = null;
      this.certificado_autos_modelo = null;
      this.certificado_autos_año = null;
      this.certificado_autos_color = null;
      this.certificado_autos_placa = null;
      this.certificado_autos_num_chasis = null;
      this.certificado_autos_num_motor = null;
      this.certificado_autos_version = null;
      this.certificado_autos_cesion = null;
      this.certificado_autos_vencimiento_tarjeta = null;
      this.certificado_autos_vencimiento_dui = null;
      this.certificado_autos_propietario = null;
    },
    limpiarCertificadoGastoMedico() {
      this.id_certificado_gasto_medico = 0;
      this.certificado_gasto_medico_clase = "";
      this.certificado_gasto_medico_nombre = "";
      this.certificado_gasto_medico_edad = 0;
      this.certificado_gasto_medico_fecha_nacimiento = "";
      this.certificado_gasto_medico_sexo = "";
      this.certificado_gasto_medico_max_vit_vigente = "";
      this.certificado_gasto_medico_max_vit_contratado = "";
      this.certificado_gasto_medico_cobertura = "";
    },
    limpiarCertificadoTransporteMercaderia() {
      this.cert_id_transporte_mercaderia = 0;
      this.cert_transporte_mercaderia_num_trans_merca = "";
      this.cert_transporte_mercaderia_asegurado = "";
      this.cert_transporte_mercaderia_tipo_mercaderia = "";
      this.cert_transporte_mercaderia_proveedor_cliente = "";
      this.cert_transporte_mercaderia_maritimo = false;
      this.cert_transporte_mercaderia_aereo = false;
      this.cert_transporte_mercaderia_terrestre = false;
      this.cert_transporte_mercaderia_nombre_vapor = "";
      this.cert_transporte_mercaderia_procedente_de = "";
      this.cert_transporte_mercaderia_destino_a = "";
      this.cert_transporte_mercaderia_num_factura = "";
      this.cert_transporte_mercaderia_fecha_factura = "";
      this.cert_transporte_mercaderia_fecha_despacho = "";
      this.cert_transporte_mercaderia_vigencia_desde = "";
      this.cert_transporte_mercaderia_vigencia_hasta = "";
      this.cert_transporte_mercaderia_valor_factura = "";
      this.cert_transporte_mercaderia_sub_total = "";
      this.cert_transporte_mercaderia_flete = "";
      this.cert_transporte_mercaderia_porcentaje_adicional = "";
      this.cert_transporte_mercaderia_total_suma_asegurada = "";
      this.contador_certificado_transporte_mercaderia = 0;
    },
    addCertificadoTransporteMercaderia() {
      if (
        this.cert_id_transporte_mercaderia != undefined &&
        this.cert_id_transporte_mercaderia != 0
      ) {
        var index = this.items_cert_transporte_mercaderia.findIndex(
          (obj) =>
            obj.id_certificado_transporte_mercaderia ==
            this.cert_id_transporte_mercaderia
        );

        this.items_cert_transporte_mercaderia[index].num_trans_merca =
          this.cert_transporte_mercaderia_num_trans_merca;
        this.items_cert_transporte_mercaderia[index].asegurado =
          this.cert_transporte_mercaderia_asegurado;
        this.items_cert_transporte_mercaderia[index].tipo_mercaderia =
          this.cert_transporte_mercaderia_tipo_mercaderia;
        this.items_cert_transporte_mercaderia[index].proveedor_cliente =
          this.cert_transporte_mercaderia_proveedor_cliente;
        this.items_cert_transporte_mercaderia[index].marititmo =
          this.cert_transporte_mercaderia_maritimo;
        this.items_cert_transporte_mercaderia[index].aereo =
          this.cert_transporte_mercaderia_aereo;
        this.items_cert_transporte_mercaderia[index].terrestre =
          this.cert_transporte_mercaderia_terrestre;
        this.items_cert_transporte_mercaderia[index].nombre_vapor =
          this.cert_transporte_mercaderia_nombre_vapor;
        this.items_cert_transporte_mercaderia[index].procedente_de =
          this.cert_transporte_mercaderia_procedente_de;
        this.items_cert_transporte_mercaderia[index].destino_a =
          this.cert_transporte_mercaderia_destino_a;
        this.items_cert_transporte_mercaderia[index].num_factura =
          this.cert_transporte_mercaderia_num_factura;
        this.items_cert_transporte_mercaderia[index].fecha_factura =
          this.cert_transporte_mercaderia_fecha_factura;
        this.items_cert_transporte_mercaderia[
          index
        ].fecha_despacho_merca_proveedor =
          this.cert_transporte_mercaderia_fecha_despacho;
        this.items_cert_transporte_mercaderia[
          index
        ].vigencia_certificado_desde =
          this.cert_transporte_mercaderia_vigencia_desde;
        this.items_cert_transporte_mercaderia[
          index
        ].vigencia_certificado_hasta =
          this.cert_transporte_mercaderia_vigencia_hasta;
        this.items_cert_transporte_mercaderia[index].valor_factura =
          this.cert_transporte_mercaderia_valor_factura;
        this.items_cert_transporte_mercaderia[index].sub_total =
          this.cert_transporte_mercaderia_sub_total;
        this.items_cert_transporte_mercaderia[index].flete =
          this.cert_transporte_mercaderia_flete;
        this.items_cert_transporte_mercaderia[index].porcentaje_adicional =
          this.cert_transporte_mercaderia_porcentaje_adicional;
        this.items_cert_transporte_mercaderia[index].total_suma_asegurada =
          this.cert_transporte_mercaderia_total_suma_asegurada;

        this.limpiarCertificadoTransporteMercaderia();
        return;
      }
      if (
        this.contador_certificado_transporte_mercaderia >= 0 &&
        this.cert_id_transporte_mercaderia == undefined
      ) {
        var index = this.items_cert_transporte_mercaderia.findIndex(
          (obj) => obj.cont == this.contador_certificado_transporte_mercaderia
        );

        this.items_cert_transporte_mercaderia[index].num_trans_merca =
          this.cert_transporte_mercaderia_num_trans_merca;
        this.items_cert_transporte_mercaderia[index].asegurado =
          this.cert_transporte_mercaderia_asegurado;
        this.items_cert_transporte_mercaderia[index].tipo_mercaderia =
          this.cert_transporte_mercaderia_tipo_mercaderia;
        this.items_cert_transporte_mercaderia[index].proveedor_cliente =
          this.cert_transporte_mercaderia_proveedor_cliente;
        this.items_cert_transporte_mercaderia[index].marititmo =
          this.cert_transporte_mercaderia_maritimo;
        this.items_cert_transporte_mercaderia[index].aereo =
          this.cert_transporte_mercaderia_aereo;
        this.items_cert_transporte_mercaderia[index].terrestre =
          this.cert_transporte_mercaderia_terrestre;
        this.items_cert_transporte_mercaderia[index].nombre_vapor =
          this.cert_transporte_mercaderia_nombre_vapor;
        this.items_cert_transporte_mercaderia[index].procedente_de =
          this.cert_transporte_mercaderia_procedente_de;
        this.items_cert_transporte_mercaderia[index].destino_a =
          this.cert_transporte_mercaderia_destino_a;
        this.items_cert_transporte_mercaderia[index].num_factura =
          this.cert_transporte_mercaderia_num_factura;
        this.items_cert_transporte_mercaderia[index].fecha_factura =
          this.cert_transporte_mercaderia_fecha_factura;
        this.items_cert_transporte_mercaderia[
          index
        ].fecha_despacho_merca_proveedor =
          this.cert_transporte_mercaderia_fecha_despacho;
        this.items_cert_transporte_mercaderia[
          index
        ].vigencia_certificado_desde =
          this.cert_transporte_mercaderia_vigencia_desde;
        this.items_cert_transporte_mercaderia[
          index
        ].vigencia_certificado_hasta =
          this.cert_transporte_mercaderia_vigencia_hasta;
        this.items_cert_transporte_mercaderia[index].valor_factura =
          this.cert_transporte_mercaderia_valor_factura;
        this.items_cert_transporte_mercaderia[index].sub_total =
          this.cert_transporte_mercaderia_sub_total;
        this.items_cert_transporte_mercaderia[index].flete =
          this.cert_transporte_mercaderia_flete;
        this.items_cert_transporte_mercaderia[index].porcentaje_adicional =
          this.cert_transporte_mercaderia_porcentaje_adicional;
        this.items_cert_transporte_mercaderia[index].total_suma_asegurada =
          this.cert_transporte_mercaderia_total_suma_asegurada;
        this.flag_editar_cert_transporte_mercaderia = false;

        this.limpiarCertificadoTransporteMercaderia();
        return;
      }

      this.items_cert_transporte_mercaderia.push({
        cont: this.contador_certificado_transporte_mercaderia++,
        id_certificados: this.id_certificado == null ? 0 : this.id_certificado,
        num_trans_merca: this.cert_transporte_mercaderia_num_trans_merca,
        asegurado: this.cert_transporte_mercaderia_asegurado,
        valor_factura: this.cert_transporte_mercaderia_valor_factura,
        flete: this.cert_transporte_mercaderia_flete,
        sub_total: this.cert_transporte_mercaderia_sub_total,
        porcentaje_adicional:
          this.cert_transporte_mercaderia_porcentaje_adicional,
        total_suma_asegurada:
          this.cert_transporte_mercaderia_total_suma_asegurada,
        tipo_mercaderia: this.cert_transporte_mercaderia_tipo_mercaderia,
        proveedor_cliente: this.cert_transporte_mercaderia_proveedor_cliente,
        marititmo: this.cert_transporte_mercaderia_maritimo,
        aereo: this.cert_transporte_mercaderia_aereo,
        terrestre: this.cert_transporte_mercaderia_terrestre,
        nombre_vapor: this.cert_transporte_mercaderia_nombre_vapor,
        procedente_de: this.cert_transporte_mercaderia_procedente_de,
        destino_a: this.cert_transporte_mercaderia_destino_a,
        num_factura: this.cert_transporte_mercaderia_num_factura,
        fecha_factura: this.cert_transporte_mercaderia_fecha_factura,
        fecha_despacho_merca_proveedor:
          this.cert_transporte_mercaderia_fecha_despacho,
        vigencia_certificado_desde:
          this.cert_transporte_mercaderia_vigencia_desde,
        vigencia_certificado_hasta:
          this.cert_transporte_mercaderia_vigencia_hasta,
      });
      this.limpiarCertificadoTransporteMercaderia();
    },
    addCertificado() {
      var crearVida = [];
      var crearAuto = [];
      var crearGastoMedico = [];

      switch (this.ramo_seguro.descripcion) {
        case "VIDA":
        case "ACCIDENTES PERSONALES":
          crearVida.push({
            nombre: this.certificado_vida_nombre,
            sexo: this.certificado_vida_sexo,
            dui: this.certificado_vida_dui,
            fecha_nacimiento: this.certificado_vida_fecha_nacimiento,
            clase: this.certificado_vida_clase,
            edad: this.certificado_vida_edad,
            suma_asegurada: this.certificado_vida_suma_asegurada,
          });
          break;
        case "AUTOS":
          crearAuto.push({
            tipo_vehiculo: this.certificado_autos_tipo_vehiculo,
            marca: this.certificado_autos_marca,
            modelo: this.certificado_autos_modelo,
            año: this.certificado_autos_año,
            color: this.certificado_autos_color,
            placa: this.certificado_autos_placa,
            chasis: this.certificado_autos_num_chasis,
            motor: this.certificado_autos_num_motor,
            version: this.certificado_autos_version,
            cesion: this.certificado_autos_cesion,
            vencimiento_tarjeta: this.certificado_autos_vencimiento_tarjeta,
            vencimiento_dui: this.certificado_autos_vencimiento_dui,
            propietario: this.certificado_autos_propietario,
          });
          break;
        case "GASTOS MEDICOS":
          crearGastoMedico.push({
            clase: this.certificado_gasto_medico_clase,
            nombre: this.certificado_gasto_medico_nombre,
            edad: this.certificado_gasto_medico_edad,
            fecha_nacimiento: this.certificado_gasto_medico_fecha_nacimiento,
            sexo: this.certificado_gasto_medico_sexo,
            maximo_vitalicio_contratado:
              this.certificado_gasto_medico_max_vit_contratado,
            maximo_vitalicio_vigente:
              this.certificado_gasto_medico_max_vit_vigente,
            cobertura: this.certificado_gasto_medico_cobertura,
          });
          break;
      }

      if (
        !this.certificado_vida_nombre &&
        !this.certificado_vida_sexo &&
        !this.certificado_vida_dui &&
        !this.certificado_vida_fecha_nacimiento
      ) {
        crearVida = [];
      }
      if (
        !this.certificado_autos_tipo_vehiculo &&
        !this.certificado_autos_marca &&
        !this.certificado_autos_modelo &&
        !this.certificado_autos_año &&
        !this.certificado_autos_color &&
        !this.certificado_autos_placa
      ) {
        crearAuto = [];
      }

      this.contador_certificado++;
      this.items_certificados.push({
        id: this.contador_certificado,
        id_tipo_cuotas: this.certificado_tipo_cuota.id_tipo_cuota,
        num_certificado: this.num_certificado,
        fecha_inicio: this.certificado_fecha_inicio,
        fecha_fin: this.certificado_fecha_fin,
        individual: this.certificado_individual,
        dia_cobro: parseFloat(this.certificado_dia_cobro),
        fecha_primer_pago: this.certificado_fecha_primer_pago,
        periodo: this.certificado_periodo,
        prima: parseFloat(this.certificado_prima),
        cuota: parseFloat(this.certificado_cuota),
        inclusion: this.certificado_inclusion,
        excluido: this.certificado_excluido,
        motivo_excluido: this.certificado_motivo_excluido,
        pagado: this.certificado_pagado,
        fecha_pagado: this.certificado_pagado,
        aviso_cobro: this.certificado_aviso_cobro,
        crearVida: crearVida,
        crearAuto: crearAuto,
        crearPago: this.items_certificado_pagos,
        crearGastoMedico: crearGastoMedico,
        crearTransporteMercaderia: this.items_cert_transporte_mercaderia,
      });

      this.dialog_certificado = false;
      this.limpiarCertificado();
      this.limpiarCertificadoVida();
      this.limpiarCertificadoAuto();
      this.limpiarCertificadoGastoMedico();
      this.limpiarCertificadoTransporteMercaderia();
    },
    updateCertificado() {
      for (var i = 0; i < this.items_certificados.length; i++) {
        if (
          this.items_certificados[i].num_certificado == this.num_certificado
        ) {
          this.items_certificados[i].id_tipo_cuotas =
            this.certificado_tipo_cuota;
          this.items_certificados[i].num_certificado = this.num_certificado;
          this.items_certificados[i].fecha_inicio =
            this.certificado_fecha_inicio;
          this.items_certificados[i].fecha_fin = this.certificado_fecha_fin;
          this.items_certificados[i].individual = this.certificado_individual;
          this.items_certificados[i].dia_cobro = parseFloat(
            this.certificado_dia_cobro
          );
          this.items_certificados[i].fecha_primer_pago =
            this.certificado_fecha_primer_pago;
          this.items_certificados[i].periodo = this.certificado_periodo;
          this.items_certificados[i].prima = parseFloat(this.certificado_prima);
          this.items_certificados[i].cuota = parseFloat(this.certificado_cuota);
          this.items_certificados[i].inclusion = this.certificado_inclusion;
          this.items_certificados[i].excluido = this.certificado_excluido;
          this.items_certificados[i].motivo_excluido =
            this.certificado_motivo_excluido;

          var tamañoPagos = this.items_certificados[i].crearPago.length;
          if (tamañoPagos > 0) {
            this.items_certificados[i].crearPago.forEach((element) => {
              this.items_certificado_pagos.push(element);
            });
          }

          switch (this.ramo_seguro.descripcion) {
            case "VIDA":
            case "ACCIDENTES PERSONALES":
              if (this.items_certificados[i].crearVida.length > 0) {
                this.items_certificados[i].crearVida[0].nombre =
                  this.certificado_vida_nombre;
                this.items_certificados[i].crearVida[0].sexo =
                  this.certificado_vida_sexo;
                this.items_certificados[i].crearVida[0].dui =
                  this.certificado_vida_dui;
                this.items_certificados[i].crearVida[0].fecha_nacimiento =
                  this.certificado_vida_fecha_nacimiento;
              } else {
                this.items_certificados[i].crearVida.push({
                  nombre: this.certificado_vida_nombre,
                  sexo: this.certificado_vida_sexo,
                  dui: this.certificado_vida_dui,
                  fecha_nacimiento: this.certificado_vida_fecha_nacimiento,
                  suma_asegurada: this.certificado_vida_suma_asegurada,
                });
              }
              break;
            case "AUTOS":
              if (this.items_certificados[i].crearAuto.length > 0) {
                this.items_certificados[i].crearAuto[0].tipo_vehiculo =
                  this.certificado_autos_tipo_vehiculo;
                this.items_certificados[i].crearAuto[0].marca =
                  this.certificado_autos_marca;
                this.items_certificados[i].crearAuto[0].modelo =
                  this.certificado_autos_modelo;
                this.items_certificados[i].crearAuto[0].año =
                  this.certificado_autos_año;
                this.items_certificados[i].crearAuto[0].color =
                  this.certificado_autos_color;
                this.items_certificados[i].crearAuto[0].placa =
                  this.certificado_autos_placa;
                this.items_certificados[i].crearAuto[0].chasis =
                  this.certificado_autos_num_chasis;
                this.items_certificados[i].crearAuto[0].motor =
                  this.certificado_autos_num_motor;
                this.items_certificados[i].crearAuto[0].version =
                  this.certificado_autos_version;
                this.items_certificados[i].crearAuto[0].cesion =
                  this.certificado_autos_vencimiento_tarjeta;
                this.items_certificados[i].crearAuto[0].chasis =
                  this.certificado_autos_vencimiento_dui;
                this.items_certificados[i].crearAuto[0].chasis =
                  this.certificado_autos_propietario;
              } else {
                this.items_certificados[i].crearAuto.push({
                  tipo_vehiculo: this.certificado_autos_tipo_vehiculo,
                  marca: this.certificado_autos_marca,
                  modelo: this.certificado_autos_modelo,
                  año: this.certificado_autos_año,
                  color: this.certificado_autos_color,
                  placa: this.certificado_autos_placa,
                });
              }
              break;
            case "GASTOS MEDICOS":
              if (this.items_certificados[i].crearGastoMedico.length > 0) {
                this.items_certificados[i].crearGastoMedico[0].clase =
                  this.certificado_gasto_medico_clase;
                this.items_certificados[i].crearGastoMedico[0].nombre =
                  this.certificado_gasto_medico_nombre;
                this.items_certificados[i].crearGastoMedico[0].edad =
                  this.certificado_gasto_medico_edad;
                this.items_certificados[
                  i
                ].crearGastoMedico[0].fecha_nacimiento =
                  this.certificado_gasto_medico_fecha_nacimiento;
                this.items_certificados[
                  i
                ].crearGastoMedico[0].maximo_vitalicio_contratado =
                  this.certificado_gasto_medico_max_vit_contratado;
                this.items_certificados[
                  i
                ].crearGastoMedico[0].maximo_vitaliciio_vigente =
                  this.certificado_gasto_medico_max_vit_vigente;
                this.items_certificados[i].crearGastoMedico[0].sexo =
                  this.certificado_gasto_medico_sexo;
                this.items_certificados[i].crearGastoMedico[0].cobertura =
                  this.certificado_gasto_medico_cobertura;
              } else {
                (clase = this.certificado_gasto_medico_clase),
                  (nombre = this.certificado_gasto_medico_nombre),
                  (edad = this.certificado_gasto_medico_edad),
                  (fecha_nacimiento =
                    this.certificado_gasto_medico_fecha_nacimiento),
                  (sexo = this.certificado_gasto_medico_sexo),
                  (maximo_vitalicio_contratado =
                    this.certificado_gasto_medico_max_vit_contratado),
                  (maximo_vitalicio_vigente =
                    this.certificado_gasto_medico_max_vit_vigente),
                  (cobertura = this.certificado_gasto_medico_cobertura);
              }
          }

          this.dialog_certificado = false;
          this.limpiarCertificado();
          break;
        }
      }
      this.items_cert_transporte_mercaderia = [];
    },
    deleteCertificado() {
      for (var i = 0; i < this.items_certificados.length; i++) {
        if (
          this.items_certificados[i].num_certificado == this.num_certificado
        ) {
          this.items_certificados.splice(i, 1);
          this.dialog_certificado = false;
          this.limpiarCertificado();
          this.limpiarCertificadoVida();
          this.limpiarCertificadoAuto();
          this.limpiarCertificadoGastoMedico();
          break;
        }
      }
    },
    calculoFecha() {
      var date = new Date(this.certificado_fecha_inicio);
      var year = date.getFullYear() + 1;
      var month = date.getMonth();
      var day = date.getDate() + 1;
      var newDate = new Date(year, month, day);

      this.certificado_fecha_fin = this.formatoFecha(newDate);
    },
    calculoFechaPrimerPago() {
      var init = new Date();
      var año = init.getFullYear();
      var mes = init.getMonth() + 1;
      var dia = this.certificado_dia_cobro;
      this.certificado_fecha_primer_pago = this.formatoFecha(
        año + "-" + mes + "-" + dia
      );
    },
    abrirDialogCertificado() {
      this.bandera_certificado = 1;
      this.dialog_certificado = true;
    },
    ///////////////////////////////// CERTIFICADOS - PAGOS //////////////////////////////
    listarCertPagos(id) {
      axios
        .get("api/CertificadoPagos/Listar/" + id)
        .then((response) => {
          this.items_certificado_pagos = response.data;
          this.certificado_cantidad_cuotas = response.data.length;
          this.bandera_cert_pago_sin_cuotas = response.data.length;

          if (this.items_certificado_pagos.length == 0) {
            this.bandera_cert_pago = 0;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    calculoCuotaCertificado() {
      if (this.certificado_periodo == "ANUAL") {
        if (this.certificado_tipo_cuota && this.certificado_prima) {
          if (this.certificado_tipo_cuota.nombre == "MENSUAL") {
            this.certificado_cuota = (
              this.certificado_prima / this.certificado_cantidad_cuotas
            )?.toFixed(2);
            this.creacionPagosCertificadoMensual();
          } else if (this.certificado_tipo_cuota.nombre == "TRIMESTRAL") {
            this.certificado_cuota = (this.certificado_prima / 4)?.toFixed(2);
            this.creacionPagosCertificadoTrismestral();
          } else if (this.certificado_tipo_cuota.nombre == "SEMESTRAL") {
            this.certificado_cuota = (this.certificado_prima / 2)?.toFixed(2);
            this.creacionPagosCertificadoSemestral();
          } else if (this.certificado_tipo_cuota.nombre == "ANUAL") {
            this.certificado_cuota = (this.certificado_prima / 1)?.toFixed(2);
            this.creacionPagosCertificadoAnual();
          }
        }
      } else if (
        this.certificado_periodo == "PRORRATA" &&
        this.certificado_prima
      ) {
        this.creacionPagosCertificadosProrrata();
      }
    },
    creacionPagosCertificadoMensual() {
      var fecha = new Date(
        this.certificado_fecha_primer_pago + " " + "00:00:00"
      );
      var fecha_dia = fecha.getDate();
      var fecha_año = fecha.getFullYear();
      var index_mes = fecha.getMonth() + 1;

      if (this.certificado_fecha_primer_pago) {
        this.items_certificado_pagos = [];
        var contador = 0;
        if (!this.id_certificado) {
          for (var i = 0; i < this.certificado_cantidad_cuotas; i++) {
            var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
            var format_date = this.validarLimiteFecha(fecha_cuota);

            this.items_certificado_pagos.push({
              id: contador,
              id_tipo_documentos: null,
              esta_pagada: false,
              fecha: format_date,
              monto: this.certificado_cuota,
              fecha_pago: null,
              factura: "",
              nombre_tipo_documento: "",
            });
            contador++;

            if (index_mes == 12) {
              fecha_año++;
              index_mes = 1;
            } else {
              index_mes++;
            }
          }
        } else {
          for (var i = 0; i < this.certificado_cantidad_cuotas; i++) {
            var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
            var format_date = this.validarLimiteFecha(fecha_cuota);

            this.items_certificado_pagos.push({
              id: contador,
              id_certificados: this.id_certificado,
              id_tipo_documentos: null,
              esta_pagada: false,
              fecha: format_date,
              monto: this.certificado_cuota,
              fecha_pago: null,
              factura: "",
              nombre_tipo_documento: "",
            });
            contador++;

            if (index_mes == 12) {
              fecha_año++;
              index_mes = 1;
            } else {
              index_mes++;
            }
          }
        }
      }
    },
    creacionPagosCertificadoTrismestral() {
      var fecha = new Date(
        this.certificado_fecha_primer_pago + " " + "00:00:00"
      );

      if (this.certificado_fecha_primer_pago) {
        this.items_certificado_pagos = [];
        var contador = 0;
        if (!this.id_certificado) {
          for (var i = 0; i < 3; i++) {
            var fecha_dia = fecha.getDate();
            var fecha_año = fecha.getFullYear();
            var index_mes = fecha.getMonth() + 1;
            var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
            var format_date = this.validarLimiteFecha(fecha_cuota);

            this.items_certificado_pagos.push({
              id: contador,
              id_tipo_documentos: null,
              esta_pagada: false,
              fecha: format_date,
              monto: this.certificado_cuota,
              fecha_pago: null,
              factura: "",
              nombre_tipo_documento: "",
            });
            contador++;

            //SOLO SE SUMAN 4 MESES YA QUE AL OBTENER index_mes se suma un mes mas
            fecha.setMonth(fecha.getMonth() + 4);
          }
        } else {
          for (var i = 0; i < 3; i++) {
            var fecha_dia = fecha.getDate();
            var fecha_año = fecha.getFullYear();
            var index_mes = fecha.getMonth() + 1;
            var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
            var format_date = this.validarLimiteFecha(fecha_cuota);

            this.items_certificado_pagos.push({
              id: contador,
              id_certificados: this.id_certificado,
              id_tipo_documentos: null,
              esta_pagada: false,
              fecha: format_date,
              monto: this.certificado_cuota,
              fecha_pago: null,
              factura: "",
              nombre_tipo_documento: "",
            });
            contador++;

            //SOLO SE SUMAN 4 MESES YA QUE AL OBTENER index_mes se suma un mes mas
            fecha.setMonth(fecha.getMonth() + 4);
          }
        }
      }
    },
    creacionPagosCertificadoSemestral() {
      var fecha = new Date(
        this.certificado_fecha_primer_pago + " " + "00:00:00"
      );

      if (this.certificado_fecha_primer_pago) {
        this.items_certificado_pagos = [];
        var contador = 0;
        if (!this.id_certificado) {
          for (var i = 0; i < 2; i++) {
            var fecha_dia = fecha.getDate();
            var fecha_año = fecha.getFullYear();
            var index_mes = fecha.getMonth() + 1;
            var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
            var format_date = this.validarLimiteFecha(fecha_cuota);

            this.items_certificado_pagos.push({
              id: contador,
              id_tipo_documentos: null,
              esta_pagada: false,
              fecha: format_date,
              monto: this.certificado_cuota,
              fecha_pago: null,
              factura: "",
              nombre_tipo_documento: "",
            });
            contador++;

            //SOLO SE SUMAN 5 MESES YA QUE AL OBTENER index_mes se suma un mes mas
            fecha.setMonth(fecha.getMonth() + 5);
          }
        } else {
          for (var i = 0; i < 2; i++) {
            var fecha_dia = fecha.getDate();
            var fecha_año = fecha.getFullYear();
            var index_mes = fecha.getMonth() + 1;
            var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
            var format_date = this.validarLimiteFecha(fecha_cuota);

            this.items_certificado_pagos.push({
              id: contador,
              id_certificados: this.id_certificado,
              id_tipo_documentos: null,
              esta_pagada: false,
              fecha: format_date,
              monto: this.certificado_cuota,
              fecha_pago: null,
              factura: "",
              nombre_tipo_documento: "",
            });
            contador++;

            //SOLO SE SUMAN 5 MESES YA QUE AL OBTENER index_mes se suma un mes mas
            fecha.setMonth(fecha.getMonth() + 5);
          }
        }
      }
    },
    creacionPagosCertificadoAnual() {
      var fecha = new Date(
        this.certificado_fecha_primer_pago + " " + "00:00:00"
      );

      if (this.certificado_fecha_primer_pago) {
        this.items_certificado_pagos = [];
        var contador = 0;
        if (!this.id_certificado) {
          for (var i = 0; i < 1; i++) {
            this.items_certificado_pagos.push({
              id: contador,
              id_tipo_documentos: null,
              esta_pagada: false,
              fecha: this.formatoFecha(this.certificado_fecha_fin),
              monto: this.certificado_cuota,
              fecha_pago: null,
              factura: "",
              nombre_tipo_documento: "",
            });
            contador++;
          }
        } else {
          for (var i = 0; i < 1; i++) {
            this.items_certificado_pagos.push({
              id: contador,
              id_certificados: this.id_certificado,
              id_tipo_documentos: null,
              esta_pagada: false,
              fecha: this.formatoFecha(this.certificado_fecha_fin),
              monto: this.certificado_cuota,
              fecha_pago: null,
              factura: "",
              nombre_tipo_documento: "",
            });
            contador++;
          }
        }
      }
    },
    creacionPagosCertificadosProrrata() {
      var fecha = new Date(
        this.certificado_fecha_primer_pago + " " + "00:00:00"
      );
      //var fecha_fin = new Date(this.certificado_fecha_fin + " " + "00:00:00");
      //var fecha_diff = fecha_fin.getMonth() - fecha.getMonth() + 12 * (fecha_fin.getFullYear() - fecha.getFullYear());

      this.certificado_cuota = (
        this.certificado_prima / this.certificado_cantidad_cuotas
      )?.toFixed(2);

      var fecha_dia = fecha.getDate();
      var fecha_año = fecha.getFullYear();
      var index_mes = fecha.getMonth() + 1;

      if (this.certificado_fecha_primer_pago) {
        this.items_certificado_pagos = [];
        var contador = 0;
        if (!this.id_certificado) {
          for (var i = 0; i < this.certificado_cantidad_cuotas; i++) {
            var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
            var format_date = this.validarLimiteFecha(fecha_cuota);

            this.items_certificado_pagos.push({
              id: contador,
              id_tipo_documentos: null,
              esta_pagada: false,
              fecha: format_date,
              monto: this.certificado_cuota,
              fecha_pago: null,
              factura: "",
              nombre_tipo_documento: "",
            });
            contador++;

            if (index_mes == 12) {
              fecha_año++;
              index_mes = 1;
            } else {
              index_mes++;
            }
          }
        } else {
          for (var i = 0; i < this.certificado_cantidad_cuotas; i++) {
            var fecha_cuota = fecha_año + "-" + index_mes + "-" + fecha_dia;
            var format_date = this.validarLimiteFecha(fecha_cuota);

            this.items_certificado_pagos.push({
              id: contador,
              id_certificados: this.id_certificado,
              id_tipo_documentos: null,
              esta_pagada: false,
              fecha: format_date,
              monto: this.certificado_cuota,
              fecha_pago: null,
              factura: "",
              nombre_tipo_documento: "",
            });
            contador++;

            if (index_mes == 12) {
              fecha_año++;
              index_mes = 1;
            } else {
              index_mes++;
            }
          }
        }
      }
    },
    editCertPagos(item) {
      //console.log(item);
      var valor_monto = parseFloat(item.monto);
      this.id_cert_pago_aux = item.id;

      if (item.id_certificados && item.id_cert_pago) {
        this.bandera_cert_pago = 2;
      } else {
        if (item.id != undefined) {
          this.bandera_cert_pago = 1;
        }
      }
      this.id_certificado = item.id_certificados;
      this.id_cert_pago = item.id_cert_pago;
      this.cert_pago_fecha = this.formatoFecha(item.fecha);
      this.cert_pago_monto = valor_monto?.toFixed(2);
      this.id_cert_pago_tipo_documentos = {
        id_tipo_documentos: item.id_tipo_documentos,
        nombre: item.tipo_documento,
      };
      if (item.fecha_pago) {
        this.cert_pago_fecha_pago = this.formatoFecha(item.fecha_pago);
      }

      this.cert_pago_num_documento = item.factura;

      this.dialog_cert_pago = true;
    },
    aplicarCambiosCertPago() {
      this.items_certificado_pagos[this.id_cert_pago_aux].fecha =
        this.cert_pago_fecha;
      this.items_certificado_pagos[this.id_cert_pago_aux].monto =
        this.cert_pago_monto;
      this.items_certificado_pagos[this.id_cert_pago_aux].esta_pagada = true;
      this.items_certificado_pagos[this.id_cert_pago_aux].fecha_pago =
        this.cert_pago_fecha_pago;
      this.items_certificado_pagos[this.id_cert_pago_aux].id_tipo_documentos =
        this.id_cert_pago_tipo_documentos.id_tipo_documentos;
      this.items_certificado_pagos[
        this.id_cert_pago_aux
      ].nombre_tipo_documento = this.id_cert_pago_tipo_documentos.nombre;
      this.items_certificado_pagos[this.id_cert_pago_aux].factura =
        this.cert_pago_num_documento;

      this.cerrarDialogCertPago();
    },
    cerrarDialogCertPago() {
      this.dialog_cert_pago = false;

      this.limpiarCertPago();
    },
    limpiarCertPago() {
      this.id_cert_pago = null;
      this.cert_pago_fecha = null;
      this.cert_pago_monto = null;
      this.cert_pago_fecha_pago = null;
      this.id_cert_pago_tipo_documentos = null;
      this.cert_pago_num_documento = null;
      this.bandera_cert_pago = 0;
    },
    actualizarCertPagos() {
      //console.log("aqui");
      //ESTE METODO ES CUANDO PRESIONA EL CHECK-BOX DE PAGO DE CERTIFICADO
      axios
        .put("api/CertificadoPagos/Actualizar", {
          id_cert_pago: this.id_cert_pago,
          id_certificados: this.id_certificado,
          id_tipo_documentos:
            this.id_cert_pago_tipo_documentos.id_tipo_documentos,
          fecha: this.cert_pago_fecha,
          monto: this.cert_pago_monto,
          esta_pagada: true,
          fecha_pago: this.cert_pago_fecha_pago,
          factura: this.cert_pago_num_documento,
        })
        .then(() => {
          this.cerrarDialogCertPago();
          this.listarCertPagos(this.id_certificado);
          this.listarCuotasInclusivosCertificados(this.id_poliza);
          this.alerta("success", "Registro creado con exito");
        })
        .catch((error) => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    restablecerCertCuotas() {
      axios
        .put("api/Certificadopagos/RestablecerPago", {
          id_cuotas: 0,
          id_aumento_inclusion: 0,
          id_cert_pago: this.id_cert_pago,
          esta_pagada: false,
          fecha_pago: null,
          factura: null,
          id_tipo_documentos: null,
        })
        .then(() => {
          this.alerta("success", "Registro restablecido");
          this.dialog_cert_pago = false;
          this.listarCuotasInclusivosCertificados(this.id_poliza);
          this.listarCertPagos(this.id_certificado);
          this.cuota_fecha_pago = null;
        })
        .catch(() => {
          this.alerta("error", "SUcedio algún error");
        });
    },
    agregarArchivoCertificado(item) {
      this.aux_fecha_certificado = this.formatoFecha(item.fecha);
      this.$refs.uploaderCertFile.click();
    },
    async guardarArchivoCertificado(e) {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      var list_files = e.target.files;
      for (var i = 0; i < list_files.length; i++) {
        const result = await apiOneDrive.metodos.CrearArchivoCertificado(
          this.$store.state.usuario.id_usuario,
          this.nombre_cliente_carpeta,
          this.aseguradora.nombre_carpeta,
          this.fecha_inicial,
          this.categoria.descripcion,
          this.ramo_seguro.descripcion,
          this.poliza,
          this.aux_fecha_certificado,
          this.tipo_doc_cliente,
          list_files[i]
        );
        if (result.status == 201) {
          this.alertaMensaje(
            "Archivo guardado: " + result.data.name,
            "success"
          );
        } else {
          this.alertaMensaje(
            "Archivo no se pudo guardar: " + result.data.name,
            "error"
          );
        }
      }
      this.alerta("info", "Proceso terminado.");
    },
    async verArchivoCertificado(item) {
      let credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      let date = new Date(this.fecha_inicial);
      let year = date.getFullYear();

      var ruta =
        this.nombre_cliente_carpeta +
        "/" +
        year.toString() +
        "/" +
        this.aseguradora.nombre_carpeta.split(/[.,]+/).join("") +
        "/" +
        this.categoria.descripcion +
        "/" +
        this.ramo_seguro.descripcion +
        "/" +
        this.poliza +
        "/" +
        this.tipo_doc_cliente;

      credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      let token = await apiLocal.metodos.getItemObject(
        "access_token",
        credenciales
      ).valor;
      let token_decrypt = await apiLocal.metodos.desencriptar(token);

      let header = { Authorization: "Bearer " + token_decrypt };
      let configuracion = { headers: header };

      axios
        .get(
          "https://graph.microsoft.com/v1.0/me/drive/root:/" +
            process.env.VUE_APP_RAIZ_CARPETA +
            "/1-Asegurados/" +
            ruta,
          configuracion
        )
        .then((response) => {
          window.open(response.data.webUrl, "_blank").focus();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    agregarArchivoCertificadoPago(item) {
      this.aux_fecha_certificado_pago = this.formatoFecha(item.fecha);
      this.$refs.uploaderCertPago.click();
    },
    async guardarArchivoCertificadoPago(e) {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      var list_files = e.target.files;
      for (var i = 0; i < list_files.length; i++) {
        const result = await apiOneDrive.metodos.CrearArchivoPagoCertificado(
          this.$store.state.usuario.id_usuario,
          this.nombre_cliente_carpeta,
          this.aseguradora.nombre_carpeta,
          this.fecha_inicial,
          this.categoria.descripcion,
          this.ramo_seguro.descripcion,
          this.poliza,
          this.aux_fecha_certificado_pago,
          this.tipo_doc_cliente,
          list_files[i]
        );
        if (result.status == 201) {
          this.alertaMensaje(
            "Archivo guardado: " + result.data.name,
            "success"
          );
        } else {
          this.alertaMensaje(
            "Archivo no se pudo guardar: " + result.data.name,
            "error"
          );
        }
      }
      this.alerta("info", "Proceso terminado.");
    },
    async verArchivoCertificadoPago(item) {
      let credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      let date = new Date(this.fecha_inicial);
      let year = date.getFullYear();

      var ruta =
        this.nombre_cliente_carpeta +
        "/" +
        year.toString() +
        "/" +
        this.aseguradora.nombre_carpeta.split(/[.,]+/).join("") +
        "/" +
        this.categoria.descripcion +
        "/" +
        this.ramo_seguro.descripcion +
        "/" +
        this.poliza +
        "/" +
        this.tipo_doc_cliente;

      credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      let token = await apiLocal.metodos.getItemObject(
        "access_token",
        credenciales
      ).valor;
      let token_decrypt = await apiLocal.metodos.desencriptar(token);

      let header = { Authorization: "Bearer " + token_decrypt };
      let configuracion = { headers: header };

      axios
        .get(
          "https://graph.microsoft.com/v1.0/me/drive/root:/" +
            process.env.VUE_APP_RAIZ_CARPETA +
            "/1-Asegurados/" +
            ruta,
          configuracion
        )
        .then((response) => {
          window.open(response.data.webUrl, "_blank").focus();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    actualizarMontoCuotaCertificado(item) {
      if (item.id_certificados) {
        axios
          .put("api/Certificadopagos/ActualizarMontoCuota", {
            id_cert_pago: item.id_cert_pago,
            monto: item.monto,
          })
          .then(() => {
            this.alerta("success", "Cuota actualizada");
          })
          .catch(() => {
            this.alerta("error", "Sucedio algún error");
          });
      } else {
        if (item.id == 1) {
          for (var i = 1; i < this.items_certificado_pagos.length; i++) {
            this.items_certificado_pagos[i].monto = item.monto;
          }
        }
      }
    },
    /////////////////////////////////// CERTIFICADO X ARCHIVOS /////////////////////////
    async listarArchivosCertificadoArchivos() {
      let credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      let token = await apiLocal.metodos.getItemObject(
        "access_token",
        credenciales
      ).valor;
      let token_decrypt = await apiLocal.metodos.desencriptar(token);

      let header = {
        Authorization: "Bearer " + token_decrypt,
        "Content-Type": "application/json",
      };
      let configuracion = { headers: header };

      let date = new Date(this.fecha_inicial);
      let year = date.getFullYear();

      var params = "";
      if (this.tipo_carpeta_archivo_certificado != "CERTIFICADO") {
        params =
          process.env.VUE_APP_RAIZ_CARPETA +
          "/1-Asegurados/" +
          this.nombre_cliente_carpeta +
          "/" +
          year +
          "/" +
          this.aseguradora.nombre_carpeta.split(/[.,]+/).join("") +
          "/" +
          this.categoria.descripcion +
          "/" +
          this.ramo_seguro.descripcion +
          "/" +
          this.poliza +
          "/" +
          this.tipo_carpeta_archivo_certificado;
      } else {
        if (!this.certificado_seleccionado_archivo) {
          return;
        }
        params =
          process.env.VUE_APP_RAIZ_CARPETA +
          "/1-Asegurados/" +
          this.nombre_cliente_carpeta +
          "/" +
          year +
          "/" +
          this.aseguradora.nombre_carpeta.split(/[.,]+/).join("") +
          "/" +
          this.categoria.descripcion +
          "/" +
          this.ramo_seguro.descripcion +
          "/" +
          this.poliza +
          "/CERTIFICADO/" +
          this.certificado_seleccionado_archivo.num_trans_merca +
          this.certificado_seleccionado_archivo.num_factura;
      }

      axios
        .get(
          "https://graph.microsoft.com/v1.0/me/drive/root:/" +
            params +
            ":/children",
          configuracion
        )
        .then((response) => {
          response.data.value.forEach((element) => {
            if (!element.folder) {
              this.items_archivos_certificado_archivo.push(element);
            }
          });
        });
    },
    async crearArchivoCertificadoArchivo(id) {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      //ITERAMOS LA CANTIDAD DE ARCHIVOS A GUARDAR EN ONE DRIVE
      this.file_input_certificado_archivo.forEach(async (element) => {
        const result = await apiOneDrive.metodos.CrearArchivoCertificadoArchivo(
          this.$store.state.usuario.id_usuario,
          this.nombre_cliente_carpeta,
          this.aseguradora.nombre_carpeta,
          this.fecha_inicial,
          this.categoria.descripcion,
          this.ramo_seguro.descripcion,
          this.poliza,
          this.tipo_carpeta_archivo_certificado,
          element
        );
        if (result.status == 201) {
          this.alertaMensaje(
            "Archivo guardado: " + result.data.name,
            "success"
          );
        } else {
          this.alertaMensaje(
            "Archivo no se pudo guardar: " + result.data.name,
            "error"
          );
        }
      });
      this.file_input_certificado_archivo = [];

      this.alerta("info", "Proceso terminado.");
    },
    descargarCertificadoArchivos() {
      apiOneDrive.metodos.DescargarItems(
        this.$store.state.usuario.id_usuario,
        this.archivos_seleccionados_certificado_archivo
      );
    },
    async vistPreviaArchivoPolizaCertificado(item) {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      apiOneDrive.metodos.VerArchivo(
        this.$store.state.usuario.id_usuario,
        item.id
      );
    },
    ///////////////////////// DEPENDIENTES - GASTOS MEDICOS - CERTIFICADO ///////////////////////
    nuevoDependienteGastoMedico() {
      this.dialog_dependiantes_cert_gasto_medico = true;
      this.flag_dependientes_cert_gasto_medico = 1;

      this.listarDependientes();
      this.listarDependientesExcluidos();
    },
    cerrarDialogDependietenes() {
      this.dialog_dependiantes_cert_gasto_medico = false;
    },
    guardarDependiente() {
      axios
        .post("api/DependientesGastoMedicos/Crear", {
          id_certificado_gasto_medico: this.id_certificado_gasto_medico,
          nombre: this.dependientes_cert_gasto_medico_nombre,
          excluido: this.dependientes_cert_gasto_medico_excluido,
          edad: this.dependientes_cert_gasto_medico_edad,
          fecha_nacimiento:
            this.dependientes_cert_gasto_medico_fecha_nacimiento,
          sexo: this.dependientes_cert_gasto_medico_sexo,
          parentesco: this.dependientes_cert_gasto_medico_parentesco,
          maximo_vitalicio_contratado:
            this.dependientes_cert_gasto_medico_max_vita_contratado,
          maximo_vitalicio_vigente:
            this.dependientes_cert_gasto_medico_max_vita_vigente,
          cobertura: this.dependientes_cert_gasto_medico_cobertura,
          motivo_excluido: this.dependientes_cert_gasto_medico_motivo_excluido,
        })
        .then(() => {
          this.alerta("success", "Guardado con exito");
          this.listarDependientes();
          this.listarDependientesExcluidos();
          this.limpiarDependiantes();
        })
        .catch((error) => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    editarDependientesCertGastoMedico(item) {
      this.flag_dependientes_cert_gasto_medico = 2;

      this.id_dependientes_cert_gasto_medico =
        item.id_dependientes_gastos_medicos;
      this.id_certificado_gasto_medico = item.id_certificado_gasto_medico;
      this.dependientes_cert_gasto_medico_nombre = item.nombre;
      this.dependientes_cert_gasto_medico_excluido = item.excluido;
      this.dependientes_cert_gasto_medico_fecha_nacimiento = this.formatoFecha(
        item.fecha_nacimiento
      );
      this.dependientes_cert_gasto_medico_edad = item.edad;
      this.dependientes_cert_gasto_medico_sexo = item.sexo;
      this.dependientes_cert_gasto_medico_parentesco = item.parentesco;
      this.dependientes_cert_gasto_medico_max_vita_contratado =
        item.maximo_vitalicio_contratado;
      this.dependientes_cert_gasto_medico_max_vita_vigente =
        item.maximo_vitalicio_vigente;
      this.dependientes_cert_gasto_medico_cobertura = item.cobertura;
      this.dependientes_cert_gasto_medico_motivo_excluido =
        item.motivo_excluido;
    },
    confirmarEliminarDepenCertGastoMedico(item) {
      //console.log(item);
      this.id_dependientes_cert_gasto_medico =
        item.id_dependientes_gastos_medicos;
      this.dialog_eliminar_cert_depen_gastomedico = true;
    },
    eliminarDependientesCertGastoMedico() {
      //console.log(this.id_dependientes_cert_gasto_medico);
      axios
        .delete(
          "api/DependientesGastoMedicos/Eliminar/" +
            this.id_dependientes_cert_gasto_medico
        )
        .then(() => {
          this.alerta("success", "Registro eliminado con exito");
          this.dialog_eliminar_cert_depen_gastomedico = false;
          this.listarDependientes();
          this.listarDependientesExcluidos();
          this.limpiarDependiantes();
        })
        .catch(() => {
          this.alerta("error", "No se pudo eliminar el registro");
        });
    },
    actualizarDependientes() {
      axios
        .put("api/DependientesGastoMedicos/Actualizar", {
          id_dependientes_gastos_medicos:
            this.id_dependientes_cert_gasto_medico,
          id_certificado_gasto_medico: this.id_certificado_gasto_medico,
          nombre: this.dependientes_cert_gasto_medico_nombre,
          excluido: this.dependientes_cert_gasto_medico_excluido,
          edad: this.dependientes_cert_gasto_medico_edad,
          fecha_nacimiento:
            this.dependientes_cert_gasto_medico_fecha_nacimiento,
          sexo: this.dependientes_cert_gasto_medico_sexo,
          parentesco: this.dependientes_cert_gasto_medico_parentesco,
          maximo_vitalicio_contratado:
            this.dependientes_cert_gasto_medico_max_vita_contratado,
          maximo_vitalicio_vigente:
            this.dependientes_cert_gasto_medico_max_vita_vigente,
          cobertura: this.dependientes_cert_gasto_medico_cobertura,
          motivo_excluido: this.dependientes_cert_gasto_medico_motivo_excluido,
        })
        .then(() => {
          this.alerta("success", "Registro creado");
          this.listarDependientes();
          this.listarDependientesExcluidos();
          this.limpiarDependiantes();
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    listarDependientes() {
      axios
        .get(
          "api/DependientesGastoMedicos/Listar/" +
            this.id_certificado_gasto_medico
        )
        .then((response) => {
          this.items_dependientes_cert_gasto_medico = response.data;
        })
        .catch((error) => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    listarDependientesExcluidos() {
      axios
        .get(
          "api/DependientesGastoMedicos/ListarExcluidos/" +
            this.id_certificado_gasto_medico
        )
        .then((response) => {
          this.items_dependientes_cert_gasto_medico_excluidos = response.data;
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    limpiarDependiantes() {
      this.dependientes_cert_gasto_medico_nombre = "";
      this.dependientes_cert_gasto_medico_excluido = false;
      this.dependientes_cert_gasto_medico_edad = 0;
      this.dependientes_cert_gasto_medico_sexo = "";
      this.dependientes_cert_gasto_medico_parentesco = "";
      this.dependientes_cert_gasto_medico_fecha_nacimiento = "";
      this.dependientes_cert_gasto_medico_max_vita_contratado = "";
      this.dependientes_cert_gasto_medico_max_vita_vigente = "";
      this.dependientes_cert_gasto_medico_cobertura = "";
      this.dependientes_cert_gasto_medico_motivo_excluido = "";
    },
    /////////////////////////////////// RECLAMOS ///////////////////////////////////
    listarReclamos(id) {
      axios
        .get("api/Reclamos/Listar/" + id)
        .then((response) => {
          this.items_reclamos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abrirDialogReclamo() {
      var date = new Date();
      this.fecha_reclamo = this.formatoFecha(date);
      this.formatoNumeroReclamo();

      this.dialog_reclamo = true;
      this.bandera_reclamo = 1;
      this.setPolizaReclamo();
    },
    formatoNumeroReclamo() {
      var formato_fecha = this.fecha_reclamo.replace(/-0+/g, "-");
      var date = new Date(formato_fecha);
      this.num_reclamo =
        date.getDate() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        date.getFullYear();
    },
    setPolizaReclamo() {
      this.reclamo_poliza = this.poliza;
      this.reclamo_cliente = this.codigo_cliente;
      this.reclamo_nombre_cliente = this.nombre_cliente;
      this.reclamo_aseguradora = this.aseguradora.id_aseguradoras;
    },
    addReclamo() {
      this.items_reclamos.push({
        id_tipo_reclamos: this.reclamo_estado,
        num_reclamo: this.num_reclamo,
        fecha_pago: this.reclamo_fecha_pago,
        fecha_reclamo: this.fecha_reclamo,
        taller: this.reclamo_taller,
        motivo: this.reclamo_motivo,
      });
      this.dialog_reclamo = false;
      this.limpiarReclamo();
    },
    updateReclamo() {
      for (var i = 0; i < this.items_reclamos.length; i++) {
        if (this.items_reclamos[i].num_reclamo == this.num_reclamo) {
          this.items_reclamos[i].id_tipo_reclamos = this.reclamo_estado;
          this.items_reclamos[i].num_reclamo = this.num_reclamo;
          this.items_reclamos[i].fecha_pago = this.reclamo_fecha_pago;
          this.items_reclamos[i].fecha_reclamo = this.fecha_reclamo;
          this.items_reclamos[i].taller = this.reclamo_taller;
          this.items_reclamos[i].motivo = this.reclamo_motivo;
          this.dialog_reclamo = false;
          this.limpiarReclamo();
          break;
        }
      }
    },
    deleteReclamo() {
      for (var i = 0; i < this.items_reclamos.length; i++) {
        if (this.items_reclamos[i].num_reclamo == this.num_reclamo) {
          this.items_reclamos.splice(i, 1);
          this.dialog_reclamo = false;
          this.limpiarReclamo();
          break;
        }
      }
    },
    listarTipoReclamos() {
      axios
        .get("api/TipoReclamos/Listar")
        .then((response) => {
          this.items_tipo_reclamos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cerrarDialogReclamo() {
      this.dialog_reclamo = false;

      this.limpiarReclamo();
    },
    limpiarReclamo() {
      this.id_reclamos = null;
      this.reclamo_id_polizas = null;
      this.reclamo_id_tipo_reclamos = null;
      this.num_reclamo = null;
      this.reclamo_poliza = null;
      this.fecha_reclamo = null;
      this.reclamo_fecha_pago = null;
      this.reclamo_cliente = null;
      this.reclamo_nombre_cliente = null;
      this.reclamo_aseguradora = null;
      this.reclamo_taller = null;
      this.reclamo_estado = null;
      this.reclamo_motivo = null;
      this.reclamo_valor_indemnizado = 0;
      this.reclamo_placa_nombre = null;
      this.reclamo_caracteristica_auto = null;
    },
    crearReclamo() {
      axios
        .post("api/Reclamos/Crear", {
          id_polizas: this.id_poliza,
          id_tipo_reclamos: this.reclamo_estado,
          num_reclamo: this.num_reclamo,
          fecha_pago: this.reclamo_fecha_pago,
          fecha_reclamo: this.fecha_reclamo,
          taller: this.reclamo_taller,
          motivo: this.reclamo_motivo,
          valor_indemnizado: this.reclamo_valor_indemnizado,
          placa_nombre: this.reclamo_placa_nombre,
          caracteristicas_auto: this.reclamo_caracteristica_auto,
          es_eliminado: 0,
        })
        .then(() => {
          this.cerrarDialogReclamo();
          this.listarReclamos(this.id_poliza);
          this.limpiarReclamo();
          this.alerta("success", "Registro creado con exito");
        })
        .catch((error) => {
          if (error.response.data == "-1") {
            this.alerta("info", "El número de reclamo ya existe");
          } else {
            this.alerta("error", "Sucedio algún error");
          }
        });
    },
    actualizarReclamo() {
      axios
        .put("api/Reclamos/Actualizar", {
          id_reclamos: this.id_reclamos,
          id_polizas: this.id_poliza,
          id_tipo_reclamos: this.reclamo_estado,
          num_reclamo: this.num_reclamo,
          fecha_pago: this.reclamo_fecha_pago,
          fecha_reclamo: this.fecha_reclamo,
          taller: this.reclamo_taller,
          motivo: this.reclamo_motivo,
          valor_indemnizado: this.reclamo_valor_indemnizado,
          placa_nombre: this.reclamo_placa_nombre,
          caracteristicas_auto: this.reclamo_caracteristica_auto,
          es_eliminado: 0,
        })
        .then(() => {
          this.cerrarDialogReclamo();
          this.limpiarReclamo();
          this.listarReclamos(this.id_poliza);
          this.alerta("success", "Registro creado con exito");
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    editarReclamo(item) {
      this.id_reclamos = item.id_reclamos;
      this.reclamo_id_polizas = item.id_polizas;
      this.reclamo_estado = item.id_tipo_reclamos;
      this.num_reclamo = item.num_reclamo;
      this.reclamo_poliza = this.poliza;
      this.fecha_reclamo = this.formatoFecha(item.fecha_reclamo);
      this.reclamo_fecha_pago = this.formatoFecha(item.fecha_pago);
      this.reclamo_cliente = this.codigo_cliente;
      this.reclamo_nombre_cliente = this.nombre_cliente;
      this.reclamo_aseguradora = this.aseguradora.id_aseguradoras;
      this.reclamo_taller = item.taller;
      this.reclamo_motivo = item.motivo;
      this.reclamo_valor_indemnizado = this.formatoMoneda(
        item.valor_indemnizado
      );
      this.reclamo_placa_nombre = item.placa_nombre;
      this.reclamo_caracteristica_auto = item.caracteristicas_auto;

      this.bandera_reclamo = 2;
      this.dialog_reclamo = true;
    },
    eliminarReclamo(item) {
      //console.log(item);
      axios
        .put("api/Reclamos/SafeDelete", {
          id_reclamos: item.id_reclamos,
          es_eliminado: true,
        })
        .then(() => {
          this.cerrarDialogReclamo();
          this.limpiarReclamo();
          this.listarReclamos(this.id_poliza);
          this.alerta("success", "Registro eliminado");
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    async listarArchivosReclamo() {
      let credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      let date = new Date(this.fecha_inicial);
      let year = date.getFullYear();

      this.dialog_reclamos_archivos = true;

      credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      let token = await apiLocal.metodos.getItemObject(
        "access_token",
        credenciales
      );
      let token_decrypt = await apiLocal.metodos.desencriptar(token.valor);
      let header = { Authorization: "Bearer " + token_decrypt };
      let configuracion = { headers: header };

      this.items_reclamos_archivos = [];

      axios
        .get(
          "https://graph.microsoft.com/v1.0/me/drive/root:/" +
            process.env.VUE_APP_RAIZ_CARPETA +
            "/1-Asegurados/" +
            this.nombre_cliente_carpeta +
            "/" +
            year +
            "/" +
            this.aseguradora.nombre_carpeta.split(/[.,]+/).join("") +
            "/" +
            this.categoria.descripcion +
            "/" +
            this.ramo_seguro.descripcion +
            "/" +
            this.poliza +
            "/RECLAMOS/" +
            this.fecha_reclamo +
            " " +
            this.reclamo_placa_nombre.trim() +
            ":/children",
          configuracion
        )
        .then((response) => {
          this.items_reclamos_archivos = response.data.value;
        });
    },
    async verArchivoReclamo(item) {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }
      apiOneDrive.metodos.VerArchivo(
        this.$store.state.usuario.id_usuario,
        item.id
      );
    },
    async guardarArchivoReclamo() {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }
      var nombre_carpeta = "";

      /*if(this.nombre_ramo == "AUTO"){
        nombre_carpeta = this.reclamo_placa_nombre;
      } else if(this.nombre_ramo == "VIDAS" || this.nombre_ramo == "GASTOS MEDICOS"){
        nombre_carpeta = this.reclamo_placa_nombre;
      }*/

      this.items_reclamos_archivo_guardar.forEach(async (element) => {
        const result = await apiOneDrive.metodos.CrearArchivoReclamo(
          this.$store.state.usuario.id_usuario,
          this.nombre_cliente_carpeta,
          this.aseguradora.nombre_carpeta,
          this.fecha_inicial,
          this.categoria.descripcion,
          this.ramo_seguro.descripcion,
          this.poliza,
          this.fecha_reclamo + " " + this.reclamo_placa_nombre,
          element
        );
        if (result.status == 201) {
          this.alertaMensaje(
            "Archivo guardado: " + result.data.name,
            "success"
          );
        } else {
          this.alertaMensaje(
            "Archivo no se pudo guardar: " + result.data.name,
            "error"
          );
        }
      });
      this.items_reclamos_archivo_guardar = [];
      this.dialog_reclamos_archivos = false;

      this.alerta("info", "Proceso terminado.");
    },
    async dercargarReclamoArchivo() {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      apiOneDrive.metodos.DescargarArchivo(
        this.$store.state.usuario.id_usuario,
        this.items_reclamo_archivo_seleccionados
      );
    },
    ///////////////////////////////// ARCHIVOS POLIZA //////////////////////////
    async listarArchivos() {
      if (this.bandera == 1) {
        this.items_archivos = [];
        return;
      }
      let credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      let year = this.fecha_inicial.split("-")[0];

      credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      let token = await apiLocal.metodos.getItemObject(
        "access_token",
        credenciales
      ).valor;
      let token_decrypt = await apiLocal.metodos.desencriptar(token);
      let header = { Authorization: "Bearer " + token_decrypt };
      let configuracion = { headers: header };

      this.items_archivos = [];
      axios
        .get(
          "https://graph.microsoft.com/v1.0/me/drive/root:/" +
            process.env.VUE_APP_RAIZ_CARPETA +
            "/1-Asegurados/" +
            this.nombre_cliente_carpeta +
            "/" +
            year +
            "/" +
            this.aseguradora.nombre_carpeta?.split(/[.,]+/).join("") +
            "/" +
            this.categoria.descripcion +
            "/" +
            this.ramo_seguro.descripcion +
            "/" +
            this.poliza +
            "/" +
            this.tipo_carpeta_archivo_poliza +
            ":/children",
          configuracion
        )
        .then((response) => {
          this.items_archivos = [];
          response.data.value.forEach((element) => {
            if (!element.folder) {
              this.items_archivos.push(element);
            }
          });
        });
    },
    async crearArchivo() {
      if (
        !this.nombre_cliente_carpeta ||
        !this.aseguradora.descripcion ||
        !this.categoria.descripcion ||
        !this.ramo_seguro.descripcion ||
        !this.poliza ||
        !this.tipo_carpeta_archivo_poliza
      ) {
        this.alerta(
          "info",
          "Debe de completar los campos de # poliza, aseguradora, categoria y ramo"
        );
        return;
      }

      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      //ITERAMOS LA CANTIDAD DE ARCHIVOS A GUARDAR EN ONE DRIVE
      this.file_input.forEach(async (element) => {
        const result = await apiOneDrive.metodos.CrearArchivoPoliza(
          this.$store.state.usuario.id_usuario,
          this.nombre_cliente_carpeta,
          this.aseguradora.nombre_carpeta,
          this.fecha_inicial,
          this.categoria.descripcion,
          this.ramo_seguro.descripcion,
          this.poliza,
          this.tipo_carpeta_archivo_poliza,
          element
        );
        if (result.status == 201) {
          this.alertaMensaje(
            "Archivo guardado: " + result.data.name,
            "success"
          );
        } else {
          this.alertaMensaje(
            "Archivo no se pudo guardar: " + result.data.name,
            "error"
          );
        }

        this.listarArchivos();
      });
      this.file_input = [];
    },
    async vistaPreviaArchivo(item) {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      apiOneDrive.metodos.VerArchivo(
        this.$store.state.usuario.id_usuario,
        item.id
      );
    },
    async descargarArchivo() {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      await apiOneDrive.metodos.DescargarArchivo(
        this.$store.state.usuario.id_usuario,
        this.archivos_seleccionados
      );
    },
    limpiarArchivos() {
      this.file_input = [];
      this.archivos_seleccionados = [];
      this.items_archivos = [];
      this.id_archivos_clientes = null;
    },
    ////////////////////////////////// BENEFICIARIOS ///////////////////////////
    listarDetalles(id) {
      axios
        .get("api/Beneficiarios/Listar/" + id)
        .then((response) => {
          this.items_detalles = response.data;

          this.contador = null;
          this.items_detalles.forEach((element) => {
            this.contador = this.contador + element.porcentaje;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abrirDialogDetalles() {
      this.bandera_beneficiarios = 1;
      this.dialog_beneficiarios = true;
    },
    cerrarDialogDetalles() {
      this.dialog_beneficiarios = false;
      this.limpiarDetalles();
    },
    addDetalle() {
      var suma = 0;
      if (this.items_detalles.length == 0) {
        if (this.beneficiarios_nivel == "PRIMARIO") {
          if (this.beneficiarios_porcentaje > 100) {
            this.alerta("info", "El porcentaje no puede ser mayor al 100%");
            return;
          }
        }

        this.identificador = this.identificador + 1;
        this.items_detalles.push({
          id: this.identificador,
          parentesco: this.beneficiarios_parentesco,
          nombre: this.beneficiarios_nombre,
          porcentaje: this.beneficiarios_porcentaje,
          fecha_nacimiento: this.beneficiarios_fecha_nacimiento,
          sexo: this.beneficiarios_sexo,
          dui: this.beneficiarios_dui,
          nivel: this.beneficiarios_nivel,
        });
        this.cerrarDialogDetalles();
      } else {
        this.items_detalles.forEach((element) => {
          if (element.nivel == "PRIMARIO") {
            suma = parseFloat(suma) + parseFloat(element.porcentaje);
          }
        });
        if (this.beneficiarios_nivel == "PRIMARIO") {
          if (suma + parseFloat(this.beneficiarios_porcentaje) > 100) {
            this.alerta("info", "El porcentaje no puede ser mayor al 100%");
            return;
          }
        }

        this.identificador = this.identificador + 1;
        this.items_detalles.push({
          id: this.identificador,
          parentesco: this.beneficiarios_parentesco,
          nombre: this.beneficiarios_nombre,
          porcentaje: this.beneficiarios_porcentaje,
          fecha_nacimiento: this.beneficiarios_fecha_nacimiento,
          sexo: this.beneficiarios_sexo,
          dui: this.beneficiarios_dui,
          nivel: this.beneficiarios_nivel,
        });
        this.cerrarDialogDetalles();
      }
    },
    updateDetalle() {
      var suma = 0;

      for (var i = 0; i < this.items_detalles.length; i++) {
        if (this.items_detalles[i].nivel == "PRIMARIO") {
          if (this.items_detalles[i].id != this.identificador_aux) {
            suma =
              parseFloat(suma) + parseFloat(this.items_detalles[i].porcentaje);
          }
        }
      }

      if (this.beneficiarios_nivel == "PRIMARIO") {
        if (suma + parseFloat(this.beneficiarios_porcentaje) > 100) {
          this.alerta("info", "El porcentaje no puede ser mayor al 100%");
          return;
        }
      }

      for (var i = 0; i < this.items_detalles.length; i++) {
        if (this.items_detalles[i].id == this.identificador_aux) {
          this.items_detalles[i].parentesco = this.beneficiarios_parentesco;
          this.items_detalles[i].nombre = this.beneficiarios_nombre;
          this.items_detalles[i].porcentaje = this.beneficiarios_porcentaje;
          this.items_detalles[i].fecha_nacimiento =
            this.beneficiarios_fecha_nacimiento;
          this.items_detalles[i].sexo = this.beneficiarios_sexo;
          this.items_detalles[i].dui = this.beneficiarios_dui;
          this.items_detalles[i].nivel = this.beneficiarios_nivel;
          this.cerrarDialogDetalles();
          break;
        }
      }
    },
    deleteDetalle() {
      for (var i = 0; i < this.items_detalles.length; i++) {
        if (this.items_detalles[i].id == this.identificador_aux) {
          this.items_detalles.splice(i, 1);
          this.cerrarDialogDetalles();
          break;
        }
      }
    },
    crearDetalle() {
      if (this.beneficiarios_nivel == "PRIMARIO") {
        var suma =
          parseFloat(this.beneficiarios_porcentaje) + parseFloat(this.contador);

        if (suma > 100) {
          this.alerta("info", "El porcentaje no puede ser mayor al 100%");
          return;
        }
      }

      axios
        .post("api/Beneficiarios/Crear", {
          id_polizas: this.id_poliza,
          parentesco: this.beneficiarios_parentesco,
          nombre: this.beneficiarios_nombre,
          porcentaje: this.beneficiarios_porcentaje,
          fecha_nacimiento: this.beneficiarios_fecha_nacimiento,
          sexo: this.beneficiarios_sexo,
          dui: this.beneficiarios_dui,
          nivel: this.beneficiarios_nivel,
        })
        .then(() => {
          this.cerrarDialogDetalles();
          this.listarDetalles(this.id_poliza);
          this.alerta("success", "Registro creado con exito");
        })
        .catch((error) => {
          console.log(error);
          this.alerta("error", "Sucedio algún error");
        });
    },
    actualizarDetalle() {
      if (this.beneficiarios_nivel == "PRIMARIO") {
        var suma = 0;
        this.items_detalles.forEach((element) => {
          if (element.id_beneficiarios != this.id_beneficiarios) {
            suma = suma + parseFloat(element.porcentaje);
          }
        });
        suma = suma + parseFloat(this.beneficiarios_porcentaje);

        if (suma > 100) {
          this.alerta("info", "El porcentaje no puede ser mayor al 100%");
          return;
        }
      }

      axios
        .put("api/Beneficiarios/Actualizar", {
          id_beneficiarios: this.id_beneficiarios,
          id_polizas: this.id_poliza,
          parentesco: this.beneficiarios_parentesco,
          nombre: this.beneficiarios_nombre,
          porcentaje: this.beneficiarios_porcentaje,
          fecha_nacimiento: this.beneficiarios_fecha_nacimiento,
          sexo: this.beneficiarios_sexo,
          dui: this.beneficiarios_dui,
          nivel: this.beneficiarios_nivel,
        })
        .then(() => {
          this.listarDetalles(this.id_poliza);
          this.cerrarDialogDetalles();
          this.alerta("success", "Registro actualizado con exito");
        })
        .catch((error) => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    limpiarDetalles() {
      this.id_beneficiarios = null;
      this.beneficiarios_parentesco = null;
      this.beneficiarios_nombre = null;
      this.beneficiarios_porcentaje = null;
      this.beneficiarios_fecha_nacimiento = null;
      this.beneficiarios_sexo = null;
      this.beneficiarios_dui = null;
      this.beneficiarios_nivel = null;
    },
    editarDetalles(item) {
      this.id_beneficiarios = item.id_beneficiarios;
      this.beneficiarios_parentesco = item.parentesco;
      this.beneficiarios_nombre = item.nombre;
      this.beneficiarios_porcentaje = item.porcentaje;
      this.beneficiarios_fecha_nacimiento = this.formatoFecha(
        item.fecha_nacimiento
      );
      this.beneficiarios_dui = item.dui;
      this.beneficiarios_sexo = item.sexo;
      this.identificador_aux = item.id;
      this.beneficiarios_nivel = item.nivel;

      this.bandera_beneficiarios = 2;
      this.dialog_beneficiarios = true;
    },
    confirmarEliminarBeneficiarioProdIndVida(item) {
      //console.log(item);
      this.id_beneficiarios = item.id_beneficiarios;
      this.dialog_eliminar_beneficiarios = true;
    },
    eliminarBeneficiarioProdIndVida() {
      if (this.id_beneficiarios == 0) {
        return;
      }
      axios
        .delete("api/Beneficiarios/Eliminar/" + this.id_beneficiarios)
        .then(() => {
          this.alerta("success", "Registro eliminado con exito");
          this.dialog_eliminar_beneficiarios = false;
          this.listarDetalles(this.id_poliza);
        })
        .catch(() => {
          this.alerta("error", "No se pudo eliminar el registro");
        });
    },
    async listarArchivosTransMerca() {
      this.dialog_archivos_trans_merca = true;

      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      let year = this.fecha_inicial.split("-");

      var ruta =
        process.env.VUE_APP_RAIZ_CARPETA +
        "/1-Asegurados/" +
        this.nombre_cliente_carpeta.trim() +
        "/" +
        year[0].toString() +
        "/" +
        this.aseguradora.nombre_carpeta?.split(/[.,]+/).join("") +
        "/" +
        this.categoria.descripcion +
        "/" +
        this.ramo_seguro.descripcion +
        "/" +
        this.poliza +
        "/TRANS MERCADERIA/" +
        this.prod_ind_transporte_mercaderia_num_trans_merca;

      const result = await apiOneDrive.metodos.verListaArchivos(
        this.$store.state.usuario.id_usuario,
        ruta
      );
      if (result.length > 0) {
        this.items_archivos_trans_merca = result;
      }
    },
    async GuardarArchivoTransMerca() {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      for (var i = 0; i < this.items_file_input_trans_merca.length; i++) {
        const result =
          await apiOneDrive.metodos.CrearArchivoTransporteMercaderia(
            this.$store.state.usuario.id_usuario,
            this.nombre_cliente_carpeta,
            this.aseguradora.nombre_carpeta,
            this.fecha_inicial,
            this.categoria.descripcion,
            this.ramo_seguro.descripcion,
            this.poliza,
            this.prod_ind_transporte_mercaderia_num_trans_merca,
            this.items_file_input_trans_merca[i]
          );
        if (result.status == 201) {
          this.alertaMensaje(
            "Archivo guardado: " + result.data.name,
            "success"
          );
        } else {
          this.alertaMensaje(
            "Archivo no se pudo guardar: " + result.data.name,
            "error"
          );
        }
      }
    },
    verArchivosProdIndTransMerca(item) {
      apiOneDrive.metodos.VerArchivo(
        this.$store.state.usuario.id_usuario,
        item.id
      );
    },
    //////////////////////////////////////////////////////
    listarPorNumPoliza(num_poliza) {
      //console.log(num_poliza);
      if (num_poliza == "all") {
        return;
      }
      axios
        .get("api/Polizas/ListarPorNumPoliza/" + num_poliza)
        .then((response) => {
          //console.log(response.data);
          this.editarItem(response.data[0], "editar");
        })
        .catch(() => {
          this.alerta("error", "No se encontro asegurado");
        });
    },
    editarItem(item, opcion) {
      //console.log(item);
      this.aux_item = item;
      this.aux_editar_cuota = item.id_tipo_cuotas;

      this.id_poliza = item.id_polizas;
      this.id_cliente = item.id_cliente;
      this.id_seguro = {
        id_seguros: item.id_seguros,
      };
      this.id_usuario = item.id_usuario;
      this.plan_seleccionado = {
        id_planes: item.id_planes,
        comision: item.comision,
      };

      this.tipo_cuota = item.id_tipo_cuotas;
      this.nombre_tipo_cuota = item.nombre_tipo_cuota;

      this.poliza = item.num_poliza.trim();
      this.codigo_cliente = item.codigo_cliente;
      this.nombre_cliente = item.nombre_cliente;
      this.nombre_cliente_carpeta = item.nombre_cliente_carpeta.trim();
      if (item.registro_cliente) {
        this.tipo_doc_cliente = "CCF";
      } else {
        this.tipo_doc_cliente = "FAC";
      }

      this.periodos = item.periodo;
      this.dia_cobro = item.dia_cobro;
      this.inicial_renovacion = item.inicial_renovacion;
      this.ejecutivo = item.ejecutivo;
      this.activo = item.activo ? 1 : 0;
      this.prima_neta = this.formatoMoneda(item.prima_total);
      this.fraccionamiento = this.formatoMoneda(item.monto_cuota);
      this.suma_asegurada = this.formatoMoneda(item.suma_asegurada);
      this.gasto_bomberos = this.formatoMoneda(item.gasto_bomberos);
      this.gastos_emision = this.formatoMoneda(item.gasto_emision);
      this.iva_13_por_ciento = this.formatoMoneda(item.iva);
      this.total_a_pagar = this.formatoMoneda(item.total_pagar);
      this.metodo_pago = item.metodo_pago;
      this.comision = item.comision;
      this.aumentos_inclusiones = item.aumento_inclusion;
      this.aumentos_inclusiones_prima = item.prima_extra;
      this.aumentos_inclusiones_iva = item.iva_extra;
      this.aumentos_inclusiones_total = item.total_prima_extra;
      this.aumentos_inclusiones_cant_cuotas = item.cantidad_cuotas_extra;
      this.motivo_cancelado_excluido = item.motivo_cancelado_inactivo;
      this.fecha_cancelado = item.fecha_cancelado;
      this.año_creacion = item.año_creacion;
      this.prima_comisionable = item.prima_comisionable;

      if (item.fecha_inicial) {
        this.fecha_inicial = this.formatoFecha(item.fecha_inicial);
      }
      if (item.fecha_fin) {
        this.fecha_fin = this.formatoFecha(item.fecha_fin);
      }

      if (item.fecha_extra) {
        this.aumentos_inclusiones_fecha = this.formatoFecha(item.fecha_extra);
      }

      this.nombre_aseguradora = item.nombre_aseguradora;
      this.aseguradora_segun_carpeta = item.nombre_aseguradora_carpeta.trim();
      this.nombre_categoria = item.nombre_categoria;
      this.nombre_ramo = item.nombre_ramo;

      this.listarPolizasCliente(item.id_cliente);
      this.setSeguro(item.id_seguros);
      this.listarTipoReclamos();
      this.listarReclamos(item.id_polizas);
      this.listarCertificados(item.id_polizas);
      this.listarDetalles(item.id_polizas);
      this.listarCuotas(item.id_polizas);
      this.listarCuotasIndependientes(item.id_polizas);
      this.listarProductoIndividual(item.id_polizas, item.nombre_ramo);
      this.listarCuotasInclusivosCertificados(item.id_polizas);
      this.listarCuotasAumentosInclusivos();
      this.listarProdIndIncendioCobertura();
      this.listarProdIndIncendioRiesos();

      this.deshabilitar_actualizar = opcion == "ver" ? true : false;
      this.bandera = 2;
      this.bandera_archivos = 2;
      this.bandera_archivos_certificado_archivo = 2;
      this.dialog = true;
    },
    limpiar() {
      this.id_poliza = null;
      this.poliza = null;
      this.codigo_cliente = null;
      this.nombre_cliente = null;
      this.nombre_cliente_carpeta = null;
      this.items_clientes = [];
      this.id_cliente = null;
      this.id_seguro = null;
      this.aseguradora = "";
      this.categoria = "";
      this.seguro = "";
      this.tipo_cuota = null;
      this.nombre_tipo_cuota = null;
      this.items_seguros = [];
      this.tipo_seguro = "";
      this.items_tipo_seguros = [];
      this.ramo_seguro = "";
      this.items_ramo_seguros = [];
      this.periodos = "";
      this.fecha_inicial = null;
      this.fecha_fin = null;
      this.dia_cobro = null;
      this.activo = false;
      this.inicial_renovacion = "Es poliza inicial";
      this.ejecutivo = "";
      this.prima_neta = 0;
      this.fraccionamiento = 0;
      this.suma_asegurada = 0;
      this.items_reclamos = [];
      this.items_certificados = [];
      this.items_detalles = [];
      this.items_cuotas = [];
      this.items_certificado_pagos = [];
      this.identificador = null;
      this.bandera_cuota = -1;
      this.file_input = [];
      this.gastos_emision = 0;
      this.gasto_bomberos = 0;
      this.iva_13_por_ciento = 0;
      this.total_a_pagar = 0;
      this.metodo_pago = null;
      this.items_prod_ind_respon_civil = [];
      this.items_prod_ind_robo_hurto = [];
      this.items_prod_ind_dinero_valores = [];
      this.items_prod_ind_fidelidad = [];
      this.items_prod_ind_incendio = [];
      this.items_prod_ind_incendio_excluidos = [];
      this.items_prod_ind_residencia = [];
      this.items_planes = [];
      this.plan_seleccionado = "";
      this.motivo_cancelado_excluido = "";
      this.fecha_cancelado = "";
      this.comision = 0;
      this.items_prod_ind_coberturas = [];
      this.items_prod_ind_riesgos = [];
      this.items_prod_ind_transporte_mercaderia = [];
      this.items_prod_ind_riesgo_constru = [];
      this.items_prod_ind_riesgo_constru_coberturas = [];
      this.items_prod_ind_riesgo_constru_riesgos = [];
      this.items_pago_certificados_incluidos = [];
      this.items_prod_ind_fidelidad = [];
      this.items_aumentos_inclusiones = [];
      this.prima_comisionable = "";
      this.aumentos_inclusiones = null;
      this.aumentos_inclusiones_prima = null;
      this.aumentos_inclusiones_iva = null;
      this.aumentos_inclusiones_total = null;
      this.aumentos_inclusiones_cant_cuotas = null;
      this.aumentos_inclusiones_fecha = null;
      this.items_beneficios = [];

      this.limpiarProductoIndividualAuto();
      this.limpiarProductoIndividualVida();
      this.limpiarProductoIndividualDineroValores();
      this.limpiarProductoIndividualRoboHurto();
      this.limpiarProductoIndividualTransporteInterno();
      this.limpiarProductoIndividualResponsabilidadCivil();
    },
    crearPoliza() {
      if (this.se_esta_renovando_poliza) {
        axios
          .post("api/Polizas/CrearRenovacion", {
            id_cliente: this.id_cliente,
            id_seguros: this.id_seguro,
            id_tipo_cuotas:
              this.tipo_cuota.id_tipo_cuota == null
                ? this.tipo_cuota
                : this.tipo_cuota.id_tipo_cuota,
            id_planes: this.plan_seleccionado.id_planes,
            id_usuario: this.$store.state.usuario.id_usuario,
            num_poliza: this.poliza.trim(),
            periodo: this.periodos,
            fecha_inicial: this.fecha_inicial,
            fecha_fin: this.fecha_fin,
            dia_cobro: this.dia_cobro,
            activo: this.activo,
            inicial_renovacion: this.inicial_renovacion,
            ejecutivo: this.ejecutivo == "" ? "" : this.ejecutivo,
            prima_total: this.prima_neta,
            monto_cuota: this.fraccionamiento,
            suma_asegurada: this.suma_asegurada,
            gasto_bomberos: this.gasto_bomberos,
            gasto_emision: this.gastos_emision,
            iva: this.iva_13_por_ciento,
            total_pagar: this.total_a_pagar,
            metodo_pago: this.metodo_pago,
            comision: this.comision,
            beneficiarios: this.items_detalles,
            cuotas: this.items_cuotas,
            aumento_inclusion: this.aumentos_inclusiones,
            prima_extra: this.aumentos_inclusiones_prima,
            iva_extra: this.aumentos_inclusiones_iva,
            total_prima_extra: this.aumentos_inclusiones_total,
            fecha_extra: this.aumentos_inclusiones_fecha,
            cantidad_cuotas_extra: this.aumentos_inclusiones_cant_cuotas,
            aumentos_inclusiones: this.items_aumentos_inclusiones,
            motivo_cancelado_inactivo: this.motivo_cancelado_excluido,
            año_creacion: this.año_creacion,
            prima_comisionable: this.prima_comisionable,
            fecha_cancelado: this.fecha_cancelado
          })
          .then((response) => {
            this.id_poliza = response.data;

            this.listar();
            this.listarProdIndIncendioCobertura();
            this.listarProdIndIncendioRiesos();
            this.listarBeneficios();
            this.alerta("success", "Registro creado con exito");
          })
          .catch(() => {
            this.alerta("error", "Sucedio algún error");
          });

        return;
      }

      if (this.id_cliente && this.id_seguro) {
        axios
          .post("api/Polizas/Crear", {
            id_cliente: this.id_cliente,
            id_seguros: this.id_seguro,
            id_tipo_cuotas:
              this.tipo_cuota.id_tipo_cuota == null
                ? this.tipo_cuota
                : this.tipo_cuota.id_tipo_cuota,
            id_planes: this.plan_seleccionado.id_planes,
            id_usuario: this.$store.state.usuario.id_usuario,
            num_poliza: this.poliza.trim(),
            periodo: this.periodos,
            fecha_inicial: this.fecha_inicial,
            fecha_fin: this.fecha_fin,
            dia_cobro: this.dia_cobro,
            activo: this.activo,
            inicial_renovacion: this.inicial_renovacion,
            ejecutivo: this.ejecutivo == "" ? "" : this.ejecutivo,
            prima_total: this.prima_neta,
            monto_cuota: this.fraccionamiento,
            suma_asegurada: this.suma_asegurada,
            gasto_bomberos: this.gasto_bomberos,
            gasto_emision: this.gastos_emision,
            iva: this.iva_13_por_ciento,
            total_pagar: this.total_a_pagar,
            metodo_pago: this.metodo_pago,
            comision: this.comision,
            reclamos: this.items_reclamos,
            beneficiarios: this.items_detalles,
            cuotas: this.items_cuotas,
            aumento_inclusion: this.aumentos_inclusiones,
            prima_extra: this.aumentos_inclusiones_prima,
            iva_extra: this.aumentos_inclusiones_iva,
            total_prima_extra: this.aumentos_inclusiones_total,
            fecha_extra: this.aumentos_inclusiones_fecha,
            cantidad_cuotas_extra: this.aumentos_inclusiones_cant_cuotas,
            aumentos_inclusiones: this.items_aumentos_inclusiones,
            motivo_cancelado_inactivo: this.motivo_cancelado_excluido,
            año_creacion: this.año_creacion,
            prima_comisionable: this.prima_comisionable,
            observaciones: this.items_observaciones_poliza,
            incendioCoberturas: this.items_prod_ind_coberturas,
            incendioRiesgos: this.items_prod_ind_riesgos,
            beneficios: this.items_beneficios,
            fecha_cancelado: this.fecha_cancelado
          })
          .then((response) => {
            if (response.data == -1) {
              this.alerta("info", "El número de póliza ya existe");
              return;
            }
            this.crearProdIndividual(response.data, "_nuevo");

            this.id_poliza = response.data;
            this.crearCertificadoNuevo(response.data);

            if (this.file_input.length > 0) {
              this.crearArchivo(response.data);
              return;
            }

            this.listar();
            this.listarProdIndIncendioCobertura();
            this.listarProdIndIncendioRiesos();
            this.listarBeneficios();
            this.alerta("success", "Registro creado con exito");
          })
          .catch((error) => {
            this.alerta("error", "Sucedio algún error");
          });
      } else {
        this.alerta("info", "Debe de seleccionar un cliente y el producto");
      }
    },
    actualizarPoliza() {
      //VALIDACION PORQUE SI MODIFICA LA CUOTA NO DE ERROR YA QUE SE ESTABLECE UN OBJETO Y NO EL ID
      var aux_cuota = "";
      if (this.tipo_cuota == this.aux_editar_cuota) {
        aux_cuota = this.tipo_cuota;
      } else {
        aux_cuota = this.tipo_cuota.id_tipo_cuota;
      }

      axios
        .put("api/Polizas/Actualizar", {
          id_polizas: this.id_poliza,
          id_cliente: this.id_cliente,
          id_seguros: this.id_seguro,
          id_tipo_cuotas: aux_cuota,
          id_planes:
            this.plan_seleccionado == null
              ? null
              : this.plan_seleccionado.id_planes,
          id_usuario: this.id_usuario,
          num_poliza: this.poliza.trim(),
          periodo: this.periodos,
          fecha_inicial: this.fecha_inicial,
          fecha_fin: this.fecha_fin,
          dia_cobro: this.dia_cobro,
          activo: this.activo,
          inicial_renovacion: this.inicial_renovacion,
          ejecutivo: this.ejecutivo == null ? "" : this.ejecutivo,
          prima_total: this.prima_neta,
          monto_cuota: this.fraccionamiento,
          suma_asegurada: this.suma_asegurada,
          gasto_bomberos: this.gasto_bomberos,
          gasto_emision: this.gastos_emision,
          iva: this.iva_13_por_ciento,
          total_pagar: this.total_a_pagar,
          metodo_pago: this.metodo_pago,
          comision: this.comision,
          aumento_inclusion: this.aumentos_inclusiones,
          prima_extra: this.aumentos_inclusiones_prima,
          iva_extra: this.aumentos_inclusiones_iva,
          total_prima_extra: this.aumentos_inclusiones_total,
          fecha_extra: this.aumentos_inclusiones_fecha,
          cantidad_cuotas_extra: this.aumentos_inclusiones_cant_cuotas,
          motivo_cancelado_inactivo: this.motivo_cancelado_excluido,
          año_creacion: this.año_creacion,
          prima_comisionable: this.prima_comisionable,
          observaciones: this.items_observaciones_poliza,
          incendioCoberturas: this.items_prod_ind_coberturas,
          incendioRiesgos: this.items_prod_ind_riesgos,
          beneficios: this.items_beneficios,
          fecha_cancelado: this.fecha_cancelado
        })
        .then(() => {
          if (
            this.tipo_seguro.descripcion == "INDIVIDUAL" &&
            this.flag_individual > 0
          ) {
            this.actualizarProdIndividual();
          }

          if (
            this.tipo_seguro.descripcion == "INDIVIDUAL" &&
            this.flag_individual == 0
          ) {
            this.crearProdIndividual(this.id_poliza, "");
          }
          if (this.bandera_sin_cuotas) {
            this.crearCuotas();
          }
          if (this.aumentos_inclusiones_bandera_sin_cuotas) {
            this.crearAumentosInclusiones();
          }
          this.listar();
          this.listarProdIndIncendioCobertura();
          this.listarProdIndIncendioRiesos();
          this.listarBeneficios();
          this.alerta("success", "Registro actualizado con exito");
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    confirmarDesactivarPoliza() {
      this.dialog_desactivar_poliza = true;
    },
    desactivarPoliza() {
      axios
        .put(
          "api/Polizas/Desactivar/" +
            this.id_poliza +
            "/" +
            false +
            "/" +
            this.$store.state.usuario.nombre
        )
        .then(() => {
          this.listar();
          this.dialog_desactivar_poliza = false;
          this.cerrarDialog();
        });
    },
    eliminarCuotas() {
      axios
        .delete("api/Cuotas/Eliminar/" + this.id_poliza)
        .then(() => {
          this.alerta("success", "Cuotas eliminadas");
          this.listarCuotas(this.id_poliza);
          this.dialog_eliminar_cuotas = false;
        })
        .catch(() => {
          this.alerta("error", "No se pueden eliminar las cuotas");
          this.dialog_eliminar_cuotas = false;
        });
    },
    async eliminarCuotasSeleccionadas() {
      var list_id_cuotas = [];
      //console.log(this.cuotas_polizas_seleccionadas);
      this.cuotas_polizas_seleccionadas.forEach((element) => {
        list_id_cuotas.push(element.id_cuotas);
      });

      await axios
        .delete("api/Cuotas/EliminarSeleccionado", { data: list_id_cuotas })
        .then(() => {
          this.listarCuotas(this.id_poliza);
          this.dialog_confirmar_eliminar_cuotas_seleccionadas = false;
          this.cuotas_polizas_seleccionadas = [];
        });
    },
    eliminarCuotasIndependientes() {
      axios
        .delete("api/Cuotas/EliminarIndependientes/" + this.id_poliza)
        .then(() => {
          this.alerta("success", "Cuotas eliminadas");
          this.listarCuotasIndependientes(this.id_poliza);
          this.dialog_eliminar_cuotas_independiente = false;
        })
        .catch(() => {
          this.alerta("error", "No se pueden eliminar las cuotas");
          this.dialog_eliminar_cuotas = false;
        });
    },
    async crearCuotas() {
      for (var i = 0; i < this.items_cuotas.length; i++) {
        await axios.post("api/Cuotas/Crear", {
          id_polizas: this.items_cuotas[i].id_polizas,
          id_tipo_documentos: this.items_cuotas[i].id_tipo_documentos,
          fecha: this.items_cuotas[i].fecha,
          monto: this.items_cuotas[i].monto,
          esta_pagada: this.items_cuotas[i].esta_pagada,
          fecha_pago: this.items_cuotas[i].fecha_pago,
          factura: this.items_cuotas[i].factura,
          cuota_independiente: false,
          gestion_cobros: [],
        });
      }
      this.bandera_sin_cuotas = false;
    },
    crearCuotaIndependiente() {
      axios
        .post("api/Cuotas/Crear", {
          id_polizas: this.id_poliza,
          id_tipo_documentos: this.id_tipo_documentos,
          fecha:
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            new Date().getDate(),
          monto: 0.0,
          esta_pagada: false,
          fecha_pago: "",
          factura: "",
          cuota_independiente: true,
          gestion_cobros: [],
        })
        .then(() => {
          this.listarCuotasIndependientes(this.id_poliza);
        });
    },
    restablecerCuotas() {
      axios
        .put("api/Cuotas/RestablecerPago", {
          id_cuotas: this.id_cuota,
          id_aumento_inclusion: 0,
          id_cert_pago: 0,
          esta_pagada: false,
          fecha_pago: null,
          factura: null,
          id_tipo_documentos: null,
        })
        .then(() => {
          this.alerta("success", "Registro restablecido");
          this.dialog_cuotas_tipo_documento = false;
          this.listarCuotas(this.id_poliza);
          this.cuota_fecha_pago = null;
        })
        .catch(() => {
          this.alerta("error", "SUcedio algún error");
        });
    },
    aplicarCambiosAumentoInclusionesPago() {
      var index = this.items_aumentos_inclusiones.findIndex(
        (obj) => obj.id == this.contador_aumento_inclusion
      );
      if (index != -1) {
        this.items_aumentos_inclusiones[index].esta_pagada = true;
        this.items_aumentos_inclusiones[index].fecha_pago =
          this.aumento_inclu_pago_fecha_pago;
        this.items_aumentos_inclusiones[index].id_tipo_documentos =
          this.id_aumento_inclu_pago_tipo_documentos.id_tipo_documentos;
        this.items_aumentos_inclusiones[index].factura =
          this.aumento_inclu_pago_num_documento;
        this.items_aumentos_inclusiones[index].nombre_tipo_documento =
          this.id_aumento_inclu_pago_tipo_documentos.nombre;

        this.dialog_aumento_inclusion_pago = false;
      }
    },
    async crearAumentosInclusiones() {
      for (var i = 0; i < this.items_aumentos_inclusiones.length; i++) {
        await axios.post("api/AumentosInclusiones/Crear", {
          id_polizas: this.items_aumentos_inclusiones[i].id_poliza,
          id_tipo_documentos:
            this.items_aumentos_inclusiones[i].id_tipo_documentos,
          fecha: this.items_aumentos_inclusiones[i].fecha,
          monto: this.items_aumentos_inclusiones[i].monto,
          esta_pagada: this.items_aumentos_inclusiones[i].esta_pagada,
          fecha_pago: this.items_aumentos_inclusiones[i].fecha_pago,
          factura: this.items_aumentos_inclusiones[i].factura,
        });
      }
    },
    actualizarAumentosInclusiones() {
      axios
        .put("api/AumentosInclusiones/Actualizar", {
          id_aumento_inclusion: this.id_aumento_inclusion,
          id_polizas: this.id_poliza,
          id_tipo_documentos:
            this.id_aumento_inclu_pago_tipo_documentos.id_tipo_documentos ==
            undefined
              ? this.id_aumento_inclu_pago_tipo_documentos
              : this.id_aumento_inclu_pago_tipo_documentos.id_tipo_documentos,
          fecha: this.aumento_inclu_pago_fecha,
          monto: this.aumento_inclu_pago_monto,
          esta_pagada: true,
          fecha_pago: this.aumento_inclu_pago_fecha_pago,
          factura: this.aumento_inclu_pago_num_documento,
        })
        .then(() => {
          this.alerta("success", "Registro actualizado");
          this.listarCuotasAumentosInclusivos();

          this.dialog_aumento_inclusion_pago = false;
        })
        .catch(() => {
          this.alerta("error", "Registro eliminado");
        });
    },
    editAumentoInclusionPagos(item) {
      //console.log(item);
      this.contador_aumento_inclusion = item.id;
      this.id_aumento_inclusion = item.id_aumento_inclusion;
      this.aumento_inclu_pago_fecha = this.formatoFecha(item.fecha);
      this.aumento_inclu_pago_monto = item.monto;
      this.aumento_inclu_pago_fecha_pago = this.formatoFecha(item.fecha_pago);
      this.id_aumento_inclu_pago_tipo_documentos = item.id_tipo_documentos;
      this.aumento_inclu_pago_num_documento = item.factura;
      this.aumento_inclu_pago_nom_doc = item.nombre_tipo_documento;

      this.dialog_aumento_inclusion_pago = true;
    },
    eliminarAumentosInclusiones() {
      this.items_aumentos_inclusiones.forEach((element) => {
        axios
          .delete(
            "api/AumentosInclusiones/Eliminar/" + element.id_aumento_inclusion
          )
          .then(() => {
            this.dialog_eliminar_cuotas_aumentos_inclusiones = false;
            this.alerta("success", "Registro eliminado");
            this.listarCuotasAumentosInclusivos();
          })
          .catch(() => {
            this.alerta("error", "No se pudo eliminado");
          });
      });
    },
    restablecerAumentoInclusionCuotas() {
      axios
        .put("api/AumentosInclusiones/RestablecerPago", {
          id_cuotas: 0,
          id_aumento_inclusion: this.id_aumento_inclusion,
          esta_pagada: false,
          fecha_pago: null,
          factura: null,
          id_tipo_documentos: null,
        })
        .then(() => {
          this.alerta("success", "Registro restablecido");
          this.dialog_aumento_inclusion_pago = false;
          this.listarCuotasAumentosInclusivos(this.id_poliza);
          this.cuota_fecha_pago = null;
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    cerrarDialogAumentoInclusionPago() {
      this.id_aumento_inclusion = null;
      this.aumento_inclu_pago_fecha = "";
      this.aumento_inclu_pago_monto = "";

      this.dialog_aumento_inclusion_pago = false;
    },
    agregarArchivoAumentosInclusiones(item) {
      this.$refs.uploaderAumInclFile.click();
    },
    async guardarArchivoAumentosInclusiones(e) {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      for (var i = 0; i < e.target.files.length; i++) {
        const result =
          await apiOneDrive.metodos.CrearArchivoCuotaAumentosInclusiones(
            this.$store.state.usuario.id_usuario,
            this.nombre_cliente_carpeta,
            this.aseguradora.nombre_carpeta,
            this.fecha_inicial,
            this.categoria.descripcion,
            this.ramo_seguro.descripcion,
            this.poliza,
            e.target.files[i]
          );
        if (result.status == 201) {
          this.alertaMensaje(
            "Archivo guardado: " + result.data.name,
            "success"
          );
        } else {
          this.alertaMensaje(
            "Archivo no se pudo guardar: " + result.data.name,
            "error"
          );
        }
      }
    },
    agregarArchivoCertificadoPago(item) {
      this.$refs.uploaderAumInclPago.click();
    },
    async guardarArchivoAumentosInclusionesPago(e) {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      for (var i = 0; i < e.target.files.length; i++) {
        const result =
          await apiOneDrive.metodos.CrearArchivoPagoCuotaAumentosInclusiones(
            this.$store.state.usuario.id_usuario,
            this.nombre_cliente_carpeta,
            this.aseguradora.nombre_carpeta,
            this.fecha_inicial,
            this.categoria.descripcion,
            this.ramo_seguro.descripcion,
            this.poliza,
            e.target.files[i]
          );
        if (result.status == 201) {
          this.alertaMensaje(
            "Archivo guardado: " + result.data.name,
            "success"
          );
        } else {
          this.alertaMensaje(
            "Archivo no se pudo guardar: " + result.data.name,
            "error"
          );
        }
      }
    },
    formatoBooleano(item) {
      if (item) {
        return "SI";
      } else {
        return "NO";
      }
    },
    formatoFecha(fecha) {
      if (fecha) {
        return moment(fecha).format("YYYY-MM-DD");
      } else {
        return null;
      }
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
    colorActivo(item) {
      if (item) {
        return "green";
      } else {
        return "red";
      }
    },
    calculoEdadProdIndGastoMedicoDependientes(fecha_nacimiento) {
      this.prod_ind_depen_gastomedico_edad = this.calculoEdad(fecha_nacimiento);
    },
    calculoEdadCertificadoGastoMedicoDependientes(fecha_nacimiento) {
      this.dependientes_cert_gasto_medico_edad =
        this.calculoEdad(fecha_nacimiento);
    },
    calculoEdadCertificadoGastoMedico(fecha_nacimiento) {
      this.certificado_gasto_medico_edad = this.calculoEdad(fecha_nacimiento);
    },
    calculoEdadCertificadoVida(fecha_nacimiento) {
      this.certificado_vida_edad = this.calculoEdad(fecha_nacimiento);
    },
    calculoEdadProdIndVida(fecha_nacimiento) {
      this.prod_ind_vida_edad = this.calculoEdad(fecha_nacimiento);
    },
    calculoEdadProdIndGastoMedico(fecha_nacimiento) {
      this.prod_ind_gastomedico_edad = this.calculoEdad(fecha_nacimiento);
    },
    calculoEdadProdIndSeguroViaje(fecha_nacimiento) {
      this.prod_ind_seguro_viaje_edad = this.calculoEdad(fecha_nacimiento);
    },
    calculoEdadProdCertificadoVida(fecha_nacimiento) {
      this.colectivo_vida_beneficiario_edad =
        this.calculoEdad(fecha_nacimiento);
    },
    calculoEdadProdIndVidaBeneficiarios(fecha_nacimiento) {
      this.individual_vida_beneficiario_edad =
        this.calculoEdad(fecha_nacimiento);
    },
    calculoEdad(fecha_nacimiento) {
      var hoy = new Date();
      var cumple = new Date(fecha_nacimiento);
      var edad = hoy.getFullYear() - cumple.getFullYear();
      var mes = hoy.getMonth() - cumple.getMonth();
      if (mes < 0 || (mes === 0 && hoy.getDate() < cumple.getDate())) {
        edad--;
      }

      return edad;
    },
    formatoMoneda(numero) {
      return Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
        .formatToParts(numero)
        .map((p) =>
          p.type != "literal" && p.type != "currency" ? p.value : ""
        )
        .join("");
    },
    ///////////////////////////////PLANES/////////////////////////////////////////////////
    listarPlanesInicialRenovacion() {
      if (
        !this.aseguradora.id_aseguradoras ||
        !this.categoria.id_categoria_seguros ||
        !this.ramo_seguro.id_ramo_seguros
      ) {
        return;
      }

      this.items_planes = [];
      this.comision = null;

      axios
        .get(
          "api/Planes/Listar/" +
            this.aseguradora.id_aseguradoras +
            "/" +
            this.ramo_seguro.id_ramo_seguros +
            "/" +
            this.categoria.id_categoria_seguros
        )
        .then((response) => {
          if (this.inicial_renovacion == "Es poliza inicial") {
            response.data.forEach((element) => {
              this.items_planes.push({
                id_planes: element.id_planes,
                nombre: element.nombre,
                comision: element.comision_inicial,
              });
            });
          } else {
            response.data.forEach((element) => {
              this.items_planes.push({
                id_planes: element.id_planes,
                nombre: element.nombre,
                comision: element.comision_renovacion,
              });
            });
          }
        });
    },
    asignarComision() {
      if (this.plan_seleccionado)
        this.comision = this.plan_seleccionado.comision;
    },
    //PRODUCTOS INDIVIDUALES
    listarProductoIndividual(id_poliza, ramo_seguro) {
      axios
        .get("api/Individuales/Listar/" + id_poliza + "/" + ramo_seguro)
        .then((response) => {
          //console.log(response.data);
          //DETECTAR SI TIENE REGISTRADO LAS CARACTERISTICAS DEL PRODUCTO INDIVIDUAL
          this.flag_individual = response.data.length;

          if (response.data.length <= 0) {
            return;
          }

          switch (ramo_seguro) {
            case "AUTOS":
              this.prod_ind_auto_id_indiv_auto =
                response.data[0].id_individual_auto;
              this.prod_ind_auto_id_indiv = response.data[0].id_individuales;
              this.prod_ind_auto_marca = response.data[0].marca;
              this.prod_ind_auto_modelo = response.data[0].modelo;
              this.prod_ind_auto_año = response.data[0].año;
              this.prod_ind_auto_placa = response.data[0].placa;
              this.prod_ind_auto_chasis = response.data[0].chasis;
              this.prod_ind_auto_motor = response.data[0].motor;
              this.prod_ind_auto_version = response.data[0].version;
              this.prod_ind_auto_cesion = response.data[0].cesion;
              this.prod_ind_auto_monto_cesion = response.data[0].monto_cesion_a;
              this.prod_ind_auto_beneficiario_cesion =
                response.data[0].beneficiario_cesion_a;
              this.prod_ind_auto_tipo_vehiculo = response.data[0].tipo_vehiculo;
              this.prod_ind_auto_vencimiento_tarjeta = this.formatoFecha(
                response.data[0].vencimiento_tarjeta
              );
              this.prod_ind_auto_vencimiento_dui = this.formatoFecha(
                response.data[0].vencimiento_dui
              );
              this.prod_ind_auto_vencimiento_licencia = this.formatoFecha(
                response.data[0].vencimiento_licencia
              );
              break;
            case "VIDA":
              this.prod_ind_vida_id_indiv_vida =
                response.data[0].id_individual_vida;
              this.prod_ind_vida_id_indiv = response.data[0].id_individuales;
              this.prod_ind_vida_nombre = response.data[0].nombre;
              this.prod_ind_vida_edad = response.data[0].edad;
              this.prod_ind_vida_sexo = response.data[0].sexo;
              this.prod_ind_vida_fecha_nacimiento = this.formatoFecha(
                response.data[0].fecha_nacimiento
              );
              this.prod_ind_vida_cesion = response.data[0].cesion;
              this.prod_ind_vida_monto_cesion = response.data[0].monto_cesion_v;
              this.prod_ind_vida_beneficiario_cesion =
                response.data[0].beneficiario_cesion_v;
              this.prod_ind_vida_temporal = response.data[0].temporal;
              this.prod_ind_vida_numero_años = response.data[0].numero_años;
              break;
            case "GASTOS MEDICOS":
              this.prod_ind_gastomedico_id_individual_gasto_medico =
                response.data[0].id_individual_gasto_medico;
              this.prod_ind_gastomedico_id_indiv =
                response.data[0].id_individuales;
              this.prod_ind_gastomedico_nombre = response.data[0].nombre_gm;
              this.prod_ind_gastomedico_edad = response.data[0].edad_gm;
              this.prod_ind_gastomedico_sexo = response.data[0].sexo_gm;
              this.prod_ind_gastomedico_fecha_nacimiento = this.formatoFecha(
                response.data[0].fecha_nacimiento_gm
              );
              this.prod_ind_gastomedico_max_vit_contratado = this.formatoMoneda(
                response.data[0].max_vit_contratado
              );
              this.prod_ind_gastomedico_max_vit_vigente = this.formatoMoneda(
                response.data[0].max_vit_vigente
              );
              this.prod_ind_gastomedico_cobertura = response.data[0].cobertura;
              break;
            case "RESIDENCIA":
              this.items_prod_ind_residencia = [];
              response.data.forEach((element) => {
                this.items_prod_ind_residencia.push({
                  id_individual_residencial: element.id_individual_residencial,
                  id_individuales: element.id_individuales,
                  n_riesgo: element.n_riesgo,
                  direccion: element.direccion,
                  sum_asegurada_construcciones:
                    element.sum_asegurada_construcciones,
                  sum_asegurada_contenido: element.sum_asegurada_contenido,
                  cesion: element.cesion,
                  monto_cesion: element.monto_cesion_r,
                  beneficiario_cesion: element.beneficiario_cesion_r,
                  plan_contratado: element.plan_contratado,
                  total_suma_asegurada: element.total_suma_asegurada,
                });
              });
              this.prod_ind_residencia_id_individuales =
                response.data[0].id_individuales;
              break;
            case "INCENDIO":
              this.items_prod_ind_incendio = [];
              this.items_prod_ind_incendio_excluidos = [];
              response.data.forEach((element) => {
                if (element.excluido_i) {
                  this.items_prod_ind_incendio_excluidos.push({
                    id_individual_incendio: element.id_individual_incendio,
                    id_individuales: element.id_individuales,
                    excluido: element.excluido_i,
                    n_riesgo: element.n_riesgo_i,
                    direccion: element.direccion_i,
                    sum_aseg_construcciones:
                      element.sum_asegurada_construcciones_i,
                    sum_aseg_contenido: element.sum_asegurada_contenido_i,
                    sum_aseg_existencias: element.sum_asegurada_existencia,
                    intr_neg_lucr_ces_perd_unid:
                      element.intr_neg_lucr_ces_perd_unid,
                    cesion: element.cesion,
                    riesgos_cubierto: element.riesgos_cubierto,
                    cobertura_compl: element.cobertura_compl,
                    total_suma_asegurada: element.total_suma_asegurada_i,
                    monto_cesion: element.monto_cesion_i,
                    beneficiario_cesion: element.beneficiario_cesion_i,
                    excluir_contrucciones: element.excluir_contrucciones_i,
                    excluir_contenido: element.excluir_contenido_i,
                    excluir_existencias: element.excluir_existencias_i,
                    motivo_construcciones: element.motivo_construcciones_i,
                    motivo_contenido: element.motivo_contenido_i,
                    motivo_existencias: element.motivo_existencias_i,
                  });
                } else {
                  this.items_prod_ind_incendio.push({
                    id_individual_incendio: element.id_individual_incendio,
                    id_individuales: element.id_individuales,
                    excluido: element.excluido_i,
                    n_riesgo: element.n_riesgo_i,
                    direccion: element.direccion_i,
                    sum_aseg_construcciones:
                      element.sum_asegurada_construcciones_i,
                    sum_aseg_contenido: element.sum_asegurada_contenido_i,
                    sum_aseg_existencias: element.sum_asegurada_existencia,
                    intr_neg_lucr_ces_perd_unid:
                      element.intr_neg_lucr_ces_perd_unid,
                    cesion: element.cesion,
                    riesgos_cubierto: element.riesgos_cubierto,
                    cobertura_compl: element.cobertura_compl,
                    total_suma_asegurada: element.total_suma_asegurada_i,
                    monto_cesion: element.monto_cesion_i,
                    beneficiario_cesion: element.beneficiario_cesion_i,
                    excluir_contrucciones: element.excluir_contrucciones_i,
                    excluir_contenido: element.excluir_contenido_i,
                    excluir_existencias: element.excluir_existencias_i,
                    motivo_construcciones: element.motivo_construcciones_i,
                    motivo_contenido: element.motivo_contenido_i,
                    motivo_existencias: element.motivo_existencias_i,
                  });
                }
              });
              this.prod_ind_incendio_id_individuales =
                response.data[0].id_individuales;
              break;
            case "FIDELIDAD":
              this.items_prod_ind_fidelidad = [];
              response.data.forEach((element) => {
                this.items_prod_ind_fidelidad.push({
                  id_individual_fidelidad: element.id_individual_fidelidad,
                  id_individuales: element.id_individuales,
                  n_riesgo: element.n_riesgo_f,
                  direccion: element.direccion_f,
                  nombre_empleado: element.nombres_empleados,
                  puesto_afianzado: element.puestos_afianzados,
                });
              });
              this.prod_ind_fidelidad_id_individuales =
                response.data[0].id_individuales;
              break;
            case "DINERO Y VALORES":
              this.items_prod_ind_dinero_valores = [];
              response.data.forEach((element) => {
                this.items_prod_ind_dinero_valores.push({
                  id_individual_dinero_valores:
                    element.id_individual_dinero_valores,
                  id_individuales: element.id_individuales,
                  n_riesgo: element.n_riesgo_dv,
                  direccion: element.direccion_dv,
                });
              });
              this.prod_ind_dinero_valores_id_individuales =
                response.data[0].id_individuales;
              break;
            case "ROBO HURTO":
              this.items_prod_ind_robo_hurto = [];
              response.data.forEach((element) => {
                this.items_prod_ind_robo_hurto.push({
                  id_individual_robo_hurto: element.id_individual_robo_hurto,
                  id_individuales: element.id_individuales,
                  n_riesgo: element.n_riesgo_rh,
                  direccion: element.direccion_rh,
                });
              });
              this.prod_ind_robo_hurto_id_individuales =
                response.data[0].id_individuales;
              break;
            case "TRANSPORTE INTERNO":
              this.prod_ind_id_transporte_interno =
                response.data[0].id_individual_transporte_interno;
              this.prod_ind_transporte_interno_id_individuales =
                response.data[0].id_individuales;
              this.prod_ind_transporte_interno_direccion =
                response.data[0].direccion_ti;
              this.prod_ind_transporte_interno_tipo_mercaderia =
                response.data[0].tipo_mercaderia;
              this.prod_ind_transporte_interno_guardia_seguridad =
                response.data[0].guardia_seguridad;
              break;
            case "TRANSPORTE MERCADERIA":
              this.items_prod_ind_transporte_mercaderia = [];
              response.data.forEach((element) => {
                this.items_prod_ind_transporte_mercaderia.push({
                  id_individual_transporte_mercaderia:
                    element.id_individual_transporte_mercaderia,
                  id_individuales: element.id_individuales,
                  num_trans_merca: element.num_trans_merca_tm,
                  asegurado: element.asegurado_tm,
                  valor_factura: element.valor_factura_tm,
                  flete: element.flete_tm,
                  sub_total: element.sub_total_tm,
                  porcentaje_adicional: element.porcentaje_adicional_tm,
                  total_suma_asegurada: element.total_suma_asegurada_tm,
                  tipo_mercaderia: element.tipo_mecaderia_tm,
                  proveedor_cliente: element.proveedor_cliente_tm,
                  maritimo: element.maritimo_tm,
                  aereo: element.aereo_tm,
                  terrestre: element.terrestre_tm,
                  nombre_vapor: element.nombre_vapor_tm,
                  procedente_de: element.procedente_de_tm,
                  destino_a: element.destino_a_tm,
                  num_factura: element.num_factura_tm,
                  fecha_factura: element.fecha_factura_tm,
                  fecha_despacho_merca_proveedor:
                    element.fecha_despacho_merca_proveedor_tm,
                  vigencia_certificado_desde:
                    element.vigencia_certificado_desde_tm,
                  vigencia_certificado_hasta:
                    element.vigencia_certificado_hasta_tm,
                });
              });
              break;
            case "RESPONSABILIDAD CIVIL":
              this.items_prod_ind_respon_civil = [];
              response.data.forEach((element) => {
                this.items_prod_ind_respon_civil.push({
                  id_individual_responsabilidad_civil:
                    element.id_individual_responsabilidad_civil,
                  id_individuales: element.id_individuales,
                  n_riesgo: element.n_riesgo_rc,
                  direccion: element.direccion_rc,
                });
              });
              this.prod_ind_responsabilidad_civil_id_individuales =
                response.data[0].id_individuales;
              break;
            case "EQUIPOS ELECTRONICOS":
              this.items_prod_ind_equipo_electronico = [];
              response.data.forEach((element) => {
                this.items_prod_ind_equipo_electronico.push({
                  id_individual_equipo_eletronico:
                    element.id_individual_equipo_eletronico,
                  id_individuales: element.id_individuales,
                  n_riesgo: element.n_riesgo_ee,
                  direccion: element.direccion_ee,
                  suma_asegurada_equipo: element.suma_asegurada_equipo_ee,
                  cesion: element.cesion_ee,
                });
              });
            case "INCAPACIDAD TOTAL Y PERMANENTE":
              this.id_prod_ind_inc_total_perma =
                response.data[0].id_incapacidad_total_permanente;
              this.prod_ind_inc_total_perma_id_individuales =
                response.data[0].id_individuales;
              this.prod_ind_inc_total_perma_desme_acci =
                response.data[0].desmembramiento_accidental__itp;
              this.prod_ind_inc_total_perma_enfer_graves =
                response.data[0].enfermedades_graves__itp;
              this.prod_ind_inc_total_perma_muerte_acci =
                response.data[0].muerte_accidental__itp;
              this.prod_ind_inc_total_perma_suma_aseg =
                response.data[0].suma_asegurada_itp;
              this.prod_ind_inc_total_perma_otros = response.data[0].otros_itp;
              break;
            case "SEGURO DE VIAJES":
              this.items_prod_ind_seguro_viaje = [];
              response.data.forEach((element) => {
                this.items_prod_ind_seguro_viaje.push({
                  id_seguro_viaje: element.id_seguro_viaje,
                  id_individuales: element.id_individuales,
                  nombre: element.nombre_sv,
                  sexo: element.sexo_sv,
                  fecha_nacimiento: element.fecha_nacimiento_sv,
                  edad: element.edad_sv,
                  numero_dias: element.numero_dias_sv,
                });
              });
              break;
            case "TODO RIESGO CONSTRUCCIÓN":
              this.items_prod_ind_riesgo_constru = [];
              response.data.forEach((element) => {
                this.items_prod_ind_riesgo_constru.push({
                  id_riesgo_construcciones: element.id_riesgo_construcciones,
                  id_individuales: element.id_individuales,
                  n_riesgo: element.n_riesgo_trc,
                  direccion: element.direccion_trc,
                  sum_aseg_construcciones: element.sum_aseg_construcciones_trc,
                  sum_aseg_contenido: element.sum_aseg_contenido_trc,
                  sum_aseg_existencias: element.sum_aseg_existencias_trc,
                  intr_neg_lucr_ces_perd_unid:
                    element.intr_neg_lucr_ces_perd_unid_trc,
                  cesion: element.cesion_trc,
                });
              });
              this.prod_ind_riesgo_constru_id_individuales =
                response.data[0].id_individuales;
              break;
            case "TIPO DE FIANZAS":
              this.id_prod_ind_fianza = response.data[0].id_tipo_fianza;
              this.prod_ind_fianza_id_individuales =
                response.data[0].id_individuales;
              this.prod_ind_fianza_tipo_fianza =
                response.data[0].tipo_fianza_tf;
              this.prod_ind_fianza_otras = response.data[0].otros_tf;
              this.prod_ind_fianza_especificar =
                response.data[0].especificar_tf;
              break;
          }
        });
    },
    async listarProdIndIncendioCobertura() {
      this.items_prod_ind_coberturas = await (
        await axios.get(
          "api/IndividualIncendioCoberturas/Listar/" + this.id_poliza
        )
      ).data;
    },
    async listarProdIndIncendioRiesos() {
      this.items_prod_ind_riesgos = await (
        await axios.get(
          "api/IndividualIncendioRiesgos/Listar/" + this.id_poliza
        )
      ).data;
    },
    eliminarItemIndIncendioRiesgo(item) {
      //console.log(item);
      axios
        .delete("api/IndividualIncendioRiesgos/Eliminar/" + item.id_riesgo)
        .then(() => {
          this.alerta("success", "Resgistro eliminado");
          this.listarProdIndIncendioRiesos();
        })
        .catch(() => {
          this.alerta("error", "No se pudo eliminar");
        });
    },
    eliminarItemIndIncendioCobertura(item) {
      //console.log(item);
      axios
        .delete(
          "api/IndividualIncendioCoberturas/Eliminar/" + item.id_cobertura
        )
        .then(() => {
          this.alerta("success", "Resgitro eliminado");
          this.listarProdIndIncendioCobertura();
        })
        .catch(() => {
          this.alerta("error", "No se pudo eliminar");
        });
    },
    crearProdIndividual(id_poliza, estado) {
      switch (this.ramo_seguro.descripcion) {
        case "AUTOS":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              auto: [
                {
                  marca: this.prod_ind_auto_marca,
                  modelo: this.prod_ind_auto_modelo,
                  año: this.prod_ind_auto_año,
                  placa: this.prod_ind_auto_placa,
                  chasis: this.prod_ind_auto_chasis,
                  motor: this.prod_ind_auto_motor,
                  version: this.prod_ind_auto_version,
                  cesion: this.prod_ind_auto_cesion,
                  monto_cesion: this.prod_ind_auto_monto_cesion,
                  beneficiario_cesion: this.prod_ind_auto_monto_cesion,
                  tipo_vehiculo: this.prod_ind_auto_tipo_vehiculo,
                  vencimiento_tarjeta: this.prod_ind_auto_vencimiento_tarjeta,
                  vencimiento_dui: this.prod_ind_auto_vencimiento_dui,
                  vencimiento_licencia: this.prod_ind_auto_vencimiento_licencia
                },
              ],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then(() => {
              this.limpiarProductoIndividualAuto();
            });
          break;
        case "VIDA":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              vida: [
                {
                  nombre: this.prod_ind_vida_nombre,
                  edad: this.prod_ind_vida_edad,
                  sexo: this.prod_ind_vida_sexo,
                  fecha_nacimiento: this.prod_ind_vida_fecha_nacimiento,
                  cesion: this.prod_ind_vida_cesion,
                  monto_cesion: this.prod_ind_vida_monto_cesion,
                  beneficiario_cesion: this.prod_ind_vida_beneficiario_cesion,
                  temporal: this.prod_ind_vida_temporal,
                  numero_años: this.prod_ind_vida_numero_años,
                  items_beneficiarios: this.items_individual_vida_beneficiarios,
                },
              ],
              auto: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then(() => {
              this.limpiarProductoIndividualVida();
            });
          break;
        case "GASTOS MEDICOS":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              auto: [],
              vida: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then((response) => {
              this.crearGastoMedico(response.data);
            });
          break;
        case "RESIDENCIA":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then((response) => {
              this.agregarProdIndResidencia(response.data, estado);
              this.limpiarProductoIndividualResidencia();
            });
          break;
        case "INCENDIO":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then((response) => {
              this.crearIncendio(response.data, estado);
            });
          break;
        case "FIDELIDAD":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then((response) => {
              this.crearProdIndFidelidad(response.data, estado);
            });
          break;
        case "DINERO Y VALORES":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              dinero_valores: [
                {
                  n_riesgo: this.prod_ind_dinero_valores_n_riesgo,
                  direccion: this.prod_ind_dinero_valores_direccion,
                },
              ],
              auto: [],
              vida: [],
              gasto_medico: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then(() => {
              this.limpiarProductoIndividualDineroValores();
            });
          break;
        case "ROBO HURTO":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              robo_hurto: [
                {
                  n_riesgo: this.prod_ind_robo_hurto_n_riesgo,
                  direccion: this.prod_ind_robo_hurto_direccion,
                },
              ],
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then(() => {
              this.limpiarProductoIndividualRoboHurto();
            });
          break;
        case "TRANSPORTE INTERNO":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              transporte_interno: [
                {
                  direccion: this.prod_ind_transporte_interno_direccion,
                  tipo_mercaderia:
                    this.prod_ind_transporte_interno_tipo_mercaderia,
                  guardia_seguridad:
                    this.prod_ind_transporte_interno_guardia_seguridad,
                },
              ],
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then(() => {
              this.limpiarProductoIndividualTransporteInterno();
            });
          break;
        case "TRANSPORTE MERCADERIA":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then((response) => {
              this.crearTransporteMercaderia(response.data, estado);
              this.limpiarProductoIndividualTransporteMercaderia();
            });
          break;
        case "RESPONSABILIDAD CIVIL":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              responsabilidad_civil: [
                {
                  n_riesgo: this.prod_ind_responsabilidad_civil_n_riesgo,
                  direccion: this.prod_ind_responsabilidad_civil_direccion,
                },
              ],
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              transporte_mercaderia: [],
              tipo_fianza: [],
            })
            .then(() => {
              this.limpiarProductoIndividualResponsabilidadCivil();
            });
          break;
        case "EQUIPOS ELECTRONICOS":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then((response) => {
              this.crearEquipoElectronico(response.data, estado);
            });
          break;
        case "INCAPACIDAD TOTAL Y PERMANENTE":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then((response) => {
              this.crearIncapacidadTotalPermanente(response.data, estado);
            });
          break;
        case "SEGURO DE VIAJES":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then((response) => {
              this.crearSeguroViajes(response.data, estado);
            });
        case "TODO RIESGO CONSTRUCCIÓN":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [],
            })
            .then((response) => {
              this.crearRiesgoConstrucciones(response.data, estado);
            });
          break;
        case "TIPO DE FIANZAS":
          axios
            .post("api/Individuales/Crear", {
              id_polizas: id_poliza,
              auto: [],
              vida: [],
              gasto_medico: [],
              dinero_valores: [],
              robo_hurto: [],
              transporte_interno: [],
              responsabilidad_civil: [],
              tipo_fianza: [
                {
                  tipo_fianza: this.prod_ind_fianza_tipo_fianza,
                  otros: this.prod_ind_fianza_otras,
                  especificar: this.prod_ind_fianza_especificar,
                },
              ],
            })
            .then((response) => {
              this.cerrarDialog();
              this.listar();
              this.limpiarIndividualTipoFianza();
            });
          break;
      }
    },
    crearRiesgoConstrucciones(id_individuales, estado) {
      if (estado == "nuevo") {
        //SI YA EXISTE LA POLIZA
        axios
          .post("api/IndividualRiesgoConstrucciones/Crear", {
            id_individuales: id_individuales,
            n_riesgo: this.prod_ind_incendio_n_riesgo,
            direccion: this.prod_ind_incendio_direccion,
            sum_aseg_contenido: this.prod_ind_incendio_sum_aseg_contenido,
            sum_aseg_construcciones:
              this.prod_ind_incendio_sum_aseg_construcciones,
            sum_aseg_existencias: this.prod_ind_incendio_sum_aseg_existencias,
            total_suma_asegurada: this.prod_ind_incendio_total_sum_aseg,
            intr_neg_lucr_ces_perd_unid:
              this.prod_ind_incendio_intr_neg_lucr_ces_perd_unid,
            cesion: this.prod_ind_incendio_cesion,
            items_riesgos: this.items_prod_ind_riesgos,
            items_coberturas: this.items_prod_ind_coberturas,
          })
          .then(() => {
            this.cerrarDialog();
            this.listar();
            this.limpiarProductoIndividualRiesgoConstrucciones();
          });
      } else if (estado == "_nuevo") {
        //EN CASO DE CREAR NUEVA POLIZA
        if (this.items_prod_ind_riesgo_constru.length > 0) {
          this.items_prod_ind_riesgo_constru.forEach((element) => {
            axios.post("api/IndividualRiesgoConstrucciones/Crear", {
              id_individuales: id_individuales,
              n_riesgo: element.n_riesgo,
              direccion: element.direccion,
              sum_aseg_contenido: element.sum_aseg_contenido,
              sum_aseg_construcciones: element.sum_aseg_construcciones,
              sum_aseg_existencias: element.sum_aseg_existencias,
              intr_neg_lucr_ces_perd_unid: element.intr_neg_lucr_ces_perd_unid,
              cesion: element.cesion,
              items_riesgos: element.riesgos,
              items_coberturas: element.coberturas,
            });
          });
          this.cerrarDialog();
          this.listar();
          this.limpiarProductoIndividualRiesgoConstrucciones();
          return;
        }
      }
    },
    crearSeguroViajes(id_individuales, estado) {
      if (estado == "nuevo") {
        //SI YA EXISTE LA POLIZA
        axios
          .post("api/IndividualSeguroViajes/Crear", {
            id_individuales: id_individuales,
            nombre: this.prod_ind_seguro_viaje_nombre,
            sexo: this.prod_ind_seguro_viaje_sexo,
            fecha_nacimiento: this.prod_ind_seguro_viaje_fecha_nacimiento,
            edad: this.prod_ind_seguro_viaje_edad,
            numero_dias: this.prod_ind_seguro_viaje_numero_dias,
          })
          .then(() => {
            this.cerrarDialog();
            this.listar();
            this.limpiarProductoIndividualSeguroViajes();
          });
      } else if (estado == "_nuevo") {
        //EN CASO DE CREAR NUEVA POLIZA
        if (this.items_prod_ind_seguro_viaje.length > 0) {
          this.items_prod_ind_seguro_viaje.forEach((element) => {
            axios.post("api/IndividualSeguroViajes/Crear", {
              id_individuales: id_individuales,
              nombre: element.nombre,
              sexo: element.sexo,
              fecha_nacimiento: element.fecha_nacimiento,
              edad: element.edad,
              numero_dias: element.numero_dias,
            });
          });
          this.cerrarDialog();
          this.listar();
          this.limpiarProductoIndividualSeguroViajes();
          return;
        }
      }
    },
    actualizarProdIndSeguroViaje() {
      axios
        .put("api/IndividualSeguroViajes/Actualizar", {
          id_seguro_viaje: this.id_prod_ind_seguro_viaje,
          id_individuales: this.prod_ind_seguro_viaje_id_individuales,
          nombre: this.prod_ind_seguro_viaje_nombre,
          sexo: this.prod_ind_seguro_viaje_sexo,
          fecha_nacimiento: this.prod_ind_seguro_viaje_fecha_nacimiento,
          edad: this.prod_ind_seguro_viaje_edad,
          numero_dias: this.prod_ind_seguro_viaje_numero_dias,
        })
        .then(() => {
          this.alerta("success", "Registro actualizado.");
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
          this.limpiarProductoIndividualSeguroViajes();
        });
    },
    crearIncapacidadTotalPermanente(id_individuales, estado) {
      //SI YA EXISTE LA POLIZA
      axios
        .post("api/IndividualIncapacidadTotalPermanentes/Crear", {
          id_individuales: id_individuales,
          desmembramiento_accidental: this.prod_ind_inc_total_perma_desme_acci,
          enfermedades_graves: this.prod_ind_inc_total_perma_enfer_graves,
          muerte_accidental: this.prod_ind_inc_total_perma_muerte_acci,
          suma_asegurada: this.prod_ind_inc_total_perma_suma_aseg,
          otros: this.prod_ind_inc_total_perma_otros,
        })
        .then(() => {
          this.cerrarDialog();
          this.listar();
        });
    },
    crearEquipoElectronico(id_individuales, estado) {
      if (estado == "nuevo") {
        //SI YA EXISTE LA POLIZA
        axios
          .post("api/IndividualEquipoElectronicos/Crear", {
            id_individuales: id_individuales,
            n_riesgo: this.prod_ind_equipo_electronico_n_riesgo,
            direccion: this.prod_ind_equipo_electronico_direccion,
            cesion: this.prod_ind_equipo_electronico_cesion,
            suma_asegurada_equipo:
              this.prod_ind_equipo_electronico_suma_asegurada,
            riesgos: this.items_prod_ind_equipo_electronico_riesgos,
            coberturas: this.items_prod_ind_equipo_electronico_coberturas,
          })
          .then(() => {
            this.cerrarDialog();
            this.listar();
            this.limpiarProductoIndividualEquipoElectronico();
          });
      } else if (estado == "_nuevo") {
        //EN CASO DE CREAR NUEVA POLIZA
        if (this.items_prod_ind_equipo_electronico.length > 0) {
          this.items_prod_ind_equipo_electronico.forEach((element) => {
            axios.post("api/IndividualEquipoElectronicos/Crear", {
              id_individuales: id_individuales,
              n_riesgo: element.n_riesgo,
              direccion: element.direccion,
              cesion: element.cesion,
              suma_asegurada_equipo: element.suma_asegurada_equipo,
              riesgos: element.riesgos,
              coberturas: element.coberturas,
            });
          });
          this.cerrarDialog();
          this.listar();
          this.limpiarProductoIndividualEquipoElectronico();
          return;
        }
      }
    },
    actualizarProdEquipoElectronico() {
      axios
        .put("api/IndividualEquipoElectronicos/Actualizar", {
          id_individual_equipo_eletronico: this.id_individual_equipo_eletronico,
          id_individuales: this.prod_ind_equipo_electronico_id_individuales,
          n_riesgo: this.prod_ind_equipo_electronico_n_riesgo,
          direccion: this.prod_ind_equipo_electronico_direccion,
          cesion: this.prod_ind_equipo_electronico_cesion,
          suma_asegurada_equipo:
            this.prod_ind_equipo_electronico_suma_asegurada,
          riesgos: this.items_prod_ind_equipo_electronico_riesgos,
          coberturas: this.items_prod_ind_equipo_electronico_coberturas,
        })
        .then(() => {
          this.alerta("success", "Datos actualizados");
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);

          this.id_individual_equipo_eletronico = 0;
          this.prod_ind_equipo_electronico_id_individuales = 0;
          this.prod_ind_equipo_electronico_n_riesgo = null;
          this.prod_ind_equipo_electronico_direccion = null;
          this.prod_ind_equipo_electronico_suma_asegurada = 0;
          this.prod_ind_equipo_electronico_cesion = null;
          this.items_prod_ind_equipo_electronico_riesgos = [];
          this.items_prod_ind_equipo_electronico_coberturas = [];
        })
        .catch(() => {
          this.alerta("error", "No se pudo actualizar");
        });
    },
    crearGastoMedico(id_invididuales) {
      axios
        .post("api/IndividualGastoMedicos/Crear", {
          id_individuales: id_invididuales,
          nombre: this.prod_ind_gastomedico_nombre,
          edad: this.prod_ind_gastomedico_edad,
          sexo: this.prod_ind_gastomedico_sexo,
          fecha_nacimiento: this.prod_ind_gastomedico_fecha_nacimiento,
          max_vit_contratado: this.prod_ind_gastomedico_max_vit_contratado,
          max_vit_vigente: this.prod_ind_gastomedico_max_vit_vigente,
          cobertura: this.prod_ind_gastomedico_cobertura,
          dependientes: this.items_prod_ind_depen_gastomedico,
        })
        .then(() => {
          this.cerrarDialog();
          this.listar();
          this.limpiarProductoIndividualIncendio();
        });
    },
    crearTransporteMercaderia(id_individuales, estado) {
      if (estado == "nuevo") {
        axios
          .post("api/IndividualTransporteMercaderias/Crear", {
            id_individuales: id_individuales,
            num_trans_merca:
              this.prod_ind_transporte_mercaderia_num_trans_merca,
            asegurado: this.prod_ind_transporte_mercaderia_asegurado,
            valor_factura: this.prod_ind_transporte_mercaderia_valor_factura,
            flete: this.prod_ind_transporte_mercaderia_flete,
            sub_total: this.prod_ind_transporte_mercaderia_sub_total,
            porcentaje_adicional:
              this.prod_ind_transporte_mercaderia_porcentaje_adicional,
            total_suma_asegurada:
              this.prod_ind_transporte_mercaderia_total_suma_asegurada,
            tipo_mecaderia: this.prod_ind_transporte_mercaderia_tipo_mercaderia,
            proveedor_cliente:
              this.prod_ind_transporte_mercaderia_proveedor_cliente,
            maritimo: this.prod_ind_transporte_mercaderia_maritimo,
            aereo: this.prod_ind_transporte_mercaderia_aereo,
            terrestre: this.prod_ind_transporte_mercaderia_terrestre,
            nombre_vapor: this.prod_ind_transporte_mercaderia_nombre_vapor,
            procedente_de: this.prod_ind_transporte_mercaderia_procedente_de,
            destino_a: this.prod_ind_transporte_mercaderia_destino_a,
            num_factura: this.prod_ind_transporte_mercaderia_num_factura,
            fecha_factura: this.prod_ind_transporte_mercaderia_fecha_factura,
            fecha_despacho_merca_proveedor:
              this.prod_ind_transporte_mercaderia_fecha_despacho,
            vigencia_certificado_desde:
              this.prod_ind_transporte_mercaderia_vigencia_desde,
            vigencia_certificado_hasta:
              this.prod_ind_transporte_mercaderia_vigencia_hasta,
          })
          .then(() => {
            this.cerrarDialog();
            this.listar();
            this.limpiarProductoIndividualTransporteMercaderia();
          });
      } else if (estado == "_nuevo") {
        if (this.items_prod_ind_transporte_mercaderia.length > 0) {
          this.items_prod_ind_transporte_mercaderia.forEach((element) => {
            axios.post("api/IndividualTransporteMercaderias/Crear", {
              id_individuales: id_individuales,
              num_trans_merca: element.num_trans_merca,
              asegurado: element.asegurado,
              valor_factura: element.valor_factura,
              flete: element.flete,
              sub_total: element.sub_total,
              porcentaje_adicional: element.porcentaje_adicional,
              total_suma_asegurada: element.total_suma_asegurada,
              tipo_mercaderia: element.tipo_mercaderia,
              proveedor_cliente: element.proveedor_cliente,
              maritimo: element.maritimo,
              aereo: element.aereo,
              terrestre: element.terrestre,
              nombre_vapor: element.nombre_vapor,
              procedente_de: element.procedente_de,
              destino_a: element.destino_a,
              num_factura: element.num_factura,
              fecha_factura: element.fecha_factura,
              fecha_despacho_merca_proveedor:
                element.fecha_despacho_merca_proveedor,
              vigencia_certificado_desde: element.vigencia_certificado_desde,
              vigencia_certificado_hasta: element.vigencia_certificado_hasta,
            });
          });
          this.cerrarDialog();
          this.listar();
          this.limpiarProductoIndividualTransporteMercaderia();
        }
      }
    },
    actualizarProdIndividual() {
      switch (this.ramo_seguro.descripcion) {
        case "AUTOS":
          axios
            .put("api/IndividualAutos/Actualizar", {
              id_individual_auto: this.prod_ind_auto_id_indiv_auto,
              id_individuales: this.prod_ind_auto_id_indiv,
              marca: this.prod_ind_auto_marca,
              modelo: this.prod_ind_auto_modelo,
              año: this.prod_ind_auto_año,
              placa: this.prod_ind_auto_placa,
              chasis: this.prod_ind_auto_chasis,
              motor: this.prod_ind_auto_motor,
              version: this.prod_ind_auto_version,
              cesion: this.prod_ind_auto_cesion,
              monto_cesion: this.prod_ind_auto_monto_cesion,
              beneficiario_cesion: this.prod_ind_auto_beneficiario_cesion,
              tipo_vehiculo: this.prod_ind_auto_tipo_vehiculo,
              vencimiento_tarjeta: this.prod_ind_auto_vencimiento_tarjeta,
              vencimiento_dui: this.prod_ind_auto_vencimiento_dui,
              vencimiento_licencia: this.prod_ind_auto_vencimiento_licencia
            })
            .then(() => {
              this.alerta("success", "Caracteristicas actualizados");
            })
            .catch(() => {
              this.alerta("error", "No se pudo actualizar las caracteristicas");
            });
          break;
        case "VIDA":
          axios
            .put("api/IndividualVidas/Actualizar", {
              id_individual_vida: this.prod_ind_vida_id_indiv_vida,
              id_individuales: this.prod_ind_vida_id_indiv,
              nombre: this.prod_ind_vida_nombre,
              edad: this.prod_ind_vida_edad,
              sexo: this.prod_ind_vida_sexo,
              fecha_nacimiento: this.prod_ind_vida_fecha_nacimiento,
              cesion: this.prod_ind_vida_cesion,
              monto_cesion: this.prod_ind_vida_monto_cesion,
              beneficiario_cesion: this.prod_ind_vida_beneficiario_cesion,
              temporal: this.prod_ind_vida_temporal,
              numero_años: this.prod_ind_vida_numero_años,
            })
            .then(() => {
              this.alerta("success", "Caracteristicas actualizados");
            })
            .catch(() => {
              this.alerta("error", "No se pudo actualizar las caracteristicas");
            });
          break;
        case "GASTOS MEDICOS":
          axios
            .put("api/IndividualGastoMedicos/Actualizar", {
              id_individual_gasto_medico:
                this.prod_ind_gastomedico_id_individual_gasto_medico,
              id_individuales: this.prod_ind_gastomedico_id_indiv,
              nombre: this.prod_ind_gastomedico_nombre,
              edad: this.prod_ind_gastomedico_edad,
              sexo: this.prod_ind_gastomedico_sexo,
              fecha_nacimiento: this.prod_ind_gastomedico_fecha_nacimiento,
              max_vit_contratado: this.prod_ind_gastomedico_max_vit_contratado,
              max_vit_vigente: this.prod_ind_gastomedico_max_vit_vigente,
              cobertura: this.prod_ind_gastomedico_cobertura,
            })
            .then(() => {
              this.limpiarProductoIndividualGastoMedico();
            });
          break;
        case "DINERO Y VALORES":
          axios
            .put("api/IndividualDineroValores/Actualizar", {
              id_individual_dinero_valores: this.prod_ind_id_dinero_valores,
              id_individuales: this.prod_ind_dinero_valores_id_individuales,
              n_riesgo: this.prod_ind_dinero_valores_n_riesgo,
              direccion: this.prod_ind_dinero_valores_direccion,
            })
            .then(() => {
              this.alerta("success", "Caracteristicas actualizados");
            })
            .catch(() => {
              this.alerta("error", "No se pudo actualizar las caracteristicas");
            });
          break;
        case "ROBO HURTO":
          axios
            .put("api/IndividualRoboHurtos/Actualizar", {
              id_individual_robo_hurto: this.prod_ind_id_robo_hurto,
              id_individuales: this.prod_ind_robo_hurto_id_individuales,
              n_riesgo: this.prod_ind_robo_hurto_n_riesgo,
              direccion: this.prod_ind_robo_hurto_direccion,
            })
            .then(() => {
              this.alerta("success", "Caracteristicas actualizados");
            })
            .catch(() => {
              this.alerta("error", "No se pudo actualizar las caracteristicas");
            });
          break;
        case "TRANSPORTE INTERNO":
          axios
            .put("api/IndividualTransporteInternos/Actualizar", {
              id_individual_transporte_interno:
                this.prod_ind_id_transporte_interno,
              id_individuales: this.prod_ind_transporte_interno_id_individuales,
              direccion: this.prod_ind_transporte_interno_direccion,
              tipo_mercaderia: this.prod_ind_transporte_interno_tipo_mercaderia,
              guardia_seguridad:
                this.prod_ind_transporte_interno_guardia_seguridad,
            })
            .then(() => {
              this.alerta("success", "Caracteristicas actualizados");
            })
            .catch(() => {
              this.alerta("error", "No se pudo actualizar las caracteristicas");
            });
          break;
        case "RESPONSABILIDAD CIVIL":
          axios
            .put("api/IndividualResponsabilidadCivil/Actualizar", {
              id_individual_responsabilidad_civil:
                this.prod_ind_id_responsabilidad_civil,
              id_individuales:
                this.prod_ind_responsabilidad_civil_id_individuales,
              n_riesgo: this.prod_ind_responsabilidad_civil_n_riesgo,
              direccion: this.prod_ind_responsabilidad_civil_direccion,
            })
            .then(() => {
              this.alerta("success", "Caracteristicas actualizados");
            })
            .catch(() => {
              this.alerta("error", "No se pudo actualizar las caracteristicas");
            });
          break;
        case "INCAPACIDAD TOTAL Y PERMANENTE":
          axios
            .put("api/IndividualIncapacidadTotalPermanentes/Actualizar", {
              id_incapacidad_total_permanente: this.id_prod_ind_inc_total_perma,
              id_individuales: this.prod_ind_inc_total_perma_id_individuales,
              desmembramiento_accidental:
                this.prod_ind_inc_total_perma_desme_acci,
              enfermedades_graves: this.prod_ind_inc_total_perma_enfer_graves,
              muerte_accidental: this.prod_ind_inc_total_perma_muerte_acci,
              suma_asegurada: this.prod_ind_inc_total_perma_suma_aseg,
              otros: this.prod_ind_inc_total_perma_otros,
            })
            .then(() => {
              this.alerta("success", "Caracteristicas actualizados");
            })
            .catch(() => {
              this.alerta("error", "No se pudo actualizar las caracteristicas");
            });
          break;
        case "TIPO DE FIANZAS":
          axios
            .put("api/IndividualTipoFianzas/Actualizar", {
              id_tipo_fianza: this.id_prod_ind_fianza,
              id_individuales: this.prod_ind_fianza_id_individuales,
              tipo_fianza: this.prod_ind_fianza_tipo_fianza,
              otros: this.prod_ind_fianza_otras,
              especificar: this.prod_ind_fianza_especificar,
            })
            .then(() => {
              this.alerta("success", "Caracteristicas actualizados");
            })
            .catch(() => {
              this.alerta("error", "No se pudo actualizar las caracteristicas");
            });
          break;
      }
    },
    listarProdIndDepenGastoMedico() {
      axios
        .get(
          "api/IndividualDependientesGastoMedicos/Listar/" +
            this.prod_ind_gastomedico_id_individual_gasto_medico
        )
        .then((response) => {
          this.prod_ind_depen_dialog = true;
          this.items_prod_ind_depen_gastomedico = response.data;
        });

      axios
        .get(
          "api/IndividualDependientesGastoMedicos/ListarExcluidos/" +
            this.prod_ind_gastomedico_id_individual_gasto_medico
        )
        .then((response) => {
          this.prod_ind_depen_dialog = true;
          this.items_prod_ind_depen_gastomedico_excluidos = response.data;
        });
    },
    crearProdIndDepenGastoMedico() {
      axios
        .post("api/IndividualDependientesGastoMedicos/Crear", {
          id_individual_gasto_medico:
            this.prod_ind_gastomedico_id_individual_gasto_medico,
          nombre: this.prod_ind_depen_gastomedico_nombre,
          excluido: this.prod_ind_depen_gastomedico_excluido,
          edad: this.prod_ind_depen_gastomedico_edad,
          sexo: this.prod_ind_depen_gastomedico_sexo,
          fecha_nacimiento: this.prod_ind_depen_gastomedico_fecha_nacimiento,
          max_vit_contratado:
            this.prod_ind_depen_gastomedico_max_vit_contratado,
          max_vit_vigente: this.prod_ind_depen_gastomedico_max_vit_vigente,
          cobertura: this.prod_ind_depen_gastomedico_cobertura,
          parentesco: this.prod_ind_depen_gastomedico_parentesco,
          motivo_excluido: this.prod_ind_depen_gastomedico_motivo_excluido,
        })
        .then(() => {
          this.alerta("success", "Registro creado");
          this.limpiarProducoIndividualDepenGastoMedico();
          this.listarProdIndDepenGastoMedico();
        })
        .catch(() => {
          console.log("Error");
        });
    },
    actualizarProdIndDepenGastoMedico() {
      axios
        .put("api/IndividualDependientesGastoMedicos/Actualizar", {
          id_individual_dependiente_gasto_medico:
            this.prod_ind_depen_id_depen_gastomedico,
          id_individual_gasto_medico: this.prod_ind_depen_id_gastomedico,
          nombre: this.prod_ind_depen_gastomedico_nombre,
          excluido: this.prod_ind_depen_gastomedico_excluido,
          edad: this.prod_ind_depen_gastomedico_edad,
          sexo: this.prod_ind_depen_gastomedico_sexo,
          parentesco: this.prod_ind_depen_gastomedico_parentesco,
          fecha_nacimiento: this.prod_ind_depen_gastomedico_fecha_nacimiento,
          max_vit_contratado:
            this.prod_ind_depen_gastomedico_max_vit_contratado,
          max_vit_vigente: this.prod_ind_depen_gastomedico_max_vit_vigente,
          cobertura: this.prod_ind_depen_gastomedico_cobertura,
          motivo_excluido: this.prod_ind_depen_gastomedico_motivo_excluido,
        })
        .then(() => {
          this.alerta("success", "Registro actualizado");
          this.prod_ind_depen_bandera_editar = 0;
          this.limpiarProducoIndividualDepenGastoMedico();
          this.listarProdIndDepenGastoMedico();
        });
    },
    editarProduIndDepenGastoMedico(item) {
      this.prod_ind_depen_id_depen_gastomedico =
        item.id_individual_dependiente_gasto_medico;
      this.prod_ind_depen_id_gastomedico = item.id_individual_gasto_medico;
      this.prod_ind_depen_gastomedico_nombre = item.nombre;
      this.prod_ind_depen_gastomedico_excluido = item.excluido;
      this.prod_ind_depen_gastomedico_edad = item.edad;
      this.prod_ind_depen_gastomedico_sexo = item.sexo;
      this.prod_ind_depen_gastomedico_parentesco = item.parentesco;
      this.prod_ind_depen_gastomedico_fecha_nacimiento = this.formatoFecha(
        item.fecha_nacimiento
      );
      this.prod_ind_depen_gastomedico_max_vit_contratado =
        item.max_vit_contratado;
      this.prod_ind_depen_gastomedico_max_vit_vigente = item.max_vit_vigente;
      this.prod_ind_depen_gastomedico_cobertura = item.cobertura;
      this.prod_ind_depen_gastomedico_motivo_excluido = item.motivo_excluido;
      this.prod_ind_depen_bandera_editar = 1;
    },
    confirmarEliminarProdIndDepenGastoMedico(item) {
      this.dialog_eliminar_prod_ind_depen_gastomedico = true;
      this.prod_ind_depen_id_depen_gastomedico =
        item.id_individual_dependiente_gasto_medico;
    },
    eliminarProduIndDepenGastoMedico(item) {
      //console.log(item);
      axios
        .delete(
          "api/IndividualDependientesGastoMedicos/Eliminar/" +
            this.prod_ind_depen_id_depen_gastomedico
        )
        .then(() => {
          this.dialog_eliminar_prod_ind_depen_gastomedico = false;
          this.limpiarProducoIndividualDepenGastoMedico();
          this.listarProdIndDepenGastoMedico();
          this.alerta("success", "Registro eliminado correctamente");
        })
        .catch(() => {
          this.alerta("error", "No se pudo eliminar el registro");
        });
    },
    agregarProdIndResidencia(id_individuales, estado) {
      if (estado == "nuevo") {
        axios
          .post("api/IndividualResidencias/Crear", {
            id_individuales: id_individuales,
            n_riesgo: this.prod_ind_residencia_n_riesgo,
            direccion: this.prod_ind_residencia_direccion,
            sum_aseg_contenido: this.prod_ind_residencia_sum_aseg_contenido,
            sum_aseg_construcciones:
              this.prod_ind_residencia_sum_aseg_construcciones,
            cesion: this.prod_ind_residencia_cesion,
            monto_cesion: this.prod_ind_residencia_monto_cesion,
            beneficiario_cesion: this.prod_ind_residencia_beneficiario_cesion,
            plan_contratado: this.prod_ind_residencia_plan_contratado,
            total_suma_asegurada: this.prod_ind_residencia_total_suma_aseg,
          })
          .then(() => {
            this.alerta("success", "Registro creado.");
            this.limpiarProductoIndividualResidencia();
            this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
          })
          .catch(() => {
            this.alerta("error", "No se pudo guardar el registro.");
          });
      } else {
        if (this.items_prod_ind_residencia.length > 0) {
          this.items_prod_ind_riesgo_constru.forEach((element) => {
            axios.post("api/IndividualResidencias/Crear", {
              id_individuales: id_individuales,
              n_riesgo: element.n_riesgo,
              direccion: element.direccion,
              sum_aseg_contenido: element.sum_aseg_contenido,
              sum_aseg_construcciones: element.sum_aseg_construcciones,
              cesion: element.cesion,
              monto_cesion: this.prod_ind_residencia_monto_cesion,
              beneficiario_cesion: this.prod_ind_residencia_beneficiario_cesion,
              plan_contratado: element.plan_contratado,
            });
          });
          this.limpiarProductoIndividualResidencia();
          return;
        }
      }
    },
    actualizarProdIndResidencia() {
      axios
        .put("api/IndividualResidencias/Actualizar", {
          id_individual_residencia: this.prod_ind_id_residencia,
          id_individuales: this.prod_ind_residencia_id_individuales,
          n_riesgo: parseInt(this.prod_ind_residencia_n_riesgo),
          direccion: this.prod_ind_residencia_direccion,
          sum_aseg_contenido: parseInt(
            this.prod_ind_residencia_sum_aseg_contenido
          ),
          sum_aseg_construcciones: parseInt(
            this.prod_ind_residencia_sum_aseg_construcciones
          ),
          cesion: this.prod_ind_residencia_cesion,
          monto_cesion: this.prod_ind_residencia_monto_cesion,
          beneficiario_cesion: this.prod_ind_residencia_beneficiario_cesion,
          plan_contratado: this.prod_ind_residencia_plan_contratado,
          total_suma_asegurada: this.prod_ind_residencia_total_suma_aseg,
        })
        .then(() => {
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
          this.limpiarProductoIndividualResidencia();
        });
    },
    editarItemIndResidencia(item) {
      //console.log(item);
      this.prod_ind_id_residencia = item.id_individual_residencial;
      this.prod_ind_residencia_id_individuales = item.id_individuales;
      this.prod_ind_residencia_n_riesgo = item.n_riesgo;
      this.prod_ind_residencia_direccion = item.direccion;
      this.prod_ind_residencia_sum_aseg_construcciones =
        item.sum_asegurada_construcciones;
      this.prod_ind_residencia_sum_aseg_contenido =
        item.sum_asegurada_contenido;
      this.prod_ind_residencia_cesion = item.cesion;
      this.prod_ind_residencia_monto_cesion = item.monto_cesion;
      this.prod_ind_residencia_beneficiario_cesion = item.beneficiario_cesion;
      this.prod_ind_residencia_plan_contratado = item.plan_contratado;
      this.prod_ind_residencia_total_suma_aseg = item.total_suma_asegurada;
    },
    eliminarIndResidencia(item) {
      //console.log(item);
      axios
        .put("api/IndividualResidencias/SafeDelete", {
          id_individual_residencia: item.id_individual_residencial,
          es_eliminado: true,
        })
        .then(() => {
          this.alerta("success", "Registro eliminado");
          this.items_prod_ind_residencia = [];
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
        })
        .catch(() => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    calcularTotalIndResidencia() {
      this.prod_ind_residencia_total_suma_aseg = (
        Number(this.prod_ind_residencia_sum_aseg_construcciones) +
        Number(this.prod_ind_residencia_sum_aseg_contenido)
      ).toFixed(2);
    },
    //////////////////////////////////////// PROD IND INCENDIO ///////////////////////////////
    addProdIndIncendio() {
      this.items_prod_ind_incendio.push({
        contador_items_prod_ind_incendio: this
          .contador_items_prod_ind_incendio++,
        excluido: this.prod_ind_incendio_excluir_incendio,
        n_riesgo: this.prod_ind_incendio_n_riesgo,
        direccion: this.prod_ind_incendio_direccion,
        sum_aseg_construcciones: this.prod_ind_incendio_sum_aseg_construcciones,
        sum_aseg_contenido: this.prod_ind_incendio_sum_aseg_contenido,
        sum_aseg_existencias: this.prod_ind_incendio_sum_aseg_existencias,
        total_suma_asegurada: this.prod_ind_incendio_total_sum_aseg,
        intr_neg_lucr_ces_perd_unid:
          this.prod_ind_incendio_intr_neg_lucr_ces_perd_unid,
        cesion: this.prod_ind_incendio_cesion,
        monto_cesion: this.prod_ind_incendio_monto_cesion,
        beneficiario_cesion: this.prod_ind_incendio_beneficiario_cesion,
        excluir_contrucciones: this.prod_ind_incendio_excluir_construcciones,
        excluir_contenido: this.prod_ind_incendio_excluir_contenido,
        excluir_existencias: this.prod_ind_incendio_excluir_existencias,
        motivo_construcciones: this.prod_ind_incendio_motivo_construcciones,
        motivo_contenido: this.prod_ind_incendio_motivo_contenido,
        motivo_existencias: this.prod_ind_incendio_motivo_existencias,
        //riesgos: this.items_prod_ind_riesgos,
        //coberturas: this.items_prod_ind_coberturas,
      });

      this.prod_ind_incendio_excluir_incendio = false;
      this.prod_ind_incendio_n_riesgo = null;
      this.prod_ind_incendio_direccion = null;
      this.prod_ind_incendio_sum_aseg_contenido = null;
      this.prod_ind_incendio_sum_aseg_construcciones = null;
      this.prod_ind_incendio_sum_aseg_existencias = null;
      this.prod_ind_incendio_total_sum_aseg = null;
      this.prod_ind_incendio_intr_neg_lucr_ces_perd_unid = null;
      this.prod_ind_incendio_cesion = null;
      this.prod_ind_incendio_monto_cesion = null;
      this.prod_ind_incendio_beneficiario_cesion = null;
      this.prod_ind_incendio_riesgos_cubierto = null;
      this.prod_ind_incendio_cobertura_compl = null;
      this.prod_ind_incendio_excluir_construcciones = false;
      this.prod_ind_incendio_excluir_contenido = false;
      this.prod_ind_incendio_excluir_existencias = false;
      this.prod_ind_incendio_motivo_construcciones = null;
      this.prod_ind_incendio_motivo_contenido = null;
      this.prod_ind_incendio_motivo_existencias = null;
      this.items_prod_ind_riesgos = [];
      this.items_prod_ind_coberturas = [];
    },
    updateProdIndIncendio() {
      var index = this.items_prod_ind_incendio.findIndex(
        (obj) =>
          obj.contador_items_prod_ind_incendio ==
          this.contador_items_prod_ind_incendio
      );

      this.items_prod_ind_incendio[index].excluido =
        this.prod_ind_incendio_excluir_incendio;
      this.items_prod_ind_incendio[index].n_riesgo =
        this.prod_ind_incendio_n_riesgo;
      this.items_prod_ind_incendio[index].direccion =
        this.prod_ind_incendio_direccion;
      this.items_prod_ind_incendio[index].sum_aseg_construcciones =
        this.prod_ind_incendio_sum_aseg_contenido;
      this.items_prod_ind_incendio[index].sum_aseg_contenido =
        this.prod_ind_incendio_sum_aseg_construcciones;
      this.items_prod_ind_incendio[index].sum_aseg_existencias =
        this.prod_ind_incendio_sum_aseg_existencias;
      this.items_prod_ind_incendio[index].total_suma_asegurada =
        this.prod_ind_incendio_total_sum_aseg;
      this.items_prod_ind_incendio[index].intr_neg_lucr_ces_perd_unid =
        this.prod_ind_incendio_intr_neg_lucr_ces_perd_unid;
      this.items_prod_ind_incendio[index].cesion =
        this.prod_ind_incendio_cesion;
      this.items_prod_ind_incendio[index].monto_cesion =
        this.prod_ind_incendio_monto_cesion;
      this.items_prod_ind_incendio[index].beneficiario_cesion =
        this.prod_ind_incendio_beneficiario_cesion;
      this.items_prod_ind_incendio[index].excluir_contrucciones =
        this.prod_ind_incendio_excluir_construcciones;
      this.items_prod_ind_incendio[index].excluir_contenido =
        this.prod_ind_incendio_excluir_contenido;
      this.items_prod_ind_incendio[index].excluir_existencia =
        this.prod_ind_incendio_excluir_existencias;
      this.items_prod_ind_incendio[index].motivo_construcciones =
        this.prod_ind_incendio_motivo_construcciones;
      this.items_prod_ind_incendio[index].motivo_contenido =
        this.prod_ind_incendio_motivo_contenido;
      this.items_prod_ind_incendio[index].motivo_existencias =
        this.prod_ind_incendio_motivo_existencias;
      //this.items_prod_ind_incendio[index].riesgos = this.items_prod_ind_riesgos;
      //this.items_prod_ind_incendio[index].coberturas = this.items_prod_ind_coberturas;

      this.prod_ind_incendio_excluir_incendio = false;
      this.prod_ind_incendio_n_riesgo = null;
      this.prod_ind_incendio_direccion = null;
      this.prod_ind_incendio_sum_aseg_contenido = null;
      this.prod_ind_incendio_sum_aseg_construcciones = null;
      this.prod_ind_incendio_sum_aseg_existencias = null;
      this.prod_ind_incendio_total_sum_aseg = null;
      this.prod_ind_incendio_intr_neg_lucr_ces_perd_unid = null;
      this.prod_ind_incendio_cesion = null;
      this.prod_ind_incendio_monto_cesion = null;
      this.prod_ind_incendio_beneficiario_cesion = null;
      this.prod_ind_incendio_excluir_construcciones = false;
      this.prod_ind_incendio_excluir_contenido = false;
      this.prod_ind_incendio_excluir_existencias = false;
      this.prod_ind_incendio_motivo_construcciones = null;
      this.prod_ind_incendio_motivo_contenido = null;
      this.prod_ind_incendio_motivo_existencias = null;
      //this.items_prod_ind_coberturas = [];
      //this.items_prod_ind_riesgos = [];

      this.flag_editar_prod_ind_incendio = false;
    },
    crearIncendio(id_individuales, estado) {
      if (estado == "nuevo") {
        //SI YA EXISTE LA POLIZA
        axios
          .post("api/IndividualIncendios/Crear", {
            id_individuales: id_individuales,
            excluido: this.prod_ind_incendio_excluir_incendio,
            n_riesgo: this.prod_ind_incendio_n_riesgo,
            direccion: this.prod_ind_incendio_direccion,
            sum_aseg_contenido: this.prod_ind_incendio_sum_aseg_contenido,
            sum_aseg_construcciones:
              this.prod_ind_incendio_sum_aseg_construcciones,
            sum_aseg_existencias: this.prod_ind_incendio_sum_aseg_existencias,
            total_suma_asegurada: this.prod_ind_incendio_total_sum_aseg,
            intr_neg_lucr_ces_perd_unid:
              this.prod_ind_incendio_intr_neg_lucr_ces_perd_unid,
            cesion: this.prod_ind_incendio_cesion,
            monto_cesion: this.prod_ind_incendio_monto_cesion,
            beneficiario_cesion: this.prod_ind_incendio_beneficiario_cesion,
            excluir_contrucciones:
              this.prod_ind_incendio_excluir_construcciones,
            excluir_contenido: this.prod_ind_incendio_excluir_contenido,
            excluir_existencias: this.prod_ind_incendio_excluir_existencias,
            motivo_construcciones: this.prod_ind_incendio_motivo_construcciones,
            motivo_contenido: this.prod_ind_incendio_motivo_contenido,
            motivo_existencias: this.prod_ind_incendio_motivo_existencias,
          })
          .then(() => {
            this.limpiarProductoIndividualIncendio();
            this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
          });
      } else if (estado == "_nuevo") {
        //EN CASO DE CREAR NUEVA POLIZA
        if (this.items_prod_ind_incendio.length > 0) {
          this.items_prod_ind_incendio.forEach((element) => {
            axios.post("api/IndividualIncendios/Crear", {
              id_individuales: id_individuales,
              excluido: element.excluido,
              n_riesgo: element.n_riesgo,
              direccion: element.direccion,
              sum_aseg_contenido: element.sum_aseg_contenido,
              sum_aseg_construcciones: element.sum_aseg_construcciones,
              sum_aseg_existencias: element.sum_aseg_existencias,
              intr_neg_lucr_ces_perd_unid: element.intr_neg_lucr_ces_perd_unid,
              cesion: element.cesion,
              monto_cesion: this.prod_ind_incendio_monto_cesion,
              beneficiario_cesion: this.prod_ind_incendio_beneficiario_cesion,
              excluir_contrucciones:
                element.prod_ind_incendio_excluir_construcciones,
              excluir_contenido: element.prod_ind_incendio_excluir_contenido,
              excluir_existencias:
                element.prod_ind_incendio_excluir_existencias,
              motivo_construcciones:
                element.prod_ind_incendio_motivo_construcciones,
              motivo_contenido: element.prod_ind_incendio_motivo_contenido,
              motivo_existencias: element.prod_ind_incendio_motivo_existencias,
              //items_riesgos: element.riesgos,
              //items_coberturas: element.coberturas,
            });
          });
          this.limpiarProductoIndividualIncendio();
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
          return;
        }
      }
    },
    actualizarProdIndIncendio() {
      axios
        .put("api/IndividualIncendios/Actualizar", {
          id_individual_incendio: this.prod_ind_id_incendio,
          id_individuales: this.prod_ind_incendio_id_individuales,
          excluido: this.prod_ind_incendio_excluir_incendio,
          n_riesgo: this.prod_ind_incendio_n_riesgo,
          direccion: this.prod_ind_incendio_direccion,
          sum_aseg_contenido: this.prod_ind_incendio_sum_aseg_contenido,
          sum_aseg_construcciones:
            this.prod_ind_incendio_sum_aseg_construcciones,
          sum_aseg_existencias: this.prod_ind_incendio_sum_aseg_existencias,
          total_suma_asegurada: this.prod_ind_incendio_total_sum_aseg,
          intr_neg_lucr_ces_perd_unid:
            this.prod_ind_incendio_intr_neg_lucr_ces_perd_unid,
          cesion: this.prod_ind_incendio_cesion,
          monto_cesion: this.prod_ind_incendio_monto_cesion,
          beneficiario_cesion: this.prod_ind_incendio_beneficiario_cesion,
          excluir_contrucciones: this.prod_ind_incendio_excluir_construcciones,
          excluir_contenido: this.prod_ind_incendio_excluir_contenido,
          excluir_existencias: this.prod_ind_incendio_excluir_existencias,
          motivo_construcciones: this.prod_ind_incendio_motivo_construcciones,
          motivo_contenido: this.prod_ind_incendio_motivo_contenido,
          motivo_existencias: this.prod_ind_incendio_motivo_existencias,
          //items_riesgos: this.items_prod_ind_riesgos,
          //items_coberturas: this.items_prod_ind_coberturas,
        })
        .then(() => {
          this.alerta("success", "Registro actualizado.");

          this.contador_items_prod_ind_incendio = 1;
          this.prod_ind_id_incendio = 0;
          this.prod_ind_incendio_excluir_incendio = false;
          this.prod_ind_incendio_n_risgo = null;
          this.prod_ind_incendio_direccion = null;
          this.prod_ind_incendio_sum_aseg_contenido = null;
          this.prod_ind_incendio_sum_aseg_construcciones = null;
          this.prod_ind_incendio_sum_aseg_existencias = null;
          this.prod_ind_incendio_total_sum_aseg = null;
          this.prod_ind_incendio_intr_neg_lucr_ces_perd_unid = null;
          this.prod_ind_incendio_cesion = null;
          this.prod_ind_incendio_monto_cesion = null;
          this.prod_ind_incendio_beneficiario_cesion = null;
          this.prod_ind_incendio_riesgos_cubierto = null;
          this.prod_ind_incendio_cobertura_compl = null;
          this.prod_ind_incendio_excluir_construcciones = false;
          this.prod_ind_incendio_excluir_contenido = false;
          this.prod_ind_incendio_excluir_existencias = false;
          this.prod_ind_incendio_motivo_construcciones = null;
          this.prod_ind_incendio_motivo_contenido = null;
          this.prod_ind_incendio_motivo_existencias = null;
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
        });
    },
    editarDetallesProdIndIncendio(item) {
      //console.log(item);
      this.contador_items_prod_ind_incendio =
        item.contador_items_prod_ind_incendio;
      this.prod_ind_incendio_id_individuales = item.id_individuales;
      this.prod_ind_id_incendio = item.id_individual_incendio;
      this.prod_ind_incendio_excluir_incendio = item.excluido;
      this.prod_ind_incendio_n_riesgo = item.n_riesgo;
      this.prod_ind_incendio_direccion = item.direccion;
      this.prod_ind_incendio_sum_aseg_construcciones =
        item.sum_aseg_construcciones;
      this.prod_ind_incendio_sum_aseg_contenido = item.sum_aseg_contenido;
      this.prod_ind_incendio_sum_aseg_existencias = item.sum_aseg_existencias;
      this.prod_ind_incendio_total_sum_aseg = item.total_suma_asegurada;
      this.prod_ind_incendio_intr_neg_lucr_ces_perd_unid =
        item.intr_neg_lucr_ces_perd_unid;
      this.prod_ind_incendio_cesion = item.cesion;
      this.prod_ind_incendio_monto_cesion = item.monto_cesion;
      this.prod_ind_incendio_beneficiario_cesion = item.beneficiario_cesion;
      this.prod_ind_incendio_excluir_construcciones =
        item.excluir_construcciones;
      this.prod_ind_incendio_excluir_contenido = item.excluir_contenido;
      this.prod_ind_incendio_excluir_existencias = item.excluir_existencias;
      this.prod_ind_incendio_motivo_construcciones = item.motivo_construcciones;
      this.prod_ind_incendio_motivo_contenido = item.motivo_contenido;
      this.prod_ind_incendio_motivo_existencias = item.motivo_existencias;

      this.flag_editar_prod_ind_incendio =
        item.contador_items_prod_ind_incendio == undefined ? false : true;

      /*if (item.id_individual_incendio != undefined) {
        this.listarProdIndIncendioCobertura(item.id_individual_incendio);
        this.listarProdIndIncendioRiesos(item.id_individual_incendio);
      } else {
        this.items_prod_ind_riesgos = item.riesgos;
        this.items_prod_ind_coberturas = item.coberturas;
      }*/
    },
    confirmarEliminarProdIndIncendio(item) {
      //console.log(item);
      this.dialog_eliminar_prod_ind_incendio = true;
      this.prod_ind_id_incendio = item.id_individual_incendio;
    },
    eliminarProdIndIncendio() {
      //console.log(item);
      axios
        .put("api/IndividualIncendios/SafeDelete", {
          id_individual_incendio: this.prod_ind_id_incendio,
          es_eliminado: true,
        })
        .then(() => {
          this.dialog_eliminar_prod_ind_incendio = false;
          this.prod_ind_id_incendio = null;
          this.alerta("success", "Registro eliminado.");
          this.items_prod_ind_incendio = [];
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
        })
        .catch((error) => {
          console.log(error);
          this.alerta("error", "Sucedio algún error " + error);
        });
    },
    ///////////////////////////////////////// PROD IND FIDELIDAD //////////////////////////////
    agregarProdIndFidelidad() {
      axios
        .post("api/IndividualFidelidades/Crear", {
          id_individuales: this.prod_ind_fidelidad_id_individuales,
          n_riesgo: this.prod_ind_fidelidad_n_riesgo,
          direccion: this.prod_ind_fidelidad_direccion,
          nombres_empleados: this.prod_ind_fidelidad_nombre_empleado,
          puestos_afianzados: this.prod_ind_fidelidad_puesto_afianzado,
        })
        .then(() => {
          this.alerta("success", "Registro creado.");
          this.prod_ind_fidelidad_n_riesgo = null;
          this.prod_ind_fidelidad_direccion = null;
          this.prod_ind_fidelidad_nombre_empleado = null;
          this.prod_ind_fidelidad_puesto_afianzado = null;
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
        })
        .catch(() => {
          this.alerta("error", "No se pudo guardar el registro.");
        });
    },
    crearProdIndFidelidad(id_individuales, estado) {
      if (estado == "nuevo") {
        axios
          .post("api/IndividualFidelidades/Crear", {
            id_individuales: id_individuales,
            n_riesgo: this.prod_ind_fidelidad_n_riesgo,
            direccion: this.prod_ind_fidelidad_direccion,
            nombres_empleados: this.prod_ind_fidelidad_nombre_empleado,
            puestos_afianzados: this.prod_ind_fidelidad_puesto_afianzado,
          })
          .then(() => {
            this.cerrarDialog();
            this.listar();
            this.limpiarProductoIndividualFidelidad();
          });
      } else if (estado == "_nuevo") {
        if (this.items_prod_ind_fidelidad.length > 0) {
          this.items_prod_ind_fidelidad.forEach((element) => {
            axios
              .post("api/IndividualFidelidades/Crear", {
                id_individuales: id_individuales,
                n_riesgo: element.n_riesgo,
                direccion: element.direccion,
                nombres_empleados: element.nombre_empleado,
                puestos_afianzados: element.puesto_afianzado,
              })
              .then(() => {
                this.cerrarDialog();
                this.listar();
                this.limpiarProductoIndividualFidelidad();
              });
          });
        }
      }
    },
    editarDetallesProdIndFidelidad(item) {
      //console.log(item);
      this.contador_items_prod_ind_fidelidad =
        item.contador_items_prod_ind_fidelidad;
      this.prod_ind_id_fidelidad = item.id_individual_fidelidad;
      this.prod_ind_fidelidad_id_individuales = item.id_individuales;
      this.prod_ind_fidelidad_n_riesgo = item.n_riesgo;
      this.prod_ind_fidelidad_direccion = item.direccion;
      this.prod_ind_fidelidad_nombre_empleado = item.nombre_empleado;
      this.prod_ind_fidelidad_puesto_afianzado = item.puesto_afianzado;

      this.flag_prod_ind_fidelidad = true;
    },
    addDetalleProdIndFidelidad() {
      this.items_prod_ind_fidelidad.push({
        contador_items_prod_ind_fidelidad: this
          .contador_items_prod_ind_fidelidad++,
        n_riesgo: this.prod_ind_fidelidad_n_riesgo,
        direccion: this.prod_ind_fidelidad_direccion,
        nombre_empleado: this.prod_ind_fidelidad_nombre_empleado,
        puesto_afianzado: this.prod_ind_fidelidad_puesto_afianzado,
      });
      this.limpiarProductoIndividualFidelidad();
    },
    updateDetallesProdIndFidelidad() {
      var index = this.items_prod_ind_fidelidad.findIndex(
        (obj) =>
          obj.contador_items_prod_ind_fidelidad ==
          this.contador_items_prod_ind_fidelidad
      );

      this.items_prod_ind_fidelidad[index].n_riesgo =
        this.prod_ind_fidelidad_n_riesgo;
      this.items_prod_ind_fidelidad[index].direccion =
        this.prod_ind_fidelidad_direccion;
      this.items_prod_ind_fidelidad[index].nombre_empleado =
        this.prod_ind_fidelidad_nombre_empleado;
      this.items_prod_ind_fidelidad[index].puesto_afianzado =
        this.prod_ind_fidelidad_puesto_afianzado;

      this.limpiarProductoIndividualFidelidad();
    },
    actualizarProdIndFidelidad() {
      axios
        .put("api/IndividualFidelidades/Actualizar", {
          id_individual_fidelidad: this.prod_ind_id_fidelidad,
          id_individuales: this.prod_ind_fidelidad_id_individuales,
          n_riesgo: this.prod_ind_fidelidad_n_riesgo,
          direccion: this.prod_ind_fidelidad_direccion,
          nombres_empleados: this.prod_ind_fidelidad_nombre_empleado,
          puestos_afianzados: this.prod_ind_fidelidad_puesto_afianzado,
        })
        .then(() => {
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
          this.limpiarProductoIndividualFidelidad();
          this.flag_prod_ind_fidelidad = false;
        });
    },
    //////////////////////////////////////////////////////////////////////////////
    agregarProdIndDineroValores() {
      axios
        .post("api/IndividualDineroValores/Crear", {
          id_individuales: this.prod_ind_dinero_valores_id_individuales,
          n_riesgo: this.prod_ind_dinero_valores_n_riesgo,
          direccion: this.prod_ind_dinero_valores_direccion,
        })
        .then(() => {
          this.alerta("success", "Registro creado.");
          this.prod_ind_dinero_valores_n_riesgo = null;
          this.prod_ind_dinero_valores_direccion = null;
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
        })
        .catch(() => {
          this.alerta("error", "No se pudo guardar el registro.");
        });
    },
    agregarProdIndRoboHurto() {
      axios
        .post("api/IndividualRoboHurtos/Crear", {
          id_individuales: this.prod_ind_robo_hurto_id_individuales,
          n_riesgo: this.prod_ind_robo_hurto_n_riesgo,
          direccion: this.prod_ind_robo_hurto_direccion,
        })
        .then(() => {
          this.alerta("success", "Registro creado.");
          this.prod_ind_robo_hurto_n_riesgo = null;
          this.prod_ind_robo_hurto_direccion = null;
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
        })
        .catch(() => {
          this.alerta("error", "No se pudo guardar el registro.");
        });
    },
    agregarProdIndResponCivil() {
      axios
        .post("api/IndividualResponsabilidadCivil/Crear", {
          id_individuales: this.prod_ind_responsabilidad_civil_id_individuales,
          n_riesgo: this.prod_ind_responsabilidad_civil_n_riesgo,
          direccion: this.prod_ind_responsabilidad_civil_direccion,
        })
        .then(() => {
          this.alerta("success", "Registro creado.");
          this.prod_ind_responsabilidad_civil_n_riesgo = null;
          this.prod_ind_responsabilidad_civil_direccion = null;
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
        })
        .catch(() => {
          this.alerta("error", "No se pudo guardar el registro.");
        });
    },
    addProdIndIncendioRiesgo() {
      if (this.prod_ind_incendio_riesgos_cubierto) {
        this.items_prod_ind_riesgos.push({
          nombre: this.prod_ind_incendio_riesgos_cubierto,
        });

        this.prod_ind_incendio_riesgos_cubierto = "";
      }
    },
    addProdIndIncendioCobertura() {
      if (this.prod_ind_incendio_cobertura_compl) {
        this.items_prod_ind_coberturas.push({
          nombre: this.prod_ind_incendio_cobertura_compl,
        });

        this.prod_ind_incendio_cobertura_compl = "";
      }
    },
    addProdIndTransMerca() {
      this.items_prod_ind_transporte_mercaderia.push({
        num_trans_merca: this.prod_ind_transporte_mercaderia_num_trans_merca,
        asegurado: this.prod_ind_transporte_mercaderia_asegurado,
        valor_factura: this.prod_ind_transporte_mercaderia_valor_factura,
        flete: this.prod_ind_transporte_mercaderia_flete,
        sub_total: this.prod_ind_transporte_mercaderia_sub_total,
        porcentaje_adicional:
          this.prod_ind_transporte_mercaderia_porcentaje_adicional,
        total_suma_asegurada:
          this.prod_ind_transporte_mercaderia_total_suma_asegurada,
        tipo_mercaderia: this.prod_ind_transporte_mercaderia_tipo_mercaderia,
        proveedor_cliente:
          this.prod_ind_transporte_mercaderia_proveedor_cliente,
        maritimo: this.prod_ind_transporte_mercaderia_maritimo,
        aereo: this.prod_ind_transporte_mercaderia_aereo,
        terrestre: this.prod_ind_transporte_mercaderia_terrestre,
        nombre_vapor: this.prod_ind_transporte_mercaderia_nombre_vapor,
        procedente_de: this.prod_ind_transporte_mercaderia_procedente_de,
        destino_a: this.prod_ind_transporte_mercaderia_destino_a,
        num_factura: this.prod_ind_transporte_mercaderia_num_factura,
        fecha_factura: this.prod_ind_transporte_mercaderia_fecha_factura,
        fecha_despacho_merca_proveedor:
          this.prod_ind_transporte_mercaderia_fecha_despacho,
        vigencia_certificado_desde:
          this.prod_ind_transporte_mercaderia_vigencia_desde,
        vigencia_certificado_hasta:
          this.prod_ind_transporte_mercaderia_vigencia_hasta,
      });

      this.prod_ind_transporte_mercaderia_num_trans_merca = "";
      this.prod_ind_transporte_mercaderia_asegurado = "";
      this.prod_ind_transporte_mercaderia_valor_factura = 0;
      this.prod_ind_transporte_mercaderia_flete = 0;
      this.prod_ind_transporte_mercaderia_sub_total = 0;
      this.prod_ind_transporte_mercaderia_porcentaje_adicional = 0;
      this.prod_ind_transporte_mercaderia_total_suma_asegurada = 0;
      this.prod_ind_transporte_mercaderia_tipo_mercaderia = "";
      this.prod_ind_transporte_mercaderia_proveedor_cliente = "";
      this.prod_ind_transporte_mercaderia_maritimo = "";
      this.prod_ind_transporte_mercaderia_aereo = "";
      this.prod_ind_transporte_mercaderia_terrestre = "";
      this.prod_ind_transporte_mercaderia_nombre_vapor = "";
      this.prod_ind_transporte_mercaderia_procedente_de = "";
      this.prod_ind_transporte_mercaderia_destino_a = "";
      this.prod_ind_transporte_mercaderia_num_factura = "";
      this.prod_ind_transporte_mercaderia_fecha_factura = "";
      this.prod_ind_transporte_mercaderia_fecha_despacho = "";
      this.prod_ind_transporte_mercaderia_vigencia_desde = "";
      this.prod_ind_transporte_mercaderia_vigencia_hasta = "";
    },
    updateProdIndTransMerca() {
      var index = this.items_prod_ind_transporte_mercaderia.findIndex(
        (obj) => obj.num_trans_merca == this.contador_prod_ind_trans_merca
      );

      this.items_prod_ind_transporte_mercaderia[index].num_trans_merca =
        this.prod_ind_transporte_mercaderia_num_trans_merca;
      this.items_prod_ind_transporte_mercaderia[index].asegurado =
        this.prod_ind_transporte_mercaderia_asegurado;
      this.items_prod_ind_transporte_mercaderia[index].valor_factura =
        this.prod_ind_transporte_mercaderia_valor_factura;
      this.items_prod_ind_transporte_mercaderia[index].flete =
        this.prod_ind_transporte_mercaderia_flete;
      this.items_prod_ind_transporte_mercaderia[index].porcentaje_adicional =
        this.prod_ind_transporte_mercaderia_porcentaje_adicional;
      this.items_prod_ind_transporte_mercaderia[index].total_suma_asegurada =
        this.prod_ind_transporte_mercaderia_total_suma_asegurada;
      this.items_prod_ind_transporte_mercaderia[index].tipo_mercaderia =
        this.prod_ind_transporte_mercaderia_tipo_mercaderia;
      this.items_prod_ind_transporte_mercaderia[index].proveedor_cliente =
        this.prod_ind_transporte_mercaderia_proveedor_cliente;
      this.items_prod_ind_transporte_mercaderia[index].maritimo =
        this.prod_ind_transporte_mercaderia_maritimo;
      this.items_prod_ind_transporte_mercaderia[index].aereo =
        this.prod_ind_transporte_mercaderia_aereo;
      this.items_prod_ind_transporte_mercaderia[index].terrestre =
        this.prod_ind_transporte_mercaderia_terrestre;
      this.items_prod_ind_transporte_mercaderia[index].nombre_vapor =
        this.prod_ind_transporte_mercaderia_nombre_vapor;
      this.items_prod_ind_transporte_mercaderia[index].procedente_de =
        this.prod_ind_transporte_mercaderia_procedente_de;
      this.items_prod_ind_transporte_mercaderia[index].destino_a =
        this.prod_ind_transporte_mercaderia_destino_a;
      this.items_prod_ind_transporte_mercaderia[index].num_factura =
        this.prod_ind_transporte_mercaderia_num_factura;
      this.items_prod_ind_transporte_mercaderia[index].fecha_factura =
        this.prod_ind_transporte_mercaderia_fecha_factura;
      this.items_prod_ind_transporte_mercaderia[
        index
      ].fecha_despacho_merca_proveedor =
        this.prod_ind_transporte_mercaderia_fecha_despacho;
      this.items_prod_ind_transporte_mercaderia[
        index
      ].vigencia_certificado_desde =
        this.prod_ind_transporte_mercaderia_vigencia_desde;
      this.items_prod_ind_transporte_mercaderia[
        index
      ].vigencia_certificado_hasta =
        this.prod_ind_transporte_mercaderia_vigencia_hasta;

      this.prod_ind_transporte_mercaderia_asegurado = "";
      this.prod_ind_transporte_mercaderia_valor_factura = 0;
      this.prod_ind_transporte_mercaderia_flete = 0;
      this.prod_ind_transporte_mercaderia_sub_total = 0;
      this.prod_ind_transporte_mercaderia_porcentaje_adicional = 0;
      this.prod_ind_transporte_mercaderia_total_suma_asegurada = 0;
      this.prod_ind_transporte_mercaderia_tipo_mercaderia = "";
      this.prod_ind_transporte_mercaderia_proveedor_cliente = "";
      this.prod_ind_transporte_mercaderia_maritimo = "";
      this.prod_ind_transporte_mercaderia_aereo = "";
      this.prod_ind_transporte_mercaderia_terrestre = "";
      this.prod_ind_transporte_mercaderia_nombre_vapor = "";
      this.prod_ind_transporte_mercaderia_procedente_de = "";
      this.prod_ind_transporte_mercaderia_destino_a = "";
      this.prod_ind_transporte_mercaderia_num_factura = "";
      this.prod_ind_transporte_mercaderia_fecha_factura = "";
      this.prod_ind_transporte_mercaderia_fecha_despacho = "";
      this.prod_ind_transporte_mercaderia_vigencia_desde = "";
      this.prod_ind_transporte_mercaderia_vigencia_hasta = "";

      this.flag_editar_transporte_mercaderia = false;
    },
    editarDetallesProdIndTransMerca(item) {
      //console.log(item);
      this.contador_prod_ind_trans_merca = item.contador_prod_ind_trans_merca;
      this.prod_ind_id_transporte_mercaderia =
        item.id_individual_transporte_mercaderia;
      this.prod_ind_transporte_mercaderia_id_individuales =
        item.id_individuales;
      this.prod_ind_transporte_mercaderia_num_trans_merca =
        item.num_trans_merca;
      this.prod_ind_transporte_mercaderia_asegurado = item.asegurado;
      this.prod_ind_transporte_mercaderia_valor_factura = this.formatoMoneda(
        item.valor_factura
      );
      this.prod_ind_transporte_mercaderia_flete = this.formatoMoneda(
        item.flete
      );
      this.prod_ind_transporte_mercaderia_sub_total = this.formatoMoneda(
        item.sub_total
      );
      this.prod_ind_transporte_mercaderia_total_suma_asegurada =
        this.formatoMoneda(item.total_suma_asegurada);
      this.prod_ind_transporte_mercaderia_tipo_mercaderia =
        item.tipo_mercaderia;
      this.prod_ind_transporte_mercaderia_proveedor_cliente =
        item.proveedor_cliente;
      this.prod_ind_transporte_mercaderia_maritimo = item.maritimo;
      this.prod_ind_transporte_mercaderia_aereo = item.aereo;
      this.prod_ind_transporte_mercaderia_terrestre = item.terrestre;
      this.prod_ind_transporte_mercaderia_nombre_vapor = item.nombre_vapor;
      this.prod_ind_transporte_mercaderia_procedente_de = item.procedente_de;
      this.prod_ind_transporte_mercaderia_destino_a = item.destino_a;
      this.prod_ind_transporte_mercaderia_num_factura = item.num_factura;
      this.prod_ind_transporte_mercaderia_fecha_factura = this.formatoFecha(
        item.fecha_factura
      );
      this.prod_ind_transporte_mercaderia_fecha_despacho = this.formatoFecha(
        item.fecha_despacho_merca_proveedor
      );
      this.prod_ind_transporte_mercaderia_vigencia_desde = this.formatoFecha(
        item.vigencia_certificado_desde
      );
      this.prod_ind_transporte_mercaderia_vigencia_hasta = this.formatoFecha(
        item.vigencia_certificado_hasta
      );

      this.flag_editar_transporte_mercaderia = true;
    },
    actualizarProdIndTransMerca() {
      axios
        .put("api/IndividualTransporteMercaderias/Actualizar", {
          id_individual_transporte_mercaderia:
            this.prod_ind_id_transporte_mercaderia,
          id_individuales: this.prod_ind_transporte_mercaderia_id_individuales,
          num_trans_merca: this.prod_ind_transporte_mercaderia_num_trans_merca,
          asegurado: this.prod_ind_transporte_mercaderia_asegurado,
          valor_factura: this.prod_ind_transporte_mercaderia_valor_factura,
          flete: this.prod_ind_transporte_mercaderia_flete,
          sub_total: this.prod_ind_transporte_mercaderia_sub_total,
          porcentaje_adicional:
            this.prod_ind_transporte_mercaderia_porcentaje_adicional,
          total_suma_asegurada:
            this.prod_ind_transporte_mercaderia_total_suma_asegurada,
          tipo_mecaderia: this.prod_ind_transporte_mercaderia_tipo_mercaderia,
          proveedor_cliente:
            this.prod_ind_transporte_mercaderia_proveedor_cliente,
          maritimo: this.prod_ind_transporte_mercaderia_maritimo,
          aereo: this.prod_ind_transporte_mercaderia_aereo,
          terrestre: this.prod_ind_transporte_mercaderia_terrestre,
          nombre_vapor: this.prod_ind_transporte_mercaderia_nombre_vapor,
          procedente_de: this.prod_ind_transporte_mercaderia_procedente_de,
          destino_a: this.prod_ind_transporte_mercaderia_destino_a,
          num_factura: this.prod_ind_transporte_mercaderia_num_factura,
          fecha_factura: this.prod_ind_transporte_mercaderia_fecha_factura,
          fecha_despacho_merca_proveedor:
            this.prod_ind_transporte_mercaderia_fecha_despacho,
          vigencia_certificado_desde:
            this.prod_ind_transporte_mercaderia_vigencia_desde,
          vigencia_certificado_hasta:
            this.prod_ind_transporte_mercaderia_vigencia_hasta,
        })
        .then(() => {
          this.alerta("success", "Registro actualizado");
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
          this.limpiarProductoIndividualTransporteMercaderia();
        })
        .catch(() => {
          this.alerta("error", "No se pudo actualizar");
        });
    },
    addProdIndEquipoElectronico() {
      this.items_prod_ind_equipo_electronico.push({
        contador_items_prod_ind_equipo_electronico: this
          .contador_items_prod_ind_equipo_electronico++,
        n_riesgo: this.prod_ind_equipo_electronico_n_riesgo,
        direccion: this.prod_ind_equipo_electronico_direccion,
        suma_asegurada_equipo: this.prod_ind_equipo_electronico_suma_asegurada,
        cesion: this.prod_ind_equipo_electronico_cesion,
        riesgos: this.items_prod_ind_equipo_electronico_riesgos,
        coberturas: this.items_prod_ind_equipo_electronico_coberturas,
      });

      this.prod_ind_equipo_electronico_n_riesgo = null;
      this.prod_ind_equipo_electronico_direccion = null;
      this.prod_ind_equipo_electronico_suma_asegurada = 0;
      this.prod_ind_equipo_electronico_cesion = null;
      this.items_prod_ind_equipo_electronico_riesgos = [];
      this.items_prod_ind_equipo_electronico_coberturas = [];
    },
    addProdIndEquipoElectronicoRiesgo() {
      if (this.prod_ind_equipo_electronico_riesgo_nombre) {
        this.items_prod_ind_equipo_electronico_riesgos.push({
          nombre: this.prod_ind_equipo_electronico_riesgo_nombre,
        });
      }
      this.prod_ind_equipo_electronico_riesgo_nombre = "";
    },
    addProdEquipoElectronicoCobertura() {
      if (this.prod_ind_equipo_electronico_cobertura_nombre) {
        this.items_prod_ind_equipo_electronico_coberturas.push({
          nombre: this.prod_ind_equipo_electronico_cobertura_nombre,
        });
      }
      this.prod_ind_equipo_electronico_cobertura_nombre = "";
    },
    updateProdEquipoElectronico() {
      var index = this.items_prod_ind_equipo_electronico.findIndex(
        (obj) =>
          obj.contador_items_prod_ind_equipo_electronico ==
          this.contador_items_prod_ind_equipo_electronico
      );

      this.items_prod_ind_equipo_electronico[index].n_riesgo =
        this.prod_ind_equipo_electronico_n_riesgo;
      this.items_prod_ind_equipo_electronico[index].direccion =
        this.prod_ind_equipo_electronico_direccion;
      this.items_prod_ind_equipo_electronico[index].suma_asegurada_equipo =
        this.prod_ind_equipo_electronico_suma_asegurada;
      this.items_prod_ind_equipo_electronico[index].cesion =
        this.prod_ind_equipo_electronico_cesion;
      this.items_prod_ind_equipo_electronico[index].riesgos =
        this.items_prod_ind_equipo_electronico_riesgos;
      this.items_prod_ind_equipo_electronico[index].coberturas =
        this.items_prod_ind_equipo_electronico_coberturas;

      this.prod_ind_equipo_electronico_n_riesgo = "";
      this.prod_ind_equipo_electronico_direccion = "";
      this.prod_ind_equipo_electronico_suma_asegurada = 0;
      this.prod_ind_equipo_electronico_cesion = "";
      this.items_prod_ind_equipo_electronico_riesgos = [];
      this.items_prod_ind_equipo_electronico_coberturas = [];

      this.flag_editar_prod_ind_equipo_electronico = false;
    },
    editarDetallesProdIndEquipoElectronico(item) {
      //console.log(item);
      this.contador_items_prod_ind_equipo_electronico =
        item.contador_items_prod_ind_equipo_electronico;
      this.prod_ind_equipo_electronico_id_individuales = item.id_individuales;
      this.id_individual_equipo_eletronico =
        item.id_individual_equipo_eletronico;
      this.prod_ind_equipo_electronico_n_riesgo = item.n_riesgo;
      this.prod_ind_equipo_electronico_direccion = item.direccion;
      this.prod_ind_equipo_electronico_suma_asegurada = this.formatoMoneda(
        item.suma_asegurada_equipo
      );
      this.prod_ind_equipo_electronico_cesion = item.cesion;

      this.flag_editar_prod_ind_equipo_electronico =
        item.contador_items_prod_ind_equipo_electronico == undefined
          ? false
          : true;

      if (item.id_individual_equipo_eletronico != undefined) {
        this.listarProdIndEquipoElectronicoCobertura(
          item.id_individual_equipo_eletronico
        );
        this.listarProdIndEquipoElectronicoRiesos(
          item.id_individual_equipo_eletronico
        );
      } else {
        this.items_prod_ind_equipo_electronico_riesgos = item.riesgos;
        this.items_prod_ind_equipo_electronico_coberturas = item.coberturas;
      }
    },
    editarItemIndEquipoElectronicoCobertura(item) {
      //console.log(item);
      axios
        .put("api/IndividualEquipoElectronicoCoberturas/Actualizar", {
          id_cobertura: item.id_cobertura,
          id_equipo_electronico: item.id_equipo_electronico,
          nombre: item.nombre,
        })
        .then(() => {
          this.alerta("success", "Dato actualizado");
          this.listarProdIndEquipoElectronicoCobertura(
            item.id_equipo_electronico
          );
        })
        .catch(() => {
          this.alerta("error", "No se pudo actualizar");
        });
    },
    editarItemIndEquipoElectronicoRiesgo(item) {
      //console.log(item);
      axios
        .put("api/IndividualEquipoElectronicoRiesgos/Actualizar", {
          id_riesgo: item.id_riesgo,
          id_equipo_electronico: item.id_equipo_electronico,
          nombre: item.nombre,
        })
        .then(() => {
          this.alerta("success", "Dato actualizado");
          this.listarProdIndEquipoElectronicoRiesos(item.id_equipo_electronico);
        })
        .catch(() => {
          this.alerta("error", "No se pudo actualizar");
        });
    },
    listarProdIndEquipoElectronicoCobertura(id_individual_equipo_eletronico) {
      axios
        .get(
          "api/IndividualEquipoElectronicoCoberturas/Listar/" +
            id_individual_equipo_eletronico
        )
        .then((response) => {
          //console.log(response.data);
          this.items_prod_ind_equipo_electronico_coberturas = response.data;
        });
    },
    listarProdIndEquipoElectronicoRiesos(id_individual_equipo_eletronico) {
      axios
        .get(
          "api/IndividualEquipoElectronicoRiesgos/Listar/" +
            id_individual_equipo_eletronico
        )
        .then((response) => {
          //console.log(response.data);
          this.items_prod_ind_equipo_electronico_riesgos = response.data;
        });
    },
    addProdIndSeguroViajes() {
      this.items_prod_ind_seguro_viaje.push({
        contador_prod_ind_seguro_viajes: this.contador_prod_ind_seguro_viajes++,
        nombre: this.prod_ind_seguro_viaje_nombre,
        sexo: this.prod_ind_seguro_viaje_sexo,
        fecha_nacimiento: this.prod_ind_seguro_viaje_fecha_nacimiento,
        edad: this.prod_ind_seguro_viaje_edad,
        numero_dias: this.prod_ind_seguro_viaje_numero_dias,
      });

      this.prod_ind_seguro_viaje_nombre = "";
      this.prod_ind_seguro_viaje_sexo = "";
      this.prod_ind_seguro_viaje_fecha_nacimiento = "";
      this.prod_ind_seguro_viaje_edad = 0;
      this.prod_ind_seguro_viaje_numero_dias = 0;
    },
    editarDetallesProdIndSeguroViajes(item) {
      this.id_prod_ind_seguro_viaje = item.id_seguro_viaje;
      this.prod_ind_seguro_viaje_id_individuales = item.id_individuales;
      this.prod_ind_seguro_viaje_nombre = item.nombre;
      this.prod_ind_seguro_viaje_sexo = item.sexo;
      this.prod_ind_seguro_viaje_fecha_nacimiento = item.fecha_nacimiento;
      this.prod_ind_seguro_viaje_edad = item.edad;
      this.prod_ind_seguro_viaje_numero_dias = item.numero_dias;
      this.flag_prod_ind_seguro_viaje = false;
    },
    updateProdIndSeguroViaje() {
      var index = this.items_prod_ind_seguro_viaje.findIndex(
        (obj) =>
          obj.contador_prod_ind_seguro_viajes ==
          this.contador_prod_ind_seguro_viajes
      );

      this.items_prod_ind_seguro_viaje[index].nombre =
        this.prod_ind_seguro_viaje_nombre;
      this.items_prod_ind_seguro_viaje[index].sexo =
        this.prod_ind_seguro_viaje_sexo;
      this.items_prod_ind_seguro_viaje[index].fecha_nacimiento =
        this.prod_ind_seguro_viaje_fecha_nacimiento;
      this.items_prod_ind_seguro_viaje[index].edad =
        this.prod_ind_seguro_viaje_edad;
      this.items_prod_ind_seguro_viaje[index].numero_dias =
        this.prod_ind_seguro_viaje_numero_dias;

      this.prod_ind_seguro_viaje_nombre = "";
      this.prod_ind_seguro_viaje_sexo = "";
      this.prod_ind_seguro_viaje_fecha_nacimiento = "";
      this.prod_ind_seguro_viaje_edad = 0;
      this.prod_ind_seguro_viaje_numero_dias = 0;

      this.flag_prod_ind_seguro_viaje = true;
    },
    addProdIndRiesgoConstruRiesgo() {
      if (this.prod_ind_riesgo_constru_riesgos_cubierto) {
        this.items_prod_ind_riesgo_constru_riesgos.push({
          nombre: this.prod_ind_riesgo_constru_riesgos_cubierto,
        });

        this.prod_ind_riesgo_constru_riesgos_cubierto = "";
      }
    },
    addProdIndRiesgoConstruCobertura() {
      if (this.prod_ind_riesgo_constru_cobertura_compl) {
        this.items_prod_ind_riesgo_constru_coberturas.push({
          nombre: this.prod_ind_riesgo_constru_cobertura_compl,
        });

        this.prod_ind_riesgo_constru_cobertura_compl = "";
      }
    },
    editarItemIndRiesgoConstruRiesgo(item) {
      //console.log(item);
      axios
        .put("api/IndividualRiesgoConstruccionesRiesgos/Actualizar", {
          id_riesgo: item.id_riesgo,
          id_riesgo_construcciones: item.id_riesgo_construcciones,
          nombre: item.nombre,
        })
        .then(() => {
          this.alerta("success", "Dato actualizado");
        })
        .catch(() => {
          this.alerta("error", "No se pudo actualizar dato");
        });
    },
    editarItemIndRiesgoConstruCobertura(item) {
      //console.log(item);
      axios
        .put("api/IndividualRiesgoConstruccionesCoberturas/Actualizar", {
          id_cobertura: item.id_cobertura,
          id_riesgo_construcciones: item.id_riesgo_construcciones,
          nombre: item.nombre,
        })
        .then(() => {
          this.alerta("success", "Dato actualizado");
        })
        .catch(() => {
          this.alerta("error", "No se pudo actualizar dato");
        });
    },
    addProdIndRiesgoConstru() {
      this.items_prod_ind_riesgo_constru.push({
        contador_items_prod_ind_riesgo_constru: this
          .contador_items_prod_ind_riesgo_constru++,
        n_riesgo: this.prod_ind_riesgo_constru_n_riesgo,
        direccion: this.prod_ind_riesgo_constru_direccion,
        sum_aseg_construcciones:
          this.prod_ind_riesgo_constru_sum_aseg_construcciones,
        sum_aseg_contenido: this.prod_ind_riesgo_constru_sum_aseg_contenido,
        sum_aseg_existencias: this.prod_ind_riesgo_constru_sum_aseg_existencias,
        intr_neg_lucr_ces_perd_unid:
          this.prod_ind_riesgo_constru_intr_neg_lucr_ces_perd_unid,
        cesion: this.prod_ind_riesgo_constru_cesion,
        riesgos: this.items_prod_ind_riesgo_constru_riesgos,
        coberturas: this.items_prod_ind_riesgo_constru_coberturas,
      });

      this.prod_ind_riesgo_constru_n_riesgo = null;
      this.prod_ind_riesgo_constru_direccion = null;
      this.prod_ind_riesgo_constru_sum_aseg_contenido = null;
      this.prod_ind_riesgo_constru_sum_aseg_construcciones = null;
      this.prod_ind_riesgo_constru_sum_aseg_existencias = null;
      this.prod_ind_riesgo_constru_intr_neg_lucr_ces_perd_unid = null;
      this.prod_ind_riesgo_constru_cesion = null;
      this.prod_ind_riesgo_constru_riesgos_cubierto = null;
      this.prod_ind_riesgo_constru_cobertura_compl = null;
      this.items_prod_ind_riesgo_constru_riesgos = [];
      this.items_prod_ind_riesgo_constru_coberturas = [];
    },
    updateProdIndRiesgoConstru() {
      var index = this.items_prod_ind_riesgo_constru.findIndex(
        (obj) =>
          obj.contador_items_prod_ind_riesgo_constru ==
          this.contador_items_prod_ind_riesgo_constru
      );

      this.items_prod_ind_riesgo_constru[index].n_riesgo =
        this.prod_ind_riesgo_constru_n_riesgo;
      this.items_prod_ind_riesgo_constru[index].direccion =
        this.prod_ind_riesgo_constru_direccion;
      this.items_prod_ind_riesgo_constru[index].sum_aseg_construcciones =
        this.prod_ind_riesgo_constru_sum_aseg_construcciones;
      this.items_prod_ind_riesgo_constru[index].sum_aseg_contenido =
        this.prod_ind_riesgo_constru_sum_aseg_contenido;
      this.items_prod_ind_riesgo_constru[index].sum_aseg_existencias =
        this.prod_ind_riesgo_constru_sum_aseg_existencias;
      this.items_prod_ind_riesgo_constru[index].intr_neg_lucr_ces_perd_unid =
        this.prod_ind_riesgo_constru_intr_neg_lucr_ces_perd_unid;
      this.items_prod_ind_riesgo_constru[index].cesion =
        this.prod_ind_riesgo_constru_cesion;
      this.items_prod_ind_riesgo_constru[index].riesgos =
        this.items_prod_ind_riesgo_constru_riesgos;
      this.items_prod_ind_riesgo_constru[index].coberturas =
        this.items_prod_ind_riesgo_constru_coberturas;

      this.prod_ind_riesgo_constru_n_riesgo = null;
      this.prod_ind_riesgo_constru_direccion = null;
      this.prod_ind_riesgo_constru_sum_aseg_contenido = null;
      this.prod_ind_riesgo_constru_sum_aseg_construcciones = null;
      this.prod_ind_riesgo_constru_sum_aseg_existencias = null;
      this.prod_ind_riesgo_constru_intr_neg_lucr_ces_perd_unid = null;
      this.prod_ind_riesgo_constru_cesion = null;
      this.items_prod_ind_riesgo_constru_coberturas = [];
      this.items_prod_ind_riesgo_constru_riesgos = [];

      this.flag_editar_prod_ind_riesgo_constru = false;
    },
    actualizarProdIndRiesgoConstru() {
      axios
        .put("api/IndividualRiesgoConstrucciones/Actualizar", {
          id_riesgo_construcciones: this.prod_ind_id_riesgo_constru,
          id_individuales: this.prod_ind_riesgo_constru_id_individuales,
          n_riesgo: this.prod_ind_riesgo_constru_n_riesgo,
          direccion: this.prod_ind_riesgo_constru_direccion,
          sum_aseg_construcciones:
            this.prod_ind_riesgo_constru_sum_aseg_construcciones,
          sum_aseg_contenido: this.prod_ind_riesgo_constru_sum_aseg_contenido,
          sum_aseg_existencias:
            this.prod_ind_riesgo_constru_sum_aseg_existencias,
          intr_neg_lucr_ces_perd_unid:
            this.prod_ind_riesgo_constru_intr_neg_lucr_ces_perd_unid,
          cesion: this.prod_ind_riesgo_constru_cesion,
        })
        .then(() => {
          this.alerta("success", "Datos actualizados");
          this.listarProductoIndividual(this.id_poliza, this.nombre_ramo);
          this.limpiarProductoIndividualRiesgoConstrucciones();
        })
        .catch(() => {
          this.alerta("error", "No se pudo actualizar");
        });
    },
    editarDetallesProdRiesgoConstru(item) {
      this.contador_items_prod_ind_riesgo_constru =
        item.contador_items_prod_ind_riesgo_constru;
      this.prod_ind_riesgo_constru_id_individuales = item.id_individuales;
      this.prod_ind_id_riesgo_constru = item.id_riesgo_construcciones;
      this.prod_ind_riesgo_constru_n_riesgo = item.n_riesgo;
      this.prod_ind_riesgo_constru_direccion = item.direccion;
      this.prod_ind_riesgo_constru_sum_aseg_construcciones =
        item.sum_aseg_construcciones;
      this.prod_ind_riesgo_constru_sum_aseg_contenido = item.sum_aseg_contenido;
      this.prod_ind_riesgo_constru_sum_aseg_existencias =
        item.sum_aseg_existencias;
      this.prod_ind_riesgo_constru_intr_neg_lucr_ces_perd_unid =
        item.intr_neg_lucr_ces_perd_unid;
      this.prod_ind_riesgo_constru_cesion = item.cesion;

      this.flag_editar_prod_ind_riesgo_constru =
        item.contador_items_prod_ind_riesgo_constru == undefined ? false : true;

      if (item.id_riesgo_construcciones != undefined) {
        this.listarProdIndRiesgoConstruCobertura(item.id_riesgo_construcciones);
        this.listarProdIndRiesgoConstruRiesos(item.id_riesgo_construcciones);
      } else {
        this.items_prod_ind_riesgo_constru_riesgos = item.riesgos;
        this.items_prod_ind_riesgo_constru_coberturas = item.coberturas;
      }
    },
    listarProdIndRiesgoConstruCobertura(id) {
      axios
        .get("api/IndividualRiesgoConstruccionesRiesgos/Listar/" + id)
        .then((response) => {
          this.items_prod_ind_riesgo_constru_riesgos = response.data;
        });
    },
    listarProdIndRiesgoConstruRiesos(id) {
      axios
        .get("api/IndividualRiesgoConstruccionesCoberturas/Listar/" + id)
        .then((response) => {
          this.items_prod_ind_riesgo_constru_coberturas = response.data;
        });
    },
    //////////// AGREGAR PROD INV DEPENDIENTES DE GASTO MEDICO PARA NUEVA POLIZA
    addDependienteGastoMedico() {
      this.items_prod_ind_depen_gastomedico.push({
        nombre: this.prod_ind_depen_gastomedico_nombre,
        excluido: this.prod_ind_depen_gastomedico_excluido,
        edad: this.prod_ind_depen_gastomedico_edad,
        sexo: this.prod_ind_depen_gastomedico_sexo,
        fecha_nacimiento: this.prod_ind_depen_gastomedico_fecha_nacimiento,
        max_vit_contratado: this.prod_ind_depen_gastomedico_max_vit_contratado,
        max_vit_vigente: this.prod_ind_depen_gastomedico_max_vit_vigente,
        cobertura: this.prod_ind_depen_gastomedico_cobertura,
        parentesco: this.prod_ind_depen_gastomedico_parentesco,
        motivo_excluido: this.prod_ind_depen_gastomedico_motivo_excluido,
      });

      this.prod_ind_depen_gastomedico_nombre = "";
      this.prod_ind_depen_gastomedico_excluido = false;
      this.prod_ind_depen_gastomedico_edad = "";
      this.prod_ind_depen_gastomedico_sexo = "";
      this.prod_ind_depen_gastomedico_fecha_nacimiento = "";
      this.prod_ind_depen_gastomedico_max_vit_contratado = "";
      this.prod_ind_depen_gastomedico_max_vit_vigente = "";
      this.prod_ind_depen_gastomedico_cobertura = "";
      this.prod_ind_depen_gastomedico_parentesco = "";
      this.prod_ind_depen_gastomedico_motivo_excluido = "";
    },
    limpiarIndividualTipoFianza() {
      this.id_prod_ind_fianza = 0;
      this.prod_ind_fianza_id_individuales = 0;
      this.prod_ind_fianza_tipo_fianza = "";
      this.prod_ind_fianza_otras = "";
      this.prod_ind_fianza_especificar = "";
    },
    limpiarProductoIndividualSeguroViajes() {
      this.contador_prod_ind_seguro_viajes = 1;
      this.flag_prod_ind_seguro_viaje = true;
      this.id_prod_ind_seguro_viaje = 0;
      this.prod_ind_seguro_viaje_id_individuales = 0;
      this.prod_ind_seguro_viaje_nombre = "";
      this.prod_ind_seguro_viaje_sexo = "";
      this.prod_ind_seguro_viaje_fecha_nacimiento = "";
      this.prod_ind_seguro_viaje_edad = 0;
      this.prod_ind_seguro_viaje_numero_dias = 0;
      this.items_prod_ind_seguro_viaje = [];
    },
    limpiarProductoIndividualIncaTotalPerma() {
      this.id_prod_ind_inc_total_perma = 0;
      this.prod_ind_inc_total_perma_id_individuales = 0;
      this.prod_ind_inc_total_perma_desme_acci = false;
      this.prod_ind_inc_total_perma_enfer_graves = false;
      this.prod_ind_inc_total_perma_muerte_acci = false;
      this.prod_ind_inc_total_perma_suma_aseg = 0;
      this.prod_ind_inc_total_perma_otros = "";
    },
    limpiarProductoIndividualEquipoElectronico() {
      this.id_individual_equipo_eletronico = 0;
      this.prod_ind_equipo_electronico_id_individuales = 0;
      this.prod_ind_equipo_electronico_n_riesgo = null;
      this.prod_ind_equipo_electronico_direccion = null;
      this.prod_ind_equipo_electronico_suma_asegurada = 0;
      this.prod_ind_equipo_electronico_cesion = null;
      this.items_prod_ind_equipo_electronico_riesgos = [];
      this.items_prod_ind_equipo_electronico_coberturas = [];
      this.contador_items_prod_ind_equipo_electronico = 1;
    },
    limpiarProductoIndividualAuto() {
      this.prod_ind_auto_id_indiv_auto = 0;
      this.prod_ind_auto_id_indiv = 0;
      this.prod_ind_auto_marca = "";
      this.prod_ind_auto_modelo = "";
      this.prod_ind_auto_año = 0;
      this.prod_ind_auto_placa = "";
      this.prod_ind_auto_chasis = "";
      this.prod_ind_auto_motor = "";
      this.prod_ind_auto_version = "";
      this.prod_ind_auto_cesion = "";
      this.prod_ind_auto_monto_cesion = "";
      this.prod_ind_auto_beneficiario_cesion = "";
      this.prod_ind_auto_tipo_vehiculo = "";
      this.prod_ind_auto_vencimiento_tarjeta = "";
      this.prod_ind_auto_vencimiento_dui = "";
      this.prod_ind_auto_vencimiento_licencia = "";
    },
    limpiarProductoIndividualVida() {
      this.prod_ind_vida_id_indiv_vida = 0;
      this.prod_ind_auto_id_indiv = 0;
      this.prod_ind_vida_nombre = "";
      this.prod_ind_vida_edad = 0;
      this.prod_ind_vida_sexo = "";
      this.prod_ind_vida_fecha_nacimiento = "";
      this.prod_ind_vida_cesion = "";
      this.prod_ind_vida_monto_cesion = "";
      this.prod_ind_vida_beneficiario_cesion = "";
      this.prod_ind_vida_temporal = "";
      this.prod_ind_vida_numero_años = 0;
    },
    limpiarProductoIndividualGastoMedico() {
      this.prod_ind_gastomedico_id_individual_gasto_medico = 0;
      this.prod_ind_gastomedico_id_indiv = 0;
      this.prod_ind_gastomedico_nombre = "";
      this.prod_ind_gastomedico_edad = 0;
      this.prod_ind_gastomedico_sexo = "";
      this.prod_ind_gastomedico_fecha_nacimiento = "";
      this.prod_ind_gastomedico_max_vit_contratado = "";
      this.prod_ind_gastomedico_max_vit_vigente = "";
      this.prod_ind_gastomedico_cobertura = "";
      this.items_prod_ind_depen_gastomedico = [];
      this.items_prod_ind_depen_gastomedico_excluidos = [];
    },
    limpiarProducoIndividualDepenGastoMedico() {
      this.prod_ind_depen_dialog = false;

      this.prod_ind_depen_id_depen_gastomedico = "";
      this.prod_ind_depen_id_gastomedico = "";
      this.prod_ind_depen_gastomedico_nombre = "";
      this.prod_ind_depen_gastomedico_excluido = false;
      this.prod_ind_depen_gastomedico_edad = "";
      this.prod_ind_depen_gastomedico_sexo = "";
      this.prod_ind_depen_gastomedico_fecha_nacimiento = "";
      this.prod_ind_depen_gastomedico_max_vit_contratado = "";
      this.prod_ind_depen_gastomedico_max_vit_vigente = "";
      this.prod_ind_depen_gastomedico_cobertura = "";
      this.prod_ind_depen_gastomedico_parentesco = "";
    },
    limpiarProductoIndividualResidencia() {
      this.prod_ind_id_residencia = 0;
      this.prod_ind_residencia_id_individuales = 0;
      this.prod_ind_residencia_n_riesgo = 0;
      this.prod_ind_residencia_direccion = "";
      this.prod_ind_residencia_sum_aseg_construcciones = 0;
      this.prod_ind_residencia_sum_aseg_contenido = 0;
      this.prod_ind_residencia_cesion = "";
      this.prod_ind_residencia_monto_cesion = "";
      this.prod_ind_residencia_beneficiario_cesion = "";
      this.prod_ind_residencia_plan_contratado = "";
      this.prod_ind_residencia_total_suma_aseg = 0;
    },
    limpiarProductoIndividualIncendio() {
      this.prod_ind_id_incendio = 0;
      this.prod_ind_incendio_id_individuales = 0;
      this.prod_ind_incendio_excluir_incendio = false;
      this.prod_ind_incendio_n_riesgo = 0;
      this.prod_ind_incendio_direccion = "";
      this.prod_ind_incendio_sum_aseg_construcciones = 0;
      this.prod_ind_incendio_sum_aseg_contenido = 0;
      this.prod_ind_incendio_sum_aseg_existencias = 0;
      this.prod_ind_incendio_total_sum_aseg = 0;
      this.prod_ind_incendio_intr_neg_lucr_ces_perd_unid = "";
      this.prod_ind_incendio_cesion = "";
      this.prod_ind_incendio_monto_cesion = "";
      this.prod_ind_incendio_beneficiario_cesion = "";
      this.prod_ind_incendio_riesgos_cubierto = "";
      this.prod_ind_incendio_cobertura_compl = "";
      this.contador_items_prod_ind_incendio = 1;
      this.prod_ind_incendio_excluir_construcciones = false;
      this.prod_ind_incendio_excluir_contenido = false;
      this.prod_ind_incendio_excluir_existencias = false;
      this.prod_ind_incendio_motivo_construcciones = null;
      this.prod_ind_incendio_motivo_contenido = null;
      this.prod_ind_incendio_motivo_existencias = null;
      this.items_prod_ind_incendio = [];
      this.items_prod_ind_coberturas = [];
      this.items_prod_ind_riesgos = [];
    },
    limpiarProductoIndividualRiesgoConstrucciones() {
      this.contador_prod_ind_seguro_viajes = 1;
      this.prod_ind_id_riesgo_constru = null;
      this.prod_ind_riesgo_constru_id_individuales = null;
      this.prod_ind_riesgo_constru_n_riesgo = null;
      this.prod_ind_riesgo_constru_direccion = null;
      this.prod_ind_riesgo_constru_sum_aseg_contenido = null;
      this.prod_ind_riesgo_constru_sum_aseg_construcciones = null;
      this.prod_ind_riesgo_constru_sum_aseg_existencias = null;
      this.prod_ind_riesgo_constru_intr_neg_lucr_ces_perd_unid = null;
      this.prod_ind_riesgo_constru_cesion = null;
      this.items_prod_ind_riesgo_constru_coberturas = [];
      this.items_prod_ind_riesgo_constru_riesgos = [];
    },
    limpiarProductoIndividualFidelidad() {
      //this.contador_items_prod_ind_fidelidad = 0;
      this.prod_ind_id_fidelidad = 0;
      this.prod_ind_fidelidad_id_individuales = 0;
      this.prod_ind_fidelidad_n_riesgo = 0;
      this.prod_ind_fidelidad_direccion = "";
      this.prod_ind_fidelidad_nombre_empleado = "";
      this.prod_ind_fidelidad_puesto_afianzado = "";
      this.flag_prod_ind_fidelidad = false;
    },
    limpiarProductoIndividualDineroValores() {
      this.prod_ind_id_dinero_valores = 0;
      this.prod_ind_dinero_valores_id_individuales = 0;
      this.prod_ind_dinero_valores_n_riesgo = "";
      this.prod_ind_dinero_valores_direccion = "";
    },
    limpiarProductoIndividualRoboHurto() {
      this.prod_ind_id_robo_hurto = 0;
      this.prod_ind_robo_hurto_id_individuales = 0;
      this.prod_ind_robo_hurto_n_riesgo = "";
      this.prod_ind_robo_hurto_direccion = "";
    },
    limpiarProductoIndividualTransporteInterno() {
      this.prod_ind_id_transporte_interno = 0;
      this.prod_ind_transporte_interno_id_individuales = 0;
      this.prod_ind_transporte_interno_direccion = "";
      this.prod_ind_transporte_interno_tipo_mercaderia = "";
      this.prod_ind_transporte_interno_guardia_seguridad = "";
    },
    limpiarProductoIndividualTransporteMercaderia() {
      this.prod_ind_id_transporte_mercaderia = 0;
      this.prod_ind_transporte_mercaderia_id_individuales = 0;
      this.prod_ind_transporte_mercaderia_num_trans_merca = "";
      this.prod_ind_transporte_mercaderia_asegurado = 0;
      this.prod_ind_transporte_mercaderia_valor_factura = 0;
      this.prod_ind_transporte_mercaderia_flete = 0;
      this.prod_ind_transporte_mercaderia_sub_total = 0;
      this.prod_ind_transporte_mercaderia_porcentaje_adicional = 0;
      this.prod_ind_transporte_mercaderia_total_suma_asegurada = 0;
      this.prod_ind_transporte_mercaderia_tipo_mercaderia = "";
      this.prod_ind_transporte_mercaderia_proveedor_cliente = "";
      this.prod_ind_transporte_mercaderia_maritimo = "";
      this.prod_ind_transporte_mercaderia_aereo = "";
      this.prod_ind_transporte_mercaderia_terrestre = "";
      this.prod_ind_transporte_mercaderia_nombre_vapor = "";
      this.prod_ind_transporte_mercaderia_procedente_de = "";
      this.prod_ind_transporte_mercaderia_destino_a = "";
      this.prod_ind_transporte_mercaderia_num_factura = "";
      this.prod_ind_transporte_mercaderia_fecha_factura = "";
      this.prod_ind_transporte_mercaderia_fecha_despacho = "";
      this.prod_ind_transporte_mercaderia_vigencia_desde = "";
      this.prod_ind_transporte_mercaderia_vigencia_hasta = "";
      this.items_prod_ind_transporte_mercaderia = [];

      this.flag_editar_transporte_mercaderia = false;
    },
    limpiarProductoIndividualResponsabilidadCivil() {
      this.prod_ind_id_responsabilidad_civil = 0;
      this.prod_ind_responsabilidad_civil_id_individuales = 0;
      this.prod_ind_responsabilidad_civil_n_riesgo = 0;
      this.prod_ind_responsabilidad_civil_direccion = "";
    },
    valoresMaxVitalicio() {
      if (this.ramo_seguro.descripcion == "GASTOS MEDICOS") {
        this.prod_ind_gastomedico_max_vit_contratado = this.suma_asegurada;
        this.prod_ind_gastomedico_max_vit_vigente = this.suma_asegurada;
      }
    },
    async alertaMensaje(texto, tipo_alerta) {
      this.$notify({
        group: "files",
        title: texto,
        type: tipo_alerta,
        speed: 1000,
      });
    },
    //API ONDE DRIVE
    async obtenerNuevoToken() {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const refresh_token = await apiLocal.metodos.getItemObject(
        "refresh_token",
        credenciales
      ).valor;

      await apiLocal.metodos
        .getTokenRefresh(refresh_token)
        .then(async (response) => {
          await apiLocal.metodos.actualizarCredenciales(
            response.data,
            credenciales
          );
        });
      return;
    },
  },
};
</script>

<style scoped>
.lineaMargen {
  border: 1px solid rgb(95, 92, 92);
  border-radius: 5px;
  padding: 5px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 3px;
}
.lineaMargenCliente {
  border: 1px solid rgb(95, 92, 92);
  border-radius: 5px;
  padding: 5px;
  margin-top: 3px;
}
</style>